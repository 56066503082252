<template>
    <section>
            <div 
                v-if="data.has_select_filter" 
                class="analytics-button-container" 
                style="margin-bottom:10px;"
            >
               <multiselect-filter-component
                    v-if="data.has_select_filter_department"
                    placeholder="Any Department"
                    filter_name="Departments"
                    :options="filters_options.departments"
                    @filter-data="filterData"
                />
                <multiselect-filter-component
                    v-if="data.has_select_filter_focus_area"
                    placeholder="Any Focus Area"
                    filter_name="Focus Area"
                    :options="filters_options.focus_area"
                    @filter-data="filterData"
                />
                <multiselect-filter-component
                    v-if="data.has_select_filter_identities"
                    placeholder="Any Identity"
                    filter_name="Identities"
                    :options="filters_options.identities"
                    @filter-data="filterData"
                />
            </div>
            <div v-if="isLoading">
                <loading-spinner />
            </div>

            <analytics-card-table 
                v-if="!isLoading" 
                :firstColumn="firstColumn" 
                :otherColumns="otherColumns" 
                :data="table_body_data"
            />
    </section>
</template>
<script>
import MultiselectFilterComponent  from '../../form/components/MultiselectFilterComponent.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import AnalyticsService from '../services/AnalyticsService';
import AnalyticsCardTable from './AnalyticsCardTable.vue';


export default {
    name: "analytics-card-table-fetch-data",

    components: { 
        MultiselectFilterComponent,
        AnalyticsCardTable, 
        LoadingSpinner 
    },

    props: {
        keys: String,
        filtersData : {
            start_date: Date,
            end_date: Date,
            keys: String,
        },
        params_mentor: {
            type: Object,
            default: function() {
                return {};
            },
        },
        filters_options:{},
        options:[],
        data:{},
    },

    data() {
        return{
            table_body_data : null,
            firstColumn : '',
            otherColumns : null,
            isLoading : true,
            service: new AnalyticsService(),
            params: {
                type: Object,
                default: function() {
                    return {};
                },
            },
            selectedOption: null,
            restart_data: false,
            dic: {},
        }
    },
    async created() {
        if(this.keys == undefined) return;
        if(this.params_mentor){
            this.params = {...this.params_mentor}; 
        }
        this.fetch();
    },
    methods: {
        async get_data(params){
            let data = await this.service.getTableData(this.keys, params);
            this.firstColumn = data.table.firstColumn;
            this.otherColumns = data.table.otherColumns;
            this.table_body_data = data.body_data;
            this.isLoading = false;
        },
        fetch(){
            this.get_data(this.params);
        },
        filterData(value, name){
            if(name === 'Departments'){
                this.params = {
                    ...this.params,
                     departments: value.map(v=> encodeURIComponent(v.name))
                }
            }
            else if(name === 'Focus Area'){
                 this.params = {
                    ...this.params,
                    focus_areas: value.map(v=> encodeURIComponent(v.name))
                }
            }
            else if(name === 'Identities'){
                 this.params = {
                    ...this.params,
                    identities: value.map(v=> encodeURIComponent(v.name))
                }
            }
             this.isLoading = true;
            this.get_data(this.params);
        }
    },
    watch: {
        filtersData: async function(newVal, oldVal) {
            let params= {
                ...this.params,
                start_date: this.filtersData.start_date,
                end_date: this.filtersData.end_date
            }
            this.isLoading = true;
            this.params = params;
            this.fetch();
        },
        keys: function(newVal, oldVal){
            this.isLoading = true;
            this.get_data(this.params)
        }
    }

}
</script>
