<template>
  <section class="partner-tenant-home-opportunities-container">
    <div class="partner-tenant-home-opportunities-image">
    </div>
    <div class="partner-tenant-home-opportunities-text-container">
      <h2 class="partner-tenant-home-opportunities-title">
        Our Opportunities
      </h2>
      <p class="partner-tenant-home-opportunities-description">
        Discover a world of opportunities. Find Programs abroad,
        internships &amp; jobs, graduate programs, scholarships and events —
        all in one place.
      </p>
      <div class="partner-tenant-home-basic-info-button-container">
        <button
          class="partner-tenant-home-basic-info-button-outlined"
          aria-label="Go to sign up page"
          @click="redirectTo('/accounts/register')"
        >
          Join Our Community
        </button>
      </div>
    </div>

  </section>
</template>

<script>
import SliderDots from './SliderDots.vue';

export default {
  name: 'partner-tenant-opportunities',
  components: {
    SliderDots,
  },
  data() {
    return {
      pillSelected: 'events',
    }
  },
  methods: {
    changeSelectedPill(selection) {
      this.pillSelected = selection;
    },
    redirectTo(url) {
      window.location.href = url;
    }
  },
}
</script>
