<template>
    <div class="eu-search">
        <form action="" @submit.prevent="fetchData()" class="eu-search-form" style="margin-top: 50px" role="search">
            <input
                v-model="search_input"
                onfocus="this.placeholder=''"
                onblur="this.placeholder='Search'"
                name="q"
                type="text"
                :placeholder="placeholder"
                class="eu-search-input"
            />
            <button
                class="eu-search-btn-icon"
                :class="displaySettings ? 'not-last courses': ''"
                aria-label="search">
                <i class="eu-search-icon fas fa-search"></i>
            </button>
            <span v-show="displaySettings" class="eu-courses-filters">
                <span
                    tabindex="0"
                    aria-label="Change to grid view"
                    role="button"
                    @click="changeLayout('grid')"
                    @keyup.enter="changeLayout('grid')"
                    :style="is_active_layout_grid ? '' : 'cursor:pointer'"
                >
                    <i
                        class="eu-courses-table-icon fas fa-grid-2"
                        :class="is_active_layout_grid ? 'active': 'disabled'"
                    ></i>
                </span>
                <span
                    aria-label="Change to list view"
                    role="button"
                    tabindex="0"
                    @click="changeLayout('list')"
                    @keyup.enter="changeLayout('list')"
                    :style="is_active_layout_grid ? 'cursor:pointer' : ''"
                >
                    <i
                        class="eu-courses-list-icon fas fa-list"
                        :class="!is_active_layout_grid ? 'active': 'disabled'"
                    ></i>
                </span>
            </span>
        </form>
    </div>
</template>
<script lang="ts">
export default {
components: {},
    name: 'search-component',
    props: [
          'placeholder',
          'displaySettings',
          'currentLayout'
      ],
    data() {
        return {
        search_input: '',
        is_active_layout_grid: true

        }
    },
    async mounted() {
        this.is_active_layout_grid = this.currentLayout === 'grid' ? true: false;
        this.changeLayout(this.currentLayout, true);

    },
    methods: {
        fetchData(){
            this.$emit('search-data', this.search_input);
        },
        changeLayout(targetLayout: string, init=false) {
            if(!init) this.is_active_layout_grid = !this.is_active_layout_grid;
            this.$emit('change-layout', targetLayout);
        },
    }
}

</script>
<style scoped>
    .active{
        color: #4c55a7;
        pointer-events: none;
    }
    .disabled{
        color: #707070;
        pointer-events: none;
    }
</style>
