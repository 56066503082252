export const getNavigationItems = (canManage: boolean) => {
  const navItems = [
    {
      label: 'Browse Guides',
      routeName: 'browse-guides',
      isRedirectUrl: false,
      redirectionUrl: '',
    },
    {
      label: 'Saved Items',
      routeName: 'saved-guides',
      isRedirectUrl: false,
      redirectionUrl: '',
    },
  ];

  if (canManage) {
    navItems.splice(1, 0, {
      label: 'Manage Guides',
      routeName: 'manage-guides',
      isRedirectUrl: false,
      redirectionUrl: '',
    });
  }

  return navItems;
};
