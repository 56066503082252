<template>
    <Modal :isActiveProp="isModalActive">
        <div class="call-modal-container">
            <h3>Incoming Call</h3>
            <img :src="profilePicture" alt="caller avatar" class="profile-picture">
            <h4 style="margin-bottom: 0;">{{ userName }}</h4>
            <p class="getting-call-text">is calling you.</p>

            <div class="switch-container">
                <p class="label-text">Video</p>
                <toggle-button
                    id="video-toggle"
                    :value="isCameraEnabled"
                    :sync="true"
                    :color="{checked: '#4c55a7', unchecked: '#b4b4b4'}"
                    :labels="{checked: 'On', unchecked: 'Off'}"
                    @change="videoSwitchHandler"
                />

                <p class="label-text">Mic</p>
                <toggle-button
                    id="mic-toggle"
                    :value="isMicEnabled"
                    :sync="true"
                    :color="{checked: '#4c55a7', unchecked: '#b4b4b4'}"
                    :labels="{checked: 'On', unchecked: 'Off'}"
                    @change="audioSwitchHandler"
                />
            </div>

            <div class="receive-call-btn-container">
                <div>
                    <button
                        @click="acceptCall"
                        class="accept-btn"
                    >
                        <i class="fas fa-phone-alt fa-lg"></i>
                    </button>
                    <p class="accept-text">Accept</p>
                </div>

                <div>
                    <button
                        @click="declineCall"
                        class="decline-btn"
                    >
                        <i class="fas fa-times fa-lg"></i>
                    </button>
                    <p class="decline-text">Decline</p>
                </div>
            </div>

            <p class="info-text">Video calls have a 30-minute limit.</p>
        </div>
    </Modal>
</template>
<script lang="ts">
import Modal from '../../../components/ModalExtended.vue';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
    components: { Modal, ToggleButton, },

    name: 'receive-call-modal',

    props: ['isModalActive', 'userName', 'profilePicture', 'isMicEnabled', 'isCameraEnabled',],

    data() {
        return {};
    },

    methods: {
        closeReceiveCallModal: function () {
            this.$emit('close-receive-call-modal');
        },

        acceptCall: function () {
            this.$emit('accept-call');
        },

        declineCall: function () {
            this.$emit('decline-call');
        },

        videoSwitchHandler: function(valueObject: {value: Boolean, tag: any, srcEvent: Event}) {
            this.$emit('video-toggled', valueObject.value);
        },

        audioSwitchHandler: function(valueObject: {value: Boolean, tag: any, srcEvent: Event}) {
            this.$emit('audio-toggled', valueObject.value);
        },
    },
}
</script>
