<template>
  <div class="dabi-search-container">
    <input-base
      type="text"
      :aria-label="ariaLabel"
      :placeholder="placeholder"
      :id="id"
      @keyupEnter="emitSearch"
      wrapperClass="dabi-search-input"
      v-model="searchText"
    />
    <button @click="emitSearch" aria-label="Search" class="dabi-search-button">
      <i class="fa-regular fa-magnifying-glass"></i>
    </button>
  </div>
</template>
<script lang="ts">
import InputBase from './InputBase.vue';

export default {
  name: 'search-base',
  components: {
    InputBase,
  },
  props: ['ariaLabel', 'placeholder', 'id'],
  data() {
    return {
      searchText: '',
    };
  },
  methods: {
    emitSearch() {
      this.$emit('search', this.searchText);
    },
    clearSearchInput() {
      this.searchText = '';
    },
  },
};
</script>
