<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <h1 class="eu-simple-title">Edit Course</h1>
      <div v-if="!isLoading" class="eu-form-container inup-form-container eu-form-page">
        <form class="eu-form inup-form" @submit.prevent="create()" id="course-form" enctype="multipart/form-data">
          <div class="eu-form-section inup-form-section">
            <div style="margin-bottom: 15px">
              <h2>Course Photo</h2>
            </div>
            <img :src="course.course_card_image_url" alt="Course image" class="thumbnail-image" />
          </div>
          <div class="eu-form-section inup-form-section">
            <div class="eu-form-element-container-no-bot-margin">
              <input class="inup-input disabled" type="text" name="title" disabled v-model="course.name" />
              <div class="inup-form-label-container">
                <label class="disabled-label" for="title">Course Title</label>
              </div>
            </div>
            <div class="eu-form-description-container">
              <textarea
                name="description"
                cols="40"
                rows="10"
                class="inup-textarea-input vertical disabled"
                disabled
                v-model="course.description"
              >
              </textarea>
              <div class="inup-textarea-label-container">
                <label class="disabled-label" for="description">Description</label>
              </div>
            </div>
          </div>
          <div class="eu-form-section inup-form-section">
            <h2 class="header-two-margin-bot">Course details</h2>
            <div class="eu-form-element-container" style="margin-top: 0">
              <multiselect-component
                :options="focus_areas"
                :preselected="preselected_focus_areas"
                @handle-change="handleChange"
                :limit="5"
                name="focus_areas"
              />
              <div class="inup-form-label-container">
                <label for="type">Focus Area</label>
              </div>
            </div>
            <div class="eu-form-element-container" style="margin-top: 0">
              <multiselect-component
                :options="identities"
                :preselected="preselected_identities"
                @handle-change="handleChange"
                :limit="5"
                name="identities"
              />
              <div class="inup-form-label-container">
                <label for="type">Identity Groups</label>
              </div>
            </div>
            <div class="eu-form-element-container" style="margin-top: 0">
              <multiselect-component
                :options="audiences"
                :preselected="preselected_audiences"
                @handle-change="handleChange"
                :limit="5"
                name="audiences"
              />
              <div class="inup-form-label-container">
                <label for="type">Audiences</label>
              </div>
            </div>
          </div>
          <div class="eu-form-section inup-form-section feature-section">
            <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Private</h2>
            <div style="display: flex">
              <div class="selection-tabs-container">
                <span
                  tabindex="0"
                  role="button"
                  aria-label="Public"
                  class="selection-tab"
                  :class="!course.is_private ? `active` : ``"
                  @click="isSelected(false, 'is_private')"
                  @keyup.enter="isSelected(false, 'is_private')"
                >
                  Public
                </span>
                <span
                  tabindex="0"
                  aria-label="Private"
                  role="button"
                  class="selection-tab"
                  :class="course.is_private ? `active` : ``"
                  @click="isSelected(true, 'is_private')"
                  @keyup.enter="isSelected(true, 'is_private')"
                >
                  Private
                </span>
              </div>
              <span class="tooltip-container" style="align-self: center">
                <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
                <span class="tooltip medium right" style="left: 39%; top: 100%">
                  The Public course will be visible to everyone!
                </span>
              </span>
            </div>
          </div>
          <div v-if="course.is_private" class="eu-form-section inup-form-section">
            <h2 class="header-two-margin-bot">Course contacts</h2>
            <div
              @click="openPrivateUsersSelectModal"
              @keyup.enter="openPrivateUsersSelectModal"
              class="eu-form-element-container"
              style="margin-top: 0"
            >
              <multiselect
                id="course-private-list-multiselect"
                v-model="selected_contact"
                :options="all_users"
                :multiple="true"
                :limit="20"
                :limitText="limitText"
                label="name"
                track-by="name"
                :close-on-select="false"
                placeholder=""
                aria-label="Open dialog for selecting private users."
                @click.self.prevent
              >
              </multiselect>
              <div class="inup-form-label-container">
                <label for="group_private_list">Private Members</label>
              </div>
            </div>
          </div>
          <div class="inup-button-container">
            <input type="submit" value="Save" class="eu-btn-primary-small" />
            <router-link class="eu-btn-secondary-small" to="/courses/manage"> Cancel </router-link>
          </div>
        </form>

        <objects-select-modal
          :isModalActive="isUsersSelectModalActive"
          @close-modal="closeModalFunction"
          title="Select course contact"
          actionName="Select"
          objectName="User"
          :isLoading="false"
          :objectsList="contacts"
          :page="selectUserPage"
          :totalPageNumber="selectUserTotalPageNumber"
          :currentPage="selectUserCurrentPage"
          :showIcon="false"
          :preselectedObjects="selectedUsers"
          :filterGroups="contactCourse_list"
        />
        <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
      </div>
      <div v-else>
        <loading-spinner wrapperClass="wrapper" />
      </div>
    </div>
  </div>
</template>
<script>
import MultiselectComponent from "../../form/components/MultiselectComponent.vue";
import ObjectsSelectModal from "../../accounts/components/modals/ObjectsSelectModal.vue";
import Multiselect from "vue-multiselect";
import Notification from "../../components/Notification.vue";
import CoursesService from "../services/CoursesService";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import * as Cookies from "js-cookie";

export default {
  components: {
    MultiselectComponent,
    Multiselect,
    ObjectsSelectModal,
    Notification,
    LoadingSpinner,
  },
  name: "edit-course",
  props: [],
  data() {
    return {
      course: {
        name: "",
        description: "",
        course_card_image_url: "",
        focus_areas: [],
        identities: [],
        course_contacts: [],
        is_private: false,
      },
      preselected_focus_areas: [],
      preselected_identities: [],
      preselected_audiences: [],
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      service: new CoursesService(),
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      isUsersSelectModalActive: false,
      selectedUsers: [],
      closeModalFunction: this.closePrivateUsersSelectModal,
      usersList: [],
      contactCourse_list: [],
      selectUserPage: 1,
      selectUserTotalPageNumber: 1,
      selectUserCurrentPage: 1,
      course_contacts_list: [],
      selected_contact: [],
      contacts: [],
      audiences: [],
      identities: [],
      focus_areas: [],
      all_users: [],
      isLoading: false,
    };
  },
  async created() {
    this.isLoading = true;
    const courseId = this.$route.path.split("/").pop();
    const res = await this.service.getPredataCourseEdit(courseId);
    if (res.course) {
      this.course = res.course;
      this.focus_areas = res.focus_areas;
      this.identities = res.identities;
      this.audiences = res.audiences;
      this.contacts = res.contacts;
      this.all_users = res.all_users;
      this.preselected_focus_areas = this.focus_areas.filter((e) => this.course.focus_areas.includes(e.value));
      this.preselected_identities = this.identities.filter((e) => this.course.identities.includes(e.value));
      this.preselected_audiences = this.audiences.filter((e) => this.course.audiences.includes(e.value));
      this.selectedUsers = this.contacts.filter((e) => this.course.course_contacts.includes(e.id));
      this.selected_contact = this.all_users.filter((e) => this.course.course_contacts.includes(e.id));
    }
    this.isLoading = false;
  },
  methods: {
    handleChange(value, name) {
      this.course[name] = value.map((v) => v.value);
    },
    isSelected(selected, key) {
      this.course[key] = selected;
    },
    async create() {
      this.course.course_contacts = this.selected_contact.map((x) => x.id);
      var res = await this.service.update(this.course, this.headers);
      if (res) {
        this.$router.push("/courses/manage");
        return;
      }
      this.showNotification({ msg: "Server Error", isError: true, icon: "fad fa-exclamation-circle" });
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    limitText(count) {
      return `+ ${count} more`;
    },
    openPrivateUsersSelectModal() {
      this.closeModalFunction = this.closePrivateUsersSelectModal;
      this.isUsersSelectModalActive = true;
      focusElement("objects-select-modal-title");
    },
    closePrivateUsersSelectModal(closeInfo) {
      if (!closeInfo["canceled"]) {
        this.selectedUsers = closeInfo["selectedObjects"];
        this.selected_contact = this.selectedUsers;
      }
      this.isUsersSelectModalActive = false;
    },
  },
};
</script>
<style scoped>
.disabled {
  background-color: #dadada;
}

.disabled-label {
  background-color: #dadada;
  border-radius: 8px;
}
</style>
