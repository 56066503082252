<template>
  <section>
    <meetings-table
      v-if="view === 'list' && !isLoading"
      :meetings="meetings"
      :calendarDropdownOpen="calendarDropdownOpen"
      :loggedUserId="loggedUserId"
      :icons="icons"
      :isPartnerTenant="isPartnerTenant()"
      :ids_favorite_mentors="ids_favorite_mentors"
      @handle-favorite="handleFavoritesMentors"
      @show-burger="showBurger"
      @open-modal="openModal"
      @open-cancelation-modal="openCancelationModal"
      @open-follow-up-modal="openFollowUpModal"
      @toggle-calendar-dropdown="toggleCalendarDropdown"
      @open-external-calendar="openExternalCalendar"
      @close-burgers="closeBurgers"
      @accept-meeting="handleAcceptMeeting"
      @deny-meeting="handleDenyMeeting"
      @archive-meeting="archiveMeeting"
    />

    <meetings-grid
      v-if="view === 'grid' && !isLoading"
      :meetings="meetings"
      :isPartnerTenant="isPartnerTenant()"
      :calendarDropdownOpen="calendarDropdownOpen"
      :loggedUserId="loggedUserId"
      :icons="icons"
      :ids_favorite_mentors="ids_favorite_mentors"
      @handle-favorite="handleFavoritesMentors"
      @show-burger="showBurger"
      @open-modal="openModal"
      @open-follow-up-modal="openFollowUpModal"
      @open-cancelation-modal="openCancelationModal"
      @toggle-calendar-dropdown="toggleCalendarDropdown"
      @open-external-calendar="openExternalCalendar"
      @close-burgers="closeBurgers"
      @accept-meeting="handleAcceptMeeting"
      @deny-meeting="handleDenyMeeting"
      @archive-meeting="archiveMeeting"
    />

    <div v-if="isLoading">
      <loading-spinner />
    </div>

    <div class="accounts-view-section">
      <Modal :isActiveProp="isCancelationModalActive" @close="closeCancelationModal">
        <div v-if="cancelation_modal_state" class="appointment-cancel-modal">
          <div class="appointment-cancel-modal-header">
            <p tabindex="0" role="heading" id="appointment_cancelatio_modal_title" class="appointment-cancel-modal-header-title">
              Are you sure you want to cancel this appointment?
            </p>
          </div>
          <div class="appointment-cancel-modal-body">
            <div class="appointment-cancel-modal-body-meeting">
              <p class="appointment-cancel-modal-body-meeting-date">
                <i class="fas fa-calendar-day"></i>
                <span class="meeting-date"
                  ><b>{{ parseLongDate(selectedMeeting.meeting_hours.date) }} </b></span
                >
              </p>
              <p class="appointment-cancel-modal-body-meeting-time">
                <i class="fas fa-clock"></i>
                <span class="info-text">{{
                  parseTime({ startTime: selectedMeeting.meeting_hours.startTime, endTime: selectedMeeting.meeting_hours.endTime })
                }}</span>
              </p>
              <p class="appointment-cancel-modal-body-meeting-persone">
                <i class="fas fa-user"></i>
                <span class="info-text">{{
                  selectedMeeting.is_mentor
                    ? selectedMeeting.requester_obj.first_name + ' ' + selectedMeeting.requester_obj.last_name
                    : selectedMeeting.chosen_mentor.first_name + ' ' + selectedMeeting.chosen_mentor.last_name
                }}</span>
              </p>
            </div>
          </div>
          <div class="row" style="width: 100%">
            <div class="column">
              <button
                @click="change_cancelation_modal_state"
                class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                style="width: fit-content; height: fit-content"
              >
                Continue
              </button>
              <button
                @click="closeCancelationModal"
                class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                style="width: fit-content; height: fit-content"
              >
                Back
              </button>
            </div>
            <div class="eu-featured-event-carousel-dots-container">
              <span class="eu-featured-event-carousel-dot eu-featured-event-carousel-dot-selected"></span>
              <span class="eu-featured-event-carousel-dot"></span>
              <span style="margin-right:4px">1</span>
              <span style="margin-right:4px"> of </span>
              <span>2</span>
            </div>
          </div>
        </div>
        <div v-else class="appointment-cancel-modal-state-second">
          <div class="appointment-cancel-modal">
            <div class="appointment-cancel-modal-header">
              <p tabindex="0" id="let_know_reason" class="appointment-cancel-modal-header-title">
                Let {{ selectedMeeting.is_mentor ? selectedMeeting.requester_obj.first_name : selectedMeeting.chosen_mentor.first_name }} know why you canceled.
              </p>
            </div>
            <div class="appointment-cancel-modal-body">
              <div class="appointment-cancel-modal-body-meeting">
                <select id="cancelation_note" class="eu-select" v-model="cancelation_note">
                  <option value="Scheduling conflict"> Scheduling conflict</option>
                  <option value="Sick/Ill"> Sick/Ill</option>
                  <option value="Questions/Issues Resolved"> Questions/Issues Resolved</option>
                  <option value="Other"> Other</option>
                </select>
                <i class="fal fa-angle-down eu-select-arrow"></i>
                <p v-show="error_text_showing" class="appointment-cancel-modal-body-meeting-text-error" style="margin-top:-7px">
                  You should select cancelation reason
                </p>
              </div>
              <div v-if="cancelation_note === 'Other'" class="appointment-cancel-modal-body-meeting">
                <input
                  id="cancelation_note_other"
                  v-model="cancelation_note_other"
                  type="text"
                  placeholder="Cancelation reason"
                  onfocus="this.placeholder=''"
                  onblur="this.placeholder='Cancelation reason'"
                  class="da-components-forms-input large js-da-login-email footer-input"
                  maxlength="150"
                  style="margin-left:5px"
                />
              </div>
            </div>
            <div class="row" style="width: 100%">
              <div class="column">
                <button
                  @click="cancelMeeting"
                  class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                  style="width: fit-content; height: fit-content"
                >
                  Cancel Appointment
                </button>
                <button
                  @click="closeCancelationModal"
                  class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                  style="width: fit-content; height: fit-content"
                >
                  Back
                </button>
              </div>
              <div class="eu-featured-event-carousel-dots-container">
                <span class="eu-featured-event-carousel-dot"></span>
                <span class="eu-featured-event-carousel-dot eu-featured-event-carousel-dot-selected"></span>
                <span style="margin-right:4px">2</span>
                <span style="margin-right:4px"> of </span>
                <span>2</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal v-if="isFollowUpModalActive" :isActiveProp="true" @close="closeFollowUpModal">
        <div class="appointment-cancel-modal" style="height: auto">
          <div class="appointment-cancel-modal-header">
            <p tabindex="0" role="heading" id="appointment_cancelatio_modal_title" class="appointment-cancel-modal-header-title" style="font-size: 18px">
              Send a follow up meeting request
            </p>
          </div>
          <div class="appointment-cancel-modal-body">
            Choose a department contact:
             <div class="appointment-cancel-modal-body-meeting" style="margin: 0 auto; text-align: center">
                <select id="cancelation_note" class="eu-select" v-model="followUpContact">
                  <option
                        v-for="us in selectedMeeting.chosen_mentor.department.department_contacts"
                        :key="us.first_name + ' ' + us.last_name"
                        :value="us.email"
                    >
                        {{ us.first_name + ' ' + us.last_name }}
                    </option>
                </select>
                <i class="fal fa-angle-down eu-select-arrow"></i>
              </div>
              <p style="margin-top: 16px; margin-bottom:8px">Reason for follow up meeting:</p>
              <textarea rows="3"
              style="border-radius: 8px; border: 2px solid #949494;resize: none;padding: 5px"
              v-model="followUpReason"
            >
            </textarea>
            <p v-if="followUpError" style="color: #f16b4c;margin: 12px;">Error: You should fill in all required fields</p>
          </div>
          <div class="appointment-cancel-modal-body">
            <div class="appointment-cancel-modal-body-meeting">
              <p class="appointment-cancel-modal-body-meeting-date">
                <i class="fas fa-calendar-day"></i>
                <span class="meeting-date"
                  ><b>{{ parseLongDate(selectedMeeting.meeting_hours.date) }} </b></span
                >
              </p>
              <p class="appointment-cancel-modal-body-meeting-time">
                <i class="fas fa-clock"></i>
                <span class="info-text">{{
                  parseTime({ startTime: selectedMeeting.meeting_hours.startTime, endTime: selectedMeeting.meeting_hours.endTime })
                }}</span>
              </p>
              <p class="appointment-cancel-modal-body-meeting-persone">
                <i class="fas fa-user"></i>
                <span class="info-text">{{
                  selectedMeeting.is_mentor
                    ? selectedMeeting.requester_obj.first_name + ' ' + selectedMeeting.requester_obj.last_name
                    : selectedMeeting.chosen_mentor.first_name + ' ' + selectedMeeting.chosen_mentor.last_name
                }}</span>
              </p>
            </div>
          </div>
          <div class="row" style="width: 100%">
            <div class="column">
              <button
                @click="sendFollowUp"
                class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                style="width: fit-content; height: fit-content"
              >
                Send Follow Up Request
              </button>
              <button
                @click="closeFollowUpModal"
                class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                style="width: fit-content; height: fit-content"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <profile-notification-modal
        :isModalActive="isModalActive"
        :user="selectedMeeting.is_mentor ? selectedMeeting.requester_obj : selectedMeeting.chosen_mentor"
        :department="selectedMeeting.is_mentor ? '' : selectedMeeting.department && selectedMeeting.department.name"
        modal_title_id="mentor_connect_appointment_details_modal"
        :meetingMsg="
          !selectedMeeting.is_mentor && selectedMeeting.mentors_answer
            ? { title: `Message from mentor: `, text: selectedMeeting.mentors_answer }
            : selectedMeeting.is_mentor && selectedMeeting.mentors_answer
            ? { title: `Message from student: `, text: selectedMeeting.description }
            : { title: 'Meeting Description: ', text: selectedMeeting.description }
        "
        title="Appointment Details"
        :cards="[
          [
            { title: 'Status: ', text: `${selectedMeeting.status}` },
            { title: 'Canceled note:', text: selectedMeeting.cancelation_note ? selectedMeeting.cancelation_note : false },
            { title: 'Date: ', text: `${parseLongDate(selectedMeeting.meeting_hours.date)}`, bold: true },
            { title: 'Time: ', text: parseTime({ startTime: selectedMeeting.meeting_hours.startTime, endTime: selectedMeeting.meeting_hours.endTime }) },
            { title: 'Meeting Type: ', text: capitalizeFirstLetter(selectedMeeting.meeting_type) },
            { title: 'Focus Area: ', text: selectedMeeting.focus_area ? selectedMeeting.focus_area.name : 'Any' },
            { title: 'Mentor Group: ', text: selectedMeeting.mentor_group ? selectedMeeting.mentor_group.name : 'Any' }
          ]
        ]"
        @onClose="closeModal"
      />

      <meeting-accept-modal
        :isModalActive="isAcceptModalActive"
        :isAccept="isAcceptedMeeting"
        :title="isAcceptedMeeting ? 'Accept Appointment Request' : 'Deny Appointment Request'"
        :isAcceptedMeeting="isAcceptedMeeting"
        :meeting="selectedMeeting"
        @onClose="closeAcceptModal"
        @answerRequest="answerMeeting"
      />
    </div>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>
<script lang="ts">
import Modal from '../../components/ModalExtended.vue';
import MeetingsService from '../services/MeetingsService';
import * as Cookies from 'js-cookie';
import makeUrls, { TCalendarEvent } from '../utils/AddEventToCalendar';
import handleOutsideClick from '../../directives/handleOutsideClick';
import ProfileNotificationModal from '../../accounts/components/ProfileNotificationModal.vue';
import MeetingsTable from './MeetingDataSections/MeetingsTable.vue';
import MeetingsGrid from './MeetingDataSections/MeetingsGrid.vue';
import MeetingAcceptModal from './MeetingAcceptModal.vue';
import { parseLongDate, parseTime } from '../utils/DateParser';
import { capitalizeFirstLetter } from '../utils/common';
import '../utils/DateParser';
import { focusElement, focus_burger_menu } from '../../utils/CustomUtils';
import AccountsService from '../../accounts/services/AccountsService';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import Notification from '../../components/Notification.vue';
import { isPartnerTenant } from "../../utils/CustomUtils";

export default {
  components: { Modal, ProfileNotificationModal, MeetingsTable, MeetingsGrid, MeetingAcceptModal, LoadingSpinner, Notification },

  directives: { closable: handleOutsideClick },

  name: 'meetings-data',

  props: ['meetings', 'view', 'isMentor', 'loggedUserId', 'isLoading'],

  data() {
    return {
      isModalActive: false,
      isCancelationModalActive: false,
      isAcceptModalActive: false,
      isFollowUpModalActive: false,

      isAcceptedMeeting: null,

      notification: { msg: '', isError: false, icon: null },
      isNotificationActive: false,

      selectedMeeting: {
        requester_obj: { profile_pic: null },
        chosen_mentor: { profile_pic: null, department: { department_contacts: []} },
        department: { name: null },
        focus_area: { name: null },
        meeting_hours: { startTime: null, endTime: null }
      },

      lastOpenedBurger: null,
      calendarDropdownOpen: false,

      icons: {
        active: '',
        past: 'fas fa-flag-checkered',
        pending: 'fas fa-spinner',
        canceled: 'fas fa-xmark',
        expired: 'fas fa-hourglass-end',
        urgent: 'fas fa-exclamation-triangle',
        confirmed: 'fas fa-check-circle'
      },
      followUpContact: null,
      followUpReason: null,
      followUpError: null,


      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json'
      },
      cancelation_modal_state: true,
      cancelation_note: null,
      cancelation_note_other: null,
      error_text_showing: false,
      ids_favorite_mentors: []
    };
  },

  async created() {
    this.service = new MeetingsService();
    this.accountsService = new AccountsService();
    this.ids_favorite_mentors = await this.accountsService.getFavoriteMentors({ flag: 'ids' });
  },

  methods: {
    parseLongDate,
    parseTime,
    capitalizeFirstLetter,
    isPartnerTenant,
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },

    openExternalCalendar(cal, event) {
      const eventTitle = `MentorConnect: ${event.is_mentor ? event.requester : event.chosen_mentor_fullname}`;
      let eventDetails = event.description && event.description !== null ? event.description : 'Includifi MentorConnect Meeting';
      const startTime = event.meeting_hours.startTime;
      const endTime = event.meeting_hours.endTime;

      eventDetails += '\\n\\n Meeting link: https://' + window.location.host + '/meetings/' + event.meeting_type + '/' + event.id;

      const calEvent: TCalendarEvent = {
        name: eventTitle,
        details: eventDetails,
        location: '',
        startsAt: startTime,
        endsAt: endTime
      };
      const eventUrls = makeUrls(calEvent);

      if (cal === 'ics') {
        this.handleUrlDownload(eventUrls.ics, isPartnerTenant() ? 'beglobalii_meeting':'includifi_meeting');
      } else {
        window.open(eventUrls[cal]);
      }
    },

    async cancelMeeting() {
      if (this.cancelation_note == null) {
        this.error_text_showing = true;
        document.getElementById('cancelation_note').style.border = '1px solid red';
        return;
      }

      if (this.cancelation_note === 'Other' && this.cancelation_note_other !== null && this.cancelation_note_other !== '') {
        this.cancelation_note = this.cancelation_note_other;
      }

      if (this.cancelation_note === 'Other' && !this.cancelation_note_other) {
        this.error_text_showing = false;
        document.getElementById('cancelation_note').style.border = '1px solid #dadada';
        document.getElementById('cancelation_note_other').style.borderColor = 'red';
        return;
      }

      const res = await this.service.cancelMeeting({ meetingId: this.selectedMeeting.id, cancelation_note: this.cancelation_note }, this.headers);
      if (res.status === 'success') {
        this.selectedMeeting.status = 'canceled';
        this.cancelation_note_other = null;
        this.closeCancelationModal();
      }
    },

    async answerMeeting(meetingId, msg, isAccepted, meetingUrl) {
      const res = await this.service.acceptMeetingRequest(
        {
          message: msg,
          meeting_id: meetingId,
          meeting_url: meetingUrl,
          timezone_offset: -new Date().getTimezoneOffset(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          status: isAccepted ? 'active' : 'refused'
        },
        this.headers
      );
      if (res.status === 'success') {
        this.selectedMeeting.status = isAccepted ? 'confirmed' : 'refused';
        focus_burger_menu(this.selectedMeeting, this.view);
        this.closeAcceptModal();
      }
    },

    handleAcceptMeeting(meeting) {
      this.isAcceptedMeeting = true;
      focusElement('appointment-accept-deny-modal');
      this.openAcceptModal(meeting);
    },

    handleDenyMeeting(meeting) {
      focusElement('appointment-accept-deny-modal');
      this.isAcceptedMeeting = false;
      this.openAcceptModal(meeting);
    },

    openAcceptModal(meeting) {
      this.selectedMeeting = meeting;
      this.closeBurgers();
      this.isAcceptModalActive = true;
    },

    handleUrlDownload(url, filename) {
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', filename);
      a.click();
    },

    showBurger(el, view) {
      const burgerEl = document.querySelector(`#${view}${el.id}${el.timestamp}`);
      if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl) this.lastOpenedBurger.classList.remove('eu-resource-burger-active');
      burgerEl.classList.toggle('eu-resource-burger-active');
      this.calendarDropdownOpen = false;
      setTimeout(() => {
        this.lastOpenedBurger = burgerEl;
      }, 0);
    },

    closeBurgers() {
      this.lastOpenedBurger && this.lastOpenedBurger.classList.remove('eu-resource-burger-active');
    },

    toggleCalendarDropdown() {
      this.calendarDropdownOpen = !this.calendarDropdownOpen;
    },

    openModal(meeting) {
      this.selectedMeeting = meeting;
      this.isModalActive = true;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'none';
      focusElement('mentor_connect_appointment_details_modal');
    },

    openFollowUpModal(meeting) {
      this.selectedMeeting = meeting;
      this.isFollowUpModalActive = true;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'none';
      focusElement('mentor_connect_follow_up _modal');
    },

    openCancelationModal(meeting) {
      this.selectedMeeting = meeting;
      this.isCancelationModalActive = true;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      focusElement('appointment_cancelatio_modal_title');
      sidebar.style.display = 'none';
    },

    async sendFollowUp() {
      if(!this.followUpReason || !this.followUpContact) {
        this.followUpError = true;
      } else {
        const res = await this.service.sendFollowUp({
          timezone_offset: -new Date().getTimezoneOffset(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          followUpReason: this.followUpReason,
          followUpContact: this.followUpContact,
          meeting: this.selectedMeeting.id
          }, this.headers)
        if(res.success) {
          this.showNotification({ msg: res.message, icon: 'fad fa-check-circle' });
          this.followUpError = false;
          this.closeFollowUpModal();
        }
      }
    },

    closeModal() {
      this.isModalActive = false;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'block';
      focus_burger_menu(this.selectedMeeting, this.view);
    },

    closeFollowUpModal() {
        this.isFollowUpModalActive = false;
        this.followUpContact = null;
        this.followUpReason = null;
        const sidebar: HTMLElement = document.querySelector('#sidebar');
        sidebar.style.display = 'block';
        focus_burger_menu(this.selectedMeeting, this.view);
    },

    closeCancelationModal() {
      this.cancelation_modal_state = true;
      this.cancelation_note = null;
      this.isCancelationModalActive = false;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      focus_burger_menu(this.selectedMeeting, this.view);
      sidebar.style.display = 'block';
    },

    closeAcceptModal() {
      this.isAcceptModalActive = false;
      focus_burger_menu(this.selectedMeeting, this.view);
      this.isAcceptedMeeting = null;

      this.selectedMeeting = {
        requester_obj: { profile_pic: null },
        chosen_mentor: { profile_pic: null },
        department: { name: null },
        focus_area: { name: null },
        meeting_hours: { startTime: null, endTime: null }
      };

      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'block';
    },

    async archiveMeeting(item) {
      const res = await this.service.archiveMeeting({ meetingId: item.id }, this.headers);
      if (res.status === 'success') {
        item.status = 'archived';
        this.closeBurgers();
      }
    },
    change_cancelation_modal_state() {
      this.cancelation_modal_state = !this.cancelation_modal_state;
      focusElement('let_know_reason');
    },

    async handleFavoritesMentors(id) {
      if (this.ids_favorite_mentors.includes(id)) {
        await this.accountsService.removeFavoriteMentor(id, this.headers);
      } else {
        await this.accountsService.addFavoriteMentor({ user_id: this.loggedUserId, mentor_id: id }, this.headers);
      }
      this.ids_favorite_mentors = await this.accountsService.getFavoriteMentors({ flag: 'ids' });
      this.closeBurgers();
    }
  }
};
</script>
