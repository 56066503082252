<template>
  <section class="partner-tenant-about-us-request-a-demo-section">
      <div class="partner-tenant-about-us-request-a-demo-content-container">
        <div class="partner-tenant-about-us-request-a-demo-image-container">
          <div class="tint"></div>
          <h3 class="partner-tenant-about-us-request-a-demo-title">
            Partner with Us
          </h3>
          <p class="partner-tenant-about-us-request-a-demo-description">
            Get in touch! Fill out our form
            and our team will get back to
            you within 24 hours.
          </p>
          <div class="partner-tenant-about-us-request-a-demo-social-network-container">
            <a href="#">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a href="#">
              <i class="fa-brands fa-linkedin"></i>
            </a>
            <a href="#">
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a href="#">
              <i class="fa-solid fa-envelope"></i>
            </a>
          </div>
        </div>
        <div class="partner-tenant-about-us-request-a-demo-form-container">
          <form action="POST" class="partner-tenant-about-us-request-a-demo-form">
            <div class="partner-tenant-about-us-request-a-demo-inputs-container">
              <div class="partner-tenant-about-us-request-a-demo-input-wrapper">
                <div>
                  <label for="first_name" class="partner-tenant-about-us-request-a-demo-input-label">
                    First Name <span class="required">*</span>
                  </label>
                </div>
                <input id="first_name" v-model="formData.first_name" placeholder="First Name" type="text" class="partner-tenant-about-us-request-a-demo-input">
                <span
                  class="input-error-text"
                >
                  {{ getErrorMessage('first_name') }}
                </span>
              </div>
              <div class="partner-tenant-about-us-request-a-demo-input-wrapper">
                <div>
                  <label for="last_name" class="partner-tenant-about-us-request-a-demo-input-label">
                    Last Name <span class="required">*</span>
                  </label>
                </div>
                <input id="last_name" v-model="formData.last_name" placeholder="Last Name" type="text" class="partner-tenant-about-us-request-a-demo-input">
                <span
                  class="input-error-text"
                >
                  {{ getErrorMessage('last_name') }}
                </span>
              </div>
            </div>
            <div class="partner-tenant-about-us-request-a-demo-inputs-container">
              <div class="partner-tenant-about-us-request-a-demo-input-wrapper">
                <div>
                  <label for="email" class="partner-tenant-about-us-request-a-demo-input-label">
                    Email <span class="required">*</span>
                  </label>
                </div>
                <input id="email" v-model="formData.email" type="email" placeholder="Email" class="partner-tenant-about-us-request-a-demo-input">
                <span
                  class="input-error-text"
                >
                  {{ getErrorMessage('email') }}
                </span>
              </div>
              <div class="partner-tenant-about-us-request-a-demo-input-wrapper">
                <div>
                  <label for="phone_number" class="partner-tenant-about-us-request-a-demo-input-label">
                    Phone number <span class="required">*</span>
                  </label>
                </div>
                <input
                  id="phone_number"
                  v-model="formData.phone_number"
                  placeholder="+1 (000) 000-0000"
                  type="text"
                  class="partner-tenant-about-us-request-a-demo-input"
                >
                <span
                  class="input-error-text"
                >
                  {{ getErrorMessage('phone_number') }}
                </span>
              </div>
            </div>
            <div class="partner-tenant-about-us-request-a-demo-inputs-container">
              <div class="partner-tenant-about-us-request-a-demo-input-wrapper">
                <div>
                  <label for="company" class="partner-tenant-about-us-request-a-demo-input-label">
                    Company <span class="required">*</span>
                  </label>
                </div>
                <input id="company" v-model="formData.company" type="text" placeholder="Company/Organization Name" class="partner-tenant-about-us-request-a-demo-input">
                <span
                  class="input-error-text"
                >
                  {{ getErrorMessage('company') }}
                </span>
              </div>
            </div>
            <div>
              <div class="partner-tenant-about-us-request-a-demo-interests-title">
                Select one or more areas of interest <span class="required">*</span>
              </div>
              <div class="partner-tenant-about-us-request-a-demo-interests-checkboxes-container">
                <div class="partner-tenant-about-us-request-a-demo-interest-checkbox-wrapper">
                  <input v-model="formData.interests_events" class="partner-tenant-about-us-request-a-demo-interest-checkbox" type="checkbox" id="events">
                  <label class="partner-tenant-about-us-request-a-demo-interest-checkbox-label" for="events">Sponsor Educational Event</label>
                </div>
                <div class="partner-tenant-about-us-request-a-demo-interest-checkbox-wrapper">
                  <input v-model="formData.interests_opportunities" class="partner-tenant-about-us-request-a-demo-interest-checkbox"  type="checkbox" id="opportunities">
                  <label class="partner-tenant-about-us-request-a-demo-interest-checkbox-label" for="opportunities">Feature Your Opportunities</label>
                </div>
                <div class="partner-tenant-about-us-request-a-demo-interest-checkbox-wrapper">
                  <input v-model="formData.interests_resources" class="partner-tenant-about-us-request-a-demo-interest-checkbox" type="checkbox" id="resources">
                  <label class="partner-tenant-about-us-request-a-demo-interest-checkbox-label" for="resources">Sponsor Digital Resources</label>
                </div>
              </div>
            </div>
            <div class="partner-tenant-about-us-request-a-demo-text-area-wrapper">
              <div class="partner-tenant-about-us-request-a-demo-text-area-label-wrapper">
                <label class="partner-tenant-about-us-request-a-demo-text-area-label" for="message">Message <span class="required">*</span></label>
              </div>
              <textarea v-model="formData.message" name="message" id="message" rows="7" placeholder="Write your message..."
                class="partner-tenant-about-us-request-a-demo-text-area"
              ></textarea>
            </div>
            <div class="partner-tenant-about-us-request-a-demo-submit-container">
              <div class="partner-tenant-about-us-request-a-demo-interest-checkbox-wrapper">
                <input v-model="formData.newsletter" class="partner-tenant-about-us-request-a-demo-interest-checkbox" type="checkbox" id="newsletter">
                <label class="partner-tenant-about-us-request-a-demo-interest-checkbox-label" for="newsletter">Sign me up for the BeGlobalii Newsletter</label>
              </div>
              <div class="partner-tenant-about-us-request-a-demo-submit-button-wrapper">
                <button @click.prevent="submitForm()" class="partner-tenant-about-us-request-a-demo-submit-button">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
</template>

<script lang="ts">
import { useNotificationStore } from '../../../../stores/notification';
import * as Cookies from 'js-cookie';
import PartnerAccountService from '../../../../partneraccounts/services/PartnerAccountService';
import { isValidEmailString } from '../../../../utils/CustomUtils';

export default {
  name: 'partner-tenant-request-demo-section',
  data() {
    return {
      partnerAccountService: new PartnerAccountService(),
      notificationStore: useNotificationStore(),
      formData: {
        'first_name': '',
        'last_name': '',
        'phone_number': '',
        'email': '',
        'company': '',
        'interest_events': '',
        'interest_opportunities': '',
        'interest_resources': '',
        'message': '',
        'newsletter': ''
      },
      formErrorMessagesObject: {
        'first_name': {
          'isActive': false,
          'message': ''
        },
        'last_name': {
          'isActive': false,
          'message': ''
        },
        'phone_number': {
          'isActive': false,
          'message': ''
        },
        'email': {
          'isActive': false,
          'message': ''
        },
        'company': {
          'isActive': false,
          'message': ''
        },
      },
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    }
  },
  methods: {
    clearForm() {
      this.formData = {
        'first_name': '',
        'last_name': '',
        'phone_number': '',
        'email': '',
        'company': '',
        'interest_events': '',
        'interest_opportunities': '',
        'interest_resources': '',
        'message': '',
        'newsletter': ''
      }
    },
    getErrorMessage(inputId) {
      if (this.formErrorMessagesObject[inputId].isActive) {
        return this.formErrorMessagesObject[inputId].message;
      }

      return null;
    },
    validateEmail(email: string) {
      return isValidEmailString(email);
    },
    validateForm() {
      const requiredFields = ['first_name', 'last_name', 'phone_number', 'email', 'company'];
      let isValid = true;
      requiredFields.forEach(field => {
        if (!this.formData[field]) {
          this.formErrorMessagesObject[field].isActive = true;
          isValid = false;
          this.formErrorMessagesObject[field].message = 'This field is required.';
        } else {
          this.formErrorMessagesObject[field].isActive = false;
          this.formErrorMessagesObject[field].message = '';
        }
      });
      if (this.formData.email) {
        if (!this.validateEmail(this.formData.email)) {
          this.formErrorMessagesObject.email.isActive = true;
          isValid = false;
          this.formErrorMessagesObject.email.message = 'Please enter a valid email address.';
        }
      }
      return isValid;
    },
    submitForm() {
      this.$Progress.start();
      if(this.validateForm()) {
        this.partnerAccountService.requestDemo(this.formData, this.headers, true).then(response => {
          this.$Progress.finish();
          this.notificationStore.addNotification('Request successfully made. We\'ll get back to you as soon as possible.', 10000);
          this.clearForm();
        }).catch(error => {
          this.$Progress.fail();
          this.notificationStore.addNotification('Oops, something went wrong. Please verify the data and try again.', 5000);
        })
      } else {
        this.$Progress.fail();
        this.notificationStore.addNotification('Please verify the data and try again.', 5000);
      }
    },
  },
}
</script>
