<template>
  <div class="flex-column-center">
    <main id="main" class="main-wrapper">
      <campus-apps-navbar :canManage="isAdmin || isDeptLead" :showAddBtn="isAdmin" :deptPlural="deptPlural"></campus-apps-navbar>
      <div v-if="!isLoading && apps.length > 0" class="table-container" style="width: 100%; overflow-y: hidden; overflow-x: auto; margin: 30px 0">
        <table v-if="!isLoading" class="eu-resource-table">
          <tr class="eu-resource-row">
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              <span>App Name </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              <span>{{deptSingular}} </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              <span>Date Added </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col"></th>
            <th v-if="isAdmin" class="eu-resource-cell eu-resource-cell-th" scope="col"></th>
          </tr>
          <tr v-for="item of apps" :key="item.id" class="eu-resource-row">
            <td class="eu-resource-cell" style="display: flex; align-items: center">
              <img
                class="eu-profile-thumbnail"
                alt="app logo"
                style="width: 32px; height: 32px"
                :src="item.logo_url ? item.logo_url : '/static/assets/icons/default-profile.svg'"
              />
              <span>{{ item.name }}</span>
            </td>

            <td class="eu-resource-cell">{{ item.department.name }}</td>
            <td class="eu-resource-cell">{{ item.timestamp | parseDate }}</td>
            <td class="eu-resource-cell eu-resource-cell-dots-icon">
              <i @click="goToEdit(item.id)" @keyup.enter="goToEdit(item.id)" tabindex="0" class="fas fa-pen"></i>
            </td>
            <td v-if="isAdmin" class="eu-resource-cell eu-resource-cell-dots-icon" style="padding-left: 5px">
              <i @click="openDeleteModal(item)" @keyup.enter="openDeleteModal(item)" tabindex="0" class="fas fa-trash-can"></i>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="isLoading">
        <loading-spinner wrapperClass="wrapper" />
      </div>
      <div v-if="!isLoading && apps.length == 0">
        <div class="eu-empty-container">
          <div class="eu-empty">
            <div class="eu-empty-icon-container">
              <i class="fad fa-bullhorn eu-empty-icon"></i>
            </div>
            <div style="display: flex; flex-direction: column">
              <span class="eu-empty-text"> No data. </span>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Modal :isActiveProp="isDeleteModalActive" @close="closeDeleteModal">
      <div class="iu-resources-delete-modal-content-container">
        <div class="iu-resources-delete-modal-text-section">Are you sure you want to delete this Campus App?</div>
        <div class="iu-resources-delete-modal-file-details-section">
          <div class="iu-resources-delete-modal-file-name">
            {{ itemToDelete.name }}
          </div>
        </div>
        <div class="iu-resources-delete-modal-actions-section">
          <button
            id="campusapp_delete"
            class="iu-resources-delete-modal-actions-button iu-main-btn small solid red"
            @click="deleteItem(itemToDelete.id)"
            aria-label="Remove an item"
          >
            Delete
          </button>
          <button class="iu-resources-delete-modal-actions-button iu-main-btn small outlined gray" @click="closeDeleteModal" aria-label="Close delete modal">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </div>
</template>

<script>
import CampusAppsNavbar from "./CampusAppsNavbar.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import CampusAppService from "../services/CampusAppService";
import Modal from "../../components/ModalExtended.vue";
import { focusElement } from "../../utils/CustomUtils";
import Notification from "../../components/Notification.vue";
import { useCommonDataStore } from "../../stores/commonData";

export default {
  name: "campus-apps-manage",
  components: { CampusAppsNavbar, LoadingSpinner, Modal, Notification },
  props: ["isAdmin", "isDeptLead"],
  computed: {
    deptPlural() {
      return this.commonDataStore.getFeatureName('department_plural').toLowerCase()
    },
    deptSingular() {
      return this.commonDataStore.getFeatureName('department_singular')
    }
  },
  data() {
    return {
      isLoading: false,
      apps: [],
      service: new CampusAppService(),
      isDeleteModalActive: false,
      commonDataStore: useCommonDataStore(),
      itemToDelete: { id: null, name: null },
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
    };
  },
  async created() {
    if(!this.isDeptLead && !this.isAdmin) {
      this.$router.push(`/campusapps/`);
    }
    this.isLoading = true;
    await this.getApps();
    this.isLoading = false;
  },
  methods: {
    async getApps() {
      const filters = this.isDeptLead ? { is_dept_lead: true } : {};
      this.apps = await this.service.getAll(filters);
    },
    goToEdit(appId) {
      this.$router.push(`/campusapps/edit/${appId}`);
    },
    openDeleteModal(item) {
      this.isDeleteModalActive = true;
      focusElement("campusapp_delete");
      this.itemToDelete = { ...item };
    },
    closeDeleteModal() {
      focusElement("manage_campusapps");
      this.isDeleteModalActive = false;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    async deleteItem(appId) {
      this.isLoading = true;
      this.isDeleteModalActive = false;
      const res = await this.service.remove(appId);
      if (res.success) {
        this.showNotification({ msg: "Campus App removed successfully.", icon: "fad fa-check-circle" });
        this.getApps();
      } else {
        this.showNotification({ msg: "Error while removing Campus App", isError: true, icon: "fad fa-exclamation-circle" });
      }
      this.isLoading = false;
    },
  },
};
</script>
