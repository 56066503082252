import HttpService from "../../services/HttpService";


export default class MentorService extends HttpService {
  constructor() {
    super('/meetings/api/mentors');
  }

  getMentors(params): Promise<any> {
    this.endpoint = `/meetings/api/mentors`;
    return this.get(params);
  }

  removeMentors(ids, customHeaders): Promise<any> {
    this.endpoint = `meetings/api/mentors`;
    return this.delete(ids, customHeaders);
  }
}
