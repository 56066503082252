<template>
  <section class='accounts-browse-section'>
    <div class="eu-search">
      <form action="" v-on:submit.prevent="fetchPartners()" class="eu-search-form" style="margin-top:50px;" role="search">
        <input type="search" v-model="searchInput" placeholder="Search for a partner" class="eu-search-input js-global-search-input" />
        <button class="eu-search-btn-icon" aria-label="search">
          <i class="eu-search-icon fa-solid fa-search"></i>
        </button>
      </form>
    </div>
    <div class="filter-container">
      <!-- <multiselect-filter-component
        placeholder="Any Role"
        filter_name="Roles"
        :options="roles"
        @filter-data="filterByRoles"
      />
      <multiselect-filter-component
        placeholder="Any Department"
        filter_name="Departments"
        :options="departments"
        @filter-data="filterByDepartments"
      /> -->
      <multiselect-filter-component
        placeholder="Any Status"
        filter_name="Status"
        :options="statusList"
        @filter-data="filterByStatus"
      />
    </div>
  </section>
</template>

<script lang='ts'>
  import * as Cookies from 'js-cookie';
  import MultiselectFilterComponent from '../../../form/components/MultiselectFilterComponent.vue';

  export default {
    name: 'partners-filter',
    components: {
      MultiselectFilterComponent,
    },
    props: [
      'roles',
      'departments',
    ],
    data() {
      return {
        partners: [],
        searchInput: '',
        statusList: [
          {
            name: 'Active',
            value: true,
          },
          {
            name: 'Not Active',
            value: false,
          },
        ],
        selectedDepartments: [],
        selectedRoles: [],
        selectedStatus: [],
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
          Accept: 'application/json'
        },
      }
    },

    methods: {
      fetchPartners() {
        this.$emit('filterPartners',
        {
          searchInput: this.searchInput,
          selectedDepartments: this.selectedDepartments,
          selectedRoles: this.selectedRoles,
          selectedStatus: this.selectedStatus,
        });
      },
      filterByDepartments(value, name) {
        this.selectedDepartments = value.map(v=> encodeURIComponent(v.name));
        this.fetchPartners();
      },
      filterByRoles(value, name) {
        this.selectedRoles = value.map(v=> encodeURIComponent(v.name));
        this.fetchPartners();
      },
      filterByStatus(value, name) {
        this.selectedStatus = value.map(v => encodeURIComponent(v.value));
        this.fetchPartners();
      }
    }
  }
</script>
