<template>
  <div class="eu-feed-settings">
    <div class="department-container department-container-left">
      <div v-if="department.welcome_video_url" class="video-wrapper">
        <iframe
          :src="getYoutubeOrVimeoEmbedLink(department.welcome_video_url)"
          class="dept-video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>

      <div
        v-if="department.important_links && Object.keys(department.important_links).length > 0"
        class="eu-feed-settings-section slim-scrollbar-vertical"
        style="max-height: 355px; overflow: scroll; margin-top: 24px"
      >
        <p class="eu-feed-dept-address-title">Helpful Links</p>
        <div
          v-for="(value, title) in department.important_links"
          :key="value"
          style="border-bottom: 2px solid #ececec; margin-bottom: 24px; padding-bottom: 22px"
        >
          <p
            class="helpful-link-description"
            style="color:#868686;"
          >
            {{ title }}
          </p>
          <a
            class="eu-feed-dept-social-href"
            target="_blank"
            :href="value"
            :aria-label="`This is a helpful link with the title: ${title}`"
          >
          {{ value }}
          <i class="eu-feed-dept-social-href fa-solid fa-up-right-from-square"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="department-container department-container-right">
      <div v-if="department.email || department.phone_number || department.website" class="eu-feed-settings-section" style="padding: 0 30px">
        <h2>Contact Information</h2>
        <div style="border-bottom: 2px solid #dadada">
          <div v-if="department.phone_number" class="eu-feed-dept-contact-wrapper">
            <i class="far fa-phone-alt eu-feed-dept-social-icon" style="background-color: #f5eee5; border-radius: 4px; padding: 8px"></i>
            <a :href="'tel:' + getPhoneNumberString(department.phone_number)" :aria-label="getAriaLabelPhoneNumberString(department.phone_number)" class="eu-feed-dept-contact">{{ department.phone_number }}</a>
          </div>
          <div v-if="department.email" class="eu-feed-dept-contact-wrapper">
            <i class="far fa-envelope eu-feed-dept-social-icon" style="background-color: #f5eee5; border-radius: 4px; padding: 8px"></i>
            <a :href="'mailto:' + department.email" class="eu-feed-dept-social-href">{{ department.email }}</a>
          </div>
          <div v-if="department.website" class="eu-feed-dept-contact-wrapper" style="padding-bottom: 20px">
            <i class="far fa-globe eu-feed-dept-social-icon" style="background-color: #f5eee5; border-radius: 4px; padding: 8px"></i>
            <span class="eu-feed-dept-contact-website">
              <a class="eu-feed-dept-contact" target="_blank" :href="department.website">{{ department.website | shortenURL }}</a>
            </span>
          </div>
        </div>
        <div class="eu-feed-dept-social-icon-wrapper">
          <div class="eu-feed-dept-item">
            <div v-for="s of department.socials" :key="s.value" v-show="s.url !== ''" style="margin-right: 16px; font-size: 20px">
              <a v-if="s.url" :aria-label="`link to ${s.value} page`" :href="s.url"
                ><i :class="s.icon" style="margin-right: 10px"></i
              ></a>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="department.contacts && Object.keys(department.contacts).length > 0"
        class="eu-feed-settings-section slim-scrollbar-vertical"
        style="max-height: 390px; overflow: scroll; margin-top: 24px"
      >
        <p class="eu-feed-dept-address-title" style="padding: 0">Department Contacts</p>
        <div class="department-contacts-containers" v-for="dc of department.contacts" :key="dc.email">
          <img alt="Profile picture of user" :src="dc.profile_pic" style="width: 48px; height: 48px" />
          <div>
            <a :aria-label="`Go to ${dc.full_name} profile page`" class="eu-feed-dept-social-href" target="_blank" :href="`/profiles/${dc.url}`">{{
              dc.full_name
            }}</a>
            <p class="helpful-link-description">{{ dc.role }}</p>
          </div>
        </div>
      </div>

      <div v-if="department.address.split(', ')[0]" class="eu-feed-settings-section">
        <p class="eu-feed-dept-address-title">Address</p>
        <div v-for="e of department.address.split('- ')" :key="e">
          <div class="eu-feed-dept-address-item">{{ e }}</div>
        </div>
        <div class="eu-feed-dept-address-item">United States</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { getYoutubeOrVimeoEmbedLink } from '../../utils/CustomUtils';
import { formatURL, getAriaLabelPhoneNumberString, getPhoneNumberString } from '../../utils/StringUtils';

export default {
  components: {},

  name: 'department-details',

  props: ['loggedUser', 'department'],

  data() {
    return {};
  },

  async created() {},

  methods: {
    getAriaLabelPhoneNumberString,
    getPhoneNumberString,
    getYoutubeOrVimeoEmbedLink,
    switchView() {
      const feedEl = document.querySelector('.eu-feed-posts');
      const detailsEl = document.querySelector('.eu-feed-settings');
      detailsEl.classList.add('unHiddenEl');
      feedEl.classList.remove('unHiddenEl');
    },
  },

  computed: {},

  filters: {
    shortenURL: function (url: string) {
      return formatURL(url);
    },
  },
};
</script>
