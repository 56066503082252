<template>
  <section>
    <div class="lg-space-between">
      <div class="campus-apps-title-wrapper">
        <common-title
          title="Campus Apps"
          :description="`Explore apps used by your university &amp; its ${deptPlural}.`"
        />
      </div>
      <button
        v-if="showAddBtn"
        aria-label="Add new Campus App"
        class="iu-btn-solid big title-btn"
        @click="changePage('/campusapps/add')"
      >
        Add App
      </button>
    </div>
    <nav class="eu-simple-navbar">
      <span
        tabindex="0"
        :class="$route.path === '/campusapps/' ? 'eu-simple-navbar-item eu-simple-navbar-item-active' : 'eu-simple-navbar-item'"
        @click="changePage('/campusapps/')"
        role="link"
      >
        All Apps
      </span>
      <span
        v-if="canManage"
        tabindex="0"
        :class="$route.path === '/campusapps/manage' ? 'eu-simple-navbar-item eu-simple-navbar-item-active' : 'eu-simple-navbar-item'"
        @click="changePage('/campusapps/manage')"
        role="link"
        id='manage_campusapps'
      >
        Manage
      </span>
    </nav>
  </section>
</template>

<script>
import CommonTitle from '../../components/CommonTitle.vue';

export default {
  name: "campus-apps-navbar",
  components: {
    CommonTitle,
  },
  props: ["canManage", "showAddBtn", "deptPlural"],
  computed: {},
  data() {
    return {};
  },
  methods: {
    changePage(page) {
      this.$router.push(page);
    },
  },
};
</script>

<style lang="scss" scoped>
.campus-apps-title-wrapper {
  padding: 24px 0;
}

@media screen and (max-width: 576px) {
  .campus-apps-title-wrapper {
    padding: 24px 0 0;
  }
}
</style>
