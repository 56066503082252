<template>
  <div class="io-profile-dropdown">
    <div class="io-profile-info-wrapper" aria-haspopup="dialog" role="button">
      <img alt="Your profile picture" :src="profileImagePath" class="io-profile-picture dropdown" />
      <div class="io-name-edit-wrapper">
        <p class="io-name">{{ loggedUser.user.first_name }} {{ loggedUser.user.last_name }}</p>
        <span
          @click="redirectTo(`/profiles/${loggedUser.user.profile_url}`)"
          tabindex="0"
          class="io-edit"
          aria-label="Go to profile page"
          >View Profile</span
        >
      </div>
    </div>
    <div class="io-links-wrapper">
      <span @click="redirectTo('/my-hub')" v-if="!isPartnerTenant" to="/my-hub" tabindex="0" class="io-links-link"
        ><i class="fad fa-bookmark io-links-icon" aria-label="Go to my hub page"></i>MyHub</span
      >
      <span @click="redirectTo('/settings/profile')" tabindex="0" class="io-links-link"
        ><i class="fad fa-cog io-links-icon" aria-label="Go to profile settings page"></i>Settings</span
      >
      <a href="/logout" class="io-links-link"
        ><i class="fad fa-sign-out io-links-icon" aria-label="Sign out"></i>Sign Out</a
      >
    </div>
  </div>
</template>
<script lang="ts">
import { isPartnerTenant } from "../../utils/CustomUtils";
export default {
  name: "top-navbar-profile",
  props: ["loggedUser", "profileImagePath"],
  data() {
    return {
      isPartnerTenant: isPartnerTenant(),
    };
  },
  created() {},
  mounted() {},
  methods: {
    routeExistsInRouter(path) {
      try {
        const resolvedRoute = this.$router.resolve(path);
        return resolvedRoute.resolved.matched.length > 0;
      } catch (error) {
        return false;
      }
    },
    redirectTo(url, label = null) {
      if (this.routeExistsInRouter(url)) {
        this.$router.push(url);
      } else {
        const newTabOptions = ["Bias Report"];
        if (newTabOptions.includes(label)) {
          window.open(url, "_blank");
        } else {
          window.location.href = url;
        }
      }
    },
  },
};
</script>
