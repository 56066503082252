<template>
  <tr
    v-if="rowVisible"
  >
    <td></td>
    <td class="iu-manage-partners-managers-line-cell">
      <div class="iu-manage-partners-managers-line"></div>
    </td>
    <td class="iu-partner-managers-cell-border">
      <button
        class="iu-main-btn main x-small outlined"
        aria-label="Open add manager button"
        @click="addManager"
        @keyup.enter="addManager"
      >
        <i class="fa-solid fa-plus iu-main-button-front-icon"></i>
        Add Manager
      </button>
    </td>
    <td class="iu-partner-managers-cell-border"></td>
    <td class="iu-partner-managers-cell-border"></td>
    <td class="iu-partner-managers-cell-border"></td>
    <td class="iu-partner-managers-cell-border"></td>
    <td class="iu-partner-managers-cell-border"></td>
  </tr>
</template>

<script lang="ts">

export default {
  name: 'add-manager-row',
  props: [
    'rowVisible',
    'partner'
  ],
  methods: {
    addManager() {
      this.$emit('add-manager', this.partner.id);
    }
  }
}
</script>
