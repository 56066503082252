<template>
    <div class="partner-tenant-overall-card">
      <img
        class="bg"
        :src="bgImage"
        alt="bgImage"
      >
      <div class="relative">
        <div v-if="label" class="label-wrapper">
          <div class="label-dot"></div>
          <span class="label">
          {{label}}
          </span>
        </div>
      </div>
      <div class="main-content">
        <h4 class="title">{{title}}</h4>
        <div class="icon-number-wrapper">
          <i :class="`${icon}`" :style="`color: ${iconColor}; font-size: 29px;`"></i>
          <span class="number">{{ number }}</span>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'partner-tenant-overall-card',
  props: ['label', 'number', 'icon', 'iconColor', 'title', 'bgImage'],
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  },
  computed: {}
}
</script>
