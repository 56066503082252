import Vue from 'vue';
import PassportStepPicker from './components/PassportStepPicker.vue';

export default class PassportStepPickerView {
  protected csrfToken: string;
  protected mountPoint: HTMLElement;
  protected stepResults: [];

  constructor(el: HTMLElement, stepResults) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.stepResults = stepResults;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const passportStepPickerView = new Vue({
      components: { PassportStepPicker },
      template: `<passport-step-picker 
                  :stepsData="stepResults"/>`,
      data() { return {
        stepResults: _class.stepResults,
      }; },
      methods: {},
      mounted() {}
    });
    passportStepPickerView.$mount(this.mountPoint);
  }
}
