<template>
  <section>
    <header class="blog-header">
      <div class="blog-header-wrapper-title">
        <h1 class="blog-header-title">Includifi Blog</h1>
      </div>
      <breadcrumbs
        :routeItems="breadcrumbsLinkItems"
        currentPageLabel="Blog"
        theme="light"
      />
      <div
        class="blog-header-carousel"
        @mouseover="featuredBlogsHovered = true"
        @mouseleave="featuredBlogsHovered = false"
      >
        <a :href="'/blog/' + activeFeaturedBlog.urlSlug" :aria-label="'Open ' + activeFeaturedBlog.title + ' blog'">
          <img class="blog-header-carousel-img" :src="activeFeaturedBlogImageUrl" :alt="activeFeaturedBlog.image.alt" />
        </a>
        <div class="blog-header-carousel-body">
          <div class="blog-header-carousel-body-header">
            <i class="fas fa-stars blog-header-carousel-body-header-icon"></i>
            <span class="blog-header-carousel-body-header-title"> Featured Article </span>
            <div class="eu-featured-event-carousel-dots-container">
              <span
                v-for="(item, index) in featuredBlogs"
                :key="index"
                class="eu-featured-event-carousel-dot"
                :class="activeCarouselDot == index ? 'eu-featured-event-carousel-dot-selected' : ''"
                @click="changeActiveCarouselDot(index)"
                @keyup.enter="changeActiveCarouselDot(index)"
              ></span>
            </div>
          </div>
          <hr class="eu-featured-event-hr-small" />
          <a :href="'/blog/' + activeFeaturedBlog.urlSlug" :aria-label="'Open ' + activeFeaturedBlog.title + ' blog'">
            <h2 class="blog-header-carousel-body-title">{{ activeFeaturedBlog.title }}</h2>
          </a>
          <p class="blog-header-carousel-body-description">
            {{ activeFeaturedBlog.description }}
          </p>
          <div class="blog-header-carousel-body-category" style="margin-top: 16px;">
            <div v-for="(tag,index) in activeFeaturedBlog.tag" :key="index" class="blog-header-carousel-body-category-item">
              {{tag.tagText}}
            </div>
          </div>
          <div class="blog-header-carousel-body-author"  style="position:unset; margin-top: 8px;">
            <img class="blog-header-carousel-body-author-img" :src="activeFeaturedBlogAuthorProfilePicUrl" :alt="activeFeaturedBlog.author.profilePic.alt" />
            <div class="blog-header-carousel-body-author-about">
              <p class="blog-header-carousel-body-author-about-name">{{ activeFeaturedBlog.author.name }}</p>
              <p class="blog-header-carousel-body-author-about-date">{{ formatDate(activeFeaturedBlog.createdAt) }}</p>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main class="blog-main">
      <div class="blog-wrapper">
        <div class="blog-collection">
          <div v-for="(blog, index) in blog_list" :key="index" class="blog-card">
            <img class="blog-card-image" :src="blog.image.url" :alt="blog.image.alt">
            <div class="blog-card-body">
              <a :href="'/blog/' + blog.urlSlug" :aria-label="'Open ' + blog.title + ' blog'">
                <h2 class="blog-card-header">
                  {{ blog.title }}
                </h2>
              </a>
              <div class="blog-card-description">
                <p>
                  {{ blog.description }}
                </p>
              </div>
              <div class="blog-body-card-footer">
                <div class="blog-header-carousel-body-category">
                  <div v-for="(tag, index) in blog.tag" :key="index" class="blog-header-carousel-body-category-item">
                    {{ tag.tagText }}
                  </div>
                </div>
                <div class="blog-header-carousel-body-author">
                  <img class="blog-header-carousel-body-author-img" :src="blog.author.profilePic.url" :alt="blog.author.profilePic.alt"/>
                  <div class="blog-header-carousel-body-author-about">
                    <p class="blog-header-carousel-body-author-about-name">{{ blog.author.name }}</p>
                    <p class="blog-header-carousel-body-author-about-date">{{ formatDate(blog.createdAt) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import { MediaCMSService } from '../../services/CMSService';
import moment from 'moment';
import Breadcrumbs from '../../partners/components/landing_pages/components/Breadcrumbs.vue';

export default {
  name: 'blogs-overview',
  components: {
    Breadcrumbs,
  },
  props: [
    'blog_list',
  ],
  computed: {
    activeFeaturedBlog() {
      return this.featuredBlogs[this.activeCarouselDot];
    },
    activeFeaturedBlogImageUrl() {
      return this.activeFeaturedBlog.image.url;
    },
    activeFeaturedBlogAuthorProfilePicUrl() {
      return this.activeFeaturedBlog.author.profilePic.url;
    },
    featuredBlogs() {
      return this.blog_list.filter((blog) => blog.featured == "true");
    },
  },
  created() {
    this.setSliderInterval(10);
  },
  data() {
    return {
      breadcrumbsLinkItems: [
        {
          'label': 'Home',
          'path': '/',
          'isSpa': false
        }
      ],
      activeCarouselDot: 0,
      featuredBlogsHovered: false,
      mediaCMSService: new MediaCMSService(),
    };
  },
  methods: {
    formatDate (dateString) {
      return moment(dateString).format('MMMM DD, YYYY');
    },
    changeActiveCarouselDot(index) {
      this.activeCarouselDot = index;
    },
    showNextFeaturedBlog() {
      if (this.featuredBlogs.length == this.activeCarouselDot + 1) {
        this.activeCarouselDot = 0;
      } else {
        this.activeCarouselDot += 1;
      }
    },
    setSliderInterval(secondsLimit) {
      let secondsPassed = 0;
      setInterval(() => {
        if (!this.featuredBlogsHovered) {
          secondsPassed += 1;
          if (secondsLimit == secondsPassed) {
            this.showNextFeaturedBlog();
            secondsPassed = 0;
          }
        }
      }, 1000);
    },
  },
};
</script>
