import Vue from 'vue';
import Notification from './Notification.vue'


export default class NotificationInterface {
  protected mountPoint: HTMLElement;
  protected isActive: boolean;
  protected notification: object;

  constructor(el: HTMLElement, isActive: boolean, notification: object) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.isActive = isActive;
    this.notification = notification;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const notification = new Vue({
      components: {Notification},
      template: `
        <notification
          :isActiveProp="isActive"
          :notification="notification"
          @close="closeNotification"
        />
      `,

      data() {
        return {
          isActive: _class.isActive,
          notification: _class.notification,
        };
      },

      methods: {
        closeNotification() {
          this.isActive = false;
          const newMountPoint = document.createElement('div');
          newMountPoint.id = 'notification';
          this.$el.parentNode.appendChild(newMountPoint);
          this.$el.parentNode.removeChild(this.$el);
          this.$destroy;
        },
      },
    });

    notification.$mount(this.mountPoint);
  }
}
