<template>
  <section>
        <div tabindex="0" :aria-label="`Question: ${stepTitles[currentStepNo]}`" class="passport-form-title">{{stepTitles[currentStepNo]}}</div>
        <div v-if="currentStepNo === 0">
              <div class="multiselect-wrapper" style="margin: 0 auto">
              <multiselect
                v-model="passport.user_interests"
                :options="focus_areas"
                :multiple="true"
                :close-on-select="false"
                placeholder=""
                label="name"
                track-by="name"
              ></multiselect>
              <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer"></i>
            </div>
        </div>
        <div v-if="currentStepNo === 1">
            <section v-if="isBadgeEarned()">
              <div class="passport-form-title">Woohoo! You earned a badge.</div>
              <img class="passport-badge-earned" :src="`${assetPath}badges/interests-earned.svg`"/>
            </section>
            <div class="passport-form-label" style="margin-bottom: 30px">Keep filling out sections to earn badges and complete your passport.</div>
            <a v-if="nextStep" :href="`/passport?step=${encodeURIComponent(nextStep)}`" class="da-components-button da-btn-solid enup-demo-button passport-final-btn">Continue with Passport</a>
            <a :href="dashboardLink" class="da-components-button da-btn-solid enup-demo-button passport-final-btn inverted">Quit & Go to Dashboard</a>
        </div>
  </section>
</template>
<script lang="ts">
import Multiselect from 'vue-multiselect';
export default {
  components: { Multiselect },
  name: "passport-my-interests",
  props: ['passport', 'currentStepNo', 'dashboardLink', 'focus_areas', 'nextStep'],
  data() {
    return {
      assetPath: process.env.MEDIA_URL,
      stepTitles: ['Select your interest(s).'],
    };
  },
  created() {
    },
  methods: {
      setPassportProp(propName, value) {
        this.$emit('set-passport-prop', propName, value);
    },
    isBadgeEarned() {
        this.$emit('is-badge-earned');
        return this.passport.badge;
    },
  },
  computed: {},
};
</script>

