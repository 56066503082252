import Vue from 'vue';
import FirstLoginIndex from './components/first_login/FirstLoginIndex.vue';

export default class FirstLogin {
  protected csrfToken: string;
  protected mountPoint: HTMLElement;
  protected data: object;

  constructor(el: HTMLElement, data: object) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.data = data;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const firstLogin = new Vue({
      components: { FirstLoginIndex },
      template: `<first-login-index
                  :dashboardLink="dashboard_link"
                  :departments="departments"
                  :partners="partners"
                  :focus_areas_1="focus_areas_1"
                  :focus_areas_2="focus_areas_2"
                  :cai="cai"
                  :identities="identities"
                  :logoUrl="logo_url"
                  :audiences="audiences"
                  :onboarding_pic="onboarding_pic"
                  :focus_areas_types="focus_areas_types"
                  :welcome_video_seen="welcome_video_seen"
                  :welcome_video="welcome_video"
                  :areDeptsEnabled="are_depts_enabled"
                  :program_types="program_types"
                  />`,
      data() { return {..._class.data }},
      methods: {},
      mounted() {}
    });
    firstLogin.$mount(this.mountPoint);
  }
}