import Vue from 'vue';
import PartnerManage from './components/PartnerManage.vue'

interface AppData {
  partner,
  social_options: [],
  image_size_data,
  all_users: [],
  is_verify_partner?: boolean,
  focus_areas: [],
  destinations: [],
  career_academic_interests: [],
  opportunity_type: [],
  audiences: []
}



export default class PartnerManageView {
  protected mountPoint: HTMLElement;
  protected partner;
  protected social_options;
  protected image_size_data;
  protected all_users;
  protected is_verify_partner;
  protected focus_areas;
  protected career_academic_interests;
  protected destinations;
  protected opportunity_type;
  protected audiences;


  constructor(el: HTMLElement, appData:AppData) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.social_options = appData.social_options;
    this.image_size_data = appData.image_size_data;
    this.all_users = appData.all_users;
    this.partner = appData.partner;
    this.is_verify_partner = appData.is_verify_partner ? appData.is_verify_partner : false;
    this.focus_areas = appData.focus_areas;
    this.career_academic_interests = appData.career_academic_interests;
    this.destinations = appData.destinations;
    this.opportunity_type = appData.opportunity_type;
    this.audiences = appData.audiences;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const partnerManageView = new Vue({
      components: {PartnerManage},
      template: `
        <partner-manage
          :partner="partner"
          :social_options="social_options"
          :image_size_data="image_size_data"
          :all_users="all_users"
          :is_verify_partner="is_verify_partner"
          :focus_areas="focus_areas"
          :career_academic_interests="career_academic_interests"
          :destinations="destinations"
          :opportunity_type="opportunity_type"
          :audiences="audiences"
        />
      `,
      data() {
        return {
            partner: _class.partner,
            social_options: _class.social_options,
            image_size_data: _class.image_size_data,
            all_users: _class.all_users,
            is_verify_partner: _class.is_verify_partner,
            focus_areas: _class.focus_areas,
            career_academic_interests: _class.career_academic_interests,
            destinations: _class.destinations,
            opportunity_type: _class.opportunity_type,
            audiences: _class.audiences
        };
      },
      methods: {
      },
    });
    partnerManageView.$mount(this.mountPoint);
  }
}
