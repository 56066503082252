<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <div class="course-title-wrapper">
        <common-title
          title="Pathway Modules"
          description="Search for a course or pick from recommended classes."
        />
      </div>
      <section>
        <div id="main" class="eu-simple-navbar">
          <span
            tabindex="0"
            @click="changePage('browse')"
            @keyup.enter="changePage('browse')"
            class="eu-simple-navbar-item"
            :class="currentPage === 'browse' ? 'eu-simple-navbar-item-active' : ''"
            role="link"
            >Browse Courses
          </span>
          <span
            v-if="canManage"
            tabindex="0"
            @click="$router.push('/courses/manage')"
            @keyup.enter="$router.push('/courses/manage')"
            class="eu-simple-navbar-item"
            aria-label="Go to manage courses page"
          >
            Manage Courses</span
          >
          <span
            tabindex="0"
            role="link"
            @click="changePage('saved')"
            @keyup.enter="changePage('saved')"
            class="eu-simple-navbar-item"
            :class="currentPage === 'saved' ? 'eu-simple-navbar-item-active' : ''"
            >Saved Courses
          </span>
        </div>
        <search-component
          placeholder="Search courses"
          :displaySettings="true"
          :currentLayout="currentLayout"
          @search-data="filterCourses"
          @change-layout="changeLayout"
        />

        <div class="filter-container-courses">
          <multiselect-filter-component
            placeholder="ALL Courses"
            filter_name="Filters"
            :options="collections"
            @filter-data="createFilterData"
          />
        </div>
        <section>
          <ul v-if="!isLoading" class="eu-course-collection" :class="currentLayout">
            <li v-for="item in courses" :key="item.name" class="eu-course-card-wrapper">
              <div
                class="eu-course-collection-card"
                :class="currentLayout"
                v-if="currentPage === 'browse' || isBookmarked(item.id)"
              >
                <span
                  v-if="item.is_recommended || item.is_featured_for_audience"
                  class="inup-recommended-course-element"
                  :class="currentLayout"
                >
                  <i class="fas fa-stars"></i>
                  <span v-if="currentLayout === 'grid'">Featured</span>
                </span>
                <div
                  v-if="is_completed(item.id)"
                  class="dark-overlay-background"
                  :class="`dark-overlay-background-${currentLayout}`"
                >
                  <i class="checkmark-courses far fa-check-circle" :class="`checkmark-courses-${currentLayout}`"> </i>
                </div>
                <img
                  :src="item.course_card_image_url"
                  alt="Course cover image"
                  class="eu-course-collection-card-img"
                  :class="currentLayout"
                />
                <span
                  tabindex="0"
                  v-if="canManage"
                  :id="`course-options-dots-${item.id}`"
                  ref="menuDots"
                  class="course-options-dots"
                  :class="currentLayout"
                  @click="showManageCourseMenu(item.id)"
                  @keyup.enter="showManageCourseMenu(item.id)"
                  aria-label="Open menu"
                  role="button"
                  aria-haspopup="menu"
                >
                  <i class="fas fa-ellipsis-v"></i>
                </span>
                <div
                  :id="`course-burger-${item.id}`"
                  ref="menu"
                  v-closable="{ exclude: ['menuDots', 'menu'], handler: 'closeManageCourseMenu' }"
                  class="course-options-burger-menu"
                >
                  <div>
                    <label :for="`${item.id}`" class="inup-checkmark-container checkmark-label">
                      Mark as Featured
                      <input
                        :id="`${item.id}`"
                        type="checkbox"
                        v-model="item.is_recommended"
                        @change="toggleRecommendedCourse($event, item.id)"
                      />
                      <span class="inup-checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="eu-course-collection-body">
                  <router-link :aria-label="`Go to page of ${item.name} course`" :to="`/courses/${item.id}`">
                    <h5 class="eu-course-collection-title" :class="currentLayout">{{ item.name }}</h5>
                  </router-link>
                  <div class="eu-course-collection-details" :class="currentLayout">
                    <p>{{ item.description }}</p>
                  </div>
                  <div
                    tabindex="0"
                    @click="toggleBookmark(item.id)"
                    @keyup.enter="toggleBookmark(item.id)"
                    class="eu-course-collection-bookmark"
                    :class="currentLayout"
                    :aria-label="
                      isBookmarked(item.id)
                        ? `Remove ${item.name} from saved courses`
                        : `Add ${item.name} to saved courses`
                    "
                  >
                    <i
                      class="eu-course-collection-bookmark-icon"
                      :class="isBookmarked(item.id) ? 'fad fa-bookmark' : 'fal fa-bookmark'"
                      :style="isBookmarked(item.id) ? 'color: #4c55a7' : ''"
                    ></i>
                    <span v-if="!isBookmarked(item.id)">Save</span>
                    <span v-if="isBookmarked(item.id)" style="color: #4c55a7">Saved</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </section>
        <div v-if="isLoading">
          <loading-spinner wrapperClass="wrapper" />
        </div>
        <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
      </section>
    </div>
  </div>
</template>
<script lang="ts">
import * as Cookies from "js-cookie";
import handleOutsideClick from "../../directives/handleOutsideClick";
import CoursesService from "../services/CoursesService";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import Notification from "../../components/Notification.vue";
import { parseGETParamsIntoObject } from "../../utils/GETParametersUtil";
import MultiselectFilterComponent from "../../form/components/MultiselectFilterComponent.vue";
import SearchComponent from "../../components/search/SearchComponent.vue";
import { FEATURES, hasPermission, PERMISSION_TYPES } from "../../utils/PermissionUtils";
import CommonTitle from '../../components/CommonTitle.vue';

export default {
  name: "courses-browse",

  props: [],

  components: {
    CommonTitle,
    LoadingSpinner,
    Notification,
    MultiselectFilterComponent,
    SearchComponent,
  },

  directives: {
    closable: handleOutsideClick,
  },

  data() {
    return {
      initAllCourses: [],
      allCourses: [],
      courses: [],

      bookmarkedCourses: [],
      bookmarks: [],

      searchInput: "",
      lastSearchInput: null,
      lastOpenedMenu: null,

      currentLayout: localStorage.coursesView ? localStorage.coursesView : "grid",
      currentPage: "",
      isLoading: true,

      collections: [],
      completed: [],

      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },

      selectedCategory: [],

      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  computed: {
    canManage() {
      return hasPermission(FEATURES.COURSES, PERMISSION_TYPES.MANAGE);
    },
  },

  async created() {
    this.service = new CoursesService();

    await this.getPreData();
    await this.getCourses();
    this.sortCoursesByRecommended();
    this.setAllBookmarkedCourses();
    const params = parseGETParamsIntoObject();
    if (params.hasOwnProperty("page") && params["page"] === "saved") {
      this.changePage(params["page"]);
    } else {
      this.currentPage = "browse";
    }
  },

  async mounted() {
    this.initiateLayout();
  },

  methods: {
    async getPreData() {
      this.isLoading = true;
      const res = await this.service.getPredataCourses();
      if (res) {
        this.bookmarks = res.bookmark_ids;
        this.collections = res.collections;
        this.completed = res.completed;
      }
      this.isLoading = false;
    },
    async getCourses() {
      this.isLoading = true;
      const res = await this.service.getCourses();

      if (res) {
        this.allCourses = res;

        if (this.currentPage !== "saved") {
          this.courses = this.allCourses;
        }

        if (this.initAllCourses.length === 0) {
          this.initAllCourses = this.allCourses;
        }
      }

      this.setAllBookmarkedCourses();
      this.isLoading = false;
    },

    changeCategory(allCourses: boolean = true) {
      const category = this.selectedCategory;

      const selectFrom = allCourses ? this.allCourses : this.courses;
      const categoryCourseIdList = [];
      if (category.length === 0) {
        this.courses = selectFrom;
        return;
      }

      for (let c of category) {
        categoryCourseIdList.push(...c.product_ids);
      }

      this.courses = selectFrom.filter((course) => categoryCourseIdList.includes(course.product_id));
    },

    /* Sorts courses so recommended courses are shown first to user */
    sortCoursesByRecommended() {
      this.courses.sort(
        (a, b) => b.is_featured_for_audience - a.is_featured_for_audience || b.is_recommended - a.is_recommended
      ); // put featured audiences on top
    },

    setAllBookmarkedCourses() {
      this.bookmarkedCourses = this.initAllCourses.filter((e) => {
        for (const b of this.bookmarks) {
          if (e.id == b) {
            return true;
          }
        }

        return false;
      });
    },

    isBookmarked(course_id: number) {
      if (this.bookmarks.includes(course_id)) {
        return true;
      }

      return false;
    },

    async toggleBookmark(course_id: number) {
      let res = { success: false, bookmarks: null };

      if (this.isBookmarked(course_id)) {
        res = await this.service.toggleCourseBookmark({ course_id, is_bookmarked: false }, this.headers);
        if (res.success) {
          this.bookmarks = this.bookmarks.filter((e) => e != course_id);
          this.showNotification({
            msg: "Course successfully removed from saved courses.",
            isError: false,
            icon: "fad fa-check-circle",
          });
        } else {
          this.showNotification({
            msg: "Oops, something went wrong. Please reload and try again.",
            isError: true,
            icon: "fad fa-exclamation-circle",
          });
        }
      } else {
        res = await this.service.toggleCourseBookmark({ course_id, is_bookmarked: true }, this.headers);
        if (res.success) {
          this.bookmarks.push(`${course_id}`);
          this.showNotification({
            msg: "Course successfully saved.",
            isError: false,
            icon: "fad fa-check-circle",
          });
        } else {
          this.showNotification({
            msg: "Oops, something went wrong. Please reload and try again.",
            isError: true,
            icon: "fad fa-exclamation-circle",
          });
        }
      }

      if (res.success) {
        this.bookmarks = JSON.parse(res.bookmarks);
      }
    },

    filterCourses(searchInput) {
      if (searchInput.trim() === "") {
        this.courses = this.currentPage !== "saved" ? this.allCourses : this.bookmarkedCourses;
        this.lastSearchInput = null;
      } else {
        this.courses = this.fullTextSearch(this.allCourses, searchInput);

        this.lastSearchInput = searchInput;
      }

      this.changeCategory(false);
    },

    changePage(page) {
      if (this.currentPage !== page) {
        this.currentPage = page;

        if (page !== "saved") {
          this.courses = this.allCourses;
        } else if (page === "saved") {
          this.setAllBookmarkedCourses();
          this.courses = this.bookmarkedCourses;
        }
      }
    },

    fullTextSearch(items: Array<{ name: string; description: string }>, textInput: string) {
      const text = textInput.toLowerCase().split(" ");

      return items.filter(function (item) {
        return text.every(function (el) {
          return item.name.toLowerCase().indexOf(el) > -1 || item.description.toLowerCase().indexOf(el) > -1;
        });
      });
    },

    async toggleRecommendedCourse(e: Event, id: number) {
      this.closeManageCourseMenu();

      const target = e.target as HTMLInputElement;

      let result;

      if (target.checked) {
        result = await this.service.setRecommended({ course_id: id }, this.headers);
      } else {
        result = await this.service.removeRecommended({ course_id: id }, this.headers);
      }

      if (result.success) {
        this.showNotification({
          msg: result.success,
          isError: false,
          icon: "fad fa-check-circle",
        });
      } else if (result.error) {
        this.showNotification({
          msg: result.error,
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
    },

    showManageCourseMenu(id: number) {
      const burgerElement: HTMLElement = document.querySelector(`#course-burger-${id}`);

      if (this.lastOpenedMenu && this.lastOpenedMenu !== burgerElement) {
        this.lastOpenedMenu.classList.remove("active");
      }

      burgerElement.classList.toggle("active");

      this.lastOpenedMenu = burgerElement;
    },

    closeManageCourseMenu() {
      this.lastOpenedMenu && this.lastOpenedMenu.classList.remove("active");
    },

    showNotification(notification: { msg: string; isError: boolean; icon: string }) {
      this.notification = notification;
      this.isNotificationActive = true;
    },

    closeNotification() {
      this.isNotificationActive = false;
    },

    initiateLayout() {
      this.changeLayout(localStorage.coursesView);
    },

    changeLayout(targetLayout: string) {
      this.currentLayout = targetLayout;
      localStorage.coursesView = targetLayout;
    },

    is_completed(course_id) {
      return this.completed.includes(course_id) ? true : false;
    },
    createFilterData(value, name) {
      this.selectedCategory = value;
      this.changeCategory();
    },
  },
};
</script>

<style lang="scss" scoped>
.course-title-wrapper {
  padding: 24px 0;
}
</style>
