import Vue from "vue";
import TopNavbar from "./components/TopNavbar.vue"



export default class TopNavbarView {
  private readonly mountPoint: Element;

  constructor(el: HTMLElement) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);

    this.run();
  }

  async run(): Promise<void> {

    const navbarTop = new Vue({
      components: { TopNavbar },

      template: `
        <top-navbar />
      `,

      data() {
        return {
        };
      },
    });
    navbarTop.$mount(this.mountPoint);
  }
}
