import HttpService from '../../services/HttpService';


export default class ImportService extends HttpService {
  constructor() {
    super('/import/api');
  }

  getUsers(params): Promise<any> {
    this.endpoint = `/import/api`;
    return this.get(params);
  };
}
