<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <div class="guide-title-wrapper">
        <common-title title="Guides" description="Learn at your own pace, from anywhere." />
      </div>
      <navigation-tabs :navigationItems="navigationItems" />
      <section class="guides-content-section">
        <search-base
          id="search-guide"
          placeholder="Search by keyword or location"
          aria-label="Search guides by keywords or location"
          @search="filterGuides"
        ></search-base>
        <div class="filter-container-courses">
          <multiselect-filter-component
            placeholder="ALL Guides"
            filter_name="Filters"
            :options="collections"
            @filter-data="createFilterData"
          />
        </div>
        <section v-if="!isLoading" class="guides-container">
          <div
            v-for="(item, index) in guides"
            :key="index"
            class="guide-card"
            :class="item.is_recommended || item.is_featured_for_audience ? 'large' : ''"
          >
            <img :src="item.course_card_image_url" alt="Guide cover image" class="image" />
            <div class="tint"></div>
            <button
              class="dabi-bookmark-button guide-bookmark-button"
              :class="isBookmarked(item.id) ? 'active' : ''"
              @click="toggleBookmark(item.id)"
              :aria-label="`Toggle bookmark for course ${item.name}`"
            >
              <i class="fa-bookmark" :class="isBookmarked(item.id) ? 'fa-solid' : 'fa-regular'"></i>
            </button>
            <h3 class="title">
              <router-link :to="`/guides/${item.id}`">
                <i
                  v-if="item.is_recommended || item.is_featured_for_audience"
                  class="featured-star fa-solid fa-stars"
                ></i>
                {{ item.name }}
              </router-link>
            </h3>
          </div>
        </section>
        <div v-if="isLoading">
          <loading-spinner wrapperClass="wrapper" />
        </div>
        <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
      </section>
    </div>
  </div>
</template>
<script lang="ts">
import * as Cookies from "js-cookie";
import handleOutsideClick from "../../directives/handleOutsideClick";
import GuidesService from "../services/GuidesService";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import Notification from "../../components/Notification.vue";
import MultiselectFilterComponent from "../../form/components/MultiselectFilterComponent.vue";
import SearchBase from "../../components/formBase/SearchBase.vue";
import NavigationTabs from "../../components/NavigationTabs.vue";
import { getNavigationItems } from "../utils/utils";
import CommonTitle from "../../components/CommonTitle.vue";

export default {
  name: "guides-browse",

  props: ["isAdmin"],

  components: {
    LoadingSpinner,
    Notification,
    MultiselectFilterComponent,
    SearchBase,
    NavigationTabs,
    CommonTitle,
  },

  directives: {
    closable: handleOutsideClick,
  },
  computed: {
    canManage() {
      return this.isAdmin;
    },
    navigationItems() {
      return getNavigationItems(this.isAdmin);
    },
  },
  data() {
    return {
      initAllGuides: [],
      allGuides: [],
      guides: [],

      bookmarkedGuides: [],
      bookmarks: [],
      collections: [],

      searchInput: "",
      lastSearchInput: null,
      lastOpenedMenu: null,

      isLoading: true,

      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },

      selectedCategory: [],

      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },

  async created() {
    this.service = new GuidesService();
    await this.getPreData();
    await this.getGuides();
    this.sortGuidesByRecommended();
  },

  methods: {
    async getPreData() {
      this.isLoading = true;
      const res = await this.service.getPredataGuides();
      if (res) {
        this.bookmarks = res.bookmark_ids;
        this.collections = res.collections;
      }
      this.isLoading = false;
    },
    async getGuides() {
      this.isLoading = true;
      const res = await this.service.getGuides();

      if (res) {
        this.allGuides = res;

        this.guides = this.allGuides;

        if (this.initAllGuides.length === 0) {
          this.initAllGuides = this.allGuides;
        }
      }

      this.isLoading = false;
    },

    changeCategory(allGuides: boolean = true) {
      const category = this.selectedCategory;

      const selectFrom = allGuides ? this.allGuides : this.guides;
      const categoryGuideIdList = [];
      if (category.length === 0) {
        this.guides = selectFrom;
        return;
      }

      for (let c of category) {
        categoryGuideIdList.push(...c.product_ids);
      }

      this.guides = selectFrom.filter((guide) => categoryGuideIdList.includes(guide.product_id));
    },

    /* Sorts guides so recommended guides are shown first to user */
    sortGuidesByRecommended() {
      this.guides.sort(
        (a, b) => b.is_featured_for_audience - a.is_featured_for_audience || b.is_recommended - a.is_recommended
      ); // put featured audiences on top
    },

    isBookmarked(guide_id: number) {
      if (this.bookmarks.includes(guide_id)) {
        return true;
      }
      return false;
    },

    async toggleBookmark(guide_id: number) {
      let res = { success: false, bookmarks: null };

      if (this.isBookmarked(guide_id)) {
        res = await this.service.toggleGuideBookmark({ guide_id, is_bookmarked: false }, this.headers);
        if (res.success) {
          //this.bookmarks = this.bookmarks.filter((e) => e != guide_id);
          this.showNotification({
            msg: "Guide successfully removed from saved guides.",
            isError: false,
            icon: "fad fa-check-circle",
          });
        } else {
          this.showNotification({
            msg: "Oops, something went wrong. Please reload and try again.",
            isError: true,
            icon: "fad fa-exclamation-circle",
          });
        }
      } else {
        res = await this.service.toggleGuideBookmark({ guide_id, is_bookmarked: true }, this.headers);
        if (res.success) {
          //this.bookmarks.push(`${guide_id}`);
          this.showNotification({
            msg: "Guide successfully saved.",
            isError: false,
            icon: "fad fa-check-circle",
          });
        } else {
          this.showNotification({
            msg: "Oops, something went wrong. Please reload and try again.",
            isError: true,
            icon: "fad fa-exclamation-circle",
          });
        }
      }

      if (res.success) {
        this.bookmarks = JSON.parse(res.bookmarks);
      }
    },

    filterGuides(searchInput) {
      if (searchInput.trim() === "") {
        this.guides = this.allGuides;
        this.lastSearchInput = null;
      } else {
        this.guides = this.fullTextSearch(this.allGuides, searchInput);

        this.lastSearchInput = searchInput;
      }

      this.changeCategory(false);
    },

    fullTextSearch(items: Array<{ name: string; description: string }>, textInput: string) {
      const text = textInput.toLowerCase().split(" ");

      return items.filter(function (item) {
        return text.every(function (el) {
          return item.name.toLowerCase().indexOf(el) > -1 || item.description.toLowerCase().indexOf(el) > -1;
        });
      });
    },

    async toggleRecommendedGuide(e: Event, id: number) {
      this.closeManageGuideMenu();

      const target = e.target as HTMLInputElement;

      let result;

      if (target.checked) {
        result = await this.service.setRecommended({ guide_id: id }, this.headers);
      } else {
        result = await this.service.removeRecommended({ guide_id: id }, this.headers);
      }

      if (result.success) {
        this.showNotification({
          msg: result.success,
          isError: false,
          icon: "fad fa-check-circle",
        });
      } else if (result.error) {
        this.showNotification({
          msg: result.error,
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
    },

    showManageGuideMenu(id: number) {
      const burgerElement: HTMLElement = document.querySelector(`#course-burger-${id}`);

      if (this.lastOpenedMenu && this.lastOpenedMenu !== burgerElement) {
        this.lastOpenedMenu.classList.remove("active");
      }

      burgerElement.classList.toggle("active");

      this.lastOpenedMenu = burgerElement;
    },

    closeManageGuideMenu() {
      this.lastOpenedMenu && this.lastOpenedMenu.classList.remove("active");
    },

    showNotification(notification: { msg: string; isError: boolean; icon: string }) {
      this.notification = notification;
      this.isNotificationActive = true;
    },

    closeNotification() {
      this.isNotificationActive = false;
    },

    createFilterData(value, name) {
      this.selectedCategory = value;
      this.changeCategory();
    },
  },
};
</script>
<style lang="scss" scoped>
.guide-title-wrapper {
  padding: 24px 0;
}
</style>
