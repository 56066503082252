import Vue from 'vue';
import GroupManage from './components/GroupManage.vue'

interface AppData {
  group,
  social_options: [],
  image_size_data,
  all_users: [],
  identities: [],
  focus_areas: []
}



export default class GroupManageView {
  protected mountPoint: HTMLElement;
  protected group;
  protected social_options;
  protected image_size_data;
  protected all_users;
  protected identities;
  protected focus_areas;


  constructor(el: HTMLElement, appData:AppData) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.group = appData.group;
    this.social_options = appData.social_options;
    this.image_size_data = appData.image_size_data;
    this.all_users = appData.all_users;
    this.identities = appData.identities;
    this.focus_areas = appData.focus_areas
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const groupManageView = new Vue({
      components: {GroupManage},
      template: `
        <group-manage
          :group="group"
          :social_options="social_options"
          :image_size_data="image_size_data"
          :all_users="all_users"
          :identities="identities"
          :focus_areas="focus_areas"
        />
      `,
      data() {
        return {
            group: _class.group,
            social_options: _class.social_options,
            image_size_data: _class.image_size_data,
            all_users: _class.all_users,
            identities: _class.identities,
            focus_areas: _class.focus_areas
        };
      },
      methods: {
      },
    });
    groupManageView.$mount(this.mountPoint);
  }
}