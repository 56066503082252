<template>
  <div :class="label ? `has-label ${wrapperClass}` : wrapperClass" class="input-base-wrapper">
    <input
      :id="id"
      v-bind="$attrs"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('keyupEnter', $event.target.value)"
      class="input-base-field"
      :class="isError ? 'error' : ''"
      :style="inputStyle"
    >
    <label
      v-if="label"
      :for="id"
      class="input-base-label"
    >{{ label }} <span class="input-base-required-star" v-if="required">*</span></label>
    <div v-if="isType('number')" class="input-base-number-arrows-container">
      <button
        class="input-base-number-arrow-button"
        :class="isNumberButtonDisabled() ? 'disabled' : ''"
        aria-label="Increase input number by one"
        @click.prevent="updateInputNumber(1)"
      >
        <i class="fa-solid fa-caret-up"></i>
      </button>
      <button
        class="input-base-number-arrow-button"
        :class="isNumberButtonDisabled() ? 'disabled' : ''"
        aria-label="Decrease input number by one"
        @click.prevent="updateInputNumber(-1)"
      >
        <i class="fa-solid fa-caret-down"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'input-base',
  props: {
    wrapperClass: {
      type: String,
      required: false,
    },
    required : {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    inputStyle: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  inheritAttrs: false,
  methods: {
    isType(type) {
      return this.$attrs.type === type;
    },
    updateInputNumber(number) {
      const intValue = typeof(this.value) === 'string' ? parseInt(this.value): this.value;
      this.$emit('input', intValue + number);
    },
    isNumberButtonDisabled() {
      return this.$attrs.disabled;
    },
  }
}
</script>

<style lang="scss">
.input-base {
  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    &.has-label {
      height: 48px;
    }
  }

  &-required-star {
    font-size: 13px;
    color: #e65722;
    font-weight: 600;
  }

  &-label {
    background-color: #fff;
    padding: 0 6px 2px;
    color: #3b3355;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    bottom: 32px;
    left: 6px;
    width: unset !important;
  }

  &-field {
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    height: 40px;
    padding: 9px 16px;
    border-radius: 6px;
    border: solid 1.5px #95919b;
    width: 100%;

    font-size: 16px;
    line-height: 1.35;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      border-color: #95919b;
      pointer-events: none;

      & + label {
        color: #95919b;

        >.input-base-required-star {
          color: #95919b;
        }
      }
    }

    &:focus {
      outline: none;
      border-color: #685df3;
    }

    &:active {
      border-color: #685df3;
    }

    &.error {
      border-color: #e56722 !important;
      color: #4A4A4A !important;
    }
  }

  &-number-arrows-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;
    height: 100%;
  }

  &-number-arrow-button {
    border: none;
    background-color: transparent;
    color: #555555;
    font-size: 16px;

    width: 16px;
    height: 16px;
    border-radius: 2px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      background-color: #e7e5ec;
    }

    &.disabled {
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .input-base {
    &-field {
      height: 36px;
      font-size: 16px;
      padding: 0 12px;
    }

    &-number-arrows-container {
      display: none;
    }
  }
}
</style>
