<template>
  <div class="table-container" style="width: 100%; overflow-y: hidden;overflow-x: auto; margin: 24px 0px 30px 0px;">
    <table class="eu-resource-table">
      <tr class="eu-resource-row">
        <th scope="col" class="eu-resource-cell eu-resource-cell-th">
          <span
            tabindex="0"
            @click="toggleAll()"
            @keyup.enter="toggleAll()"
            class="eu-resource-cell-select"
            :class="selectedAll ? 'eu-resource-cell-select-active' : ''"
            aria-label="Select all mentors"
            role="button"
          ></span>
          <span 
            tabindex="0"
            @click="sort('first_name')"
            @keyup.enter="sort('first_name')"
            aria-label="sort mentors by first name"
            role="button"
           >
            Name
            <i :class="getSortIcon('first_name')"></i>
          </span>
        </th>
        <th scope="col" class="eu-resource-cell eu-resource-cell-th">
          <span 
            tabindex="0"
            @click="sort('email')"
            @keyup.enter="sort('email')"
            aria-label="sort mentors by email"
            role="button"
          >
            Email
            <i :class="getSortIcon('email')"></i>
          </span>
        </th>
        <th scope="col" class="eu-resource-cell eu-resource-cell-th">
          <span 
            tabindex="0"
            @click="sort('date_joined')"
            @keyup.enter="sort('date_joined')"
            aria-label="Sort mentors by date joined"
            role="button"
            >
            Added
          <i :class="getSortIcon('date_joined')"></i>
          </span>
        </th>
        <th scope="col" class="eu-resource-cell eu-resource-cell-th">
          <span 
            tabindex="0" 
            @click="sort('status')"
            @keyup.enter="sort('status')"
            aria-label="sort mentors by account status"
            role="button"
            >
            Status
          <i :class="getSortIcon('status')"></i>
          </span>
        </th>
        <th></th>
      </tr>
      <tr
        v-for="(item, i) in mentors"
        :key="i"
        class="eu-resource-row"
        :class="selected.includes(item.id) ? 'eu-resource-row-selected' : ''"
      >
        <td class="eu-resource-cell">
            <span
              tabindex="0"
              @click="toggleMentor(item.id)"
              @keyup.enter="toggleMentor(item.id)"
              :aria-label="`Select ${item.full_name}`"
              role="button"
              class="eu-resource-cell-select"
              :class="selected.includes(item.id) ? 'eu-resource-cell-select-active' : ''"
            ></span>
            <a target="_blank" :href="`/profiles/${item.profile_url}`" class="bold">{{ item.full_name }}</a>
        </td>
        <td class="eu-resource-cell">
          {{ item.email }}
        </td>
        <td class="eu-resource-cell">
          {{ item.added }}
        </td>
        <td class="eu-resource-cell">
          {{ userStatuses[i] }}
        </td>

        <td ref="menuDots" class="eu-resource-cell eu-resource-cell-dots-icon">
          <span 
            tabindex="0" 
            :id="`mentor_connect_manage_table_${item.id}`"
            @click="showMenu(item)" 
            @keyup.enter="showMenu(item)" 
            aria-label="Burger menu"
            role="menu"
          > 
            <i class="fas fa-ellipsis-v"></i>
          </span>
        </td>

        <div
          :id="'i' + item.id"
          ref="menu"
          v-closable="{exclude:['menuDots', 'menu',], handler: 'closeMenu'}"
          class="eu-resource-burger"
          style="width: 180px;"
        >
          <span 
            tabindex="0" 
            class="eu-resource-burger-el" 
            @click="removeMentor(item)" 
            @keyup.enter="removeMentor(item)"
            :aria-label="`Open delete ${item.full_name} mentor modal`"
            role="button"
            aria-haspopup="dialog"
          >
            <i class="fas fa-trash-alt" style="padding-right: 5px;"></i>
            <span>Delete</span>
          </span>
        </div>
      </tr>
    </table>
  </div>
</template>
<script lang="ts">
import handleOutsideClick from '../../../directives/handleOutsideClick';

export default {
  name: "mentor-table",

  directives: { closable: handleOutsideClick },

  props: [
    'mentors',
    'sortDirection',
    'sortBy',
    'selected',
    'selectedAll',
  ],

  data() {
    return {};
  },

  computed: {
    userStatuses() {
      return this.mentors.map(function(item) {
        if (item.disabled) {
          return "Disabled"
        }

        if (item.account_validated) {
          return "Active"
        }

        if (item.email_status) {
          return "Not active"
        } else {
          return "Invitation not sent"
        }
      });
    },
  },

  methods: {

    getSortIcon(sortValue: string) {
      if (this.sortBy !== sortValue) {
        return 'fas fa-sort';
      }

      if (this.sortDirection === 'up') {
        return 'fad fa-sort-up';
      } else {
        return 'fad fa-sort-down';
      }
    },

    showMenu(item) {
      const menuEl = document.querySelector(`#i${item.id}`);

      if (this.lastOpenedMenu && this.lastOpenedMenu != menuEl) {
        this.lastOpenedMenu.classList.remove('eu-resource-burger-active');
      }

      menuEl.classList.toggle('eu-resource-burger-active');

      this.lastOpenedMenu = menuEl;
    },

    closeMenu(){
      this.lastOpenedMenu && this.lastOpenedMenu.classList.remove('eu-resource-burger-active');
    },

    sort(column: string) {
      this.$emit('sort', column);
    },

    toggleMentor(id: number) {
      this.$emit('toggle-mentor', id);
    },

    toggleAll() {
      this.$emit('toggle-all');
    },

    toggleSuspend(id: number) {
      this.$emit('toggle-suspend', id);
    },

    editMentor(item) {
      this.closeMenu();
      this.$emit('edit-mentor', item);
    },

    sendEmail(id) {
      this.$emit('send-email', id);
    },

    removeMentor(item) {
      this.$emit('remove-mentor', item);
    },

    resetPassword(email: string) {
      this.closeMenu();
      this.$emit('reset-password', email);
    },
  },
}
</script>