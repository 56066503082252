<template>
  <span style="position: relative; z-index: 9" :class="componentContainerClass">
    <span
      v-closable="{
        exclude: ['button'],
        handler: 'closeOptionsDropdown'
      }"
    >
      <button
        class="iu-main-btn small solid main"
        style="padding: 9px 18px; min-width: 170px"
        :class="btnClassName"
        @click="toggleOptionsDropdown()"
        aria-haspopup="menu"
      >
        Upload a File <i class="fa-solid fa-chevron-down iu-main-button-arrow-icon"></i>
      </button>
      <div class="eu-upload-dropdown" :class="dropdownContainerClass" :style="dropdownContainerStyle" v-if="optionsDropdownVisible">
        <span
          tabindex="0"
          class="eu-upload-dropdown-element"
          @click="openFileModal()"
          @keyup.enter="openFileModal()"
          aria-label="Open upload modal"
          aria-haspopup="dialog"
          role="button"
        >
        <i class="fa-regular fa-upload eu-upload-icon"></i>Upload from your device
      </span>
        <span
          tabindex="0"
          class="eu-upload-dropdown-element"
          @click="openVideoModal()"
          @keyup.enter="openVideoModal()"
          aria-label="Open modal for embedding video links"
          aria-haspopup="dialog"
          role="button"
        >
          <i class="fas fa-video-plus eu-upload-icon"></i>Embed video from link
        </span>
        <span
          tabindex="0"
          class="eu-upload-dropdown-element"
          @click="openUrlModal()"
          @keyup.enter="openUrlModal()"
          aria-label="Open modal for url upload"
          aria-haspopup="dialog"
          role="button"
        >
          <i class="fas fa-link eu-upload-icon"></i>Upload internal/external URL
        </span>
      </div>
    </span>

    <upload-file-modal
      :isModalActive="isFileModalActive"
      :showShareBtn="showShareBtn"
      :currentDepartment="currentDepartment"
      :department_name="department_name ? department_name : ''"
      :filterData="filterData"
      :list_departments="list_departments"
      :currentGroup="currentGroup"
      :currentPartner="currentPartner"
      :isPartnerTenant="isPartnerTenant"
      :isPartnerTab="isPartnerTab"
      @close-file-modal="closeFileModal"
      @reloadResources="reloadResources"
    />

    <upload-video-modal
      :isModalActive="isVideoModalActive"
      :showShareBtn="showShareBtn"
      :currentDepartment="currentDepartment"
      :department_name="department_name ? department_name : ''"
      :filterData="filterData"
      :currentGroup="currentGroup"
      :currentPartner="currentPartner"
      :isPartnerTenant="isPartnerTenant"
      :isPartnerTab="isPartnerTab"
      :list_departments="list_departments"
      @close-video-modal="closeVideoModal"
      @reloadResources="reloadResources"
    />


    <upload-url-modal
      :isModalActive="isUrlModalActive"
      :showShareBtn="showShareBtn"
      :currentDepartment="currentDepartment"
      :department_name="department_name ? department_name : ''"
      :filterData="filterData"
      :list_departments="list_departments"
      :currentGroup="currentGroup"
      :currentPartner="currentPartner"
      :isPartnerTenant="isPartnerTenant"
      :isPartnerTab="isPartnerTab"
      @close-url-modal="closeUrlModal"
      @reloadResources="reloadResources"
    />
  </span>
</template>
<script lang="ts">
import Modal from '../../components/ModalExtended.vue';
import handleOutsideClick from '../../directives/handleOutsideClick';
import Multiselect from 'vue-multiselect';

import UploadFileModal from "./modals/UploadFileModal.vue"
import UploadVideoModal from "./modals/UploadVideoModal.vue"
import UploadUrlModal from "./modals/UploadUrlModal.vue"

export default {
  //department_name: users department
  //currentDepartment: this is populated only if we are on a departments page
  components: {
    Modal,
    Multiselect,
    UploadFileModal,
    UploadVideoModal,
    UploadUrlModal,
  },
  directives: { closable: handleOutsideClick },
  name: 'resources-upload',
  props: ['filterData',
          'btnClassName',
          'showShareBtn',
          'currentDepartment',
          'currentGroup',
          'currentPartner',
          'isPartnerTenant',
          'iconColor',
          'isAdminRole',
          'department_name',
          'list_departments',
          'dropdownContainerStyle',
          'dropdownContainerClass',
          'componentContainerClass',
          'isPartnerTab'
        ],
  data() {
    return {
      optionsDropdownVisible: false,
      isFileModalActive: false,
      isVideoModalActive: false,
      isUrlModalActive: false,
    };
  },
  methods: {
    toggleOptionsDropdown() {
      this.optionsDropdownVisible = !this.optionsDropdownVisible;
    },
    closeOptionsDropdown() {
      this.optionsDropdownVisible = false;
    },
    openFileModal() {
      this.isFileModalActive = true;
      this.toggleSidebar(false);
    },
    openVideoModal() {
      this.isVideoModalActive = true;
      this.toggleSidebar(false);
    },
    openUrlModal() {
      this.isUrlModalActive = true;
      this.toggleSidebar(false);
    },
    closeFileModal() {
      this.isFileModalActive = false;
      this.toggleSidebar(true);
    },
    closeVideoModal() {
      this.isVideoModalActive = false;
      this.toggleSidebar(true);
    },
    closeUrlModal() {
      this.isUrlModalActive = false;
      this.toggleSidebar(true);
    },

    reloadResources() {
      this.$emit('reloadResources');
    },

    toggleSidebar(show) {
      this.$emit('toggle-sidebar')
    },
  },

  computed: {}
};
</script>

<style lang="scss" scoped>
.community-library-button-override.partner {
  background-color: #2d98d2;

  &:hover {
    background-color: #006ead;
  }
}
</style>
