import HttpService from '../../services/HttpService';
import * as Cookies from 'js-cookie';

const headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class DashboardService extends HttpService {
  constructor() {
    super('/dashboard/api');
  }
  getDashboardPreData(params): Promise<any> {
    this.endpoint = `/dashboard/api/predata`;
    return this.get(params);
  }
  getDashboardAdminPreData(params): Promise<any> {
    this.endpoint = `/dashboard/api/admin-predata`;
    return this.get(params);
  }
  getDashboardData(params): Promise<any> {
    this.endpoint = `/dashboard/api/data`;
    return this.get(params);
  }
  getCalendarData(params): Promise<any> {
    this.endpoint = `/dashboard/api/calendar/data`;
    return this.get(params);
  }
  getAvailableFeaturesFromLicense(params): Promise<any> {
    this.endpoint = `/dashboard/api/availableFeatures`;
    return this.get(params);
  }
  closeWelcomeVideo(userId) {
    this.endpoint = `/accounts/api/user/${userId}/welcome_video`;
    return this.put({welcome_video_seen: true}, headers);
  }
}
