<template>
  <section class="main-container">
    <div class="lg-space-between">
      <section style="margin-top: 24px">
        <h1 v-if="$route.params.id">Edit Quest</h1>
        <h1 v-else>Create a Quest</h1>
      </section>
      <button aria-label="Add new Quest" class="iu-btn-light big title-btn" @click="changePage('/quests/')">
        Back/Cancel
      </button>
    </div>
    <form v-if="!isLoading" class="ca-form" @submit.prevent="" id="eu-form" enctype="multipart/form-data">
      <div class="ca-form-section">
        <h3 style="margin-top: 24px">Quest Details</h3>

        <div class="inup-form-element-container" style="margin-top: 24px">
          <input type="text" v-model="quest.name" id="quest_name" class="inup-input" maxlength="255" required="" />
          <div class="inup-form-label-container">
            <label for="quest_name">Quest Name <span>*</span></label>
          </div>
        </div>

        <div class="inup-form-element-container">
          <textarea
            name="description"
            cols="40"
            rows="6"
            v-model="quest.description"
            id="desc"
            class="inup-textarea-input no-resize"
            placeholder="Enter details about the Quest"
          />
          <div class="inup-form-label-container">
            <label for="desc">Description <span>*</span></label>
          </div>
        </div>
        <div class="flex-row-wrapper">
          <div
            class="multiselect-wrapper"
            style="width: 100%; margin: 0; margin-bottom: 24px; position: relative; height: 50px"
          >
            <multiselect
              v-model="quest.identities"
              :options="identities"
              :multiple="true"
              :max="5"
              label="name"
              track-by="name"
              :close-on-select="false"
              placeholder=""
            >
            </multiselect>
            <i
              class="fal fa-angle-down eu-select-arrow"
              style="top: -35px; z-index: 52; cursor: pointer; left: 93%"
            ></i>
            <div class="inup-form-label-container">
              <label>Identity</label>
            </div>
          </div>
          <div
            class="multiselect-wrapper"
            style="width: 100%; margin: 0; margin-bottom: 24px; position: relative; height: 50px"
          >
            <multiselect
              v-model="quest.focus_areas"
              :options="focus_areas"
              :multiple="true"
              :max="5"
              label="name"
              track-by="name"
              :close-on-select="false"
              placeholder=""
            >
            </multiselect>
            <i
              class="fal fa-angle-down eu-select-arrow"
              style="top: -35px; z-index: 52; cursor: pointer; left: 93%"
            ></i>
            <div class="inup-form-label-container">
              <label>Focus Areas</label>
            </div>
          </div>
        </div>
        <div class="flex-row-wrapper">
          <div class="inup-form-element-container" style="width: 100%; margin-top: 0">
            <datetime
              type="date"
              class="datetime-theme-blues"
              v-model="quest.due_date"
              input-class="inup-input"
              :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
              :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
              :min-datetime="new Date().toISOString()"
              :week-start="7"
              title="Due Date"
              use12-hour
            ></datetime>
            <div class="inup-form-label-container">
              <label>Due Date</label>
            </div>
            <!-- error -->
          </div>
          <div
            class="multiselect-wrapper"
            style="width: 100%; margin: 0; margin-bottom: 24px; position: relative; height: 50px"
          >
            <multiselect
              v-model="quest.contacts"
              :options="contacts"
              :multiple="true"
              :max="5"
              label="name"
              track-by="name"
              :close-on-select="false"
              :placeholder="isAdmin && !quest.department ? `*You must select a ${deptSingular} first` : ''"
            >
            </multiselect>
            <i
              class="fal fa-angle-down eu-select-arrow"
              style="top: -35px; z-index: 52; cursor: pointer; left: 93%"
            ></i>
            <div class="inup-form-label-container">
              <label>Contacts</label>
            </div>
          </div>
        </div>
        <div class="flex-row-wrapper">
          <div
            class="multiselect-wrapper"
            style="width: 100%; margin: 0; margin-bottom: 24px; position: relative; height: 50px"
          >
            <multiselect
              v-model="quest.audiences"
              :options="audiences"
              :multiple="true"
              :max="5"
              label="name"
              track-by="name"
              :close-on-select="false"
              placeholder=""
            >
            </multiselect>
            <i
              class="fal fa-angle-down eu-select-arrow"
              style="top: -35px; z-index: 52; cursor: pointer; left: 93%"
            ></i>
            <div class="inup-form-label-container">
              <label>Audience</label>
            </div>
          </div>

          <div class="inup-form-element-container" style="width: 100%">
            <input
              type="text"
              placeholder="Embed video link"
              v-model="quest.video_url"
              id="name"
              class="inup-input"
              maxlength="255"
              required=""
            />
            <div class="inup-form-label-container">
              <label for="name">Embed Video</label>
            </div>
          </div>
        </div>
        <div
          v-if="isAdmin"
          class="multiselect-wrapper single-select"
          style="width: 100%; margin: 0; margin-bottom: 24px; position: relative; height: 50px"
        >
          <multiselect
            v-model="quest.department"
            :options="departments"
            @input="getFilterData"
            :multiple="false"
            label="name"
            track-by="name"
            :close-on-select="true"
            :allow-empty="false"
            placeholder=""
          >
          </multiselect>
          <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer; left: 93%"></i>
          <div class="inup-form-label-container">
            <label>{{deptSingular}} <span>*</span></label>
          </div>
        </div>
        <div class="flex-row-wrapper">
          <label class="eu-checkbox-container autoW"
            >Published
            <input tabindex="0" type="checkbox" v-model="quest.is_published" name="dont_show_onboarding" />
            <span class="eu-checkmark"></span>
          </label>
          <label class="eu-checkbox-container autoW"
            >Required
            <input tabindex="0" type="checkbox" v-model="quest.is_required" name="dont_show_onboarding" />
            <span class="eu-checkmark"></span>
          </label>
          <label class="eu-checkbox-container autoW"
            >Featured
            <input tabindex="0" type="checkbox" v-model="quest.is_featured" name="dont_show_onboarding" />
            <span class="eu-checkmark"></span>
          </label>
        </div>
        <div class="profile-form-section dropzone-container">
          <h3>Cover Photo</h3>
          <p class="inup-thumbnail-desc">Use a high quality, landscape orientation image for best results.</p>
          <dropzone-component
            v-if="imageInput"
            :imageSrc="quest.image_url"
            :imageInput="imageInput"
            :maxImageSize="2621440"
            maxImageSizeFormat="2 MB"
            @uploadImage="prepareImageUpload"
            @removeImage="handleRemoveImage"
          />
          <input
            type="file"
            name="thumbnail"
            id="questImg"
            class="js-input-file-control da-components-hidden-file-control"
            accept="image/*"
            maxlength="400"
          />
        </div>
      </div>
      <div class="flex-space-between">
        <div class="h-max-content">
          <button
            aria-label="Add new Quest, continue to Task creation"
            class="iu-btn-solid big title-btn"
            @click.prevent="submitForm"
          >
            Next
          </button>
          <button aria-label="Cancel" class="iu-btn-light big title-btn" @click.prevent="changePage('/quests/')">Cancel</button>
        </div>
        <button
          v-if="mode != 'add'"
          aria-label="Add new Quest, continue to Task creation"
          class="iu-btn-solid big title-btn red"
          @click.prevent="openDeleteModal(quest)"
        >
          Delete Quest
        </button>
      </div>
    </form>
    <div v-if="isLoading">
      <loading-spinner wrapperClass="wrapper" />
    </div>
    <Modal :isActiveProp="isDeleteModalActive" @close="closeDeleteModal">
      <div class="iu-resources-delete-modal-content-container">
        <div class="iu-resources-delete-modal-text-section">Are you sure you want to delete this Quest?</div>
        <div class="iu-resources-delete-modal-file-details-section">
          <div class="iu-resources-delete-modal-file-name">
            {{ itemToDelete.name }}
          </div>
        </div>
        <div class="iu-resources-delete-modal-actions-section">
          <button
            id="quest_delete"
            class="iu-resources-delete-modal-actions-button iu-main-btn small solid red"
            @click="deleteItem(itemToDelete.id)"
            aria-label="Remove an item"
          >
            Delete
          </button>
          <button
            class="iu-resources-delete-modal-actions-button iu-main-btn small outlined gray"
            @click="closeDeleteModal"
            aria-label="Close delete modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import Modal from "../../components/ModalExtended.vue";
import { Datetime } from "vue-datetime";
import DropzoneComponent from "../../form/components/DropzoneComponent.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import FeedService from "../../feed/services/FeedService";
import QuestService from "../services/QuestService";
import Notification from "../../components/Notification.vue";
import { isURLInvalid, focusElement } from "../../utils/CustomUtils";
import { useCommonDataStore } from '../../stores/commonData';
const msgs = {
  addSuccess: "Quest Successfully Created.",
  addErr: "Error while creating Quest.",
  updateSuccess: "Quest Successfully Updated.",
  updateErr: "Error while updating Quest.",
};

export default {
  name: "quests-form",
  components: { DropzoneComponent, Multiselect, Modal, LoadingSpinner, Notification, Datetime },
  props: ["isAdmin", "isDeptLead"],
  computed: {
    deptSingular() {
      return useCommonDataStore().getFeatureName('department_singular')
    },
    deptSingularToLowerCase() {
      return useCommonDataStore().getFeatureName('department_singular').toLowerCase()
    },
  },
  data() {
    return {
      isDeleteModalActive: false,
      itemToDelete: { id: null, name: null },
      imageInput: null,
      image: {},
      image_size_data: { max_size: 2621440, formatted: "2.5 MB" },
      focus_areas: [],
      identities: [],
      departments: [],
      audiences: [],
      contacts: [],
      isLoading: false,
      feedService: new FeedService(),
      service: new QuestService(),
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      mode: null,
      quest: {
        name: "",
        description: "",
        focus_areas: [],
        identities: [],
        audiences: [],
        department: null,
        due_date: null,
        contacts: [],
        video_url: "",
        image_url: "",
        is_featured: false,
        is_published: false,
        is_required: false,
      },
    };
  },
  async created() {
    this.setMode();
  },
  methods: {
    async setMode() {
      if (this.$route.path.startsWith("/quests/add")) {
        this.mode = "add";
        if (!this.isAdmin && !this.isDeptLead) {
          this.$router.push(`/quests/`);
        }
      } else {
        const questId = this.$route.path.split("/").pop();
        this.mode = "update";
        this.isLoading = true;
        this.quest = await this.service.getOne(questId);
        if (this.isDeptLead !== this.quest.department.name && !this.isAdmin) {
          this.$router.push(`/quests/`);
        }
        this.isLoading = false;
      }
      await this.getFilterData(this.quest.department);
      this.getLogoInput();
    },
    getLogoInput() {
      setTimeout(() => {
        this.imageInput = document.querySelector("#questImg");
      }, 0);
    },
    openDeleteModal(item) {
      this.isDeleteModalActive = true;
      focusElement("quest_delete");
      this.itemToDelete = { ...item };
    },
    closeDeleteModal() {
      focusElement("quest_name");
      this.isDeleteModalActive = false;
    },
    async deleteItem(appId) {
      this.isLoading = true;
      this.isDeleteModalActive = false;
      const res = await this.service.remove(appId);
      if (res.success) {
        this.showNotification({ msg: "Quest removed successfully.", icon: "fad fa-check-circle" });
        this.changePage("/quests/");
      } else {
        this.showNotification({
          msg: "Error while removing a Quest",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
      this.isLoading = false;
    },
    async getFilterData(dept = null) {
      dept = !this.isAdmin && this.isDeptLead ? this.isDeptLead : dept ? dept.name : null;
      const params = dept ? { dept_contacts: dept } : null;
      const { focus_areas, identities, departments, audiences, contacts } = await this.service.getFilterData(params);
      this.focus_areas = focus_areas;
      this.departments = departments;
      this.identities = identities;
      this.audiences = audiences;
      this.contacts = contacts.map((c) => {
        return { id: c.id, name: `${c.first_name} ${c.last_name}` };
      });
    },
    isSelected(selected, key) {
      this.quest[key] = selected;
    },
    changePage(page) {
      this.$router.push(page);
    },
    parseData() {
      const questDTO = { ...this.quest };
      questDTO.identities = this.quest.identities.map((e) => e.id);
      questDTO.focus_areas = this.quest.focus_areas.map((e) => e.id);
      questDTO.audiences = this.quest.audiences.map((e) => e.id);
      questDTO.contacts = this.quest.contacts.map((e) => e.id);
      if (!this.isDeptLead) {
        questDTO.department = this.quest.department.id;
      }
      if (!this.quest.due_date) {
        delete questDTO.due_date;
      } else {
        questDTO.due_date = this.quest.due_date.split("T")[0];
      }
      return questDTO;
    },
    validateForm() {
      if (!this.quest.name || !this.quest.description || (this.isAdmin && !this.quest.department)) {
        this.showNotification({
          msg: "Error: You must fill in all required fields",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
        return false;
      }
      if (this.quest.video_url && isURLInvalid(this.quest.video_url)) {
        this.showNotification({
          msg: "Error: You must submit a valid URL.",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
        return false;
      }
      return true;
    },
    getErrorMsg(res) {
      if (res?.Error.includes("code='unique'")) {
        return "Error: There is already a quest with that name.";
      }
      return msgs[`${this.mode}Err`];
    },
    async submitForm() {
      this.isLoading = true;
      const uploadRes = await this.uploadImage();
      if (uploadRes.success) {
        const isValid = this.validateForm();
        const questDTO = isValid && this.parseData();
        if (questDTO) {
          const res = await this.service[this.mode](questDTO);
          if (res.success) {
            const questId = this.mode === "add" ? res.id : res.updated_quest?.id;
            this.showNotification({ msg: msgs[`${this.mode}Success`], icon: "fad fa-check-circle" });
            this.changePage(`/quests/tasks/${questId}`);
          } else {
            this.showNotification({ msg: this.getErrorMsg(res), isError: true, icon: "fad fa-exclamation-circle" });
          }
        }
      } else {
        this.showNotification({ msg: "Quest Image Upload Failed", isError: true, icon: "fad fa-exclamation-circle" });
      }
      this.isLoading = false;
    },
    handleRemoveImage() {
      this.image = {};
    },
    prepareImageUpload(fileObject) {
      this.image = fileObject;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    async uploadImage() {
      let imgRes;
      if (this.image && Object.keys(this.image).length > 0) {
        imgRes = await this.feedService.uploadFile(this.image, this.headers);
        if (imgRes?.resource) {
          this.quest.image_url = imgRes.resource;
        } else {
          return { success: false, msg: "Quest Image Upload Failed" };
        }
      }
      return { success: true };
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.h-max-content {
  height: max-content;
}
@media only screen and (min-width: 578px) {
  .autoW {
    width: auto;
  }
  .flex-row-wrapper {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}
</style>
