<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <div class="title-wrapper">
        <common-title title="MyHub" description="An overview of all your saved items." />
      </div>
      <span class="separator"></span>
      <div class="one-stop-content-container">
        <div v-if="showOneStop">
          <div v-if="this.showFeatures.mentors">
            <interface-saved-mentors @empty="hideFeature('mentors')" />
          </div>
          <div v-if="this.showFeatures.courses">
            <interface-saved-courses @empty="hideFeature('courses')" />
          </div>
          <div v-if="this.showFeatures.events">
            <interface-saved-events @empty="hideFeature('events')" />
          </div>
          <div v-if="this.showFeatures.library">
            <interface-saved-resources @empty="hideFeature('library')" />
          </div>
          <div v-if="this.showFeatures.campus_apps">
            <interface-saved-campus-apps @empty="hideFeature('campus_apps')" />
          </div>
        </div>
        <section v-else class="one-stop-empty-section">
          <div class="one-stop-empty-container">
            <i class="fad fa-bookmark fa-swap-opacity one-stop-empty-icon"></i>
            <p class="one-stop-empty-text">You haven't saved anything yet.</p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import InterfaceSavedCourses from "./InterfaceSavedCourses.vue";
import InterfaceSavedEvents from "./InterfaceSavedEvents.vue";
import InterfaceSavedResources from "./InterfaceSavedResources.vue";
import InterfaceSavedMentors from "./InterfaceSavedMentors.vue";
import InterfaceSavedCampusApps from "./InterfaceSavedCampusApps.vue";
import BookmarkService from "../services/BookmarkService";
import CommonTitle from "../../components/CommonTitle.vue";

export default {
  name: "one-stop-interface",

  components: {
    CommonTitle,
    InterfaceSavedCourses,
    InterfaceSavedEvents,
    InterfaceSavedResources,
    InterfaceSavedMentors,
    InterfaceSavedCampusApps,
  },

  data() {
    return {
      bookmarkService: new BookmarkService(),
      allowedFeatures: [],
      showFeatures: {
        courses: false,
        events: false,
        library: false,
        mentors: false,
        campus_apps: false,
      },
    };
  },

  computed: {
    showOneStop(): boolean {
      return (
        this.showFeatures.mentors ||
        this.showFeatures.courses ||
        this.showFeatures.events ||
        this.showFeatures.library ||
        this.showFeatures.campus_apps
      );
    },
  },

  async created() {
    const res = await this.bookmarkService.getMyHubPredata();
    this.allowedFeatures = res.allowed_features;
    this.initializeFeaturesView();
  },

  methods: {
    initializeFeaturesView() {
      if (this.allowedFeatures.includes("appointments")) this.showFeatures.mentors = true;
      if (this.allowedFeatures.includes("courses")) this.showFeatures.courses = true;
      if (this.allowedFeatures.includes("events")) this.showFeatures.events = true;
      if (this.allowedFeatures.includes("programs")) this.showFeatures.programs = true;
      if (this.allowedFeatures.includes("resource_library")) this.showFeatures.library = true;
      if (this.allowedFeatures.includes("campus_apps")) this.showFeatures.campus_apps = true;
    },

    hideFeature(feature) {
      this.showFeatures[feature] = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.title-wrapper {
  width: 100%;
  max-width: 1400px;
  padding: 24px 0;
}

.one-stop-title-container {
  width: 100%;
  max-width: 1400px;
  margin-bottom: 48px;
}

.one-stop-content-container {
  width: 100%;
  max-width: 1400px;
}

.separator {
  display: none;
}

@media screen and (max-width: 576px) {
  .title-wrapper {
    padding: 24px 16px;
  }
  .one-stop-title-container {
    padding: 24px 16px 16px;
    margin-bottom: 0;
  }
  .one-stop-page {
    padding: 0;
  }

  .separator {
    display: initial;
    margin: 0 16px 16px;
    background-color: #e7e7e9;
    height: 1px;
    width: 92%;
  }
}
</style>
