import Vue from 'vue';
import BlogDetails from './components/BlogDetails.vue';

export default class BlogDetailsView {
    protected csrfToken: string;
    protected mountPoint: HTMLElement;
    protected blog: object;

    constructor(el: HTMLElement, blog: object) {
        this.mountPoint = el instanceof Element ? el : document.querySelector(el);
        this.blog = blog;
        this.run();
      }

      async run(): Promise<void> {
        const _class = this;
        const blogsView = new Vue({
          components: { BlogDetails },
          template: `
            <blog-details
              :blog="blog"
            />
          `,
          data() {
            return {
                blog: _class.blog,
            }
          },
        });

        blogsView.$mount(this.mountPoint);
      }
}
