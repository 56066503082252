<template>
    <div class="analytics-wrapper-cards-header" :style="mobileHeaderMargin">
        <i v-if="!isImg & has_icon" :class="'analytics-cards-header-icon analytics-cards-icon-global far ' + icon"  :style="{color:color}" > </i>
        <img alt="" v-if="isImg" :src="`${assetPath}${url}`" class="analytics-cards-header-img">
        <span class="analytics-cards-header-title analytics-cards-title-global" > {{title}} </span>
        <span
            v-if="!has_switch_option"
            tabindex="0"
            class="analytics-cards-header-right"
            @click="sendMessageToParent()"
            @keyup.enter="sendMessageToParent()"
            style="float:right"
        >
            {{!flag ? 'Collapse' : 'Show'}}
            <i :class="!flag ? 'fal fa-angle-up' : 'fal fa-angle-down'" />
        </span>
        <span
            v-if="has_switch_option"
            tabindex="0"
            class="analytics-cards-header-right"
            @click="switchView()"
            @keyup.enter="switchView()"
            style="float:right; margin-top:0"
        >
             <div class="selection-tabs-container">
                        <span
                            tabindex="0"
                            role="button"
                            aria-label="Chart"
                            class="selection-tab"
                            :class="is_chrat_view? `active`: ``"
                        >
                           Chart
                        </span>
                        <span
                            tabindex="0"
                            aria-label="List"
                            role="button"
                            class="selection-tab"
                            :class="!is_chrat_view? `active`: ``"
                        >
                            List
                        </span>
                    </div>
        </span>
    </div>
</template>

<script>
export default {
    name: "analytics-card-header",
    props:[
            'icon',
            'title',
            'color',
            'url',
            'isImg',
            'has_icon',
            'has_switch_option'
        ],
    data(){
        return{
            flag : false,
            assetPath: process.env.MEDIA_URL,
            mobileHeaderMargin: "",
            is_chrat_view: true
        }
    },
    methods: {
        sendMessageToParent(){
            this.flag = !this.flag
            if(this.flag) {
              this.mobileHeaderMargin = "margin-bottom: 42px;";
            } else {
              this.mobileHeaderMargin = "";
            }

            if(this.flag){
                this.$emit("clicked", "analytics-wrapper-cards-collapsed",this.title);
                return;
            }
            this.$emit("clicked", "",this.title);
        },
        switchView(){
            this.is_chrat_view = !this.is_chrat_view;
            this.$emit("switch_view", );
            return
        }
    }
}
</script>

<style scoped>


</style>

