import HttpService from '../../services/HttpService';

export default class NewsletterService extends HttpService {
  constructor() {
    super('/channels/api/newsletter');
  }

  subscribe(data, headers, throwError=false) {
    return this.post(data, headers, throwError);
  }
}
