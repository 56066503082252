<template>
  <div class="tasks-widget-container">
    <section class="title-section">
      <h3 class="title">
        Upcoming Tasks
      </h3>
    </section>
    <section class="content-section">
      <div class="loading-container" v-if="isLoading">
        <loading-spinner></loading-spinner>
      </div>
      <div v-else-if="isTasksListEmpty" class="empty-container">
        No upcoming tasks. Start a new quest and you'll be able to see upcoming tasks here.
      </div>
      <div v-else class="content-container">
        <div v-for="item, index in notCompletedQuestTasksList" :key="index" class="task-card-wrapper">
          <task-card
            :task="item"
          ></task-card>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import LoadingSpinner from '../../../components/LoadingSpinner.vue';
import QuestService from '../../../quests/services/QuestService';
import TaskCard from './TaskCard.vue';


export default {
  name: 'top-navbar-tasks',
  components: {
    LoadingSpinner,
    TaskCard,
  },
  computed: {
    notCompletedQuestTasksList() {
      return this.tasks_list.filter((task)=> !task.quest_completed)
    },
    isTasksListEmpty() {
      return !this.notCompletedQuestTasksList || this.notCompletedQuestTasksList.length === 0;
    },
  },
  data() {
    return {
      isLoading: true,
      questService: new QuestService(),
      tasks_list: [],
    }
  },
  async created() {
    this.tasks_list = await this.questService.getTasks();
    this.isLoading = false;
  }
}
</script>
