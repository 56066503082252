<template>
  <section>
    <div
      v-if="currentlyFeatured && !isLoading.featuredCourses && !isLoading.featuredCoursesEvents"
      class="dashboard-hero-wrapper inup-dashboard-card"
      :style="`background: url('${currentlyFeatured.img}') no-repeat center center / cover; position:relative; `"
    >
      <div class="blackLayer dashboard" style="top: 0; left: 0; opacity: 0.3"></div>
      <!-- <div style="position: absolute; width: 200vw; height: 120%; left: -50%; top: -25px; z-index: -1; background-color: #f1f1f9"></div> -->
      <span>
        <!-- <img class="big-dashboard-card-image" :src="currentlyFeatured.img" alt="featured course img" /> -->
        <div class="big-dashboard-card-content">
          <div class="eu-featured-event-carousel-dots-container" style="position: absolute; bottom: 0; right: 0;">
            <span
              tabindex="0"
              v-for="(courseOrEvent, index) in featuredCoursesEvents"
              :key="index"
              :ref="'carousel-dot-' + index"
              class="featured-course-carousel-dot"
              style="height: 8px"
              :class="`${index === 0 ? 'eu-featured-event-carousel-dot-selected' : ''} carousel-dot-${index}`"
              @click="
                stopCarousel();
                setFeaturedCourseOrEvent(courseOrEvent, index);
              "
              @keyup.enter="
                stopCarousel();
                setFeaturedCourseOrEvent(courseOrEvent, index);
              "
            ></span>
          </div>
          <p class="eu-dashboard-card-desc" style="color: white; font-weight: 600; margin: 0 0 5px 0;">
            Featured {{ currentlyFeatured.type.charAt(0).toUpperCase() + currentlyFeatured.type.slice(1) }}
            <span style="width: 100%; background: purple"></span>
          </p>
          <span class="text-clamp-2 eu-dashboard-card-title" style="color: #fff; margin: 0; margin-bottom: 8px !important;">{{
            currentlyFeatured.name
          }}</span>
          <span class="text-clamp-3 eu-dashboard-card-title" style="color: #fff; margin: 0; font-size: 17px; font-weight: 400; max-width: 600px;">{{
            currentlyFeatured.description
          }}</span>
          <span style="visibility: hidden; font-size: 6px; margin-bottom: 0 !important" class="eu-dashboard-card-title"
            >Keep learning, keep growing. Check out our selection of courses to feed your brains.</span
          >
          <div class="profile-form-button-container">
            <router-link
              aria-label="Open featured course"
              :to="currentlyFeatured.url"
              class="iu-main-btn small solid main feature-course-btn"
            >
              View {{ currentlyFeatured.type.charAt(0).toUpperCase() + currentlyFeatured.type.slice(1) }}
              <i aria-hidden="true" class="fas fa-chevron-right iu-main-button-arrow-icon"></i>
            </router-link>
          </div>
        </div>
      </span>
    </div>

    <div v-else-if="isLoading.featuredCoursesEvents" style="width: 460px; margin: 0 auto">
      <loading-spinner />
    </div>
  </section>
</template>
<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
export default {
  components: { LoadingSpinner },
  name: "dashboard-hero-carousel",
  props: ["currentlyFeatured", "isLoading", "featuredCoursesEvents"],
  data() {
    return {};
  },
  created() {},
  methods: {
    stopCarousel() {
      clearInterval(this.timer);
    },
    setFeaturedCourseOrEvent(courseOrEvent, index) {
      this.$emit("set-featured-course-or-event", courseOrEvent, index);
    },
  },
  computed: {},
};
</script>