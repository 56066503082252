import HttpService from './HttpService';

const dataElement: HTMLElement = document.querySelector(".js-payloadcms-server-url");
const payloadHost = dataElement.dataset.payloadcmsServerUrl;

const tenantDataElement: HTMLElement = document.querySelector('.js-tenant-schema');
const tenantSchema = tenantDataElement.dataset.tenantSchema;
// export class TestPayloadCMSService extends HttpService {
//   constructor() {
//     super(payloadHost + "/api/users/login");
//   }

//   async login(): Promise<any> {
//     let post_data = {};

//     post_data["email"] = payloadCredentials.email;
//     post_data["password"] = payloadCredentials.password;

//     return this.post(post_data);
//   }
// }

export class BlogService extends HttpService {
    constructor() {
      super("/blogs/api/details");
    }

    async getBlogDetails(slug) {
      this.endpoint = `/blog/api/details/${slug}`;
      return this.get();
    }
  }

export class HowTosCMSService extends HttpService {
  constructor() {
    super(payloadHost + "/api/howTos?where[tenants.name][equals]=" + tenantSchema);
  }

  async getAll(): Promise<any> {
    return this.get();
  }
}

export class FeaturesCMSService extends HttpService {
  constructor() {
    super(payloadHost + "/api/features?where[tenants.name][equals]=" + tenantSchema)
  }

  async getAll(): Promise<any> {
    return this.get();
  }
}

export class MediaCMSService extends HttpService {
  protected payloadHost: string;

  constructor() {
    super(payloadHost + "/media");
    this.payloadHost = payloadHost;
  }

  async getImage(fileUrl): Promise<any> {
    this.endpoint = payloadHost + fileUrl;
    return this.get();
  }

  getMediaUrl(fileUrl) {
    return payloadHost + fileUrl;
  }

  getBlogCmsUrl(blogId) {
    return payloadHost + "/admin/collections/blogs/" + blogId;
  }
}
