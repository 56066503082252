import Vue from 'vue';
import * as Cookies from "js-cookie";
import Modal from "../components/ModalExtended.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import GroupService from '../groups/services/GroupService';

export default class GroupsTable {
  protected csrfToken: string;
  protected mountPoint: HTMLElement;
  protected groups: any;

  constructor(el: HTMLElement, groups) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.groups = groups;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const groupsTable = new Vue({
      components: { Modal, LoadingSpinner },
      template: `
      <section>
        <div v-if="isLoading">
          <loading-spinner />
        </div>
        <table v-else class="eu-resource-table">
            <tr class="eu-resource-row">
                <th scope="col" class="eu-resource-cell eu-resource-cell-th analytics-table-small-screen-cell-th">
                    <span> Name</span>
                </th>

                <th scope="col" class="eu-resource-cell eu-resource-cell-th analytics-table-small-screen-cell-th"> 
                </th>

            </tr>
            <tr v-for="d in groups" v-bind:key="d.name" class="eu-resource-row">
                <td class="eu-resource-cell">
                    <a :aria-label="'Go to ' + d.name +  ' page'" :href="'/groups/'+ encodeURIComponent(d.name)">{{d.name}}</a>
                </td>
                <td class="eu-resource-cell eu-resource-cell-dots-icon" style="display:inline-flex;float:right">
                    <a :aria-label="'Go to ' + d.name + ' edit page'" :href="'/groups/'+ encodeURIComponent(d.name)+'/edit'" style="display: flex; margin-right: 10px;"> <i class="fas fa-edit" style="font-size: 14px"></i></a>
                    <span :aria-label="'Open ' + d.name + ' delete modal'" aria-haspopup="dialog" role="button" tabindex="0" @click="openCancelationModal(d)" @keyup.enter="openCancelationModal(d)" style="display: flex"> <i class="fas fa-trash-alt" style="font-size: 14px;color:#f16b4c;background:#ffe6e0"></i></span>
                </td>
            </tr>
        </table>
        <div class='accounts-view-section'>
            <Modal :isActiveProp="isCancelationModalActive" @close="closeCancelationModal">
            <div class='profile-notification-modal' >
                <h2 class="eu-profile-title">Remove Group</h2>
                <p style="padding:0 30px 30px 30px;text-align:center">Are you sure that you want to remove <i>{{selectedGroup.name}}</i>? This cannot be undone.</p>
                <p style="padding:0 30px 30px 30px;text-align:center" class="delete-text">For security reasons, please type in your password:</p>
                <form class="field-wrapper hasValue" style="margin-bottom: 20px; padding: 0 32px;">
                    <div class="da-form-formItem permission-input">
                        <input type="password" autocomplete="off" class="da-components-forms-input large js-da-login-email" v-model="password">
                    </div>
                    <div class="field-placeholder" style="padding: 0 42px">
                        <span>Password<span class="required-star">*</span></span>
                    </div>
                </form>
                <div class="row" style="width: 100%">
                  <p class='error' style="margin-bottom: 12px;">{{err}}</p>
                  <div class="column">
                      <span
                      @click="removeGroup"
                      @keyup.enter="removeGroup"
                      class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary orange"
                      style="width: fit-content; height: fit-content"
                      >
                      Remove Group
                      </span>
                      <span
                      @click="closeCancelationModal"
                      @keyup.enter="closeCancelationModal"
                      class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                      style="width: fit-content; height: fit-content"
                      >
                      Cancel
                      </span>
                  </div>
                </div>
            </div>
            </Modal>
        </div>
      </section>
      `,
      data() {
        return {
          groups: _class.groups,
          isCancelationModalActive: false,
          selectedGroup: { name: null, id: null },
          isLoading: false,
          password: '',
          err: '',
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
            Accept: "application/json",
          },
        };
      },
      async created() {
        this.service = new GroupService();
      },
      methods: {
        async removeGroup() {
          this.isLoading = true;
          const res = await this.service.removeGroup(this.selectedGroup.id, { password: this.password }, this.headers);
          this.password = '';
          this.isLoading = false;
          if (res?.success) {
            this.closeCancelationModal();
            location.reload();
          } else {
            this.err = 'Password is not valid.'
          }
        },
        openCancelationModal(grp) {
          this.err = '';
          this.selectedGroup = grp;
          this.isCancelationModalActive = true;
          const sidebar: HTMLElement = document.querySelector("#sidebar");
          sidebar.style.display = "none";
        },
        closeCancelationModal() {
          this.isCancelationModalActive = false;
          const sidebar: HTMLElement = document.querySelector("#sidebar");
          sidebar.style.display = "block";
        },
      },
      mounted() { }
    });
    groupsTable.$mount(this.mountPoint);
  }
}
