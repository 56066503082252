<template>
  <section v-if="quest.id" class="main-container quest-mode">
    <div class="quest-mode-header">
      <button
        aria-label="Back to overview"
        class="iu-btn-light backBtn desktopOnly"
        @click="changePage(`/quests/${quest.id}`)"
      >
        <i class="fas fa-chevron-left" style="margin-right: 4px; color: #95919b"></i>Back to Quest Overview
      </button>
      <div class="quest-mode-label item-center">
        <i class="fas fa-flag-swallowtail" style="margin-right: 4px; color: #fff"></i>Quest Mode
      </div>
    </div>
    <div class="title-progress-wrapper">
      <div class="title">{{ quest.name }}</div>
      <div class="progress-bar">
        <span
          class="progress-bar-completed"
          :style="`width: ${
            100 / (quest.tasks.length / (quest.quest_student ? quest.quest_student.no_of_completed_tasks : 0))
          }%`"
        ></span>
        <div class="circles-container">
          <div
            v-for="(task, index) in quest.tasks"
            :key="task.id"
            class="circle"
            :class="{
              filled: index < (quest.quest_student ? quest.quest_student.no_of_completed_tasks : 0),
              next: index == (quest.quest_student ? quest.quest_student.no_of_completed_tasks : 0),
            }"
            :style="{ left: (index / quest.tasks.length) * 100 + '%' }"
          ></div>
        </div>
        <div class="svg-container"></div>
      </div>
    </div>

    <div class="task-cards-wrapper">
      <div class="space-between">
        <span class="tasks-title" style="margin: 0">Tasks</span>
        <span class="mdTxt">{{ quest.tasks.length }} task{{ quest.tasks.length == 1 ? "" : "s" }}</span>
      </div>
      <div v-for="(t, index) in quest.tasks" :key="t.id" class="tasks-wrapper">
        <div class="checkbox-wrapper">
          <div
            @click="toggleFinishTask(t)"
            @keyup.enter="toggleFinishTask(t)"
            class="checkbox"
            :class="{
              active: index < (quest.quest_student ? quest.quest_student.no_of_completed_tasks : 0),
              clickable: index == (quest.quest_student ? quest.quest_student.no_of_completed_tasks : 0),
            }"
          >
            <i
              v-if="index < (quest.quest_student ? quest.quest_student.no_of_completed_tasks : 0)"
              class="fa-solid fa-check ico"
            ></i>
          </div>
          <div
            class="line"
            :class="{
              active: index < (quest.quest_student ? quest.quest_student.no_of_completed_tasks : 0),
            }"
          ></div>
        </div>
        <task-card
          :isSelected="index < (quest.quest_student ? quest.quest_student.no_of_completed_tasks : 0)"
          :task="t"
        />
      </div>
      <div
        aria-label="Finish Quest"
        :class="`${quest.tasks.length != quest.quest_student.no_of_completed_tasks ? 'disabled' : ''}`"
        class="iu-btn-light big h-w-big"
      >
        <i
          class="fa-duotone fa-flag-checkered"
          :style="`margin-right: 8px; color: ${
            quest.tasks.length == quest.quest_student.no_of_completed_tasks ? '#685df3' : '#b6b4bf'
          }`"
        ></i
        >{{ quest.tasks.length == quest.quest_student.no_of_completed_tasks ? "Quest Complete!" : "End of Quest" }}
      </div>
      <button
        aria-label="Back to overview"
        class="iu-btn-light backBtn mobileOnly item-center"
        style="margin-top: 32px"
        @click="changePage(`/quests/${quest.id}`)"
      >
        <i class="fas fa-chevron-left" style="margin-right: 4px; color: #95919b"></i>Back to Quest Overview
      </button>
    </div>
    <div v-if="isLoading">
      <loading-spinner wrapperClass="wrapper" />
    </div>
    <Modal :isActiveProp="isRateModalActive" @close="closeRateModal">
      <div class="iu-resources-delete-modal-content-container">
        <div class="animation-wrapper">
          <lottie-animation path="static/assets/icons/first_login_anim.json" :loop="false" :width="300" :height="300" />
        </div>
        <div class="modal-title">Congrats! You've completed the Quest</div>
        <div class="modal-desc-wrapper">
          <div class="modal-desc">
            Your opinion matters - was this Quest helpful?
          </div>
          <div class="ratings-wrapper">
            <div @click="rateQuest(quest.id, 1)" class="rating-item" :class="quest.current_user_rating == 1 ? 'positive' : ''" style="border-radius: 8px 0 0 8px;"><i class="fa-solid fa-thumbs-up"></i> </div>
            <div @click="rateQuest(quest.id, 0)" class="rating-item" :class="quest.current_user_rating == 0 ? 'negative' : ''" style="border-radius: 0 8px 8px 0;"><i class="fa-solid fa-thumbs-down"></i> </div>
          </div>
        </div>
        <div class="iu-resources-delete-modal-actions-section">
          <button
            class="iu-resources-delete-modal-actions-button iu-main-btn small outlined gray"
            @click="closeRateModal"
            aria-label="Close delete modal"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>
<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import QuestService from "../services/QuestService";
import Notification from "../../components/Notification.vue";
import TaskCard from "./TaskCard.vue";
import Modal from "../../components/ModalExtended.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { focusElement } from "../../utils/CustomUtils";

export default {
  name: "quest-mode",
  components: { LoadingSpinner, Notification, TaskCard, Modal, LottieAnimation },
  props: ["isAdmin", "isDeptLead"],
  computed: {},
  data() {
    return {
      isLoading: false,
      service: new QuestService(),
      assetPath: process.env.MEDIA_URL,
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      isRateModalActive: false,
      positiveRatings: 0,
      negativeRatings: 0,
      quest: {
        name: "",
        description: "",
        focus_areas: [],
        identities: [],
        department: null,
        due_date: null,
        contacts: [],
        video_url: "",
        image_url: "",
        is_featured: false,
        is_published: false,
        is_required: false,
      },
    };
  },
  mounted() {
    document.documentElement.style.setProperty("--bg-image", `url('${this.assetPath}assets/quests_bg.jpg')`);
    document.documentElement.style.setProperty("--finish-ico", `url('${this.assetPath}assets/career-goals.svg')`);
  },
  async created() {
    this.getQuest();
  },
  methods: {
    async getQuest() {
      const questId = this.$route.path.split("/").pop();
      this.isLoading = true;
      this.quest = await this.service.getOne(questId);
      this.quest.tasks.sort((a, b) => a.order - b.order);
      this.isLoading = false;
    },
    async toggleFinishTask(task) {
      let no_of_completed_tasks = this.quest.quest_student?.no_of_completed_tasks;
      no_of_completed_tasks = no_of_completed_tasks >= task.order ? task.order - 1 : task.order;
      const res = await this.service.finishTask({ no_of_completed_tasks, id: this.quest.quest_student.id });
      if (res.success) {
        this.quest = await this.service.getOne(this.quest.id);
        this.quest.tasks.sort((a, b) => a.order - b.order);
      }
      if((this.quest.tasks.length === this.quest.quest_student.no_of_completed_tasks) && this.quest.current_user_rating === null ) {
        this.openRateModal();
      }
    },
    async rateQuest(questId, rating) {
      const res = await this.service.rate({ quest: questId, rating });
        if (res.success) {
          this.closeRateModal();
        } else {
          this.showNotification({
            msg: "Error: Cannot rate this Quest",
            isError: true,
            icon: "fad fa-exclamation-circle",
          });
        }
    },
    calculateRatings() {
      this.positiveRatings = 0;
      this.negativeRatings = 0;
      this.quest.ratings.forEach(rating => {
      if (rating === 1) {
        this.positiveRatings += 1;
      } else if (rating === 0) {
        this.negativeRatings += 1;
      }
    });
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    changePage(page) {
      this.$router.push(page);
    },
    openRateModal() {
      this.calculateRatings();
      this.isRateModalActive = true;
      focusElement("quest_rate_close");
    },
    closeRateModal() {
      this.isRateModalActive = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (min-width: 577px) {
  .mobileOnly {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .desktopOnly {
    display: none;
  }
}
.tasks-wrapper {
  display: flex;
  gap: 16px;
}
.animation-wrapper {
  max-height: 200px;
  margin-top: -100px;
}
.modal-title {
  padding: 12px 24px 0px 24px;
  font-size: 16px;
  line-height: 1.35;
  color: #444444;
  font-size: 24px;
  font-weight: 600;
}
.modal-desc-wrapper {
  padding: 12px 0;
  width: 100%;
}
.modal-desc {
  color: #444;
  font-weight: 400;
  line-height: 1.25;
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
}
.ratings-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 124px;
  line-height: 0;
  margin: 0 auto;
}
.rating-item {
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 8px;
  border: 1px solid #d1d1d7;
  padding: 12px 15px;
  color: #817e87;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &.positive {
    cursor: default;
    background: #e7fef0;
    pointer-events: none;
    color: black;
  }
  &.negative {
    cursor: default;
    background: #fff6d2;
    pointer-events: none;
    color: black
  }
  &:hover {
    opacity: 0.8;
  }
}
.checkbox-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-top: 32px;
  align-items: center;
}
.checkbox {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f1f1f9;
  transform: translateY(-50%);
  border: 3px solid #b6b4bf;
  pointer-events: none;
  .ico {
    color: #b3d0d3;
    font-size: 14px;
    position: relative;
    bottom: 1px;
    left: 3px;
  }
  &.clickable {
    cursor: pointer;
    pointer-events: all;
    &:hover {
      opacity: 0.7;
    }
  }
  &.active {
    @extend .clickable;
    background: #685df3;
    border-color: #685df3;
  }
}
.line {
  width: 2px;
  height: calc(100% - 32px);
  position: relative;
  bottom: 7px;
  background-image: linear-gradient(to bottom, #b6b4bf 0%, #b6b4bf 50%, transparent 50%, transparent 100%);
  background-size: 100% 6px;
  &.active {
    background-color: #685df3;
    background-image: none;
  }
}
.title-progress-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  gap: 50px;
  margin-top: 16px;
  .title {
    color: #333333;
    position: relative;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.2;
  }
  .progress-bar {
    background-color: #d1d1d7;
    color: #777777;
    border-radius: 16px;
    height: 12px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    position: relative;

    &-completed {
      position: absolute;
      height: 12px;
      transition: 1s;
      left: 0;
      border-radius: 16px 0 0 16px;
      background-color: #77c4ca;
    }
  }
}
.svg-container {
  position: absolute;
  top: 50%;
  right: 0;
  width: 72px;
  height: 77px;
  background-size: cover;
  transform: translateY(-50%);
  background-image: var(--finish-ico);
}
.circles-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 3px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.circle {
  width: 24px;
  transition: 1s;
  height: 24px;
  border-radius: 50%;
  background-color: #b6b4bf;
  position: absolute;
  top: 6px;
  transform: translateY(-50%);
  border: 3px solid #b6b4bf;
}
.circle.next {
  background-color: #e5f8f8;
  border-color: #22a5a0;
}
.circle.filled {
  background-color: #137583;
  border-color: #22a5a0;
}
.quest-mode-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 32px;
  position: relative;
}
.backBtn {
  border: none;
  background-color: #e7e5ec;
  font-size: 14px;
  font-weight: 600;
  color: #444444;
  margin-top: 0;
  padding: 8px 16px;
  width: fit-content;
}
.quest-mode-label {
  height: 40px;
  gap: 6px;
  display: flex;
  align-items: center;
  padding: 1px 22px;
  cursor: default;
  border-radius: 4px;
  border: solid 1px #fadef5;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #a054c3;
  width: fit-content;
}
.item-center {
  margin-left: auto; /* Push it to the right */
  margin-right: auto; /* Center it */
  position: absolute; /* Take it out of the normal flow */
  left: 0; /* Align to the left edge */
  right: 0; /* Align to the right edge */
  text-align: center; /* Center the text within */
}
.h-w-big {
  height: 50px;
  width: 100%;
  margin-top: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.quest-mode {
  min-height: calc(100vh - 60px);
}
.quest-mode::before {
  content: "";
  position: absolute;
  top: 0;
  background-size: cover;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: var(--bg-image);
  opacity: 0.3;
}
.tasks-title {
  color: #333;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}
.task-cards-wrapper {
  background: transparent;
  padding: 32px;
  border-radius: 16px;
  max-width: 620px;
  margin: 0 auto;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mdTxt {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
}
</style>
