<template>
  <div class="resource-browse-section" :class="isPartnerTab ? 'partner' : ''">
    <resources-browse
      :isAdmin="loggedUser.isAdmin"
      :userDepartment="department && department.name"
      :isDepartmentProfile="!!department"
      :group="group"
      :partner="partner"
      :isPartnerTab="isPartnerTab"
      :isPartnerManager="isPartnerManager"
      :isPartnerAdmin="isPartnerAdmin"
      class="full-width-content"
    />
  </div>
</template>
<script>
import ResourcesBrowse from '../../library/components/ResourcesBrowse.vue';

export default {
  components: { ResourcesBrowse },
  name: 'resources-tab',
  props: {
    department: {},
    group: {},
    partner: {},
    loggedUser: {},
    isPartnerTab: {
      type: Boolean,
      default: false,
    },
    isPartnerManager: {
      type: Boolean,
      default: false,
    },
    isPartnerAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.resource-browse-section {
  grid-column:2/16;
  min-height: 750px;
  margin-top: 24px;
}
.resource-browse-section.partner {
  margin-top: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .full-width-content {
    width: 100%;
    max-width: 1400px;
  }
}

@media screen and (max-width: 576px) {
  .resource-browse-section.partner {
  padding: 1.5rem;
}
}
</style>
