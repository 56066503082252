import Vue from 'vue';
import MultipleInputSelect from './components/MultipleInputSelect.vue';


export default class MultipleInputSelectClass {
    private readonly mountPoint: HTMLElement;
    private input: HTMLInputElement;
    protected optionList: Array<Object>;
    protected filledOptions: Object;
    protected selectLabel: string;
    protected inputLabel: string;

    constructor(
        el: HTMLElement,
        input: HTMLInputElement,
        optionList: Array<Object>,
        filledOptions: Object,
        selectLabel: string,
        inputLabel: string,
    ) {

        this.mountPoint = el instanceof Element ? el : document.querySelector(el);
        this.input = input instanceof HTMLInputElement ? input : document.querySelector(input);
        this.optionList = optionList;
        this.filledOptions = filledOptions;
        this.selectLabel = selectLabel;
        this.inputLabel = inputLabel;

        this.run();
    }

    async run(): Promise<void> {
        const _self = this;

        const multipleInputSelect = new Vue({
            components: { MultipleInputSelect },

            template: `<multiple-input-select
                            :input="input"
                            :optionList="optionList"
                            :filledOptions="filledOptions"
                            :selectLabel="selectLabel"
                            :inputLabel="inputLabel"
                        />`,

            data() {
                return {
                    input: _self.input,
                    optionList: _self.optionList,
                    filledOptions: _self.filledOptions,
                    selectLabel: _self.selectLabel,
                    inputLabel: _self.inputLabel,
                };
            }
        });

        multipleInputSelect.$mount(this.mountPoint);
    }
}
