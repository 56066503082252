<template>
<div class="eu-mentor-collection-container">
  <ul
    class="mentors-myhub-collection"
  >
    <li
      class="eu-mentor-collection-card"
      :style="`background: url('${item.profile_picture}');background-size:cover;background-position:center;`"
      v-for="item in mentors"
      :key="item.id"
    >
      <div
          tabindex="0"
          class="mc-list-card-profile-btn mentors-text-shadow"
          @click="openProfileModal(item)"
          @keyup.enter="openProfileModal(item)"
          aria-label="Open profile modal"
          aria-haspopup="dialog"
          role="button"
        >
          View profile<i class="fas fa-chevron-right right-chevron-btn mentors-text-shadow " aria-hidden="true"></i>
      </div>
      <div class="eu-mentor-collection-card-header">
        <!-- <img :src="item.profile_picture" alt="Mentor profile picture" class="eu-mentor-collection-card-profile-picture" style="visibility: hidden"> -->
        <div>
          <div class="eu-mentor-collection-card-header-name mentors-text-shadow">
            {{item.name}}
          </div>
          <div class="eu-mentor-collection-card-header-department">
            <i class="fas fa-building eu-mentor-collection-card-header-department-icon" aria-hidden="true"></i>
            <span class="mentors-text-shadow">{{item.department_name}}</span>
          </div>
        </div>
      </div>
      <div class="eu-mentor-collection-card-areas slim-scrollbar-horizontal">
        <i
        class="fas fa-chalkboard-teacher eu-mentor-collection-card-areas-icon "
        aria-hidden="true"
        v-if="item.focus_areas.length!=0"
        ></i>
        <span
          class="mentors-text-shadow"
          v-for="(area_name, index) in item.focus_areas"
          :key="index"
          > {{area_name}}<span v-if="index != item.focus_areas.length-1">, </span>
          </span>
      </div>
    </li>
  </ul>
  <div  class='accounts-view-section'>
    <profile-card-modal :isModalActive="isProfileCardModalActive" :user="userToPreview" @onClose="closeProfileModal" />
  </div>
</div>
</template>

<script lang="ts">
import ProfileCardModal from '../../accounts/components/ProfileCardModal.vue';

export default {
  name: "browse-saved-mentors",

  props: ['mentors'],

  components: {
    ProfileCardModal,
  },

  data() {
    return {
      isProfileCardModalActive: false,
      userToPreview: null,
    };
  },

  methods: {

    removeBookmark(id: string) {
      this.$emit('remove-bookmark', id)
    },

    userToUserPreviewMapper(user) {
      let focusAreasWithName = [];

      user.focus_areas.forEach(fa => {
        focusAreasWithName.push({'name': fa});
      });

      let hours = user.hours;

      if(user.hours) {
        hours = hours.sort((a,b) => (a.startTime > b.startTime ? 1 : -1));
        hours = hours.slice(0, 5);
      }

      return {
        "first_name": user.name.split(" ")[0],
        "last_name": user.name.split(" ")[1],
        "profile_pic": user.profile_picture,
        "id": user.id,
        "department": user.department_name,
        "focus_areas": focusAreasWithName,
        "role": {
          "is_mentor": true,
        },
        "profile_url": user.url,
        "upcoming_hours": hours,
      };
    },

    openProfileModal(item) {
      this.userToPreview = this.userToUserPreviewMapper(item);
      this.isProfileCardModalActive = true;
    },

    closeProfileModal() {
      this.isProfileCardModalActive = false;
    },
  },
}
</script>

