<template>
  <section class="no-recommendations-wrapper">
    <div class="no-recommendations-text">
      {{ contentMessage.msg }}
    </div>
    <a
      :aria-label="`Update your interests`"
      :href="contentMessage.url"
      class="iu-main-btn small solid main"
      style="width: 300px; margin: 0 auto"
    >
      {{ contentMessage.btnText }}
      <i aria-hidden="true" class="fas fa-chevron-right iu-main-button-arrow-icon"></i>
    </a>
  </section>
</template>

<script>
import { useCommonDataStore } from '../../stores/commonData';

export default {
  name: "no-recommendations",
  props: ["feature", "redirectUrl"],
  computed: {
    contentMessage() {
      const deptPlural = useCommonDataStore().getFeatureName("department_plural").toLowerCase();
      if (this.feature === "departments") {
        return {
          msg: `It seems you haven't followed any ${deptPlural} yet!`,
          btnText: `Explore ${deptPlural}`,
          url: "/groups",
        };
      }
      return {
        msg: "No recommendations yet - let's change that! Complete your profile and discover personalized suggestions.",
        btnText: "Update your interests",
        url: "/settings/interests",
      };
    },
  },
};
</script>