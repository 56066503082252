<template>
    <div class="department-user-cards">
        <div v-for="u of users" :key="u.id" class="department-user-card">
            <div v-if="!followersPage && !favoriteMentorsPage && u.is_mentor && u.id != loggedUser.id"  class="mc-list-card-profile-btn">
                <span
                    tabindex="0"
                    class="eu-meetings-dots-icon"
                    :aria-label=" !ids_favorite_mentors.includes(u.id) ? `Add ${u.name} in favorite mentors`: `Remove ${u.name} from Favorite Mentors`"
                    :title="!ids_favorite_mentors.includes(u.id) ? `Add ${u.name} in favorite mentors`: `Remove ${u.name} from Favorite Mentors`"
                    @click="handleFavoritesMentors(u.id)"
                    @keyup.enter="handleFavoritesMentors(u.id)"
                    role="button"
                >
                    <i :class="`${ids_favorite_mentors.includes(u.id) ? 'fad bookmark-active' : 'fal'} fa-bookmark bookmark-icon `"></i>
                </span>
            </div>
            <div v-if="favoriteMentorsPage"
                class="mc-list-card-profile-btn"
                v-closable="{exclude:['burgerGrid'], handler: 'closeBurgers'}"
            >
                <span
                tabindex="0"
                ref='burgerGrid'
                @click="showBurger(u, 'grid')"
                @keyup.enter="showBurger(u, 'grid')"
                :id="`favorite_mentors${u.uuid}`"
                class="eu-meetings-dots-icon"
                aria-label="Burger menu"
                role="menu"
                aria-haspopup="true"
                >
                <i class="fas fa-ellipsis-v"></i>
                </span>
                <div
                ref='burgerGridDropdown'
                :id="'grid' + u.uuid"
                class="eu-resource-burger"
                style="width: 230px;"
                >
                <!-- <span
                    tabindex="0"
                    class="eu-resource-burger-el"
                    aria-label="open view meetings details modal"
                    aria-haspopup="dialog"
                    role="button"
                >
                    <i class="fas fa-pencil" style="padding-right: 5px"></i>
                    <span>Create Appointment</span>
                </span> -->
                <span
                    tabindex="0"
                    class="eu-resource-burger-el"
                    @click="remove(u)"
                    @keyup.enter="remove(u)"
                    :aria-label="`Remove ${u.first_name} ${u.last_name} from favorite mentors`"
                    role="button"
                >
                    <i class="fas fa-bookmark" style="padding-right: 5px"></i>
                    <span>Remove from Favorites</span>
                </span>
                <a
                    tabindex="0"
                    class="eu-resource-burger-el"
                    :aria-label="`Go to ${u.first_name} ${u.last_name} profile`"
                    aria-haspopup="dialog"
                    :href="`/profiles/${u.url}`"
                >
                    <i class="fas fa-eye" style="padding-right: 5px"></i>
                    <span>View Profile</span>
                </a>
                </div>
            </div>
            <div>
                <img
                    :src="u.profile_picture"
                    class="department-user-card-image"
                />
                <p class="department-user-card-full_name">{{u.name}}</p>
                <p class="department-user-card-role">{{u.role}}</p>
                <a class="view-profile-link" :aria-label="`Go to ${u.name} profile page`" :href="`/profiles/${u.url}`"> View Profile <i class="far fa-chevron-right"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
import handleOutsideClick from '../../directives/handleOutsideClick'

export default {
    components: {},
    directives: { closable: handleOutsideClick },
    props: ['users', 'favoriteMentorsPage', 'followersPage', 'loggedUser', 'ids_favorite_mentors'],
    name: "admins-and-mentors-tab",
    data(){
        return{

        }
    },
    created(){},
    methods: {
        remove: function (user){
            this.$emit('remove-mentor', user.id)
        },
        showBurger: function (i, view) {
            this.$emit('show-burger', i, view);
        },
        closeBurgers: function () {
            this.$emit('close-burgers');
        },
        handleFavoritesMentors: function (mentor_id){
            this.$emit('handle-favorite', mentor_id);
        },
    }
}
</script>
