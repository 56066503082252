<template>
 <Modal :isActiveProp="isModalActive" @close="closeModal">
    <div class='profile-notification-modal'>
     
        <h2 class="eu-profile-title">{{meeting.recurring_type && 'Recurring'}} Meeting Slot</h2>
        <i style="right: 20px; top: 20px;" class="far fa-times eu-file-upload-modal-icon" @click="closeModal"></i>
        <div class='eu-profile-card' style="padding-top: 20px;">
           
            <span>
                <div class='eu-profile-card-item'>
                    <span class='eu-profile-card-item-title'>Date:</span> 
                    <span class='eu-profile-card-item-text'>{{parseLongDate(meeting.date)}}</span>
                </div> 
            </span>
            <span v-if="meeting.recurring_type">
                <div class='eu-profile-card-item'>
                    <span class='eu-profile-card-item-title'>Repeat: </span> 
                    <span class='eu-profile-card-item-text'>Every {{meeting.recurring_type === 'months' ? 'Month' : meeting.recurring_type === 'weeks' ? 'Week' : 'Day' }}</span>
                </div> 
            </span>
            <span>
                <div class='eu-profile-card-item'>
                    <span class='eu-profile-card-item-title'>Start Time:</span> 
                    <span class='eu-profile-card-item-text'>{{meeting.startTime}}</span>
                </div> 
            </span>
            <span>
                <div class='eu-profile-card-item'>
                    <span class='eu-profile-card-item-title'>End Time:</span> 
                    <span class='eu-profile-card-item-text'>{{meeting.endTime}}</span>
                </div> 
            </span>
            <span v-if="meeting.meeting">
                <div class='eu-profile-card-item'>
                    <span class='eu-profile-card-item-title'>Meeting Type:</span> 
                    <span class='eu-profile-card-item-text'>{{capitalizeFirstLetter(meeting.meeting.meeting_type)}}</span>
                </div> 
            </span>
            <span v-if="meeting.meeting">
                <profile-card
                    :listView="true"
                    :hideProfileHref="true"
                    :user="meeting.meeting.requester" 
                    :department_name="meeting.meeting.requester.department ? meeting.meeting.requester.department.name : ''" 
                    :focus_areas="stringifyArray(meeting.meeting.requester.focus_areas, 'name')" 
                    :className="'meeting-details-card zero-margin'"
                        />
            </span>
        </div>
        <div class="row" style="width: 100%">
            
            <div class="column">
            <button
                v-if="!meeting.name"
                @click="removeSlot(meeting.id)"
                class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary orange"
                style="width: fit-content; height: fit-content;"
            >
                Remove Slot
            </button>
            <button
                @click="closeModal"
                class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                style="width: fit-content; height: fit-content"
            >
                Close
            </button>
            </div>
        </div>
    </div>
  </Modal>
</template>
<script lang="ts">
import Modal from '../../components/ModalExtended.vue';
import ProfileCard from '../../accounts/components/ProfileCard.vue';
import {parseLongDate} from  "../utils/DateParser";
import { stringifyArray, capitalizeFirstLetter } from "../utils/common";
export default {
  components: {Modal, ProfileCard},
  name: 'meeting-edit-remove-modal',
  props: ['meeting', 'isModalActive'],
  data() {
    return {
      acceptReason: ''
    };
  },
  async created() {},
  methods: {
    parseLongDate,
    capitalizeFirstLetter,
    stringifyArray,
    closeModal() {
      this.$emit('onClose');
    },
    removeSlot(meetingId) {
      this.$emit('remove-slot', meetingId);
      this.closeModal();
    }
  },
  computed: {}
};
</script>
