<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <div class="meetings-content-container">
        <appointments-section
          v-if="currentStep === 'appointments' && !filterIsLoading"
          :meetings="meetings"
          :noMeetings="noMeetings"
          :isMentor="isMentor"
          :canManageAppointments="canManageAppointments"
          :selected_status="selected_status"
          :loggedUserId="loggedUserId"
          :isLoading="isLoading"
          :filterData="filterData"
          @_search="search"
          @_setCurrentStep="setCurrentStep"
          @_fetchMeetings="fetchMeetings"
          @filter-data="createFilterData"
        />
        <create-appointment-section
          v-if="currentStep === 'create-appointment' || currentStep === 'choose-mentor'"
          :meetingRequest="meetingRequest"
          :filterData="filterData"
          :formError="formError"
          :mentor_results="mentor_results"
          :availableDates="availableDates"
          :availableTimes="availableTimes"
          :currentStep="currentStep"
          @_submitMeetingRequest="submitMeetingRequest"
          @_setCurrentStep="setCurrentStep"
          @_setMeetingReqProp="setMeetingReqProp"
          @_selectMentor="selectMentor"
          @_onDateSelected="onDateSelected"
        />
        <favorite-mentors
          v-if="currentStep === 'favorites'"
          :isMentor="isMentor"
          :noMeetings="noMeetings"
          :canManageAppointments="canManageAppointments"
          @_setCurrentStep="setCurrentStep"
        />
        <div class="accounts-view-section">
          <profile-notification-modal
            v-if="meetingClone"
            :isModalActive="isProfileNotificationModalActive"
            :user="meetingClone.chosen_mentor"
            :department="meetingClone.department"
            :meetingMsg="{ title: 'Meeting Description: ', text: meetingClone.description }"
            modal_title_id="mentor_connect_appointment_created_modal"
            title="Appointment Request Sent"
            :cards="[
              [
                { title: 'Date: ', text: `${meetingClone.date}` },
                { title: 'Time: ', text: `${getTimeForModal(meetingClone.time)}` },
              ],
            ]"
            @onClose="closeProfileNotificationModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import MeetingsService from "../services/MeetingsService";
import ResourceLibraryService from "../../library/services/ResourceLibraryService";
import MentorGroupService from "../../services/MentorGroupService";
import Modal from "../../components/ModalExtended.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import * as Cookies from "js-cookie";
import AppointmentsSection from "./MeetingIndexSections/AppointmentsSection.vue";
import CreateAppointmentSection from "./MeetingIndexSections/CreateAppointmentSection.vue";
import ChooseMentorSection from "./MeetingIndexSections/ChooseMentorSection.vue";
import ProfileNotificationModal from "../../accounts/components/ProfileNotificationModal.vue";
import { parseLongDate } from "../utils/DateParser";
import "../utils/DateParser";
import moment from "moment";
import { focusElement } from "../../utils/CustomUtils";
import FavoriteMentors from "./FavoriteMentors.vue";

export default {
  name: "meetings-index",
  props: ["tab"],
  components: {
    ProfileNotificationModal,
    CreateAppointmentSection,
    AppointmentsSection,
    ChooseMentorSection,
    FavoriteMentors,
    LoadingSpinner,
    Modal,
  },
  data() {
    return {
      pendingMeetings: [],
      activeMeetings: [],
      pastMeetings: [],
      futureMeetings: [],
      canceledMeetings: [],
      expiredMeetings: [],
      meetings: [],
      showPending: true,
      showActive: true,
      showPast: false,
      isProfileNotificationModalActive: false,
      meetingRequest: {
        description: null,
        mentor_group: "",
        focus_area: "",
        meeting_type: null,
        chosen_mentor: { profile_pic: null },
        date: null,
        time: null,
      },
      filterData: {},
      mentor_results: [],
      currentStep: "appointments",
      availableDates: [],
      availableTimes: [],
      formError: false,
      selectError: false,
      noMeetings: false,
      meetingClone: null,
      meetingsClone: [],
      isLoading: true,
      filterIsLoading: true,
      status: [],
      selected_status: [],
      pageCount: 1,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      params: {
        departments: [],
        focus_areas: [],
        status: "",
      },
      canManageAppointments: null,
      selected_tab: null,
      loggedUserId: null,
      isMentor: null,
      meetingsService: new MeetingsService(),
    };
  },
  async created() {
    this.resourceService = new ResourceLibraryService();
    this.mentorGroupService = new MentorGroupService();
    await this.initializeData();
    this.filterData = await this.resourceService.getFilterData();
    this.filterData["mentorGroups"] = (await this.mentorGroupService.getContactGroupList()).data;
    this.filterIsLoading = false;
    this.setCurrentStep(this.tab ? this.tab : this.selected_tab);
    this.scroll();
  },

  methods: {
    parseLongDate,
    async initializeData() {
      const res = await this.meetingsService.getMeetingsPredata();
      this.canManageAppointments = res.can_manage_appointments;
      this.isMentor = res.is_mentor;
      this.loggedUserId = res.loggedUserId;
      this.selected_tab = res.selected_tab;
    },
    openProfileNotificationModal() {
      this.isProfileNotificationModalActive = true;
      const sidebar: HTMLElement = document.querySelector("#sidebar");
      focusElement("mentor_connect_appointment_created_modal");
      sidebar.style.display = "none";
    },

    setCurrentStep(step) {
      this.currentStep = step;
      if (step === "appointments") {
        this.fetchMeetings(1);
      } else {
        this.isLoading = false;
      }
    },

    setMeetingReqProp(el, value) {
      if (value !== null && value !== "" && this.meetingRequest[el] === value) {
        this.meetingRequest[el] = null;
        if (el === "meeting_type") {
          // set all inputs below me to null
        }
      } else this.meetingRequest[el] = value;
    },

    closeProfileNotificationModal() {
      this.isProfileNotificationModalActive = false;
      const sidebar: HTMLElement = document.querySelector("#sidebar");
      sidebar.style.display = "block";
      this.meetingRequest = {
        description: null,
        department: null,
        focus_area: null,
        meeting_type: null,
        chosen_mentor: { profile_pic: null },
        date: null,
        time: null,
      };
    },

    selectMentor(user) {
      user.upcoming_hours = user.hours.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
      user.upcoming_hours = user.upcoming_hours.slice(0, 5);
      this.meetingRequest.chosen_mentor = user;
      this.meetingRequest.date = null;
      this.meetingRequest.time = null;
      this.availableDates = [...new Set(user.hours.map((x) => parseLongDate(x.date)))];
    },

    onDateSelected() {
      this.meetingRequest.time = null;
      this.availableTimes = this.meetingRequest.chosen_mentor.hours
        .filter((x) => parseLongDate(x.date) == this.meetingRequest.date)
        .map((x) => {
          return { id: x.id, time: `${moment(x.startTime).format("HH:mm")} - ${moment(x.endTime).format("HH:mm")}` };
        });
    },

    search(searchInput: string) {
      const searchInputLower = searchInput.toLowerCase();

      this.meetings = JSON.parse(JSON.stringify(this.meetingsClone));
      this.meetings = this.meetings.filter(
        (e) =>
          e.chosen_mentor_fullname.toLowerCase().includes(searchInputLower) ||
          e.requester.toLowerCase().includes(searchInputLower)
      );
    },

    async scroll() {
      window.onscroll = async () => {
        if (this.isLoading) return;
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;

        if (bottomOfWindow && this.pageCount !== 0) {
          this.pageCount += 1;

          this.fetchMeetings();
        }
      };
    },

    async fetchMeetings(pg, filter_is_active = false) {
      this.pageCount = pg ? pg : this.pageCount;
      if (this.pageCount < 2) this.isLoading = true;
      const newMeetings = await this.meetingsService.getMeetings({ ...this.params, page: this.pageCount });
      if (this.pageCount == 1) {
        this.meetings = newMeetings;
      } else {
        newMeetings.forEach((e) => this.meetings.push(e));
      }
      this.isLoading = false;
      if (this.meetings.length > 0) {
        this.noMeetings = false;
        this.futureMeetings = this.meetings.filter((e) => !(moment(e.meeting_hours.endTime) < moment()));
        this.activeMeetings = this.futureMeetings.filter((e) => e.status === "active");
        this.activeMeetings = this.activeMeetings.map((e) => {
          const start = moment(e.meeting_hours.startTime);
          const end = moment(e.meeting_hours.endTime);
          const meetingDuration = start.diff(end, "minutes");
          const timeUntilMeeting = start.diff(moment(), "minutes");
          if (timeUntilMeeting > 15 || timeUntilMeeting < meetingDuration) {
            //e.status = 'confirmed';
          }
          return e;
        });
        this.meetings.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        this.meetingsClone = JSON.parse(JSON.stringify(this.meetings));
      } else {
        this.noMeetings = !filter_is_active;
      }
      if (newMeetings.length == 0) {
        this.pageCount = 0;
      }
    },

    async submitMeetingRequest(isFinal = false) {
      if (!this.meetingRequest.meeting_type) {
        this.formError = true;
        focusElement("book_appointment_error");
      } else if (
        isFinal &&
        (!this.meetingRequest.time || !this.meetingRequest.date || !this.meetingRequest.chosen_mentor)
      ) {
        this.formError = true;
        focusElement("book_appointment_error");
      } else {
        this.formError = false;
        const meetingReq = isFinal
          ? {
              ...this.meetingRequest,
              chosen_mentor: this.meetingRequest.chosen_mentor.id,
              timezone_offset: -new Date().getTimezoneOffset(),
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }
          : { ...this.meetingRequest, chosen_mentor: null, timezone_offset: -new Date().getTimezoneOffset() };
        const res = await this.meetingsService.postMeetingRequest(meetingReq, this.headers);
        if (res.status === "success") {
          if (this.meetingRequest.chosen_mentor.id && this.currentStep === "choose-mentor" && isFinal) {
            this.setCurrentStep("appointments");
            this.noMeetings = false;
            this.meetingClone = JSON.parse(JSON.stringify(this.meetingRequest));
            this.openProfileNotificationModal();
          } else {
            this.mentor_results = res.mentor_results;
            this.setCurrentStep("choose-mentor");
          }
        }
      }
    },

    getTimeForModal(time) {
      if (this.availableTimes.filter((x) => time == x.id).length > 0)
        return this.availableTimes.filter((x) => time == x.id)[0].time;
      else return "";
    },

    createFilterData(value, name) {
      switch (name) {
        case "Filters":
          this.selected_status = value;
          this.params.status = value.map((v) => encodeURIComponent(v.value));
          break;
        case "Departments":
          this.params.departments = value.map((v) => encodeURIComponent(v.name));
          break;
        case "Focus Areas":
          this.params.focus_areas = value.map((v) => encodeURIComponent(v.name));
          break;
      }
      this.fetchMeetings(1, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.meetings-section {
  padding: 24px 32px;
  display: flex;
  justify-content: center;
}
.meetings-content-container {
  width: 100%;
  max-width: 1400px;
}

@media screen and (max-width: 576px) {
  .meetings-section {
    padding: 24px 16px 16px;
  }

  .content-container {
    padding: 0 !important;
  }
}
</style>
