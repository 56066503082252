<template>
  <div class="partner-tenant-recommended-groups-container">
    <div class="title-container">
      <h2 class="text">
        <i class="fa-regular fa-users icon"></i>
        Recommended Groups
      </h2>
      <router-link
        class="dabi-main-button-outlined see-all-button"
        aria-label="See all groups"
        to="/groups"
      >
        See all
      </router-link>
    </div>
    <div class="content-container">
      <button
        class="left-arrow arrow-button"
        aria-label="Show previous page"
        @click="showPreviousPage"
        @keyup.enter="showPreviousPage"
      >
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      <button
        class="right-arrow arrow-button"
        aria-label="Show next page"
        @click="showNextPage"
        @keyup.enter="showNextPage"
      >
        <i class="fa-solid fa-arrow-right"></i>
      </button>
      <div v-for="(group, index) in paginate(recommendedGroups, pageSize, activePage)" :key="index" class="item">
        <img :src="group.cover_pic" alt="Group cover image" class="image">
        <div class="info">
          <div
            class="name"
            role="button"
            aria-label="Redirect to the group page"
            @click="redirectToGroup(group.name)"
            @keyup.enter="redirectToGroup(group.name)"
            tabindex="0"
          >
            {{group.name}}
          </div>
          <div class="description">
            {{group.description}}
          </div>
          <button
            v-if="currentUserFollowing(group.group_follow_list)"
            class="follow-button"
            @click="unfollowGroup(group)">
            <i class="fa-solid fa-circle-check icon"></i>
            Following
          </button>
          <button
            v-else
            class="follow-button"
            @click="followGroup(group)">
            Follow
          </button>
        </div>
      </div>
    </div>
    <div class="actions-container">
      <slider-dots
        ref="recommendedGroupsSlider"
        :totalNo="getPagesCount(recommendedGroups, pageSize)"
        :initialSelectedNo="1"
        @changeSelected="changeDisplayedPageHandler"
      />
      <router-link
        class="dabi-main-button-outlined see-all-button mobile"
        aria-label="See all groups"
        to="/groups"
      >
        See all
      </router-link>
    </div>
  </div>
</template>
<script>
import DashboardService from '../../../dashboard/services/DashboardService';
import GroupService from '../../../groups/services/GroupService';
import SliderDots from '../../../partners/components/landing_pages/components/SliderDots.vue';
import * as Cookies from 'js-cookie';

export default {
  name: 'partner-tenant-recommended-groups',
  components: {
    SliderDots,
  },
  props: [

  ],
  data() {
    return {
      dashboardService: new DashboardService(),
      groupService: new GroupService(),
      recommendedGroups: [],
      active_user_id: 0,
      activePage: 1,
      pageSize: 3,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json'
      },
    }
  },
  created() {
    this.dashboardService.getDashboardData({ section: "recommendedGroups", limit: 9, overall_only: true }).then(data => {
      this.recommendedGroups = data;
      if (this.recommendedGroups && this.recommendedGroups.length > 0) {
        this.active_user_id = this.recommendedGroups[0].active_user_id;
      } else {
        this.emitIfEmpty();
      }
    });

    if (window.screen.width <= 576) {
      this.pageSize = 999;
    }
  },
  methods: {
    emitIfEmpty() {
      if (!this.recommendedGroups || this.recommendedGroups.length === 0) {
        this.$emit('is-empty');
      }
    },
    paginate(array, page_size, page_number) {
      return array.slice((page_number - 1) * page_size, page_number * page_size);
    },
    getPagesCount(array, page_size=4) {
      let result = Math.floor(array.length / page_size);
      if (array.length % page_size === 0) {
        return result;
      }
      return result + 1;
    },
    changeDisplayedPageHandler(pageNo) {
      this.changePage(pageNo);
    },
    changePage(pageNo) {
      this.activePage = pageNo;
    },
    showPreviousPage() {
      this.$refs.recommendedGroupsSlider.selectPrevious();
    },
    showNextPage() {
      this.$refs.recommendedGroupsSlider.selectNext();
    },
    currentUserFollowing(group_followers_ids) {
      return group_followers_ids.includes(this.active_user_id);
    },
    unfollowGroup(group) {
      const params = {
        'group_id': group.id
      }
      this.groupService.deleteGroupFollower(params, this.headers).then(data=> {
        const index = group.group_follow_list.indexOf(this.active_user_id);
        group.group_follow_list.splice(index, 1);
      });
    },
    followGroup(group) {
      const params = {
        'group_id': group.id
      }
      this.groupService.postGroupFollower(params, this.headers).then(data=> {
        group.group_follow_list.push(this.active_user_id);
      });
    },
    redirectToGroup(group_name) {
      this.$router.push(`/groups/${group_name}`);
    },
  },
  computed: {}
}
</script>
