<template>
  <div class="iu-table-burger-menu">
    <div
      class="iu-table-burger-menu-item"
      role="button"
      tabindex="0"
      aria-label="Open edit user dialog."
      @click="selectOption('edit-manager')"
      @keyup.enter="selectOption('edit-manager')"
    >
      <i class="fa-solid fa-pencil"></i>
      Edit User
    </div>
    <div
      class="iu-table-burger-menu-item"
      role="button"
      tabindex="0"
      aria-label="Open reset password dialog"
      @click="selectOption('reset-password')"
      @keyup.enter="selectOption('reset-password')"
    >
      <i class="fa-solid fa-key"></i>
      Reset Password
    </div>
    <div
      class="iu-table-burger-menu-item"
      role="button"
      tabindex="0"
      aria-label="Resend invitation"
      @click="selectOption('resend-invitation')"
      @keyup.enter="selectOption('resend-invitation')"
    >
      <i class="fa-solid fa-envelope"></i>
      Resend Invitation
    </div>
    <div
      class="iu-table-burger-menu-item"
      role="button"
      tabindex="0"
      aria-label="Delete manager"
      @click="selectOption('delete-manager')"
      @keyup.enter="selectOption('delete-manager')"
    >
      <i class="fa-solid fa-trash-alt"></i>
      Delete
    </div>
  </div>
</template>

<script>
  export default {
    name: 'managers-burger-menu',
    methods: {
      selectOption(option) {
        this.$emit(option);
      }
    }
  }
</script>
