<template>
  <div class="iu-welcome-video-screen-container" v-if="video.url && video.show" id="welcome-video-screen">
    <div class="iu-welcome-video-show-again-dialog" v-if="showDialog" id="welcome-video-show-again-dialog">
      <div class="iu-welcome-video-show-again-dialog-text">Are you sure you want to close the welcome video?</div>
      <div class="iu-welcome-video-show-again-dialog-option">
        <input
          type="checkbox"
          style="cursor: pointer"
          name="dont-show-again"
          id="dont-show-again-checkbox"
          v-model="dontShowAgain"
        />
        <label for="dont-show-again" style="position: relative; bottom: 1px; left: 2px"
          >Don't show this video again</label
        >
      </div>
      <div class="iu-welcome-video-show-again-dialog-buttons-container">
        <button id="video-show-again-submit-button" class="iu-main-btn x-small solid main" @click="submitDialogChoice">
          Submit
        </button>
        <button
          id="video-show-again-cancel-button"
          class="iu-main-btn x-small outlined main"
          @click="closeClosingDialog"
        >
          Cancel
        </button>
      </div>
    </div>
    <button
      class="iu-welcome-video-screen-close-button"
      type="button"
      id="exit-welcome-video-screen-btn"
      @click="openClosingDialog"
    >
      <i class="fa-duotone fa-xmark"></i>
    </button>
    <div class="iu-welcome-video-container">
      <h3 class="iu-welcome-video-title">
        {{ video.title || "Welcome!" }}
      </h3>
      <div v-if="video.description" class="iu-welcome-video-description">
        {{ video.description }}
      </div>
      <div class="iu-welcome-video-player-wrapper">
        <iframe
          id="welcome-video-frame"
          class="iu-welcome-video-frame"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          :src="videoEmbedLink"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { getYoutubeOrVimeoEmbedLink } from "../../utils/CustomUtils";
import DashboardService from "../services/DashboardService";
export default {
  name: "welcome-video",
  props: ["video", "userId"],
  data() {
    return {
      showDialog: false,
      videoEmbedLink: "",
      dontShowAgain: false,
      service: new DashboardService(),
    };
  },

  created() {
    if (this.video.show && this.video.url) {
      this.openWelcomeVideoScreen(this.video.url);
    }
  },
  methods: {
    getYoutubeOrVimeoEmbedLink,
    async updateSeenFlag(dontShowAgain) {
      if (dontShowAgain) {
        await this.service.closeWelcomeVideo(this.userId);
      }
    },
    closeWelcomeVideoScreen() {
      document.body.style.overflow = "auto";
      this.video.show = false;
      this.videoEmbedLink = "";
    },
    openClosingDialog() {
      this.showDialog = true;
    },
    closeClosingDialog() {
      this.showDialog = false;
    },
    submitDialogChoice() {
      this.updateSeenFlag(this.dontShowAgain);
      this.closeClosingDialog();
      this.closeWelcomeVideoScreen();
    },
    openWelcomeVideoScreen(videoUrl) {
      document.body.style.overflow = "hidden";
      this.videoEmbedLink = this.getYoutubeOrVimeoEmbedLink(videoUrl);
      this.video.show = true;
    },
  },
};
</script>
