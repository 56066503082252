<template>
  <div>
    <section v-if="listView" class='resource-row'>

      <div v-for="(resource, i) of resources" :key="resource.id">
        <div tabindex="0" class="inup-profile-row">
          <div style="display: flex">
            <img :aria-label="'Open resource dialog ' + resource.name" role="button" @click="viewItem(resource, i)" @keyup.enter="viewItem(resource, i)" v-if="resource.resource_type === 'IMG'" class="inup-profile-row-img" :src="`${resource.resource_url}`" />
            <i :aria-label="'Open resource dialog ' + resource.name" role="button" @click="viewItem(resource, i)" @keyup.enter="viewItem(resource, i)" v-if="resource.resource_type === 'VID'" class="far fa-file-video inup-profile-file" style="color:#9f2a8c" aria-hidden="true"></i>
            <i :aria-label="'Open resource dialog ' + resource.name" role="button" @click="viewItem(resource, i)" @keyup.enter="viewItem(resource, i)" v-if="resource.resource_type === 'URL'" class="far fa-external-link inup-profile-file" style="color:#438ca4;font-size: 32px;" aria-hidden="true"></i>
            <i :aria-label="'Open resource dialog ' + resource.name" role="button" @click="viewItem(resource, i)" @keyup.enter="viewItem(resource, i)" v-if="resource.resource_type === 'URL_INTERNAL'" class="far fa-external-link inup-profile-file" style="color:#438ca4;font-size: 32px;" aria-hidden="true"></i>
            <i :aria-label="'Open resource dialog ' + resource.name" role="button" @click="viewItem(resource, i)" @keyup.enter="viewItem(resource, i)" v-if="resource.resource_type === 'URL_EXTERNAL'" class="far fa-external-link inup-profile-file" style="color:#438ca4;font-size: 32px;" aria-hidden="true"></i>
            <i :aria-label="'Open resource dialog ' + resource.name" role="button" @click="viewItem(resource, i)" @keyup.enter="viewItem(resource, i)" v-if="resource.resource_type === 'DOC'" class="far fa-file-alt inup-profile-file" style="color:#8673fd" aria-hidden="true"></i>

            <div class="row-body-wrapper">
              <div :aria-label="'Open resource dialog ' + resource.name" @click="viewItem(resource, i)" @keyup.enter="viewItem(resource, i)" :id="`profile_user_card_${resource.id}`" :title="resource.name" tabindex="0" class="inup-profile-row-name">{{ resource.name }}</div>
              <div class="inup-profile-row-subtitle" :title="resource.group ? resource.group.name : resource.department.name" >
                <span class="inup-profile-row-dept">Posted by {{ resource.group ? resource.group.name : resource.department.name}}</span>
              </div>
            </div>
          </div>
          <div class="row-right-wrapper" style="margin-right: 5px">
            <span
              class="inup-profile-row-bookmark"
              :title="`${resource.is_bookmarked ? 'Remove bookmark from resource' : 'Bookmark resource'}`"
              @click="handleBookmark(resource)"
              @keyup.enter="handleBookmark(resource)"
              aria-label="Toggle bookmark"
              role="button"
            >
              <i tabindex="0" :class="`${resource.is_bookmarked ? 'bookmark-active' : ''} fad fa-bookmark bookmark-icon`"></i>
            </span>
            <span
              tabindex="0"
              ref="resOptionDots"
              class="inup-profile-row-dots"
              @click="showBurger(resource)"
              @keyup.enter="showBurger(resource)"
              aria-label="Burger menu"
              role="menu"
              aria-haspopup="menu"
            >
              <i class="fas fa-ellipsis-v"></i>
            </span>
            <div
              ref="resGridOptionsDropdown"
              :id="idPrefix + resource.id + resource.timestamp"
              v-closable="{ exclude: ['resOptionDots', 'resGridOptionsDropdown'], handler: 'closeBurgers' }"
              class="inup-profile-row-burger"
            >
              <span
                @click="viewItem(resource, i)"
                @keyup.enter="viewItem(resource, i)"
                tabindex="0"
                :aria-label="`Open ${resource.name} event`"
                aria-haspopup="dialog"
                role="button"
                class="eu-resource-burger-el"
              >
                <i class="fas fa-eye" style="padding-right: 5px"></i>
                <span>View</span>
              </span>
              <span
                tabindex="0"
                :aria-label="`Download ${resource.name}`"
                role="button"
                @click="downloadItem(resource.resource_url, resource.name, resource.id)"
                @keyup.enter="downloadItem(resource.resource_url, resource.name, resource.id)"
                v-if="resource.resource_type != 'VID' && resource.resource_type != 'URL' && resource.resource_type != 'URL_INTERNAL' && resource.resource_type != 'URL_EXTERNAL'"
                class="eu-resource-burger-el"
              >
              <i class="fas fa-download" style="padding-right: 5px"></i>
              <span>Download</span>
              </span>
            </div>
          </div>
        </div>
      </div>

    </section>
    <ul v-if="!listView && !studentDashboard" class="eu-resource-grid myhub">
      <li v-for="(item, i) in resources" :key="item.id" class="eu-resource-grid-item">
        <div class="eu-resource-grid-preview">
          <span
            tabindex="0"
            v-if="item.resource_type !== 'IMG'"
            @click="viewItem(item, i)"
            @keyup.enter="viewItem(item, i)"
            :aria-label="`Open ${item.name} in modal`"
            aria-haspopup="dialog"
            role="button"
          >
            <i v-if="item.resource_type === 'VID'" class="far fa-file-video file-icon-grid"></i>
            <i v-if="item.resource_type === 'URL'" class="far fa-external-link file-icon-grid"></i>
            <i v-if="item.resource_type === 'URL_INTERNAL'" class="far fa-external-link file-icon-grid"></i>
            <i v-if="item.resource_type === 'URL_EXTERNAL'" class="far fa-external-link file-icon-grid"></i>
            <i v-if="item.resource_type === 'DOC'" class="far fa-file-alt file-icon-grid"></i>
          </span>

          <img
            tabindex="0"
            v-if="item.resource_type === 'IMG'"
            @click="viewItem(item, i)"
            @keyup.enter="viewItem(item, i)"
            :aria-label="`Open ${item.name} in modal`"
            :src="`${item.resource_url}`"
            class="eu-resource-grid-img"
            aria-haspopup="dialog"
            role="button"
          />
          <div
            tabindex="0"
            role="button"
            :aria-label="`Remove ${item.name} from saved resources`"
            @click="removeBookmark(item.id)"
            @keyup.enter="removeBookmark(item.id)"
            class="eu-resource-cell eu-resource-cell-bookmark-icon grid-icon"
          >
            <i class="fad fa-bookmark" style="color: #9f2a8c; background: #eaeeff;"></i>
          </div>
          <div
            :id="`${idPrefix}${item.id}-options`"
            tabindex="0"
            ref="resOptionDots"
            aria-label="show more options"
            role="button"
            aria-haspopup="menu"
            @click="showBurger(item, 'grid')"
            @keyup.enter="showBurger(item, 'grid')"
            class="eu-resource-cell eu-resource-cell-dots-icon grid-icon"
          >
            <i class="fad fa-ellipsis-v-alt"></i>
          </div>
          <div
            :id="idPrefix + item.id + item.timestamp"
            ref="resGridOptionsDropdown"
            v-closable="{ exclude: ['resOptionDots', 'resGridOptionsDropdown'], handler: 'closeBurgers' }"
            class="eu-resource-burger"
            :style="`${i % 2 ? 'right: 20px' : 'right: -70px; top: 40px;'}`"
          >
            <span
              tabindex="0"
              :aria-label="`Open ${item.name} in modal`"
              role="button"
              aria-haspopup="dialog"
              @click="viewItem(item, i)"
              @keyup.enter="viewItem(item, i)"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-eye" style="margin-right: 5px"></i>
              <span>View</span>
            </span>
            <span
              tabindex="0"
              :aria-label="`Download ${item.name}`"
              role="button"
              @click="downloadItem(item.resource_url, item.name)"
              @keyup.enter="downloadItem(item.resource_url, item.name)"
              v-if="item.resource_type != 'VID' && item.resource_type != 'URL' && item.resource_type != 'URL_INTERNAL' && item.resource_type != 'URL_EXTERNAL'"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-download" style="margin-right: 5px"></i>
              <span>Download</span>
            </span>
            <span
              tabindex="0"
              :aria-label="`Remove ${item.name} from saved resources`"
              @click="removeBookmark(item.id)"
              @keyup.enter="removeBookmark(item.id)"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fad fa-bookmark" style="color: #9f2a8c; background: #eaeeff; margin-right: 10px;"></i>
              <span>{{ 'Saved' }}</span>
            </span>
          </div>
        </div>
        <div class="eu-resource-grid-footer">
          <span
            tabindex="0"
            @keyup.enter="viewItem(item, i)"
            @click="viewItem(item, i)"
            role="button"
            :aria-label="`Open ${item.name} in modal`"
            aria-haspopup="dialog"
            class="eu-resource-grid-name"
          >
            {{ item.name }}
          </span>
          <div v-if="item.description" class="eu-resource-desc-grid">
            <span class="eu-resource-desc-el">
              <h4 class="eu-resource-desc-title">Description</h4>
              <p class="eu-resource-desc-text">{{ item.description }}</p>
            </span>
          </div>
          <span v-if="item.department" class="eu-resource-grid-dept">{{ item.department.name }}</span>
        </div>
      </li>
    </ul>
    <ul v-if="!listView && studentDashboard" class="eu-resource-grid dashboard">
      <li v-for="(item, i) in resources" :key="item.id" class="eu-resource-grid-item" style="height: 151px; border: 2px solid #ede1ff;">
        <div class="eu-resource-grid-preview" style="height: 96px;">
          <span
            tabindex="0"
            v-if="item.resource_type !== 'IMG'"
            @click="viewItem(item, i)"
            @keyup.enter="viewItem(item, i)"
            :aria-label="`Open ${item.name} in modal`"
            aria-haspopup="dialog"
            role="button"
            style="height: 96px;"
          >
            <i v-if="item.resource_type === 'VID'" class="far fa-file-video file-icon-grid"></i>
            <i v-if="item.resource_type === 'URL'" class="far fa-external-link file-icon-grid"></i>
            <i v-if="item.resource_type === 'URL_INTERNAL'" class="far fa-external-link file-icon-grid"></i>
            <i v-if="item.resource_type === 'URL_EXTERNAL'" class="far fa-external-link file-icon-grid"></i>
            <i v-if="item.resource_type === 'DOC'" class="far fa-file-alt file-icon-grid"></i>
          </span>

          <img
            tabindex="0"
            v-if="item.resource_type === 'IMG'"
            @click="viewItem(item, i)"
            @keyup.enter="viewItem(item, i)"
            :aria-label="`Open ${item.name} in modal`"
            :src="`${item.resource_url}`"
            class="eu-resource-grid-img"
            aria-haspopup="dialog"
            role="button"
            style="height: 96px; border-radius: 5px 5px 0px 0px;"
          />
          <div
            tabindex="0"
            role="button"
            :aria-label="item.is_bookmarked ? 'Remove '+ item.name +' from saved resources' : 'Add '+ item.name +' to saved resources'"
            @click="handleBookmark(item)"
            @keyup.enter="handleBookmark(item)"
            class="eu-resource-cell eu-resource-cell-bookmark-icon grid-icon"
            style="position: absolute; top: 37px; right: -2px; margin: 0; padding: 0;"
          >
            <i :class="`${item.is_bookmarked ? 'bookmark-active' : ''} fad fa-bookmark bookmark-icon`"></i>
          </div>
          <div
            :id="`${idPrefix}${item.id}-options`"
            tabindex="0"
            ref="resOptionDots"
            aria-label="show more options"
            role="button"
            aria-haspopup="menu"
            @click="showBurger(item, 'grid')"
            @keyup.enter="showBurger(item, 'grid')"
            class="eu-resource-cell eu-resource-cell-dots-icon grid-icon"
            style="position: absolute; top: 5px; right: -2px; margin: 0; padding: 0;"
          >
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </div>
          <div
            :id="idPrefix + item.id + item.timestamp"
            ref="resGridOptionsDropdown"
            v-closable="{ exclude: ['resOptionDots', 'resGridOptionsDropdown'], handler: 'closeBurgers' }"
            class="eu-resource-burger"
            :style="`top: 40px; ${i % 2 ? 'right: 20px' : 'right: -70px; '}`"
          >
            <span
              tabindex="0"
              :aria-label="`Open ${item.name} in modal`"
              role="button"
              aria-haspopup="dialog"
              @click="viewItem(item, i)"
              @keyup.enter="viewItem(item, i)"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-eye" style="margin-right: 5px"></i>
              <span>View</span>
            </span>
            <span
              tabindex="0"
              :aria-label="`Download ${item.name}`"
              role="button"
              @click="downloadItem(item.resource_url, item.name)"
              @keyup.enter="downloadItem(item.resource_url, item.name)"
              v-if="item.resource_type != 'VID' && item.resource_type != 'URL' && item.resource_type != 'URL_INTERNAL' && item.resource_type != 'URL_EXTERNAL'"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-download" style="margin-right: 5px"></i>
              <span>Download</span>
            </span>
          </div>
        </div>
        <div class="eu-resource-grid-footer" style="height: 51px; border-radius: 0px 0px 8px 8px;">
          <span
            tabindex="0"
            @keyup.enter="viewItem(item, i)"
            @click="viewItem(item, i)"
            role="button"
            :aria-label="`Open ${item.name} in modal`"
            aria-haspopup="dialog"
            class="eu-resource-grid-name"
            style="width: 100%;"
          >
            {{ item.name }}
          </span>
          <div v-if="item.description" class="eu-resource-desc-grid" :class="getDescriptionClasses(i)">
            <span class="eu-resource-desc-el">
              <h4 class="eu-resource-desc-title">Description</h4>
              <p class="eu-resource-desc-text">{{ item.description }}</p>
            </span>
          </div>
          <span v-if="item.department" class="eu-resource-grid-dept" style="font-size: 10px; width: 100%;">Posted by {{ item.department.name }}</span>
        </div>
      </li>
    </ul>
    <resource-modal
      :isResourceModalActive="isModalActive"
      :activeResource="activeResource"
      @close-resource-modal="closeResourceModal"
    />
  </div>
</template>
<script lang="ts">
import handleOutsideClick from '../../directives/handleOutsideClick';
import { getYoutubeOrVimeoEmbedLink, focusElement } from '../../utils/CustomUtils';
import ResourceBookmarkService from '../../library/services/ResourceBookmarkService';
import Cookies from 'js-cookie';
import ResourceLibraryService from '../../library/services/ResourceLibraryService';
import ResourceModal from '../../library/components/modals/ResourceModal.vue';

export default {
  name: 'browse-saved-resources',

  directives: { closable: handleOutsideClick },

  components: { ResourceModal },

  props: ['resources', 'listView', 'idPrefix', 'studentDashboard'],

  computed: {
    isMyHub() {
      return !this.listView && !this.studentDashboard;
    }
  },

  data() {
    return {
      activeResource: { name: null, resource_url: null, description: null },
      isModalActive: false,
      lastOpenedBurger: null,
      bookmarkService: new ResourceBookmarkService(),
      resourcesService: new ResourceLibraryService(),
      assetPath: process.env.MEDIA_URL,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json'
      }
    };
  },

  methods: {
    getDescriptionClasses(index) {
      let item_position = index + 1;

      if (item_position % 3 == 1 && item_position % 2 == 1) {
        return "left-aligned-resource-description-container left-aligned-resource-description-container-desktop";
      }

      if (item_position % 2 == 1) {
        return "left-aligned-resource-description-container";
      }

      if (item_position % 3 == 1) {
        return "left-aligned-resource-description-container-desktop";
      }

      return "";
    },
    removeBookmark(id: string) {
      this.$emit('remove-bookmark', id);
    },

    async handleBookmark(el) {
      if (this.lastOpenedBurger) this.lastOpenedBurger.classList.remove('eu-resource-burger-active');

      if (el.is_bookmarked) {
        await this.bookmarkService.delete({ target: el.id }, this.headers);
      } else {
        await this.bookmarkService.post({ target: el.id }, this.headers);
      }

      el.is_bookmarked = !el.is_bookmarked;
    },

    viewItem(item: any, index: number) {
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'none';
      this.activeResource = item;
      this.activeResource.index = index;
      this.isModalActive = true;
      this.closeBurgers();
      this.resourcesService.increaseViews(item.id)
      focusElement('resource-header');
    },

    prevItem() {
      if (this.resources.length < 2) {
        return;
      }
      const prevIndex = this.activeResource.index - 1 >= 0 ? this.activeResource.index - 1 : this.resources.length - 1;
      const prevItem = this.resources[prevIndex];
      this.viewItem(prevItem, prevIndex);
    },

    nextItem() {
      if (this.resources.length < 2) {
        return;
      }
      const nextIndex = this.activeResource.index + 1 < this.resources.length ? this.activeResource.index + 1 : 0;
      const nextItem = this.resources[nextIndex];
      this.viewItem(nextItem, nextIndex);
    },

    closeResourceModal() {
      this.isModalActive = false;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'block';
      focusElement(`${this.idPrefix}${this.activeResource.id}-options`);
      this.activeResource = { name: null, resource_url: null, description: null };
    },

    async downloadItem(dataurl: string, filename: string, id) {
      const res = await fetch(dataurl);
      const blob = await res.blob();
      this.startDownload(blob, filename);
      this.resourcesService.increaseDownloaded(id)
      this.closeBurgers();
    },

    startDownload(content, filename) {
      var a = document.createElement('a');
      a.download = filename ? filename : 'resources';
      document.body.appendChild(a);
      a.href = URL.createObjectURL(content);
      a.style.display = 'none';
      a.click();
      document.body.removeChild(a);
    },

    closeBurgers() {
      this.lastOpenedBurger && this.lastOpenedBurger.classList.remove('eu-resource-burger-active');
    },

    showBurger(item) {
      const burgerEl = document.querySelector(`#${this.idPrefix}${item.id}${item.timestamp}`);
      if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl) {
        this.lastOpenedBurger.classList.remove('eu-resource-burger-active');
      }
      burgerEl.classList.toggle('eu-resource-burger-active');
      this.lastOpenedBurger = burgerEl;
    },

    getYoutubeOrVimeoEmbedLink
  }
};
</script>
