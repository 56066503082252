import Vue from 'vue';
import MentorMeetingsAnalyticsComponent from "./components/MentorMeetingsAnalyticsComponent.vue";

interface AppData{
    mentorId: number,
}

export default class MentorMeetingsAnalytics {
    protected mountPoint: HTMLElement;
    protected mentorId:number;
    constructor(el: HTMLElement, appData: AppData) {
        this.mountPoint = el;
        this.mentorId = appData.mentorId;
        this.run();
    }

    async run(): Promise<void> {
        const _class = this;
        const mentorAnalytics = new Vue({
            components: {
                MentorMeetingsAnalyticsComponent,
            },

            template: `
                <mentor-meetings-analytics-component :mentorId="mentorId"/>
            `,

            data() {
                return {
                    mentorId: _class.mentorId
                }
            },
        });

        mentorAnalytics.$mount(this.mountPoint);
    }
}