<template>
  <section>
    <div class="lg-space-between quest-title-wrapper">
      <common-title title="Quests" description="Browse and save quests" />
      <div>
        <button
          v-if="showAddBtn"
          aria-label="Create new Quest"
          class="iu-main-btn solid main small"
          @click="changePage('/quests/add')"
        >
          Create a Quest <i class="fa-regular fa-chevron-right iu-main-button-arrow-icon"></i>
        </button>
      </div>
    </div>
    <nav class="eu-simple-navbar">
      <span
        tabindex="0"
        :class="
          $route.path === '/quests/' ? 'eu-simple-navbar-item eu-simple-navbar-item-active' : 'eu-simple-navbar-item'
        "
        @click="changePage('/quests/')"
        role="link"
      >
        Browse Quests
      </span>
      <span
        v-if="canManage"
        tabindex="0"
        :class="
          $route.path === '/quests/manage'
            ? 'eu-simple-navbar-item eu-simple-navbar-item-active'
            : 'eu-simple-navbar-item'
        "
        @click="changePage('/quests/manage')"
        role="link"
      >
        Manage Quests
      </span>
    </nav>
  </section>
</template>

<script>
import CommonTitle from "../../components/CommonTitle.vue";

export default {
  name: "quests-header",
  components: { CommonTitle },
  props: ["canManage", "showAddBtn"],
  computed: {},
  data() {
    return {};
  },
  methods: {
    changePage(page) {
      this.$router.push(page);
    },
  },
};
</script>

<style lang="scss" scoped>
.quest-title-wrapper {
  padding: 24px 0;
}
@media screen and (max-width: 576px) {
  .quest-title-wrapper {
    gap: 16px;
  }
}
</style>
