<template>
  <div class="content-cell" :class="isFeatured ? 'featured' : ''">
    <router-link :to="`/${item.slug}`" class="link"></router-link>

    <button
      class="dabi-bookmark-button"
      :class="item.is_bookmarked ? 'active' : ''"
      @click="toggleBookmark()"
      :aria-label="`${item.is_bookmarked ? 'Remove' : 'Add'} bookmark`"
    >
      <i v-if="item.is_bookmarked" class="fa-solid fa-bookmark"></i>
      <i v-else class="fa-regular fa-bookmark"></i>
    </button>

    <img :src="item.thumbnail" alt="" class="image" />

    <h3 class="title">{{ item.title }}</h3>

    <div v-if="item.location" class="location">
      {{ item.location }}
    </div>

    <div class="label-wrapper">
      <div class="label">
        {{ labelText }}
      </div>
    </div>

    <p class="description">
      {{ item.description }}
    </p>

    <div class="partner-wrapper">
      <div
        v-if="item.partner"
        class="partner-container"
        tabindex="0"
        @click="goToPartnersPage"
        @keyup.enter="goToPartnersPage"
      >
        <img v-if="item.partner.logo_url" class="logo" :src="item.partner.logo_url" :alt="item.partner.name" />
        <span class="name">{{ item.partner.name }}</span>
      </div>

      <span v-if="isFeatured" class="featured-label">
        <i class="fa-solid fa-stars"></i>
        Featured
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'partner-event-card-v3',
  props: ['item', 'isFeatured'],
  computed: {
    labelText() {
      if (this.item.type_of_program_management == 'event') {
        return 'Event';
      }

      if (this.item.program_type) {
        return this.item.program_type;
      }

      if (this.item.type) {
        return this.item.type;
      }

      return 'Program';
    },
  },
  methods: {
    toggleBookmark() {
      this.$emit('toggle-bookmark', this.item);
    },
    goToPartnersPage() {
      this.$router.push(`/partners/${encodeURIComponent(this.item.partner.name)}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-cell {
  position: relative;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #d8dce5;
  background-color: #fff;
  box-shadow: 2px 2px 24px 16px rgba(0, 0, 0, 0.08);

  .link {
    position: absolute;
    inset: 0;
  }

  .dabi-bookmark-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .partner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    margin-top: 0.75rem;

    .featured-label {
      margin-left: auto;
    }
  }

  .featured-label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem 0.375rem 0.375rem;
    border-radius: 16px;
    background-color: #e3faf2;
    font-size: 0.813rem;
    font-weight: 600;
    color: #2d98d2;
  }

  .image {
    display: block;
    width: 4rem;
    height: 4rem;
    border-radius: 16px;
    object-fit: cover;
    float: left;
    margin: 0 0.75rem 0.75rem 0;
  }

  .title {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25;
    color: #444;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0.5rem;
    padding: 0;
    padding-right: 48px;
  }

  .location {
    font-size: 0.75rem;
    font-weight: 600;
    color: #838ea1;
  }

  .label {
    align-self: start;
    display: inline-block;
    padding: 0.063rem 0.5rem 0.188rem;
    border-radius: 16px;
    background-color: #f2f5f7;
    color: #516075;
    font-size: 0.75rem;
    font-weight: 500;

    &-wrapper {
      margin-top: 0.5rem;
      clear: none;

      @media screen and (max-width: 576px) {
        clear: left;
      }
    }
  }

  .description {
    font-size: 1rem;
    line-height: 1.4;
    color: #494949;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 0.75rem;
  }

  .partner-container {
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &:hover {
      .name {
        color: #666;
      }
    }

    .logo {
      border: 1px solid #d8dce5;
      border-radius: 16px;
      width: 1.5rem;
      height: 1.5rem;
      object-fit: cover;
      object-position: center;
    }

    .name {
      color: #444444;
      font-size: 0.875rem;
      font-weight: 600;
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  &.featured {
    border: solid 1px #c0e6eb;

    .image {
      width: 6.625rem;
      height: 7.188rem;

      @media screen and (max-width: 576px) {
        width: 4rem;
        height: 4rem;
      }
    }
  }
}
</style>
