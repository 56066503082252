<template>
  <div v-if="isPageLoading">
    <loading-spinner wrapperClass="wrapper" />
  </div>
  <div v-else class="flex-column-center">
    <div class="main-wrapper">
      <div class="title-wrapper">
        <common-title
          title="Opportunities"
          :description="`Browse ${
            hasProgramsAccess && hasEventsAccess ? 'events and programs' : hasEventsAccess ? 'events' : 'opportunities'
          } posted by your university.`"
        />
        <div v-if="canManage || isManager">
          <div v-if="hasProgramsAccess && hasEventsAccess" class="desktop-only">
            <popup-component buttonText="Create">
              <template #items>
                <a class="eu-resource-burger-el" aria-label="Go to events creation page" :href="createEventLink">
                  <i class="fa-solid fa-calendar-pen" style="padding-right: 5px"></i>
                  <span>Event</span>
                </a>
                <a class="eu-resource-burger-el" aria-label="Go to program creation page" :href="createProgramLink">
                  <i class="fa-solid fa-building-columns" style="padding-right: 5px"></i>
                  <span>Program</span>
                </a>
              </template>
            </popup-component>
          </div>
          <div v-else-if="hasProgramsAccess">
            <a
              class="iu-main-btn main solid x-small add-opportunities-button-alignment"
              style="width: 100%"
              aria-label="Go to program creation page"
              :href="createProgramLink"
            >
              <i class="fa-solid fa-landmark iu-main-button-front-icon"></i>
              Add Program
            </a>
          </div>
          <div v-else-if="hasEventsAccess">
            <a
              aria-label="Go to events creation page"
              :href="createEventLink"
              class="iu-main-btn main solid x-small add-opportunities-button-alignment"
              style="width: 100%"
            >
              <i class="fa-solid fa-calendar-pen iu-main-button-front-icon"></i>
              Add Event
            </a>
          </div>
          <div class="mobile-only add-opportunities-buttons-container">
            <span v-if="hasProgramsAccess && hasEventsAccess" class="mobile-options">
              <a
                v-if="hasEventsAccess"
                aria-label="Go to events creation page"
                :href="createEventLink"
                class="iu-main-btn main solid x-small add-opportunities-button-alignment"
                style="width: 100%"
              >
                <i class="fa-solid fa-calendar-pen iu-main-button-front-icon"></i>
                Add Event
              </a>
              <a
                v-if="hasProgramsAccess"
                class="iu-main-btn main solid x-small add-opportunities-button-alignment"
                style="width: 100%"
                aria-label="Go to program creation page"
                :href="createProgramLink"
              >
                <i class="fa-solid fa-landmark iu-main-button-front-icon"></i>
                Add Program
              </a>
            </span>
          </div>
        </div>
      </div>

      <navigation-tabs
        :navigationItems="navigationItems"
        :preventRedirect="true"
        @changeTab="changePage"
        :initialTab="currentPage"
        class="partner-nav-container"
      ></navigation-tabs>

      <!-- Search -->
      <div :key="searchKey" class="event-search mobile-full-w">
        <p class="event-search-title">Search events, programs, internships, scholarships, grad school, and jobs</p>

        <form @submit.prevent="handleFormSubmit" class="event-search-form">
          <div class="event-search-main-section">
            <div class="event-search-icon-wrapper">
              <i class="event-search-icon fa-regular fa-magnifying-glass"></i>
            </div>

            <input-base
              type="text"
              aria-label="Search opportunities"
              placeholder="Search opportunities"
              id="search-opportunities"
              wrapperClass="dabi-search-input"
              class="event-search-search-opportunities-input"
              v-model="params.q"
            />

            <multiselect-filter-component
              v-if="hasEventsAccess && hasProgramsAccess"
              placeholder="Any Type"
              filter_name="Events & Program"
              class="event-search-type-multiselect-input"
              style="margin: 0 !important"
              :options="type_of_entity"
              @filter-data="createFilterData"
            />

            <div class="event-search-actions">
              <button type="submit" class="event-search-button">Search</button>

              <button
                type="button"
                @click="toggleFilterSection"
                class="event-search-filters-button"
                :class="!filterSectionCollapsed ? 'event-search-filters-button-active' : ''"
              >
                <i class="fa-solid fa-sliders"></i>
                Filters
                <span v-if="filtersBadgeNumber" class="event-search-filters-button-indicator">
                  {{ filtersBadgeNumber }}
                </span>
              </button>
            </div>
          </div>

          <div class="event-search-filters" v-show="!filterSectionCollapsed">
            <multiselect-filter-component
              placeholder="Any Career/Academic"
              filter_name="Career/Academic interests"
              style="margin: 0 !important"
              :options="filterData.cae"
              @filter-data="createFilterData"
            />
            <multiselect-filter-component
              placeholder="Any Location"
              filter_name="Location"
              style="margin: 0 !important"
              :options="filterData.countries"
              @filter-data="createFilterData"
            />
            <multiselect-filter-component
              placeholder="Any Audience"
              filter_name="Audiences"
              style="margin: 0 !important"
              :options="filterData.audiences"
              @filter-data="createFilterData"
            />
            <multiselect-filter-component
              placeholder="Any Partner"
              filter_name="Partners"
              style="margin: 0 !important"
              :options="filterData.partners"
              @filter-data="createFilterData"
            />
          </div>
        </form>
      </div>

      <div v-if="showFeatured" class="featured-section">
        <h2 class="title">Featured Opportunities</h2>

        <div class="event-cards-container">
          <partner-event-card-v2
            v-for="(item, index) in featuredEvents"
            :key="index"
            :item="item"
            :isFeatured="item.is_featured"
            @toggle-bookmark="handleBookmarkEvent"
          ></partner-event-card-v2>
        </div>
      </div>

      <template v-if="initialEventsFetched">
        <div v-if="isLoading">
          <loading-spinner wrapperClass="wrapper" />
        </div>
        <div v-else-if="events.length > 0" class="event-search-results-section">
          <div class="event-search-results-section-header">
            <p class="event-search-results-section-title">{{ total_num }} {{ `result${isPlural ? 's' : ''}` }}</p>
          </div>

          <div ref="eventsContainer" class="event-search-results-section-results mobile-full-w">
            <partner-event-card-v3
              v-for="(item, index) in events"
              :key="index"
              :item="item"
              :isFeatured="item.is_featured"
              @toggle-bookmark="handleBookmarkEvent"
            ></partner-event-card-v3>
          </div>
        </div>
        <div v-else class="empty-state">
          <div>
            <i class="fad fa-calendar-star eu-empty-icon partner"></i>
          </div>

          <div class="empty-state-content">
            <p class="empty-state-title">
              Oops, no results found but check out these other opportunities or try another search
            </p>
          </div>

          <div class="event-cards-container">
            <partner-event-card-v2
              v-for="(item, index) in featuredEvents"
              :key="index"
              :item="item"
              :isFeatured="item.is_featured"
              @toggle-bookmark="handleBookmarkEvent"
            ></partner-event-card-v2>
          </div>
        </div>
      </template>

      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </div>
  </div>
</template>
<script lang="ts">
import '../utils/DateFormat';
import Cookies from 'js-cookie';
import { parseGETParamsIntoObject } from '../../utils/GETParametersUtil';
import EventsIndexService from '../services/EventsIndexService';
import EventList from './EventList.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import EventUtils from '../utils/EventUtils';
import MultiselectFilterComponent from '../../form/components/MultiselectFilterComponent.vue';
import CardComponent from './cards/CardComponent.vue';
import PartnerEventCardV2 from './cards/PartnerEventCardV2.vue';
import PartnerEventCardV3 from './cards/PartnerEventCardV3.vue';
import RSVPService from '../services/RSVPService';
import PopupComponent from '../../components/popup/PopupComponent.vue';
import Notification from '../../components/Notification.vue';
import NavigationTabs from '../../components/NavigationTabs.vue';
import { useCommonDataStore } from '../../stores/commonData';
import CommonTitle from '../../components/CommonTitle.vue';
import { FEATURES, hasPermission } from '../../utils/PermissionUtils';
import InputBase from '../../components/formBase/InputBase.vue';

export default {
  name: 'events-index',
  components: {
    EventList,
    LoadingSpinner,
    MultiselectFilterComponent,
    CardComponent,
    PopupComponent,
    Notification,
    PartnerEventCardV2,
    PartnerEventCardV3,
    NavigationTabs,
    InputBase,
    CommonTitle,
  },
  data() {
    return {
      events: [],
      featuredEvents: [],
      commonDataStore: useCommonDataStore(),
      currentPage: 'browse',
      isPageLoading: false,
      isLoading: false,
      filterData: null,
      type_of_entity: [
        { name: 'Programs', value: 'program' },
        { name: 'Events', value: 'event' },
      ],
      isNotificationActive: false,
      notification: { msg: '', isError: false, icon: null },
      initialParams: {},
      params: {
        q: '',
        type: [],
        career_academic_interests: [],
        countries: [],
        focus_areas: [],
        audiences: [],
        partners_name: [],
        saved: false,
        is_public: true,
        page: 1,
      },
      attending_event_ids_live_list: [],
      filterSectionCollapsed: true,
      total_num: null,
      isManager: null,
      canManage: null,
      partner_name: '',
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
      navigationItems: [
        {
          label: 'Browse Opportunities',
          routeName: 'browse',
          isRedirectUrl: false,
        },
        {
          label: 'Saved/Upcoming',
          routeName: 'saved',
          isRedirectUrl: false,
        },
      ],
      initialEventsFetched: false,
      isFetchingEvents: false,
      searchKey: null,
    };
  },
  computed: {
    createProgramLink() {
      return this.partner_name && this.partner_name !== 'None'
        ? `/programs/create?partner=${this.partner_name}`
        : '/programs/create';
    },
    createEventLink() {
      return this.partner_name && this.partner_name !== 'None'
        ? `/events/create/?partner=${this.partner_name}`
        : '/events/create/';
    },
    isPlural() {
      return this.total_num !== 1;
    },
    appliedFilters() {
      const appliedFilters = [];

      if (this.params.q !== '') appliedFilters.push('q');
      if (this.params.type.length > 0) appliedFilters.push('type');
      if (this.params.career_academic_interests.length > 0) appliedFilters.push('career_academic_interests');
      if (this.params.countries.length > 0) appliedFilters.push('countries');
      if (this.params.audiences.length > 0) appliedFilters.push('audiences');
      if (this.params.partners_name.length > 0) appliedFilters.push('partners_name');

      return appliedFilters;
    },
    filtersBadgeNumber() {
      const badgeFilters = ['career_academic_interests', 'countries', 'audiences', 'partners_name'];
      return this.appliedFilters.filter((filter) => badgeFilters.includes(filter)).length;
    },
    showFeatured() {
      return this.currentPage === 'browse' && this.featuredEvents.length > 0 && !this.initialEventsFetched;
    },
    hasMoreEvents() {
      return this.total_num !== this.events.length;
    },
  },
  async created() {
    this.initialParams = { ...this.params };
    this.hasEventsAccess = hasPermission(FEATURES.EVENTS);
    this.hasProgramsAccess = hasPermission(FEATURES.PROGRAMS);

    this.eventsService = new EventsIndexService();
    this.rsvpService = new RSVPService();
    this.eventUtils = new EventUtils(this.eventsService, this.headers);

    const params = parseGETParamsIntoObject();

    if (params.hasOwnProperty('page') && params['page'] === 'saved') {
      await this.changePage('saved');
    }

    await this.getPreData();

    this.type_of_entity = [
      { name: 'Events', value: 'event' },
      { name: 'Programs', value: 'program' },
      { name: 'Graduate School', value: 'Graduate School' },
      { name: 'Internships & Jobs', value: 'Internships & Jobs' },
      { name: 'Global Programs', value: 'Global Programs' },
      { name: 'Scholarships', value: 'Scholarships' },
      { name: 'Professional/Leadership Programs', value: 'Professional/Leadership Programs' },
    ];

    if (this.canManage) {
      this.navigationItems = [
        {
          label: 'Browse Opportunities',
          routeName: 'browse',
          isRedirectUrl: false,
        },
        {
          label: 'Manage Opportunities',
          routeName: 'manage',
          isRedirectUrl: false,
        },
        {
          label: 'Saved/Upcoming',
          routeName: 'saved',
          isRedirectUrl: false,
        },
        {
          label: 'Manage Leads',
          routeName: 'leads',
          isRedirectUrl: false,
        },
      ];
    }

    this.searchKey = Date.now();
  },
  mounted() {
    window.addEventListener('scroll', this.infiniteEventsFetch);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.infiniteEventsFetch);
  },
  methods: {
    async getPreData() {
      this.isPageLoading = true;

      const res = await this.eventsService.getPredata();

      if (res) {
        this.filterData = this.commonDataStore.getCommonData;
        this.filterData.partners = res.partners;
        this.filterData.countries = [{ name: 'Virtual/Hybrid', value: 'Virtual/Hybrid' }, ...this.filterData.countries];

        this.isManager = res.is_manager;
        this.canManage = res.can_manage;
        this.attending_event_ids_live_list = res.attending_event_ids;
        this.partner_name = res.partner_name;
        this.featuredEvents = res.featured_events;
      }

      this.isPageLoading = false;
    },
    resetFilters() {
      this.params = { ...this.initialParams };
      this.searchKey = Date.now();
    },
    resetData() {
      this.params.page = 1;
      this.filterSectionCollapsed = true;
      this.initialEventsFetched = false;
      this.events = [];
      this.total_num = null;
    },
    async handleFormSubmit() {
      this.resetData();
      await this.fetchEvents();
    },
    async fetchEvents() {
      if (!this.hasMoreEvents || this.isFetchingEvents) return;

      if (!this.initialEventsFetched) {
        this.isLoading = true;
        this.initialEventsFetched = true;
      }

      const payload = {
        q: encodeURIComponent(this.params.q),
        type: JSON.stringify(this.params.type),
        career_academic_interests: JSON.stringify(this.params.career_academic_interests),
        focus_areas: JSON.stringify([...this.params.focus_areas, ...this.params.countries]),
        audiences: JSON.stringify(this.params.audiences),
        partners_name: JSON.stringify(this.params.partners_name),
        saved: this.params.saved,
        page: this.params.page,
        sort: JSON.stringify(['-is_featured', 'start_date']),
        is_public: this.params.is_public,
      };

      this.isFetchingEvents = true;

      const response = await this.eventsService.getEventsDabi(payload);

      this.total_num = response.total_num;

      if (response.opportunities.length) {
        this.events.push(...response.opportunities);
        this.params.page += 1;
      }

      if (this.isLoading) {
        this.isLoading = false;
      }

      this.isFetchingEvents = false;

      this.$nextTick(() => {
        this.infiniteEventsFetch();
      });
    },
    async changePage(page: string) {
      if (this.currentPage === page) return;

      if (['browse', 'saved'].includes(page)) {
        this.resetData();
        this.resetFilters();
      }

      if (page === 'browse') {
        this.params.saved = false;
        this.params.is_public = true;
      }

      if (page === 'manage') {
        this.$router.push('/opportunities/manage');
      }

      if (page === 'saved') {
        this.params.saved = true;
        this.params.is_public = false;
        this.fetchEvents();
      }

      if (page === 'leads') {
        this.$router.push('/opportunities/leads');
      }

      this.currentPage = page;
    },
    createFilterData(value, name) {
      if (name === 'Events & Program') {
        this.params.type = value.map((v) => encodeURIComponent(v.value));
      }
      if (name === 'Career/Academic interests') {
        this.params.career_academic_interests = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === 'Location') {
        this.params.countries = value.map((v) => encodeURIComponent(v.value));
      }
      if (name === 'Audiences') {
        this.params.audiences = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === 'Partners') {
        this.params.partners_name = value.map((v) => encodeURIComponent(v.name));
      }
    },
    async changeAttendance(id): Promise<void> {
      let isAttending = false;
      if (this.attending_event_ids_live_list.includes(id)) {
        isAttending = true;
        this.attending_event_ids_live_list = this.attending_event_ids_live_list.filter((list_id) => list_id != id);
      } else {
        this.attending_event_ids_live_list.push(id);
      }

      const payload = {
        target: id,
        isAttending: !isAttending,
      };

      const res = await this.rsvpService.postAttendance(payload, this.headers);
      if (res.success) {
        this.notification.msg = 'Successfully updated event';
        this.notification.icon = 'fad fa-check-circle';
      } else {
        this.notification.msg = `Oops, something went wrong. Please reload page and try again.`;
        this.notification.isError = true;
        this.notification.icon = 'fad fa-exclamation-circle';

        if (this.attending_event_ids_live_list.includes(id)) {
          this.attending_event_ids_live_list = this.attending_event_ids_live_list.filter((list_id) => list_id != id);
        } else {
          this.attending_event_ids_live_list.push(id);
        }
      }
      this.showNotification(this.notification);
    },
    async infiniteEventsFetch() {
      const eventsContainer = this.$refs.eventsContainer;

      if (!eventsContainer) return;

      const eventsContainerRect = eventsContainer.getBoundingClientRect();
      const isScrolledBottom = eventsContainerRect.bottom <= window.innerHeight && eventsContainerRect.bottom >= 0;

      if (isScrolledBottom) {
        this.fetchEvents();
      }
    },
    closeNotification() {
      this.notification = { msg: '', isError: false, icon: null };
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.notification = { ...notification };
      this.isNotificationActive = true;
    },
    handleBookmarkEvent(item) {
      this.handleBookmark(item.id, item.is_bookmarked ? 'remove' : 'add');
      item.is_bookmarked = !item.is_bookmarked;
    },
    async handleBookmark(itemId, action: string): Promise<void> {
      const res = await this.eventUtils.handleBookmark(itemId, action);
      const notification = { msg: '', isError: false, icon: null };

      if (res.success) {
        notification.msg = `Bookmark successfully ${action == 'add' ? 'added' : 'removed'}`;
        notification.icon = 'fad fa-check-circle';
      } else {
        notification.msg = `Oops, something went wrong. Please reload page and try again.`;
        notification.isError = false;
        notification.icon = 'fad fa-exclamation-circle';
      }

      this.showNotification(notification);
    },
    toggleFilterSection() {
      this.filterSectionCollapsed = !this.filterSectionCollapsed;
    },
  },
};
</script>

<style scoped lang="scss">
.main-wrapper {
  padding-bottom: 2rem;
}

.featured-section .title {
  margin-bottom: 1.5rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #333;
}

.event-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1rem;
}

.title-wrapper {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
}

.mobile-options {
  width: 100%;
  display: flex;
  gap: 8px;
}

.collapse-filter-button.partner {
  width: 100%;
  padding: 0.75rem 0;
  border: 1px solid #c3cbd6;
  border-radius: 24px;
  background-color: #fff;
}

.partner-nav-container {
  padding: 0;
  overflow: auto;
}

.empty-state {
  padding: 2rem 0 0 0;

  &-title {
    font-size: 1.25rem;
    text-align: center;
    font-weight: 600;
    line-height: 1.2;
    color: #333;
  }

  &-subtitle {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    color: #444444;
  }

  &-content {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .event-cards-container {
    margin-top: 64px;
  }
}

@media screen and (max-width: 576px) {
  .title-wrapper {
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
  }
}
</style>
