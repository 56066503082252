<template>
  <div class="breadcrumbs-section" :class="theme">
    <div class="breadcrumbs-content-container">
      <div
        v-for="item, index in routeItems"
        :key="index"
        class="breadcrumbs-item-wrapper"
      >
        <span
          v-if="index !== 0"
          class="breadcrumbs-item-arrow"
        >
          <i class="fa-solid fa-chevron-right"></i>
        </span>
        <router-link
          v-if="item.isSpa"
          class="breadcrumbs-item"
          :to="item.path"
        >
          {{ item.label }}
        </router-link>
        <a
          v-else
          :href="item.path"
          class="breadcrumbs-item"
        >
          {{ item.label }}
        </a>
      </div>
      <div class="breadcrumbs-item-wrapper">
        <span
          class="breadcrumbs-item-arrow"
        >
          <i class="fa-solid fa-chevron-right"></i>
        </span>
        <span class="breadcrumbs-item inactive">
          {{ currentPageLabel }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'breadcrumbs',
  props: [
    'routeItems',
    'currentPageLabel',
    'theme'
  ],
}
</script>
