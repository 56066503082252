<template>
  <tr
    v-if="rowVisible"
    :class="isSelected ? 'iu-table-selected-row': ''"
  >
    <td v-if="!isManagerOnly"></td>
    <td class="iu-manage-partners-managers-line-cell" v-if="!isManagerOnly">
      <div class="iu-manage-partners-managers-line"></div>
    </td>
    <td v-if="!isManagerOnly" class="iu-partner-managers-cell-border">
      <div class="iu-partner-managers-cell-border-name-container">
        <span
        tabindex="0"
        @click="toggleManager(manager.id, manager.partner_id)"
        @keyup.space="toggleManager(manager.id, manager.partner_id)"
        :aria-label="`Select ${manager.name}`"
        role="checkbox"
        style="margin-right: 0px;"
        class='eu-resource-cell-select'
        :class="isSelected ? 'eu-resource-cell-select-active' : ''"
      ></span>
      <img
        :src="manager.profile_pic ? manager.profile_pic : '/static/assets/icons/default-profile.svg'"
        alt="profile img"
        style="width:32px;height:32px;top:unset;"
        class="iu-manage-partners-thumbnail"
      />
      {{manager.name}}
      </div>
    </td>
    <td v-if="isManagerOnly" class="iu-partner-managers-cell-border iu-table-small-cell">
      <span
        tabindex="0"
        @click="toggleManager(manager.id, manager.partner_id)"
        @keyup.space="toggleManager(manager.id, manager.partner_id)"
        :aria-label="`Select ${manager.name}`"
        role="checkbox"
        style="margin-right: 0px;"
        class='eu-resource-cell-select'
        :class="isSelected ? 'eu-resource-cell-select-active' : ''"
      ></span>
    </td>
    <td v-if="isManagerOnly" class="iu-partner-managers-cell-border">
      <div class="iu-partner-managers-cell-border-name-container">
        <img
          :src="manager.profile_pic ? manager.profile_pic : '/static/assets/icons/default-profile.svg'"
          alt="profile img"
          style="width:32px;height:32px;top:unset;"
          class="iu-manage-partners-thumbnail"
        />
        {{manager.name}}
      </div>
    </td>
    <td class="iu-partner-managers-cell-border">
      {{manager.email}}
    </td>
    <td class="iu-partner-managers-cell-border">
      {{manager.partner_role}}
    </td>
    <td class="iu-partner-managers-cell-border">
      {{formatDate(manager.added)}}
    </td>
    <td class="iu-partner-managers-cell-border">
      <span class="iu-table-status-pill" :class="isActive ? 'active' : 'not-active'">
        <i class="fa-solid fa-circle" v-if="isActive"></i>
        {{isActive ? 'Active' : 'Not Active '}}
      </span>
    </td>
    <td class="iu-partner-managers-cell-border iu-table-small-cell">
      <div class="iu-table-action-cell">
        <button
          class="iu-table-action-button"
          aria-label="Burger menu"
          :ref="'managerBurgerMenuButton-' + manager.id"
          @click="toggleBurger"
        >
          <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
        </button>
        <managers-burger-menu
          v-if="burgerExpanded"
          :ref="'managerBurgerMenu-' + manager.id"
          v-closable="{ exclude: [`managerBurgerMenu-${manager.id}`, `managerBurgerMenuButton-${manager.id}`], handler: 'closeBurger' }"
          @edit-manager="editManager"
          @add-manager="addManager"
          @reset-password="resetPassword"
          @view-dashboard="viewDashboard"
          @resend-invitation="resendInvitation"
          @delete-manager="deleteManager"
        ></managers-burger-menu>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import ManagersBurgerMenu from './ManagersBurgerMenu.vue';
import handleOutsideClick from '../../../directives/handleOutsideClick';
import moment from 'moment';
export default {
  name: 'manager-row',
  directives: { closable: handleOutsideClick },
  components: {
    ManagersBurgerMenu,
  },
  props: [
    'manager',
    'partner',
    'rowVisible',
    'isSelected',
    'isManagerOnly',
  ],
  computed: {
    isActive() {
      return this.manager.is_active;
    },
  },
  created() {

  },
  data() {
    return {
      burgerExpanded: false,
    }
  },
  methods: {
    formatDate (dateString) {
      return moment(dateString).format('MMM DD, YY');
    },
    toggleBurger() {
      this.burgerExpanded = !this.burgerExpanded;
    },
    closeBurger() {
      this.burgerExpanded = false;
    },
    toggleManager(manager_id, partner_id) {
      this.$emit('toggle-manager', manager_id, Object.keys(this.partner).length == 0 ? partner_id : this.partner.id);
    },
    editManager(id) {
      this.$emit('edit-manager', this.manager, this.partner);
    },
    addManager() {
      this.$emit('add-manager');
    },
    viewDashboard(name) {
      this.$emit('view-dashboard', name);
    },
    deleteManager() {
      this.$emit('delete-manager', this.manager);
    },
    resetPassword() {
      this.$emit('reset-password', this.manager.id);
    },
    resendInvitation() {
      this.$emit('resend-invitation', this.manager.id, this.manager.partner_id);
    },
  }
}
</script>
