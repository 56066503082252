<template>
  <div class="iu-manage-partners-buttons-container">
    <button
      v-if="isManagerTable && canAddManager"
      id="bulk_import_manage_account"
      class="iu-main-btn x-small solid main"
      @click="openAddManager"
      aria-haspopup="dialog"
      :disabled="managerCount >= managerLimit"
    >
      Add Manager
    </button>
    <button
      v-if="!isManagerTable"
      id="bulk_import_manage_account"
      class="iu-main-btn x-small solid main"
      :class="canAddPartner ? '' : 'disabled'"
      @click="openAddPartner"
      aria-haspopup="dialog"
    >
      Add Partner
    </button>
    <div style="position: relative">
        <button ref="exportButton" class="iu-main-btn x-small outlined gray" aria-haspopup="true" @click="openExportMenu">
          Export as
          <i class="fa-solid fa-chevron-down iu-main-button-arrow-icon" aria-hidden="true"></i>
        </button>
        <div
          id="export-menu"
          ref="exportMenu"
          v-closable="{ exclude: ['exportMenu', 'exportButton'], handler: 'closeExportMenu' }"
          class="eu-resource-burger"
          :class="exportMenuActive ? 'eu-resource-burger-active' : ''"
          style="width: 107px; right: 40px; margin-top: 7px"
        >
          <span
            tabindex="0"
            role="button"
            aria-label="Download csv file"
            class="eu-resource-burger-el"
            @click="handleExport('csv')"
            @keyup.enter="handleExport('csv')"
          >
            <span>CSV File</span>
          </span>

          <span
            tabindex="0"
            role="button"
            aria-label="Download xlsx file"
            class="eu-resource-burger-el"
            @click="handleExport('xlsx')"
            @keyup.enter="handleExport('xlsx')"
          >
            <span>XLSX File</span>
          </span>
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import handleOutsideClick from "../../../directives/handleOutsideClick";

export default {
  name: 'partners-buttons',

  directives: { closable: handleOutsideClick },

  props: [
    'isManagerTable',
    'canAddPartner',
    'canAddManager'
  ],

  data() {
    return {
      exportMenuActive: false,
      managerCount: 3,
      managerLimit: 20,
    }
  },
  methods: {
    openAddPartner() {
      this.$emit('openAddPartner');
    },

    openAddManager() {
      this.$emit('openAddManager');
    },

    openExportMenu() {
      this.exportMenuActive = true;
    },

    closeExportMenu() {
      this.exportMenuActive = false;
    },

    handleExport(fileType: string) {
      this.$emit('handleExport', fileType);
    }
  }
}

</script>
