import HttpService from './HttpService';


export default class PermissionsService extends HttpService {
  constructor() {
    super('/roles/api/permissions');
  }

  getAllowedFeatures() {
    this.endpoint = '/licenses/api/allowed-features';
    return this.get();
  }
}
