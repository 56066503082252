<template>
  <section>
    <notification />
    <vue-progress-bar></vue-progress-bar>
    <partner-tenant-header></partner-tenant-header>
    <router-view></router-view>
    <partner-tenant-footer></partner-tenant-footer>
  </section>
</template>

<script>
import PartnerTenantHeader from './components/PartnerTenantHeader.vue';
import PartnerTenantFooter from '../landing_pages/components/PartnerTenantFooter.vue';
import Notification from '../../../common/Notification.vue';

export default {
  name: 'partner-tenant-login-register-base',

  components: {
    PartnerTenantHeader,
    PartnerTenantFooter,
    Notification
  },
}
</script>
