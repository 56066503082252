import Vue from 'vue';
import PassportIndex from './components/PassportIndex.vue';
import Passport from './models/Passport';
import Profile from './models/Profile';

export default class PassportView {
  protected csrfToken: string;
  protected mountPoint: HTMLElement;
  protected dashboardLink: string;
  protected profile: Profile;
  protected passport: Passport;
  protected socialOptions: [];
  protected subjects: [];
  protected languages: [];
  protected focus_areas: [];
  protected career_list: [];

  constructor(el: HTMLElement, dashboardLink: string, profile, socialOptions, passport, subjects, languages, focus_areas, career_list) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.dashboardLink = dashboardLink;
    this.profile = profile;
    this.profile.social_networks = JSON.stringify(this.profile.social_networks);
    this.passport = passport;
    this.socialOptions = socialOptions;
    this.subjects = subjects;
    this.languages = languages;
    this.focus_areas = focus_areas;
    this.career_list = career_list;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const passportView = new Vue({
      components: { PassportIndex },
      template: `<passport-index 
                  :_profile="profile"
                  :_passport="passport" 
                  :dashboardLink="dashboardLink" 
                  :socialOptions="socialOptions"
                  :subjects="subjects"
                  :focus_areas="focus_areas"
                  :languages="languages"
                  :career_list="career_list" />`,
      data() { return {
        dashboardLink: _class.dashboardLink,
        profile: _class.profile,
        passport: _class.passport,
        socialOptions: _class.socialOptions,
        subjects: _class.subjects,
        languages: _class.languages,
        focus_areas: _class.focus_areas,
        career_list: _class.career_list
      }; },
      methods: {},
      mounted() {}
    });
    passportView.$mount(this.mountPoint);
  }
}
