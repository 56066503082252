<template>
  <feed-posts
    v-if="loggedUser"
    :loggedUser="loggedUser"
    :isLoadingPosts="isLoadingPosts"
    :isLoadingScroll="isLoadingScroll"
    :posts="posts"
    :showCreatePost="true"
    :questName="quest.name"
    :focusAreas="[]"
    :identities="[]"
    :audiences="[]"
    :has_navbar="false"
    :show_search="false"
    @show-all-comments="showAllComments"
    @show-notification="showNotification"
    @get-posts="getPosts"
    @set-is-loading="setIsLoading"
    @search-feed="searchFeed"
    @update-post-reaction="updatePostReaction"
  />
</template>

<script>
// Import required components and services
import FeedPosts from "../../feed/components/FeedPosts.vue";
import FeedService from "../../feed/services/FeedService";
import { FEATURES, PERMISSION_TYPES, useAuthStore } from "../../stores/auth";
import { hasPermission } from '../../utils/PermissionUtils';

export default {
  name: "QuestFeed",
  components: {
    FeedPosts,
  },
  props: {
    quest: Object,
  },
  data() {
    return {
      feedService: new FeedService(),
      authStore: useAuthStore(),
      isLoadingPosts: true,
      isLoadingScroll: false,
      isFetchPending: false,
      pageCount: 1,
      posts: [],
      loggedUser: null,
      searchHolder: "",
    };
  },
  async created() {
    console.log(this.authStore.getUser);
    this.initLoggedUser();
    this.scroll();
    await this.getPosts();
  },
  methods: {
    initLoggedUser() {
        const user = this.authStore.getUser.user;
        this.loggedUser = {
          id: user.id,
          profile_pic: user.profile_image,
          fullname: `${user.first_name} ${user.last_name}`,
          isAdmin: this.authStore.isAdmin,
          has_event_access: hasPermission(FEATURES.EVENTS) || hasPermission(FEATURES.PROGRAMS),
          has_event_managing_access: hasPermission(FEATURES.EVENTS, PERMISSION_TYPES.MANAGE) || hasPermission(FEATURES.PROGRAMS, PERMISSION_TYPES.MANAGE),
          hasWriteAccess: hasPermission(FEATURES.ACTIVITY_FEED, PERMISSION_TYPES.READ_WRITE),
          has_activity_feed_access: hasPermission(FEATURES.ACTIVITY_FEED),
          resource_library_permission: {
            has_library_resource_access: hasPermission(FEATURES.RESOURCE_LIBRARY),
            has_library_resource_managing_access: hasPermission(FEATURES.RESOURCE_LIBRARY, PERMISSION_TYPES.MANAGE),
          }
        }
    },
    showAllComments(post) {
      this.posts = this.posts.map((p) => {
        if (post.id === p.id) {
          p.shrinkComments = false;
        }
        return p;
      });
    },
    checkCommentSize() {
      this.posts = this.posts.map((p) => {
        if (p.comments.length >= 3 && p.shrinkComments === undefined) {
          p.shrinkComments = true;
        }
        return p;
      });
    },
    showNotification(notification) {
      this.$emit("show-notification", notification);
    },
    async getPosts(pageNo, minPosts = 3) {
      this.pageCount = pageNo ? pageNo : this.pageCount;
      let newPosts = [];
      // with this while loop, we make sure that as a result we get at least 5 new posts
      while (newPosts.length <= minPosts) {
        this.isFetchPending = true;
        // get all posts from getstream (even if they're not the right type)
        const rawRes = await this.feedService.getQuestPosts({
          quest: encodeURIComponent(this.quest.name),
          q: this.searchHolder,
          page: this.pageCount,
        });
        if (!rawRes || rawRes.length === 0) {
          break;
        }
        // filter out right type of posts
        const res = rawRes.filter((p) => p.verb.includes("post"));
        newPosts = [...newPosts, ...res];
        if (newPosts.length <= minPosts) {
          this.pageCount++;
        }
      }
      this.posts = newPosts;
      this.posts &&
        this.posts.forEach((p) => {
          p.object = JSON.parse(p.object);
        });
      this.checkCommentSize();
      this.isFetchPending = false;
      this.isLoadingPosts = false;
    },
    async searchFeed(searchInput) {
      if (searchInput.length > 0) {
        this.searchResultsShown = true;
      } else this.searchResultsShown = false;
      this.searchHolder = searchInput;
      this.isLoadingPosts = true;
      await this.getPosts(1, 0);
    },
    async updatePostReaction(p) {
      p.object = JSON.parse(p.object);
      this.posts = this.posts.map((e) => {
        if (e.id === p.id) {
          e.own_reactions = p.own_reactions;
          e.reaction_counts = p.reaction_counts;
          e.comments = p.comments;
          e.newComment = "";
        }
        return e;
      });
    },
    setIsLoading(isLoading) {
      this.isLoadingPosts = isLoading;
    },
    async scroll() {
      window.onscroll = async () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;

        if (bottomOfWindow && this.pageCount !== 0 && !this.searchResultsShown && !this.isFetchPending) {
          this.pageCount += 1;

          if (!this.isLoadingPosts) {
            this.isLoadingScroll = true;
          }
          if (!this.isFetchPending) {
            let newPosts = [];
            while (newPosts.length <= 3) {
              this.isFetchPending = true;
              // get all posts from getstream (even if they're not the right type)
              const rawRes = await this.feedService.getQuestPosts({
                page: this.pageCount,
                quest: encodeURIComponent(this.quest.name),
                q: this.searchHolder,
              });
              if (rawRes.length === 0) {
                this.pageCount = 0;
                break;
              }
              // filter out right type of posts
              const res = rawRes.filter((p) => p.verb.includes("post"));
              newPosts = [...newPosts, ...res];
              if (newPosts.length <= 3) {
                this.pageCount++;
              }
            }

            newPosts.forEach((p) => {
              p.object = JSON.parse(p.object);
              this.posts.push(p);
            });
            this.isLoadingScroll = false;
            this.isFetchPending = false;
            this.checkCommentSize();
          }
        }
      };
    },
    // Any other methods used by QuestFeed
  },
};
</script>

<style scoped>
/* Add any styles specific to QuestFeed */
</style>