<template>
  <div class="event-card-v2" :class="isFeatured ? 'event-card-v2--featured' : ''">
    <div class="event-card-v2__image-wrapper">
      <router-link :to="`/${item.slug}`">
        <img :src="item.thumbnail" alt="" class="event-card-v2__image" />
      </router-link>

      <span v-if="isFeatured" class="event-card-v2__featured-label">
        <i class="fa-solid fa-stars"></i>
        Featured
      </span>
    </div>

    <div class="event-card-v2__content">
      <span class="event-card-v2__label">{{ labelText }}</span>

      <div class="event-card-v2__title-wrapper">
        <h3 class="event-card-v2__title">
          <router-link :to="`/${item.slug}`">
            {{ item.title }}
          </router-link>
        </h3>
        <p class="event-card-v2__description">
          {{ item.description }}
        </p>
      </div>

      <div v-if="item.is_hybrid" class="event-card-v2__location">
        <span class="event-card-v2__location-icons">
          <i class="fa-solid fa-desktop"></i>
          <i class="fa-solid fa-location-dot"></i>
        </span>
        Hybrid
      </div>
      <div v-else-if="item.is_online" class="event-card-v2__location">
        <i class="fa-solid fa-desktop"></i>
        Online
      </div>
      <div v-else-if="item.location" class="event-card-v2__location">
        <i class="fa-solid fa-location-dot"></i>
        {{ item.location }}
      </div>

      <div
        v-if="item.partner"
        class="event-card-v2__partner-container"
        tabindex="0"
        @click="goToPartnersPage"
        @keyup.enter="goToPartnersPage"
      >
        <img
          v-if="item.partner.logo_url"
          class="event-card-v2__partner-logo"
          :src="item.partner.logo_url"
          :alt="item.partner.name"
        />
        <span class="event-card-v2__partner-name">{{ item.partner.name }}</span>
      </div>

      <div class="event-card-v2__actions">
        <router-link :to="`/${item.slug}`" class="view-details-button">
          View Details
          <i class="fa-solid fa-chevron-right"></i>
        </router-link>

        <button
          class="dabi-bookmark-button"
          :class="item.is_bookmarked ? 'active' : ''"
          @click="toggleBookmark()"
          :aria-label="`${item.is_bookmarked ? 'Remove' : 'Add'} bookmark`"
        >
          <i v-if="item.is_bookmarked" class="fa-solid fa-bookmark"></i>
          <i v-else class="fa-regular fa-bookmark"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'partner-event-card-v2',
  props: ['item', 'isFeatured'],
  computed: {
    labelText() {
      if (this.item.type_of_program_management == 'event') {
        return 'Event';
      }

      if (this.item.program_type) {
        return this.item.program_type;
      }

      if (this.item.type) {
        return this.item.type;
      }

      return 'Program';
    },
  },
  methods: {
    toggleBookmark() {
      this.$emit('toggle-bookmark', this.item);
    },
    goToPartnersPage() {
      this.$router.push(`/partners/${encodeURIComponent(this.item.partner.name)}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.event-card-v2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  border: 1px solid #d8dce5;
  background-color: #fff;

  &__image-wrapper {
    position: relative;
  }

  &__featured-label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem 0.375rem 0.375rem;
    border-radius: 16px;
    background-color: #e3faf2;
    font-size: 0.813rem;
    font-weight: 600;
    color: #2d98d2;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  &__image {
    display: block;
    height: 10rem;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: auto;
  }

  &__label {
    align-self: start;
    display: inline-block;
    padding: 0.063rem 0.5rem 0.188rem;
    border-radius: 16px;
    background-color: #f2f5f7;
    color: #516075;
    font-size: 0.75rem;
    font-weight: 500;
  }

  &__title {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25;
    color: #333;
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 45px;

    a {
      color: #333;
    }
  }

  &__description {
    font-size: 1.125rem;
    line-height: 1.4;
    color: #494949;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 76px;
  }

  &__location {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #838ea1;
    font-size: 0.875rem;
    font-weight: 600;
  }

  &__location-icons {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__partner-container {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &:hover {
      .event-card-v2-partner-name {
        color: #666;
      }
    }
  }

  &__partner-logo {
    border: 1px solid #d8dce5;
    border-radius: 16px;
    width: 1.5rem;
    height: 1.5rem;
    object-fit: cover;
    object-position: center;
  }

  &__partner-name {
    color: #444444;
    font-size: 0.875rem;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .view-details-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border-radius: 100px;
    border: solid 2px #2d98d2;
    font-size: 1rem;
    font-weight: 600;
    color: #101828;

    i {
      font-size: 0.75rem;
      color: #05c1a2;
      margin-top: 2px;
    }
  }
}
</style>
