import EventsIndexService from "../services/EventsIndexService";

export default class EventUtils{
    service: EventsIndexService;
    headers: Object;

    constructor(service: EventsIndexService, headers: Object){
        this.service = service;
        this.headers = headers
    }

    async handleBookmark(itemId, action: string){
        const payload = {
          target: itemId,
          action: action,
        };
        return await this.service.postBookmark(payload, this.headers);
    }
}
