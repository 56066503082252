import {isURLInvalid} from './CustomUtils'

export function validateExtensionFile(fileName: string, allowed_ext: Array<string>): boolean {
  let ext = fileName
    .split('.')
    .pop()
    .toLowerCase();

  return allowed_ext.includes(ext);
}

export function validateResourceInput(resource): {isValid: boolean, err: string} {
  const { name, department } = resource;
  const url = resource.video_url ? resource.video_url : resource.url;
  const nameOrTitle = resource.video_url ? 'name' : 'title';

  if (name === '' || name === undefined) {
    return {isValid: false, err: `Please input some ${nameOrTitle}.`};
  } else if (url === '' || isURLInvalid(url)) {
    return {isValid: false, err: 'Please input a valid URL.'};
  } else if (department === '') {
    return {isValid: false, err: 'Please add a department.'};
  }

  return {isValid: true, err: ''};
}


