<template >
  <!-- <div v-if="isPageLoading">
    <loading-spinner wrapperClass="wrapper" />
  </div> -->
  <div class="flex-column-center">
    <div class="main-wrapper">
      <div class="title-wrapper">
        <common-title title="Opportunities" description="Browse opportunities posted by your university." />
        <div>
             <span
                class="iu-main-btn main solid x-small add-opportunities-button-alignment"
                style="width: 100%"
                aria-label="Export leads data"
                @click="exportLeads"
                @:keyup.enter="exportLeads"
              >
                <i class="fa-solid fa-download iu-main-button-front-icon"></i>
                Export CSV
              </span>
          </div>
      </div>
      <nav class="tab-navigation-container">
        <router-link to="/opportunities" class="tab-navigation-item" aria-label="Go to events pages">
          Browse <span class="desktop-only-text">Opportunities</span></router-link
        >
        <router-link to="/opportunities/manage" class="tab-navigation-item" aria-label="Go to manage event page">
          Manage <span class="desktop-only-text">Opportunities</span></router-link
        >
        <router-link to="/events?page=saved" class="tab-navigation-item" aria-label="Go to your saved events"
          >Saved<span class="desktop-only-text">/Upcoming</span></router-link
        >
        <router-link to="/opportunities/leads" class="tab-navigation-item active" aria-label="Go to manage leads page">
          <span class="desktop-only-text">Manage</span> Leads</router-link
        >
      </nav>
      <div class="manage-events-search-form-container">
        <form action="" @submit.prevent="fetchLeads()" class="eu-search-form" role="search">
          <input v-model="search_input" name="q" type="search" placeholder="Search for leads..." class="eu-search-input" />
          <button class="eu-search-btn-icon" aria-label="search">
            <i class="eu-search-icon fas fa-search"></i>
          </button>
        </form>
      </div>
      <!-- <div class="filter-container content-filters-container">
        <multiselect-filter-component
          v-if="!department"
          placeholder="Published"
          filter_name="Published &amp; Draft"
          style="margin: 0 !important"
          :options="[{ name: 'Published' }, { name: 'Draft' }]"
          @filter-data="createFilterData"
        />
      </div> -->
      <div v-if="!isLoading" class="table-container manage-event-program-wrapper manage-opportunities-table-wrapper">
        <table class="eu-resource-table">
          <tr class="eu-resource-row">
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Opportunity Name
              <span
                tabindex="0"
                aria-label="Sort table by opportunity name"
                role="button"
                @click="sort('program_management_base__title')"
                @:keyup.enter="sort('program_management_base__title')"
              >
                <i
                  :class="
                    sortedBy !== 'program_management_base__title' ? 'fas fa-sort' : directionUp.program_management_base__title ? 'fad fa-sort-up' : 'fad fa-sort-down'
                  "
                ></i>
              </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Opportunity Type
              <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Interest Date
              <span
                tabindex="0"
                aria-label="Sort table by date"
                role="button"
                @click="sort('created_at')"
                @:keyup.enter="sort('created_at')"
              >
                <i
                  :class="
                    sortedBy !== 'created_at'
                      ? 'fas fa-sort'
                      : directionUp.created_at
                      ? 'fad fa-sort-up'
                      : 'fad fa-sort-down'
                  "
                ></i>
              </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">User Email</th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">User Name</th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">School Name</th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">Additional Info</th>
          </tr>
          <tr
            v-for="(item, i) in leads"
            v-bind:key="i"
            tabindex="0"
            id="table_manage_account_${item.id}"
            role="row"
            class="eu-resource-row"
            style="position: relative"
          >
            <td class="eu-resource-cell">{{ item.opportunity_title }}</td>
            <td class="eu-resource-cell">{{ item.type_of_program_management }}</td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.created_at | shortDateFormat() }}</td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.user_email }}</td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.user_first_name }} {{ item.user_last_name }}</td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.university }}</td>
            <td class="eu-resource-cell text-clamp-1" style="white-space: nowrap; max-width: 250px; cursor: default;" :title="item.description">{{ item.description }}</td>
          </tr>
        </table>
      </div>
      <div v-if="isLoading">
        <loading-spinner wrapperClass="wrapper" />
      </div>

      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import EventsIndexService from "../services/EventsIndexService";
import MultiselectFilterComponent from "../../form/components/MultiselectFilterComponent.vue";
import Notification from "../../components/Notification.vue";
import CommonTitle from "../../components/CommonTitle.vue";
import PopupComponent from "../../components/popup/PopupComponent.vue";

export default {
  name: "event-admin-manage-component",
  components: {
    Notification,
    LoadingSpinner,
    MultiselectFilterComponent,
    CommonTitle,
    PopupComponent,
  },
  props: [],
  computed: {
  },
  data() {
    return {
      isLoading: true,
      leads: [],
      search_input: "",
      sortBy: "timestamp",
      directionUp: {
        title: true,
        start_date: true,
        creation_date: true,
      },
      params: {},
      sortedBy: "",
      pageCount: 0,
      notification: { msg: "", isError: false, icon: null },
      isNotificationActive: false,
    };
  },
  async created() {

    this.eventsService = new EventsIndexService();

    await this.fetchLeads();
    // this.scroll();
  },
  methods: {
    async fetchLeads() {
      this.isLoading = true;
      // if (this.pageCount == 0) this.pageCount = 1;
      const response = await this.eventsService.getLeads({
        ...this.params,
        q: this.search_input,
        page: this.pageCount,
      });
      if (response) {
        this.leads = response;
      }
      this.isLoading = false;
    },
    async exportLeads() {
      try {
        const csvResponse = await this.eventsService.exportLeads({
          ...this.params,
          q: this.search_input,
          page: this.pageCount,
          export: true
        });
        if (csvResponse) {
          const blob = new Blob([csvResponse], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'leads.csv';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      } catch (error) {
        console.error('Error exporting leads:', error);
      }
    },
    async sort(column) {
      // this.pageCount = 1;
      this.params["sort"] = this.directionUp[column] ? column : `-${column}`;
      await this.fetchLeads();
      this.sortedBy = column;
      this.directionUp[column] = !this.directionUp[column];
    },

    async scroll() {
      window.onscroll = async () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;

        if (bottomOfWindow && this.pageCount !== 0) {
          this.pageCount += 1;

          const response = await this.eventsService.getLeads({
            ...this.params,
            q: this.search_input,
            page: this.pageCount,
          });

          let newEvents = [];

          if (response && response.opportunities) {
            newEvents = response.opportunities;
          }

          if (newEvents.length > 0) {
            newEvents.forEach((e) => {
              this.events.push(e);
            });
          } else this.pageCount = 0;
        }
      };
    },
    closeNotification() {
      this.notification = { msg: "", isError: false, icon: null };
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.notification = notification;
      this.isNotificationActive = true;
    },
  },
};
</script>

<style scoped lang="scss">
.title-wrapper {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
}
.mobile-options {
  width: 100%;
  display: flex;
  gap: 8px;
}
@media screen and (max-width: 576px) {
  .title-wrapper {
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
  }
}
</style>
