<template>
  <div :id="valid_id" class="multiselect-filter-container" :style="additionalStyle">
    <multiselect
      v-model="value"
      :options="options"
      :multiple="!singleSelect"
      :close-on-select="singleSelect ? singleSelect : false"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="placeholder"
      :max-height="maxHeight"
      :max="limit"
      label="name"
      track-by="name"
      @close="closeHandler"
      @open="changeAriaExpanded(true)"
    >
      <template slot="tag">{{ '' }}</template>
      <template slot="selection" slot-scope="{ values, isOpen }">
        <span class="multiselect__single" v-if="values.length && !isOpen">
          <span class="multiselect_text">
            <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; display: block">
              <span class="multiselect_number"> {{ values.length }} </span>
              {{ values.length > 1 ? filter_name : value[0].name }}
            </span>
          </span>
        </span>
        <i class="fas fa-chevron-down multiselect-chevron"></i>
      </template>
    </multiselect>
  </div>
</template>
<script lang="ts">
import Multiselect from 'vue-multiselect';
export default {
  components: { Multiselect },

  name: 'multiselect-filter-component',
  props: [
    'id',
    'options',
    'placeholder',
    'filter_name',
    'selected_status',
    'additionalStyle',
    'param_name',
    'maxHeight',
    'limit',
    'singleSelect',
  ],

  data() {
    return {
      timeoutId: null,
      value: [],
      valid_id: null,
    };
  },
  created() {
    this.value = this.selected_status;
  },
  async mounted() {
    if (!this.id) {
      this.valid_id = this.makeId(15);
    } else {
      this.valid_id = this.id;
    }

    this.timeoutId = setTimeout(() => {
      if (this.$el) {
        this.changeAriaExpanded(false);
      }
    }, 500);
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    makeId(length) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    filterData() {
      this.$emit('filter-data', this.value ? this.value : [], this.filter_name, this.param_name);
    },

    changeAriaExpanded(ariaExpanded) {
      const multiselectComponent = document.querySelectorAll('#' + this.valid_id + ' .multiselect')[0];
      multiselectComponent.setAttribute('aria-expanded', ariaExpanded ? ariaExpanded : 'false');
    },

    closeHandler() {
      this.filterData();
      this.changeAriaExpanded(false);
    },
  },
};
</script>
