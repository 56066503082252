import { render, staticRenderFns } from "./NoRecommendations.vue?vue&type=template&id=0df62dd0"
import script from "./NoRecommendations.vue?vue&type=script&lang=js"
export * from "./NoRecommendations.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports