import HttpService from '../../services/HttpService';

export default class RSVPService extends HttpService {
    constructor() {
        super('/events/api/rsvp');
    }

    postAttendance(payload: Object, headers: Object): Promise<any> {
        this.endpoint = '/events/api/rsvp';
        return this.postFormData(payload, headers);
    }

    getNumberOfGuest(): Promise<any>{
        this.endpoint = '/events/api/rsvp';
        return this.get();
    }

    getEventsIdsUserAttending(user_id): Promise<any>{
        this.endpoint = `/events/api/users/${user_id}/rsvp`;
        return this.get();
    }

}