<template>
  <section>
        <div tabindex="0" :aria-label="`Question: ${stepTitles[currentStepNo]}`" class="passport-form-title">{{stepTitles[currentStepNo]}}</div>
        <div v-if="currentStepNo === 0">
            <div class="multiselect-wrapper" style="margin: 0 auto">
              <multiselect
                v-model="passport.major_field_of_study"
                :options="subjects"
                :multiple="true"
                :close-on-select="false"
                placeholder=""
              ></multiselect>
              <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer"></i>
            </div>
        </div>
        <div v-if="currentStepNo === 1">
          <div class="multiselect-wrapper" style="margin: 0 auto">
              <multiselect
                v-model="passport.minor_field_of_study"
                :options="subjects"
                :multiple="true"
                :close-on-select="false"
                placeholder=""
              ></multiselect>
              <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer"></i>
            </div>
        </div>
        <div v-if="currentStepNo === 2">
          <select class="passport-form-input-select" v-model="passport.graduation_year">
            <option v-for="year in graduationYears" :value="year" :key="`year${year}`">
              {{ year }}
            </option>
          </select>
        </div>
        <div v-if="currentStepNo === 3">
           <div class="multiselect-wrapper" style="margin: 0 auto">
              <multiselect
                v-model="passport.fluent_languages"
                :options="languages"
                :multiple="true"
                :close-on-select="false"
                placeholder=""
              ></multiselect>
              <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer"></i>
            </div>
        </div>
        <div v-if="currentStepNo === 4">
            <section v-if="isBadgeEarned()">
              <div class="passport-form-title">Woohoo! You earned a badge.</div>
              <img class="passport-badge-earned" :src="`${assetPath}badges/education-earned.svg`"/>
            </section>
            <div class="passport-form-label" style="margin-bottom: 30px">Keep filling out sections to earn badges and complete your passport.</div>
            <a v-if="nextStep" :href="`/passport?step=${encodeURIComponent(nextStep)}`" class="da-components-button da-btn-solid enup-demo-button passport-final-btn">Continue with Passport</a>
            <a :href="dashboardLink" class="da-components-button da-btn-solid enup-demo-button passport-final-btn inverted">Quit & Go to Dashboard</a>
        </div>
  </section>
</template>
<script lang="ts">

import Multiselect from 'vue-multiselect';
export default {
  components: { Multiselect },
  name: "passport-my-education",
  props: ['passport', 'currentStepNo', 'dashboardLink', 'subjects', 'languages', 'nextStep'],
  data() {
    return {
      test: null,
      stepTitles: ['Select your major(s).', 'Select your minor(s).', 'Select your graduation year.', 'Select your fluent languages'],
      graduationYears: [],
      assetPath: process.env.MEDIA_URL,
    };
  },
  created() {
      this.graduationYears = this.getGraduationYears();
    },
  methods: {
      setPassportProp(propName, value) {
        this.$emit('set-passport-prop', propName, value);
    },
    isBadgeEarned() {
        this.$emit('is-badge-earned');
        return this.passport.badge;
    },
    getGraduationYears() {
      const currentYear = new Date().getFullYear(), years = [];
      let startYear = currentYear - 5;
      while ( startYear <= currentYear + 10 ) {
          years.push(startYear++);
      }
      return years;
    }
  },
  computed: {},
};
</script>

