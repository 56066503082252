var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('button',{staticClass:"eu-sidebar-collapse-button",attrs:{"aria-label":(_vm.expanded ? 'Collapse' : 'Expand') + ' side menu'},on:{"click":_vm.toggleSideBar}},[_c('i',{class:'fa-solid fa-chevron' + (_vm.expanded ? '-left' : '-right')})]),_vm._v(" "),_c('div',{staticClass:"eu-sidebar-container",attrs:{"id":"sidebarContainer"}},[_c('div',{staticClass:"eu-logo-wrapper"},[_c('img',{staticClass:"eu-logo eu-side-menu-logo",attrs:{"src":_vm.logo},on:{"click":function($event){return _vm.redirectTo('/')}}})]),_vm._v(" "),_c('ul',{staticClass:"eu-sidebar-items"},[_c('li',{staticClass:"eu-sidebar-item-category-container only-mobile"},[_c('div',{class:'eu-sidebar-item ' +
            (_vm.activeTab === 'view_profile'
              ? 'eu-sidebar-item-' + 'view_profile' + '-active'
              : 'eu-sidebar-item-' + 'view_profile') +
            (!_vm.expanded ? ' collapsed' : ''),attrs:{"tabindex":"0","aria-label":"View profile","index":'view_profile',"content":""},on:{"click":function($event){return _vm.redirectTo('/profiles/' + _vm.userData.user.profile_url)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.redirectTo('/profiles/' + _vm.userData.user.profile_url)}}},[_c('i',{staticClass:"eu-sidebar-icon fa-regular fa-circle-user"}),_vm._v(" "),_c('a',{staticStyle:{"text-align":"center"},attrs:{"tabindex":"-1"}},[_vm._v(_vm._s(_vm.userData.user.first_name)+" "+_vm._s(_vm.userData.user.last_name))])])]),_vm._v(" "),_vm._l((_vm.separatedList),function(itemsCategory){return _c('li',{key:itemsCategory.title,staticClass:"eu-sidebar-item-category-container"},[(itemsCategory.title)?_c('div',{staticClass:"eu-sidebar-item-category-title",class:!_vm.expanded ? 'collapsed' : ''},[_vm._v("\n          "+_vm._s(itemsCategory.title)+"\n        ")]):_vm._e(),_vm._v(" "),_c('ul',_vm._l((itemsCategory.list),function(item){return _c('span',{key:item.label,class:_vm.sidebarItemClasses(item.value),attrs:{"tabindex":"0","aria-label":'Go to ' + item.label,"target":_vm.itemTarget(item.value)},on:{"click":function($event){return _vm.redirectTo(item.link, item.label)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.redirectTo(item.link), item.label}}},[_c('i',{staticClass:"eu-sidebar-icon",class:item.icon}),_vm._v(" "),_c('span',{attrs:{"role":"none"}},[_vm._v(_vm._s(_vm.getItemLabel(item)))])])}),0)])}),_vm._v(" "),_c('div',{staticClass:"eu-sidebar-separator"}),_vm._v(" "),_c('li',{staticClass:"eu-sidebar-item-category-container"},[_c('div',{class:'eu-sidebar-item ' +
            (_vm.activeTab === 'settings'
              ? 'eu-sidebar-item-' + 'settings' + '-active'
              : 'eu-sidebar-item-' + 'settings') +
            (!_vm.expanded ? ' collapsed' : ''),attrs:{"tabindex":"0","aria-label":"Open settings","index":'settings',"content":""},on:{"click":function($event){return _vm.redirectTo('/settings/profile')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.redirectTo('/settings/profile')}}},[_c('i',{staticClass:"eu-sidebar-icon fa-regular fa-gear"}),_vm._v(" "),_c('a',{staticStyle:{"text-align":"center"},attrs:{"tabindex":"-1"}},[_vm._v("Settings")])])]),_vm._v(" "),_c('li',{staticClass:"eu-sidebar-item-category-container only-mobile"},[_c('div',{class:'eu-sidebar-item ' +
            (_vm.activeTab === 'logout' ? 'eu-sidebar-item-' + 'logout' + '-active' : 'eu-sidebar-item-' + 'logout') +
            (!_vm.expanded ? ' collapsed' : ''),attrs:{"tabindex":"0","aria-label":"Logout","index":'logout',"content":""},on:{"click":function($event){return _vm.redirectTo('/logout')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.redirectTo('/logout')}}},[_c('i',{staticClass:"eu-sidebar-icon fa-regular fa-right-from-bracket"}),_vm._v(" "),_c('a',{staticStyle:{"text-align":"center"},attrs:{"tabindex":"-1"}},[_vm._v("Sign Out")])])])],2)]),_vm._v(" "),(_vm.expanded)?_c('div',{staticClass:"eu-sidebar-footer"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }