<template>
  <section v-if="!isLoading">
    <div class="flex-column-center">
      <div class="main-wrapper">
        <div v-if="isPartnerTenant()">
          <partner-tenant-dashboard/>
        </div>
        <div v-else>
          <!-- <h1 class="iu-new-dashboard-welcome-title">Welcome, {{fullName}}!</h1> -->
          <section v-if="hasFeedAccess" class="dashboard-nav-wrapper" style="margin-bottom: 32px">
            <router-link to="/" class="dashboard-nav-pill active"
              ><i class="fa-solid fa-grid" style="margin-right: 4px"></i> Dashboard
            </router-link>
            <router-link to="/feed" class="dashboard-nav-pill"
              ><i class="fa-sharp fa-solid fa-bullhorn" style="margin-right: 4px"></i>
              Campus360
            </router-link>
          </section>
          <dashboard-cards
            :props="dashboardProps"
          />
        </div>
        <!-- <welcome-video :video="welcomeVideo" :userId="userId" /> -->
      </div>
    </div>
  </section>
  <section v-else>
    <loading-spinner wrapperClass="wrapper" />
  </section>
</template>
<script lang="ts">
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import DashboardCards from "./DashboardCards.vue";
import DashboardService from "../services/DashboardService";
import PartnerTenantDashboard from "../partner_tenant/PartnerTenantDashboard.vue";
import { isPartnerTenant } from "../../utils/CustomUtils";
import { FEATURES, hasPermission } from "../../utils/PermissionUtils";
import { useAuthStore } from '../../stores/auth';
import WelcomeVideo from './WelcomeVideo.vue';

export default {
  components: {
    DashboardCards,
    PartnerTenantDashboard,
    LoadingSpinner,
    WelcomeVideo
  },
  name: "dashboard-index",
  props: [],
  data() {
    return {
      service: new DashboardService(),
      isLoading: false,
      authStore: useAuthStore(),
      dashboardProps: {
        coursesLen: null,
        mentorsLen: null,
        resourcesLen: null,
        programs_events_len: null,
        userId: null,
      },
      welcomeVideo: {
          url: null,
          title: null,
          description: null
      },
      hasFeedAccess: hasPermission(FEATURES.ACTIVITY_FEED),
    };
  },
  async created() {
    await this.getPreData();
  },
  methods: {
    isPartnerTenant,
    async getPreData() {
      this.isLoading = true;
      const userData = this.authStore.getUser;
      this.fullName = `${userData.user.first_name} ${userData.user.last_name}`;
      this.userId = userData.user.id;
      const res = await this.service.getDashboardPreData();
      if (res) {
        this.dashboardProps.coursesLen = res.courses_len;
        this.dashboardProps.mentorsLen = res.mentors_len;
        this.dashboardProps.resourcesLen = res.resources_len;
        this.dashboardProps.programs_events_len = res.programs_events_len;
        this.dashboardProps.userId = res.user_id;
        this.welcomeVideo = res.welcome_video;
        this.welcomeVideo.show = !res.welcome_video_seen;
      }
      this.isLoading = false;
    },
  },
};
</script>
