<template>
  <settings-wrapper activeTab="preferences">
    <section class="profile-form">
      <div class="profile-form-header-container">
        <h2>Preferences</h2>
      </div>
      <div v-if="isLoading" id="objects-list-loading-spinner">
        <loading-spinner />
      </div>
      <div v-else>
        <div class="preference-container">
          <div class="preference-container-header">
            <toggle-button
              id="mic-toggle"
              :value="public_details"
              :sync="true"
              :color="{ checked: '#4c55a7', unchecked: '#b4b4b4' }"
              :labels="{ checked: 'On', unchecked: 'Off' }"
              @change="publicDetailsSwitchHandler"
            />
            <h3 class="preference-container-title">Make profile details public</h3>
          </div>
          <p class="preference-container-description">
            All admins, mentors, and students can see the details of your profile. (Off: Only admins and mentors can see
            the details of your profile.)
          </p>
        </div>
        <div class="preference-container">
          <div class="preference-container-header">
            <toggle-button
              id="mic-toggle"
              :value="subscribed"
              :sync="true"
              :color="{ checked: '#4c55a7', unchecked: '#b4b4b4' }"
              :labels="{ checked: 'On', unchecked: 'Off' }"
              @change="contentEmailDigestSwitchHandler"
            />
            <h3 class="preference-container-title">Subcribe to content digest</h3>
          </div>
          <p class="preference-container-description">
            Receive scheduled email updates of recently added content on a schedule of your choosing.
          </p>
        </div>
        <div class="preference-container">
          <div class="multiselect-wrapper" style="width: 100%; margin: 0; position: relative">
            <multiselect
              v-model="selected_frequency"
              :options="frequency_options"
              :multiple="false"
              :searchable="false"
              :show-labels="false"
              :close-on-select="true"
              :allow-empty="false"
              placeholder="pick a value"
            >
            </multiselect>
            <i
              class="fal fa-angle-down eu-select-arrow"
              style="top: -28px; z-index: 52; cursor: pointer; left: 96%"
            ></i>
            <div class="inup-form-label-container">
              <label for="group_private_list">Subscription Frequency</label>
            </div>
          </div>
        </div>
        <div class="profile-form-button-container">
          <button @click="saveForm" name="Save" class="eu-btn-primary-small" style="height: 41px; width: 91px">
            Save
          </button>
        </div>
      </div>
      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </section>
  </settings-wrapper>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";
import SettingsService from "../services/SettingsService";
import Multiselect from "vue-multiselect";
import Notification from "../../components/Notification.vue";
import SettingsWrapper from "./SettingsWrapper.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";

export default {
  name: "preferences",
  components: { ToggleButton, Multiselect, Notification, SettingsWrapper, LoadingSpinner },
  props: [],
  data() {
    return {
      isNotificationActive: false,
      isLoading: false,
      notification: { msg: "", isError: false, icon: null },
      public_details: null,
      subscribed: null,
      selected_frequency: null,
      frequency_options: null,
    };
  },
  async created() {
    this.service = new SettingsService();
    await this.getPreData();
  },
  methods: {
    async getPreData() {
      this.isLoading = true;
      const response = await this.service.getPreferencesPreData();
      if (response.success) {
        this.public_details = response.is_profile_viewable;
        this.subscribed = response.profile?.subscribed_content_digest;
        this.selected_frequency = response.profile?.subscription_frequency;
        this.frequency_options = response.frequency_options;
      }
      this.isLoading = false;
    },
    publicDetailsSwitchHandler() {
      this.public_details = !this.public_details;
    },
    contentEmailDigestSwitchHandler() {
      this.subscribed = !this.subscribed;
    },
    async saveForm() {
      const res = await this.service.updatePreferences({
        is_profile_viewable: this.public_details,
        subscribed_content_digest: this.subscribed,
        subscription_frequency: this.selected_frequency,
      });
      if (res?.success) {
        this.showNotification({ msg: res.message, icon: "fad fa-check-circle" });
      } else {
        this.showNotification({
          msg: res ? res.message : "Server Error",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
  },
};
</script>

<style scoped>
</style>
