import Vue from "vue";
import PartnerInitialize from "./components/PartnerInitialize.vue"
import { createPinia, PiniaPlugin } from 'pinia';
import VueCompositionAPI from '@vue/composition-api'
import VueProgressBar from 'vue-progressbar';
import { globalProgressBarOptions } from "../GlobalComponents";



export default class PartnersView {
  private readonly mountPoint: Element;

  protected initData;

  constructor(el: HTMLElement, appData) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.initData = appData
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;

    const progressBarOptions = globalProgressBarOptions;


    Vue.use(VueCompositionAPI);
    const pinia = createPinia();
    Vue.use(PiniaPlugin);
    Vue.use(VueProgressBar, progressBarOptions)
    const partners = new Vue({
      components: { PartnerInitialize },
      pinia,
      template: `
        <partner-initialize :initData="initData"/>
      `,

      data() {
        return {
          initData: _class.initData,
        };
      },

      mounted() {
        this.$Progress.finish();
      },
      created() {
        this.$Progress.start();
      },
    });
    partners.$mount(this.mountPoint);
  }
}
