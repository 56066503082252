<template>
  <section>

    <Modal :isActiveProp="isDeleteModalActive" @close="closeDeleteModal">
      <div class="iu-resources-delete-modal-content-container">
        <div class="iu-resources-delete-modal-text-section">Are you sure you want to delete this file?</div>
        <div class="iu-resources-delete-modal-file-details-section">
          <div class="iu-resources-delete-modal-file-name">
            {{ itemToDelete.name }}
          </div>
          <div class="iu-resources-delete-modal-file-author">Uploaded by {{ getAuthorFullNameOrUnknown() }}</div>
        </div>
        <div class="iu-resources-delete-modal-actions-section">
          <button
            class="iu-resources-delete-modal-actions-button iu-main-btn small solid"
            :class="removing ? 'disabled' : 'red'"
            @click="removing ? '' : deleteItem(itemToDelete.id)"
            aria-label="Remove an item"
          >
            Delete
          </button>
          <button
            class="iu-resources-delete-modal-actions-button iu-main-btn small outlined"
            :class="removing ? 'disabled' : 'gray'"
            @click="closeDeleteModal"
            aria-label="Close delete modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
    <Modal :isActiveProp="isEditModalActive" @close="closeEditModal" class="eu-file-upload-modal">
      <div class="title-container">
        <span tabindex="0" role="heading" id="library_edit_file">Edit file details</span>
        <button
          @click="closeEditModal()"
          @keyup.enter="closeEditModal()"
          class="close-button"
          aria-label="Close file modal"
        >
          <i class="far fa-times"></i>
        </button>
      </div>
      <form class="resource-form">
        <div class="small-dropzone-container" v-if="itemToEdit && itemToEdit.file_type !== 'IMG'">
          <small-dropzone-component
            id="file-upload"
            :imageSrc="itemToEdit.thumbnail_url"
            :maxImageSize="2000000"
            :dialogDisplay="true"
            maxImageSizeFormat="2 MB"
            changeButtonText="Change Thumbnail"
            uploaderText="Add Resource Thumbnail"
            @uploadImage="handleUploadThumbnail"
            @removeImage="handleRemoveThumbnail"
          />
        </div>
        <input-base
          id="file-name"
          type="text"
          label="File Name"
          aria-label="Enter file name"
          maxlength="255"
          v-model="itemToEdit.name"
          :required="true"
          :value="itemToEdit.name"
        />
        <div class="two-inputs-row">
          <select-base v-if="!isPartnerTenant && !isDepartmentProfile && !group && areDeptsEnabled"
            id="file-department"
            :label="`${deptSingular}`"
            :placeholderDisabled="true"
            :options="
              list_departments.map((e) => {
                return {
                  label: e.name,
                  value: e.name,
                };
              })
            "
            v-model="itemToEdit.department.name"
            style="width: 100%"
            :aria-label="`Select ${deptSingular}`"
          >
          </select-base>
          <select-base
            id="file-type"
            label="Type/Label"
            :placeholderDisabled="true"
            :options="
              resourceLabelOptions.map((e) => {
                return {
                  label: e,
                  value: e,
                };
              })
            "
            v-model="itemToEdit.label"
            style="width: 100%"
            aria-label="Select label"
          >
          </select-base>
        </div>
        <div class="dialog-multiselect-wrapper">
          <multiselect
            id="focus-area"
            v-model="itemToEdit.focus_areas"
            :options="filterData.focus_areas.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="focus-area" class="multiselect-label">Focus Areas</label>
        </div>
        <div v-if="isPartnerTenant" class="dialog-multiselect-wrapper">
          <multiselect
            id="countries"
            v-model="itemToEdit.countries"
            :options="filterData.countries.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="countries" class="multiselect-label">Destinations</label>
        </div>
        <div class="dialog-multiselect-wrapper">
          <multiselect
            id="identity"
            v-model="itemToEdit.identities"
            :options="filterData.identities.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="identity" class="multiselect-label">Identities</label>
        </div>
        <div class="dialog-multiselect-wrapper">
          <multiselect
            id="audience"
            v-model="itemToEdit.audiences"
            :options="filterData.audiences.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="audience" class="multiselect-label">Audiences</label>
        </div>
        <div v-if="isPartnerTenant" class="dialog-multiselect-wrapper">
          <multiselect
            id="career_academic_interests"
            v-model="itemToEdit.career_academic_interests"
            :options="filterData.career_academic_interests.map((e) => e.name)"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select option"
            :max="5"
          ></multiselect>
          <i class="fal fa-angle-down multiselect-arrow"></i>
          <label for="career_academic_interests" class="multiselect-label">Career Academic Interests</label>
        </div>
        <div style="position: relative">
          <textarea
            id="description"
            v-model="itemToEdit.description"
            type="text"
            rows="4"
            class="modal-textarea"
            maxlength="150"
          />
          <label for="description" class="multiselect-label">Description</label>
        </div>
        <div class="modal-checkbox-container">
          <label class="eu-checkbox-container" style="width: unset"
            >Featured resource
            <input v-if="isPartnerTab" type="checkbox" v-model="itemToEdit.is_partner_featured" />
            <input v-else type="checkbox" v-model="itemToEdit.is_recommended" />
            <span class="eu-checkmark"></span>
            <span class="eu-checkmark-tooltip">
              <a href="javascript:;">
                <i class="fad fa-question-circle"></i>
                <div class="eu-resource-desc" style="right: unset; top: -33px">
                  <span class="eu-resource-desc-left-arrow"></span>
                  <span class="eu-resource-desc-el">
                    <p class="eu-resource-desc-text">
                      Check this box if you want this file to be highlighted in your students’ resource library.
                    </p>
                  </span>
                </div>
              </a>
            </span>
          </label>
          <label v-if="isAdmin() && itemToEdit.partner" class="eu-checkbox-container" style="width: unset"
            >Public resource
            <input type="checkbox" v-model="itemToEdit.is_public" />
            <span class="eu-checkmark"></span>
          </label>
        </div>
        <div class="dialog-error-container">
          {{ error }}
        </div>
        <div class="dialog-actions-container">
          <button @click.prevent="editItem" class="iu-main-btn small solid main" aria-label="Save changes">Save</button>
          <button
            @click.prevent="closeEditModal"
            class="iu-main-btn small gray outlined"
            aria-label="Close edit modal."
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  </section>
</template>
<script lang="ts">
import Modal from '../../components/ModalExtended.vue';
import Multiselect from 'vue-multiselect';
import { resourceLabelOptions } from '../../utils/constants';
import SmallDropzoneComponent from '../../form/components/SmallDropzoneComponent.vue';
import InputBase from '../../components/formBase/InputBase.vue';
import SelectBase from '../../components/formBase/SelectBase.vue';
import { useCommonDataStore } from '../../stores/commonData';
import { isPartnerTenant } from "../../utils/CustomUtils";

export default {
  components: { Modal, Multiselect, SmallDropzoneComponent, InputBase, SelectBase },
  name: 'resources-modals',
  props: [
    'resources',
    'featuredResources',
    'activeResource',
    'isDeleteModalActive',
    'isEditModalActive',
    'removing',
    'itemToDelete',
    'itemToEdit',
    'list_departments',
    'filterData',
    'currentPage',
    'showTikTokBlock',
    'isPartnerTab',
    'isDepartmentProfile',
    'areDeptsEnabled',
    'group'
  ],
  watch: {
    itemToEdit: function (newVal, oldVal) {
      this.thumbnail_url = newVal.thumbnail_url;
    },
  },
  computed: {
    deptSingular() {
      return useCommonDataStore().getFeatureName("department_singular");
    },
  },
  data() {
    return {
      resourceLabelOptions,
      thumbnail_changed: false,
      thumbnail_deleted: false,
      thumbnail_url: null,
      thumbnail: {},
      error: "",
      isPartnerTenant: isPartnerTenant()
    };
  },
  async created() {
    this.thumbnail_url = this.itemToEdit.thumbnail_url;
  },
  methods: {
    handleUploadThumbnail(fileObject) {
      this.thumbnail_changed = true;
      this.thumbnail_deleted = false;
      this.thumbnail_url = fileObject.fileUrl;
      this.thumbnail = fileObject;
    },

    handleRemoveThumbnail() {
      this.thumbnail_deleted = true;
    },

    isAdmin() {
      const userStatusDataComponent: HTMLElement = document.querySelector('.js-status-data');
      const loggedUser = JSON.parse(userStatusDataComponent.dataset.userStatus);
      return loggedUser.user.role === 'ADMIN';
    },
    getAuthorFullNameOrUnknown() {
      return this.itemToDelete && this.itemToDelete.author
        ? this.itemToDelete.author.first_name
          ? this.itemToDelete.author.first_name + ' ' + this.itemToDelete.author.last_name
          : this.itemToDelete.author
        : 'Unknown';
    },

    viewItem(el, index) {
      this.$emit('view-item', el, index);
    },
    nextItem(el, resources) {
      if (resources.length > 1) {
        const nextIndex = el.index + 1 < resources.length ? el.index + 1 : 0;
        const nextItem = resources[nextIndex];
        this.viewItem(nextItem, nextIndex);
      }
    },
    prevItem(el, resources) {
      if (resources.length > 1) {
        const prevIndex = el.index - 1 >= 0 ? el.index - 1 : resources.length - 1;
        const prevItem = resources[prevIndex];
        this.viewItem(prevItem, prevIndex);
      }
    },
    async downloadItem(dataurl, filename, id) {
      this.$emit('download-item', dataurl, filename, id);
    },
    closeDeleteModal() {
      this.$emit('close-delete-modal');
    },
    closeEditModal() {
      this.thumbnail_url = '';
      this.thumbnail_deleted = false;
      this.thumbnail_changed = false;
      this.$emit('close-edit-modal');
    },
    async editItem() {
      if (this.itemToEdit.name === '') {
        this.error = 'Name field is required';
      } else {
        this.error = '';
        this.$emit('edit-item', this.thumbnail_changed, this.thumbnail_deleted, this.thumbnail);
        this.thumbnail_deleted = false;
        this.thumbnail_changed = false;
      }
    },
    async deleteItem(id) {
      this.$emit('delete-item', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  width: 600px;
}

.title-container {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #444444;
  line-height: 1.2;
  position: relative;
}

.close-button {
  color: #95919b;
  font-size: 24px;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    cursor: pointer;
    color: #88858e;
  }
}

.resource-form {
  width: 100%;
  margin-top: 24px;
  display: grid;
  gap: 16px;
}

.multiselect-arrow {
  position: absolute;
  top: 14px;
  z-index: 2;
  right: 12px;
  color: #685df3;
  font-size: 15px;
  font-weight: 600;
  pointer-events: none;

  &:hover {
    cursor: pointer;
    color: #9f87e0;
  }
}

.multiselect-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.15;
  color: #3b3355;
  background-color: #fff;
  position: absolute;
  left: 12px;
  padding: 0 6px 2px;
  top: -8px;
  width: unset !important;
}

.dialog-multiselect-wrapper {
  position: relative;
  cursor: pointer;
}

.modal-textarea {
  padding: 12px 0 0 12px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #949494;
  resize: none;
  background-color: #fff;

  &:active {
    border-color: #685df3;
  }

  &:focus {
    border-color: #685df3;
  }
}

.two-inputs-row {
  display: flex;
  width: 100%;
  gap: 8px;
  overflow: visible;
}

.dialog-error-container {
  height: 12px;
  color: #f16b4c;
  font-size: 12px;
}

.dialog-actions-container {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.modal-checkbox-container {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 632px) {
  .modal-checkbox-container {
    gap: 16px;
  }
  .modal-container {
    width: 100%;
    margin: 0 16px;
  }

  .two-inputs-row {
    flex-direction: column;
  }
}
</style>

<style lang="scss">
.dialog-multiselect-wrapper {
  .multiselect__tags {
    border-width: 1px;
    border-radius: 6px;
  }
}
</style>
