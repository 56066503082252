<template>
  <section class="appointment-section">
    <div class="header-container">
      <div class="mentor-connect-title-wrapper">
        <common-title title="MentorConnect" description="Connect with peer mentors and manage appointments." />
        <div class="button-container">
          <button
            v-if="!noMeetings"
            id="requestMeetingBtn"
            class="iu-main-btn small main solid"
            aria-label="Go to connect with a mentor page"
            @click="setCurrentStep('create-appointment')"
          >
            Connect with a Mentor
            <i class="fas fa-chevron-right iu-main-button-arrow-icon" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="content-container">
      <div id="main" class="eu-simple-navbar">
        <router-link
          v-if="canManageAppointments"
          class="eu-simple-navbar-item"
          aria-label="Go to manage mentors page"
          to="/meetings/manage"
        >
          Manage Mentors
        </router-link>
        <router-link
          class="eu-simple-navbar-item eu-simple-navbar-item-active"
          aria-label="Go to my appointments page"
          to="/meetings"
          :style="check_screen_size() ? `padding-bottom:0;` : ''"
        >
          My Appointments
        </router-link>
        <router-link
          v-if="isMentor"
          class="eu-simple-navbar-item"
          aria-label="Go to My Availability page"
          to="/meetings/calendar"
        >
          My Availability
        </router-link>
        <router-link
          class="eu-simple-navbar-item"
          aria-label="Go to Favorite Mentors page"
          to="/meetings?tab=favorites"
        >
          Favorite Mentors
        </router-link>
      </div>
      <div
        v-if="!noMeetings"
        class="eu-search search-meetings"
        :style="!noMeetings ? 'margin-top: 32px' : 'opacity:0.5;margin-top: 32px'"
        role="search"
      >
        <input
          v-on:keyup.enter="search()"
          type="search"
          placeholder="Search for appointments"
          class="eu-search-input"
          v-model="searchInput"
          :style="!noMeetings ? '' : 'pointer-events: none'"
        />
        <button @click="search()" class="eu-search-btn-icon not-last" aria-label="Search appointments">
          <i class="eu-search-icon fas fa-search"></i>
        </button>
        <span v-if="!noMeetings" class="eu-courses-filters">
          <span
            tabindex="0"
            @click="viewIconClicked(ViewMode.GRID)"
            @keyup.enter="viewIconClicked(ViewMode.GRID)"
            aria-label="Change to grid view"
            role="button"
          >
            <i id="gridIcon" class="eu-courses-table-icon fas fa-grid-2"></i>
          </span>
          <span
            tabindex="0"
            @click="viewIconClicked(ViewMode.LIST)"
            @keyup.enter="viewIconClicked(ViewMode.LIST)"
            aria-label="Change to list view"
            role="button"
          >
            <i id="listIcon" class="eu-courses-list-icon fas fa-list"></i>
          </span>
        </span>
      </div>
      <div v-if="!noMeetings" class="filter-container">
        <multiselect-filter-component
          placeholder="Any Appointments"
          filter_name="Filters"
          :options="filter_status_options"
          :selected_status="selected_status"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="!isPartnerTenant()"
          :placeholder="`Any ${deptSingular}`"
          filter_name="Departments"
          :options="filterData.departments"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          placeholder="Any Focus Area"
          filter_name="Focus Areas"
          :options="filterData.focus_areas"
          @filter-data="createFilterData"
        />
      </div>
      <meetings-data
        v-if="isLoading || meetings.length > 0"
        style="margin-top: 20px; margin-bottom: 20px"
        :meetings="meetings"
        :view="meetingsView"
        :isMentor="isMentor"
        :loggedUserId="loggedUserId"
        :isLoading="isLoading"
        @refreshMeetings="fetchMeetings"
      />
      <div v-if="noMeetings" class="eu-empty-container">
        <div class="eu-empty">
          <div class="eu-empty-icon-container">
            <i class="fad fa-chalkboard-teacher eu-empty-icon"></i>
          </div>
          <div style="display: flex; flex-direction: column">
            <span class="eu-empty-title">No appointments yet!</span>
            <span class="eu-empty-text">You can book meetings with mentors in-person or online.</span>
            <button
              type="button"
              class="da-components-button da-btn-solid enup-demo-button"
              style="width: fit-content; align-self: center; margin-top: 45px; width: 100%"
              aria-label="Go to connect with a mentor page"
              @click="setCurrentStep('create-appointment')"
            >
              <span style="margin-left: 10px"> Connect with a Mentor </span>
              <i style="margin: 10px" class="fa fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import MeetingsData from "../MeetingsData.vue";
import MultiselectFilterComponent from "../../../form/components/MultiselectFilterComponent.vue";
import { isPartnerTenant } from "../../../utils/CustomUtils";
import CommonTitle from "../../../components/CommonTitle.vue";
import { useCommonDataStore } from '../../../stores/commonData';

const ViewMode = {
  LIST: "list",
  GRID: "grid",
};

export default {
  components: { CommonTitle, MeetingsData, MultiselectFilterComponent },

  name: "appointments-section",

  props: [
    "canManageAppointments",
    "selected_status",
    "loggedUserId",
    "noMeetings",
    "filterData",
    "isLoading",
    "meetings",
    "isMentor",
  ],
  computed: {
    deptSingular() {
      return useCommonDataStore().getFeatureName('department_singular')
    },
  },

  data() {
    return {
      meetingsView: localStorage.meetingsView,
      oldView: "",
      selectedShow: "all",
      searchInput: "",
      ViewMode: ViewMode,
      filter_status_options: [
        { name: "View Unconfirmed", value: "pending" },
        { name: "View Scheduled", value: "active" },
        { name: "View Completed", value: "completed" },
        { name: "View Canceled", value: "canceled" },
      ],
    };
  },

  mounted() {
    this.viewIconsListener();
    this.listenResize();
  },
  methods: {
    search() {
      this.$emit("_search", this.searchInput);
    },
    isPartnerTenant,
    setCurrentStep(step) {
      this.$emit("_setCurrentStep", step);
    },

    fetchMeetings() {
      this.$emit("_fetchMeetings", 1);
    },

    disableIcon(icon) {
      if (icon) {
        icon.style.color = "#707070";
        icon.style.pointerEvents = "auto";
      }
    },

    enableIcon(icon) {
      if (icon) {
        icon.style.color = "#4c55a7";
        icon.style.pointerEvents = "none";
      }
    },

    setMeetingView(view) {
      localStorage.meetingsView = view;
      this.meetingsView = view;
    },

    checkView() {
      if (this.meetingsView === ViewMode.GRID && this.oldView == "" && window.innerWidth < 1200) {
        this.setDisplayElements("none");
        this.oldView = ViewMode.GRID;
      }
      if (this.meetingsView === ViewMode.LIST && window.innerWidth < 1200) {
        this.setMeetingView(ViewMode.GRID);
        this.oldView = ViewMode.LIST;
        this.setDisplayElements("none");
      } else if (window.innerWidth >= 1200 && this.oldView != "") {
        this.setMeetingView(this.oldView);
        this.oldView = "";
        this.setDisplayElements("inherit");
      }
    },

    viewIconsListener() {
      const listIcon = document.querySelector("#listIcon");
      const gridIcon = document.querySelector("#gridIcon");

      if (localStorage.meetingsView == ViewMode.LIST) {
        this.disableIcon(gridIcon);
        this.enableIcon(listIcon);
      } else {
        this.setMeetingView(ViewMode.GRID);
        this.disableIcon(listIcon);
        this.enableIcon(gridIcon);
      }
    },

    viewIconClicked(view) {
      const listIcon = document.querySelector("#listIcon");
      const gridIcon = document.querySelector("#gridIcon");
      if (view === ViewMode.LIST) {
        this.setMeetingView(ViewMode.LIST);
        this.disableIcon(gridIcon);
        this.enableIcon(listIcon);
      } else if (view === ViewMode.GRID) {
        this.setMeetingView(ViewMode.GRID);
        this.disableIcon(listIcon);
        this.enableIcon(gridIcon);
      }
    },

    listenResize() {
      if (!this.noMeetings) {
        this.checkView();
        window.onresize = () => {
          this.checkView();
        };
      }
    },

    setDisplayElements(prop) {
      if (document.querySelector("#gridIcon")) {
        document.querySelector("#gridIcon")["style"].display = prop;
        document.querySelector("#listIcon")["style"].display = prop;
        document.querySelector(".eu-courses-filters")["style"].display = prop;
      }
    },
    createFilterData(value, name) {
      this.$emit("filter-data", value, name);
    },

    check_screen_size() {
      return screen.width <= 860;
    },
  },
};
</script>

<style lang="scss" scoped>
.mentor-connect-title-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
}
.appointment-section {
  position: relative;
}
.connect-button {
  position: absolute;
  top: 0;
  right: 0;
}
.title {
  margin: 0;
}
.header-container {
  width: 100%;
  max-width: 1400px;
}

.content-container {
  width: 100%;
  max-width: 1400px;
}

@media screen and (max-width: 576px) {
  .connect-button {
    width: 100%;
    position: unset;
    margin-bottom: 16px;
  }

  .mentor-connect-title-wrapper {
    flex-direction: column;
    gap: 16px;
  }

  .content-container {
    padding: 0 !important;
  }
}
</style>