import HttpService from '../../services/HttpService';


export default class MeetingsService extends HttpService {
  constructor() {
    super('/meetings/api')
  }

  getMeetings(params?): Promise<any> {
    this.endpoint = `/meetings/api`;
    return this.get(params);
  }

  postMeetingRequest(data, customHeaders) {
    this.endpoint = '/meetings/api';
    return this.postFormData(data, customHeaders);
  }

  getMentorHours(params): Promise<any> {
    this.endpoint = `/meetings/api/bookMentor`;
    return this.get(params);
  }

  postMentorRequest(data, customHeaders) {
    this.endpoint = '/meetings/api/bookMentor';
    return this.postFormData(data, customHeaders);
  }

  getMeetingRequests(params): Promise<any> {
    this.endpoint = `/meetings/api/requests`;
    return this.get(params);
  }

  acceptMeetingRequest(data, customHeaders) {
    this.endpoint = '/meetings/api/requests';
    return this.postFormData(data, customHeaders);
  }

  getHours(params) {
    this.endpoint = `/meetings/api/hours`;
    return this.get(params);
  }

  addHours(data, customHeaders) {
    this.endpoint = '/meetings/api/hours';
    return this.postFormData(data, customHeaders);
  }

  removeHours(id, body, headers): Promise<any> {
    this.endpoint = `/meetings/api/hours/${id}`;
    return this.delete(body, headers);
  }

  cancelMeeting(data, customHeaders) {
    this.endpoint = '/meetings/api/cancel';
    return this.postFormData(data, customHeaders);
  }

  endMeeting(data: { meetingId: string }, headers): Promise<any> {
    this.endpoint = `/meetings/api/end`;
    return this.postFormData(data, headers);
  }

  trackMeeting(data: { meetingId: string }, headers): Promise<any> {
    this.endpoint = `/meetings/api/track`;
    return this.postFormData(data, headers);
  }

  archiveMeeting(data: { meetingId: string }, headers): Promise<any> {
    this.endpoint = `/meetings/api/archive`;
    return this.postFormData(data, headers);
  }

  sendFollowUp(data, headers): Promise<any> {
    this.endpoint = `/meetings/api/followUp`;
    return this.postFormData(data, headers);
 }

  getMeetingsPredata() {
    this.endpoint = '/meetings/api/predata';
    return this.get();
  }

  getCalendarPredata() {
    this.endpoint = '/meetings/api/predata/calendar';
    return this.get();
  }

  getMentorsPredata() {
    this.endpoint = '/meetings/api/predata/manage';
    return this.get();
  }

  getBookMentorPredata(profile_url) {
    this.endpoint = `/meetings/api/predata/bookMentor/${profile_url}`;
    return this.get();
  }
}
