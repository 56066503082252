import Vue from 'vue';
import BlogsOverview from './components/BlogsOverview.vue';

export default class BlogsView {
  protected csrfToken: string;
  protected mountPoint: HTMLElement;
  protected blog_list: object[];

  constructor(el: HTMLElement, blog_list: object[]) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.blog_list = blog_list;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const blogsView = new Vue({
      components: { BlogsOverview },
      template: `
        <blogs-overview
          :blog_list="blog_list"
        />
      `,
      data() {
        return {
          blog_list: _class.blog_list,
        }
      },
    });

    blogsView.$mount(this.mountPoint);
  }
}
