import HttpService from '../../services/HttpService';

export default class BulkUploadService extends HttpService {
    constructor() {
        super('/import/api');
    }

    getStudents(params): Promise<any> {
        this.endpoint = '/import/api';
        return this.get(params);
    }
}