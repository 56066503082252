import Vue from 'vue';
import PartnerResourceBrowse from './components/PartnerResourceBrowse.vue';
import VueCompositionAPI from '@vue/composition-api';
import VueProgressBar from 'vue-progressbar';
import VueRouter from 'vue-router';
import { globalProgressBarOptions } from '../GlobalComponents';
import NavigationTabs from '../components/NavigationTabs.vue';

export default class ResourceLibraryView {
  protected mountPoint: HTMLElement;
  protected resources: Array<any>;
  protected featuredResources: Array<any>;
  protected manageAccess: boolean;
  protected isAdminRole: Boolean;
  protected department_name: string;
  protected preselected_resource: Object;

  constructor(
    el: HTMLElement,
    has_managing_access: string,
    isAdminRole: string,
    department_name: string,
    preselected_resource: Object
  ) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.manageAccess = has_managing_access === 'True' ? true : false;
    this.department_name = department_name;
    this.isAdminRole = isAdminRole === 'True' ? true : false;
    this.preselected_resource = preselected_resource;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;

    const routes = [
      {
        name: 'resources-browse',
        path: '/library',
        component: PartnerResourceBrowse,
        props: {
          manageAccess: _class.manageAccess,
          isAdminRole: _class.isAdminRole,
          department_name: _class.department_name,
          preselected_resource: _class.preselected_resource,
        },
      },
      {
        name: 'resources-saved',
        path: '/library/saved',
      },
      {
        name: 'resources-archive',
        //TODO verify route guard
        path: '/library/archived',
        beforeRouteEnter (to, from, next) {
          if (this.manageAccess || this.isAdminRole) {
            return true;
          }
          return false;
        }
      },
    ];

    Vue.use(VueCompositionAPI);

    const progressBarOptions = globalProgressBarOptions;
    Vue.use(VueRouter);
    Vue.use(VueProgressBar, progressBarOptions);

    const router = new VueRouter({
      routes, // short for `routes: routes`
      mode: 'history',
      scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
      },
    });

    const resourceLibraryView = new Vue({
      components: {
        NavigationTabs
      },
      template: `
        <section class="community-library-section partner">
          <div v-if="displayHeader" class="community-library-header-container partner">
            <h1 class="community-library-title">Community Library</h1>
            <div class="community-library-subtitle-container">
              <span class="community-library-subtitle">
                Find articles, resources, and blog posts.
              </span>
            </div>
          </div>
          <navigation-tabs
            :navigationItems="navigationItems"
            initialTab="resources-browse"
            class="community-library-nav-section partner"
          ></navigation-tabs>
          <vue-progress-bar></vue-progress-bar>
          <router-view></router-view>
        </section>
      `,
      data() {
        return {
          navigationItems: [
            {
              label: 'Browse Library',
              routeName: 'resources-browse',
              isRedirectUrl: false,
            },
            {
              label: 'Saved Items',
              routeName: 'resources-saved',
              isRedirectUrl: false,
            },
            {
              label: 'Archived Items',
              routeName: 'resources-archived',
              isRedirectUrl: false,
            },
          ],
        };
      },
      mounted() {
        //  [App.vue specific] When App.vue is finish loading finish the progress bar
        this.$Progress.finish();
      },
      created() {
        //  [App.vue specific] When App.vue is first loaded start the progress bar
        this.$Progress.start();
        //  hook the progress bar to start before we move router-view
        this.$router.beforeEach((to, from, next) => {
          //  does the page we want to go to have a meta.progress object
          if (to.meta.progress !== undefined) {
            let meta = to.meta.progress;
            // parse meta tags
            this.$Progress.parseMeta(meta);
          }
          //  start the progress bar
          this.$Progress.start();
          //  continue to next page;
          next();
        });
        //  hook the progress bar to finish after we've finished moving router-view
        this.$router.afterEach((to, from) => {
          //  finish the progress bar
          this.$Progress.finish();
        });
      },
      computed: {
        displayHeader() {
          return this.$route.name !== 'resource_blog_details';
        }
      },
      methods: {
        async handleSearch(e) {
          e.preventDefault();
        },
      },
      router,
    });
    resourceLibraryView.$mount(this.mountPoint);
  }
}
