<template>
  <section :class="showDetails ? '' : 'quest-card-wrapper'">
    <div class="recommended-item" :style="showDetails ? 'border-radius: 8px 8px 0 0' : ''">
      <img
        v-if="q.image_url"
        :src="q.image_url"
        class="image"
        :style="showDetails ? 'border-radius: 8px 8px 0 0' : ''"
        alt="Quest cover image"
      />
      <div
        class="tint"
        @click="changePage(`/quests/${q.id}`)"
        :style="showDetails ? 'border-radius: 8px 8px 0 0' : ''"
      ></div>
      <div class="upper">
        <div class="left">
          <div v-if="q.is_featured" class="featured-label">
            <i class="fa-solid fa-stars" style="margin-right: 4px"></i>Featured
          </div>
          <div v-if="q.is_required" class="required-label">Required</div>
        </div>
        <div class="right">
          <i
            @click="handleBookmark()"
            @keyup.enter="handleBookmark()"
            :class="`fa${q.is_bookmarked ? 's' : 'r'} fa-bookmark bookmark-ico`"
            aria-hidden="true"
          ></i>
        </div>
      </div>
      <div class="lower">
        <div class="left">
          <div @click="changePage(`/quests/${q.id}`)" class="title">{{ q.name }}</div>
          <div v-if="q.focus_areas.length > 0" class="fa-wrapper text-clamp-1">
            <b>Focus Areas:</b>
            {{ q.focus_areas.map((item) => item.name).join(", ") }}
          </div>
          <div class="fa-wrapper text-clamp-1">
            <span style="margin-right: 8px"
              ><i class="fa-solid fa-list" style="margin-right: 3px"></i> {{ q.tasks.length }} task{{
                q.tasks.length == 1 ? "" : "s"
              }}</span
            >
            <span><i class="fa-solid fa-building" style="margin-right: 3px"></i> {{ q.department.name }}</span>
          </div>
        </div>
        <div class="right">
          <div class="rating"><i class="fal fa-thumbs-up"></i> {{ getRatingPercentage() }}</div>
        </div>
      </div>
    </div>
    <div class="details" v-if="showDetails">
      <button @click="startQuest" class="start-label" :style="getStatusText() == 'Completed' && 'pointer-events: none'">
        <i v-if="getStatusText() != 'Completed'" class="fa-solid fa-play ico-sm"></i>{{ getStatusText() }}
      </button>
      <div v-if="q.students.length > 0" class="students-wrapper">
        <div class="students">
          <template v-for="(s, index) in q.students">
            <div
              v-bind:key="s.id"
              v-if="index < 5"
              class="student-thumb"
              :style="`background-image: url('${s.profile_pic}'); left: ${index * 16}px;`"
            ></div>
          </template>
        </div>
        <span :style="`margin-left: ${8 + (q.students.length > 5 ? 5 : q.students.length) * 18}px; line-height: 1.9`"
          >{{ q.students.length }} student<span v-if="q.students.length > 1">s</span></span
        >
      </div>
    </div>
  </section>
</template>
<script>
import QuestService from "../services/QuestService";
import BookmarkService from "../../bookmarks/services/BookmarkService";
export default {
  name: "quest-card",
  props: ["q", "showDetails"],
  data() {
    return {
      service: new QuestService(),
      bookmarkService: new BookmarkService(),
    };
  },
  async created() {},
  methods: {
    showNotification(notification) {
      this.$emit("show-notification", notification);
    },
    changePage(page) {
      this.$router.push(page);
    },
    getStatusText() {
      if (this.q.quest_student) {
        if (this.q.quest_student.no_of_completed_tasks === this.q.tasks.length) {
          return "Completed";
        }
        return "Continue";
      }
      return "Start";
    },
    getRatingPercentage() {
      let positiveRatings = 0;
      let totalRatings = this.q.ratings.length; // Total number of ratings

      // Count the number of positive ratings
      this.q.ratings.forEach(rating => {
          if (rating === 1) {
              positiveRatings += 1;
          }
      });

      let percentage = (positiveRatings / totalRatings) * 100;

      return percentage ? `${Math.floor(percentage)}%` : 0;
    },
    async handleBookmark() {
      const method = this.q.is_bookmarked ? "deleteBookmarkedQuest" : "bookmarkQuest";
      const notification = { msg: "", isError: false, icon: null };
      const res = await this.bookmarkService[method]({ target: this.q.id });
      if (res.success) {
        this.q.is_bookmarked = !this.q.is_bookmarked;
        notification.msg = `Bookmark successfully ${method == "bookmarkQuest" ? "added" : "removed"}`;
        notification.icon = "fad fa-check-circle";
      } else {
        notification.msg = `Oops, something went wrong. Please reload page and try again.`;
        notification.isError = false;
        notification.icon = "fad fa-exclamation-circle";
      }
      this.showNotification(notification);
    },
    async startQuest() {
      if (!this.q.quest_student) {
        const res = await this.service.startQuest({ quest: this.q.id });
        if (!res.success) {
          this.showNotification({
            msg: "Error: Cannot start a Quest",
            isError: true,
            icon: "fad fa-exclamation-circle",
          });
        }
      }
      this.changePage(`/quests/questMode/${this.q.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.bookmark-ico {
  cursor: pointer;
  font-size: 18px;
  color: #fafafa;
  &:hover {
    opacity: 0.7;
  }
}
.students-wrapper {
  font-size: 12px;
  font-weight: 600;
  color: #88858e;
}
.students {
  display: flex;
  position: relative;
}
.student-thumb {
  position: absolute;
  width: 24px;
  height: 24px;
  background-size: cover;
  border: 1px solid #fff;
  border-radius: 50%;
}
.details {
  display: flex;
  justify-content: space-between;
  height: 40px;
  background: #fff;
  align-items: center;
  padding: 8px;
  box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.06);
  border-radius: 0 0 8px 8px;
}
.start-label {
  color: #0b9d84;
  font-weight: 600;
  background: #e7fef0;
  padding: 1px 6px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: max-content;
  &:hover {
    opacity: 0.7;
  }
}
.ico-sm {
  color: #0b9d84;
  font-size: 11px;
  margin-right: 5px;
}
.quest-card-wrapper {
  display: flex;
  flex: 1;
}
.detailsWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 260px;
}
.recommended-item {
  color: #fff;
  padding: 16px;
  position: relative;
  min-width: 220px;
  height: 220px;
  background-color: #dadada;
  background-size: cover;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    transition: all .3s;
    &:hover {
      transform: scale(1.1);
    }
  }

  .tint {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .3s;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
  .upper {
    z-index: 9;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      gap: 8px;
    }
    .featured-label {
      padding: 4px 8px;
      border-radius: 4px;
      background: #22a5a0;
      font-size: 12px;
      font-weight: 600;
    }
    .required-label {
      @extend .featured-label;
      background: #e65722;
    }
  }
  .lower {
    z-index: 9;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .right {
      display: flex;
      align-items: flex-end;
    }

    .rating {
      width: 40px;
      display: flex;
      gap: 6px;
      align-items: center;
      font-size: 14px;
      cursor: default;
      font-weight: 600;
      justify-content: center;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      line-height: 1.15;
    }
    .fa-wrapper {
      font-size: 12px;
    }
  }
}
</style>
