<template>
  <div class="content-container">
    <notification />
    <vue-progress-bar></vue-progress-bar>
    <h1>Create a partner account</h1>
    <form class="partner-initialize-account-form" @submit="submitForm">
      <div class="input-container">
        <input-base
          id="partner-name"
          :required="true"
          type="text"
          label="Partner name"
          :isError="isInputError('partner-name')"
          v-model="formData['partner-name']"
        />
        <span
          class="input-error-text"
        >
          {{ getErrorMessage('partner-name') }}
        </span>
      </div>

      <h3>Partner contact information</h3>
      <div class="input-container">
        <input-base
          id="partner-phone-number"
          label="Phone number"
          type="tel"
          :isError="isInputError('partner-phone-number')"
          v-model="formData['partner-phone-number']"
        />
        <span
          class="input-error-text"
        >
          {{ getErrorMessage('partner-phone-number') }}
        </span>
      </div>

      <div class="input-container">
        <input-base
          id="partner-email"
          label="Partner contact email"
          type="email"
          :isError="isInputError('partner-email')"
          v-model="formData['partner-email']"
        />
        <span
          class="input-error-text"
        >
          {{ getErrorMessage('partner-email') }}
        </span>
      </div>

      <h2>Initial manager information</h2>
      <div class="input-container">
        <input-base
          id="manager-first-name"
          :required="true"
          type="text"
          label="Manager first name"
          :isError="isInputError('manager-first-name')"
          v-model="formData['manager-first-name']"
        />
        <span
          class="input-error-text"
        >
          {{ getErrorMessage('manager-first-name') }}
        </span>
      </div>
      <div class="input-container">
        <input-base
          id="manager-last-name"
          :required="true"
          type="text"
          label="Manager last name"
          :isError="isInputError('manager-last-name')"
          v-model="formData['manager-last-name']"
        />
        <span
          class="input-error-text"
        >
          {{ getErrorMessage('manager-last-name') }}
        </span>
      </div>
      <div class="input-container">
        <input-base
          id="manager-email"
          :required="true"
          label="Manager email"
          type="email"
          :isError="isInputError('manager-email')"
          v-model="formData['manager-email']"
        />
        <span
          class="input-error-text"
        >
          {{ getErrorMessage('manager-email') }}
        </span>
      </div>

      <div class="partner-initialize-actions-container">
        <button
          class="iu-main-btn x-small outlined gray"
          @click.prevent="cancel"
          aria-label="Cancel and redirect to the dashboard"
        >
          Cancel
        </button>
        <button
          class="iu-main-btn x-small solid main"
          @click.prevent="submitForm"
          aria-label="Create an account and send invitation"
          type="submit"
        >
          Confirm and send invitation
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import InputBase from '../../components/formBase/InputBase.vue';
import PartnerAccountService from '../services/PartnerAccountService';
import * as Cookies from 'js-cookie';
import Notification from '../../common/Notification.vue';
import { useNotificationStore } from '../../stores/notification';
import { isValidEmailString } from '../../utils/CustomUtils';

export default {
  name: 'partner-initialize',
  components: {
    InputBase,
    Notification
  },
  props: [
    'initData',
    'partnerData',
  ],
  data() {
    return {
      partnerAccountService: new PartnerAccountService(),
      notificationStore: useNotificationStore(),
      formData: {
        'partner-name': this.initData['partner-name'],
        'partner-phone-number': this.initData['partner-phone-number'],
        'partner-email': this.initData['partner-email'],
        'manager-first-name': this.initData['manager-first-name'],
        'manager-last-name': this.initData['manager-last-name'],
        'manager-email': this.initData['manager-email'],
      },
      formErrorMessagesObject: {
        'partner-name': {
          'isActive': false,
          'message': ''
        },
        'partner-phone-number': {
          'isActive': false,
          'message': ''
        },
        'partner-email': {
          'isActive': false,
          'message': ''
        },
        'manager-first-name': {
          'isActive': false,
          'message': ''
        },
        'manager-last-name': {
          'isActive': false,
          'message': ''
        },
        'manager-email': {
          'isActive': false,
          'message': ''
        },
      },
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    }
  },
  created() {

  },
  methods: {
    getErrorMessage(inputId) {
      if (this.formErrorMessagesObject[inputId].isActive) {
        return this.formErrorMessagesObject[inputId].message;
      }

      return null;
    },
    validateEmail(email: string) {
      return isValidEmailString(email);
    },
    clearErrorObject() {
      this.formErrorMessagesObject['partner-name'].isActive = false;
      this.formErrorMessagesObject['partner-name'].message = '';
      this.formErrorMessagesObject['partner-phone-number'].isActive = false;
      this.formErrorMessagesObject['partner-phone-number'].message = '';
      this.formErrorMessagesObject['partner-email'].isActive = false;
      this.formErrorMessagesObject['partner-email'].message = '';
      this.formErrorMessagesObject['manager-first-name'].isActive = false;
      this.formErrorMessagesObject['manager-first-name'].message = '';
      this.formErrorMessagesObject['manager-last-name'].isActive = false;
      this.formErrorMessagesObject['manager-last-name'].message = '';
      this.formErrorMessagesObject['manager-email'].isActive = false;
      this.formErrorMessagesObject['manager-email'].message = '';
    },
    validateInput() {
      this.clearErrorObject();
      let isValid = true;
      const requiredFields = ['partner-name', 'partner-email', 'manager-first-name', 'manager-last-name', 'manager-email'];
      requiredFields.forEach(field => {
        if (!this.formData[field]) {
          this.formErrorMessagesObject[field].isActive = true;
          isValid = false;
          this.formErrorMessagesObject[field].message = 'This field is required.';
        } else {
          this.formErrorMessagesObject[field].isActive = false;
          this.formErrorMessagesObject[field].message = '';
        }
      });
      if (this.formData['partner-email']) {
        if (!this.validateEmail(this.formData['partner-email'])) {
          this.formErrorMessagesObject['partner-email'].isActive = true;
          isValid = false;
          this.formErrorMessagesObject['partner-email'].message = 'Please enter a valid email address.';
        }
      }
      if (this.formData['manager-email']) {
        if (!this.validateEmail(this.formData['manager-email'])) {
          this.formErrorMessagesObject['manager-email'].isActive = true;
          isValid = false;
          this.formErrorMessagesObject['manager-email'].message = 'Please enter a valid email address.';
        }
      }
      return isValid;
    },
    submitForm() {
      this.$Progress.start()

      if (this.validateInput()) {
        this.partnerAccountService.initializePartnerAccount(this.formData, this.headers).then(data => {
          this.notificationStore.addNotification('Account successfully created and invitation email sent. You\'ll be redirected to your dashboard in a few seconds.');
          setTimeout(() => {
            this.$Progress.finish();
            window.location.href = '/dashboard/admin'
          }, 5000)
        }).catch(error => {
          this.$Progress.fail();
          this.notificationStore.addNotification('Oops, something went wrong. Please verify the data and try again.', 5000);
        });
      } else {
        this.$Progress.fail();
        this.notificationStore.addNotification('Please verify the data and try again.', 5000);
      }
    },
    isInputError(inputId) {
      return this.formErrorMessagesObject[inputId].isActive;
    },
    cancel() {
      window.location.href = '/';
    },
  },
}
</script>
