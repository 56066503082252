<template>
  <div class="iu-table-burger-menu">
    <div
      class="iu-table-burger-menu-item"
      role="button"
      tabindex="0"
      aria-label="Open edit partner dialog."
      @click="selectOption('edit-partner')"
      @keyup.enter="selectOption('edit-partner')"
    >
      <i class="fa-solid fa-pencil"></i>
      Edit Partner
    </div>
    <div
      class="iu-table-burger-menu-item"
      role="button"
      tabindex="0"
      aria-label="Open add manager dialog"
      @click="selectOption('add-manager')"
      @keyup.enter="selectOption('add-manager')"
    >
      <i class="fa-solid fa-user-plus"></i>
      Add Manager
    </div>
    <div
      class="iu-table-burger-menu-item"
      role="button"
      tabindex="0"
      aria-label="View dashboard"
      @click="selectOption('view-dashboard')"
      @keyup.enter="selectOption('view-dashboard')"
    >
      <i class="fa-solid fa-eye"></i>
      View Dashboard
    </div>
    <div
      class="iu-table-burger-menu-item"
      role="button"
      tabindex="0"
      aria-label="Delete partner"
      @click="selectOption('delete-partner')"
      @keyup.enter="selectOption('delete-partner')"
    >
      <i class="fa-solid fa-trash-alt"></i>
      Delete
    </div>
  </div>
</template>

<script>
  export default {
    name: 'partners-burger-menu',
    methods: {
      selectOption(option) {
        this.$emit(option);
      }
    }
  }
</script>
