<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <div v-if="course">
        <section
          id="header-section"
          class="da-content-main--hero eu-cover-image"
          :style="{ backgroundImage: 'url(' + course.course_card_image_url + ')' }"
        >
          <span v-if="is_completed" class="inup-completed-course-element">
            <i class="fas fa-flag-checkered"></i>
            Completed
          </span>
          <h1 class="eu-cover-image-course-title">{{ course.name }}</h1>
        </section>

        <section id="button-section" class="inup-course-button-section">
          <button
            v-if="!is_enrolled"
            id="button"
            type="submit"
            class="da-components-button da-btn-solid course-enroll-button"
            @click="enroll"
          >
            Enroll/Add to My Courses
            <i id="arrow" class="far fa-chevron-right start-icon"></i>
          </button>
          <a
            v-else
            :href="thinkific_url + chapters[0].take_url"
            target="_blank"
            class="da-components-button da-btn-solid course-enroll-button"
          >
            <i class="fas fa-check-circle enrolled-icon"></i>
            Enrolled! Go to Course
            <i id="arrow" class="far fa-chevron-right start-icon"></i>
          </a>
          <div class="status-items">
            <span v-if="course.is_recommended" class="inup-recommended-course-element">
              <i class="fas fa-stars"></i>
              Featured
            </span>
            <button
              :id="'bookmark-button-' + course.id"
              type="button"
              name="Bookmark Button"
              :class="['bookmark-button', { hidden: is_bookmarked }]"
              @click="toggleBookmark('POST', course.id)"
              :aria-label="'Add ' + course.name + ' to saved courses'"
            >
              <i class="fal fa-bookmark"></i>
              Save
            </button>
            <button
              :id="'bookmark-remove-button-' + course.id"
              type="button"
              name="Remove Bookmark Button"
              :class="['bookmark-button', { hidden: !is_bookmarked }]"
              @click="toggleBookmark('DELETE', course.id)"
              :aria-label="'Remove ' + course.name + ' from saved courses'"
            >
              <i class="fad fa-bookmark"></i>
              Saved
            </button>
            <div :id="'bookmark-spinner-' + course.id" class="spinner-content" style="display: none; padding: 0">
              <div>
                <div class="spinner-container animation-2">
                  <div class="shape shape1"></div>
                  <div class="shape shape2"></div>
                  <div class="shape shape3"></div>
                  <div class="shape shape4"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div v-if="isAdmin" class="course-settings-wrapper">
          <h2 class="course-settings-title">Course Settings</h2>
          <div>
            <div>
              <label class="inup-checkmark-container checkmark-label">
                <span class="course-settings-item">Mark as Featured</span>
                <input :id="course.id" type="checkbox" v-model="is_recommended"
                    @change="toggleRecommendedGuide($event, course.id)" />
                <span class="inup-checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div id="content-section" class="da-student-dashboard">
          <section class="da-student-dashboard-content">
            <section class="eu-course-view">
              <h2 class="eu-course-title">What You Will Learn</h2>
              <div class="eu-course-subtitle">{{ course.description }}</div>
              <div class="da-course-container">
                <div class="eu-course-title">Course Curriculum</div>
                <ul id="courseChapters" class="eu-course-chapters" :class="{ disabled: !is_enrolled }">
                  <li v-for="chapter in chapters" :key="chapter.id" class="eu-course-chapter">
                    <a
                      :href="thinkific_url + chapter.take_url"
                      target="_blank"
                      @mouseover="setHoveredChapter(chapter)"
                      @mouseout="clearHoveredChapter"
                      :tabindex="!is_enrolled ? '-1' : undefined"
                    >
                      <i
                        :class="
                          chapter === hoveredChapter
                            ? 'far fa-play-circle fa-fw eu-course-chapter-icon'
                            : getChapterIcon(chapter)
                        "
                      ></i>
                      {{ chapter.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </section>
          </section>
        </div>

        <section id="loading-modal" class="da-partners-alumni-modal">
          <!-- <div id="modal-content-loading" class="modal-content course-modal-loading-content">
            <h1 class="header">Loading Course...</h1>
            <div class="spinner-content">
              <div>
                <div class="spinner-container animation-2">
                  <div class="shape shape1"></div>
                  <div class="shape shape2"></div>
                  <div class="shape shape3"></div>
                  <div class="shape shape4"></div>
                </div>
              </div>
            </div>
            <span class="subtext">Hang in there!</span>
          </div> -->
          <div id="modal-content-success" class="modal-content course-modals-success-content">
            <h1 class="header">You’re Enrolled!</h1>
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            <div class="button-container">
              <router-link to="/courses" class="da-components-button da-btn-standard back-button"> Back </router-link>
              <a href="javascript:location.reload();" class="da-components-button da-btn-solid course-location-button">
                Go to Course
                <i id="arrow" class="far fa-chevron-right start-icon"></i>
              </a>
            </div>
          </div>
        </section>
        <section v-if="recommended_courses.length" class="recommended-courses-wrapper" style="margin: 30px 0">
          <div class="recommended-courses-header">
            <span class="recommended-courses-header-title">Other Recommended Courses</span>
            <router-link to="/courses" class="recommended-courses-header-link">
              Browse all <i class="fa fa-chevron-right icon-hover"></i
            ></router-link>
          </div>
          <ul class="recommended-courses-cards">
            <li
              v-for="recommended_course in recommended_courses"
              :key="recommended_course.id"
              class="recommended-courses-card"
            >
              <img class="recommended-courses-card-img" :src="recommended_course.course_card_image_url" />
              <div class="recommended-courses-card-body">
                <router-link
                  :aria-label="'Go to ' + recommended_course.name + ' course detailed page'"
                  :to="'/courses/' + recommended_course.id"
                >
                  <h3 class="recommended-courses-card-body-title">{{ recommended_course.name }}</h3>
                </router-link>
                <p class="recommended-courses-card-body-description">
                  {{ recommended_course.description }}
                </p>
              </div>
              <div class="recommended-courses-card-footer">
                <button
                  :id="'bookmark-button-' + recommended_course.id"
                  type="button"
                  style="font-size: 13px; z-index: 0"
                  name="Bookmark Button"
                  :class="{ 'bookmark-button': true, hidden: recommended_course.is_bookmarked }"
                  @click="toggleBookmark('POST', recommended_course.id)"
                  :aria-label="'Add ' + recommended_course.name + ' to saved courses'"
                >
                  <i class="fal fa-bookmark"></i>
                  Save
                </button>
                <button
                  :id="'bookmark-remove-button-' + recommended_course.id"
                  type="button"
                  style="font-size: 13px"
                  name="Remove Bookmark Button"
                  :class="{ 'bookmark-button': true, hidden: !recommended_course.is_bookmarked }"
                  @click="toggleBookmark('DELETE', recommended_course.id)"
                  :aria-label="'Remove ' + recommended_course.name + ' from saved courses'"
                >
                  <i class="fad fa-bookmark"></i>
                  Saved
                </button>
                <div
                  :id="'bookmark-spinner-' + recommended_course.id"
                  class="spinner-content"
                  style="display: none; padding: 0; margin: 0"
                >
                  <div>
                    <div class="spinner-container animation-2">
                      <div class="shape shape1"></div>
                      <div class="shape shape2"></div>
                      <div class="shape shape3"></div>
                      <div class="shape shape4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>
      <div v-else>
        <loading-spinner wrapperClass="wrapper" />
      </div>
    </div>
  </div>
</template>
<script>
import BookmarkService from "../../bookmarks/services/BookmarkService";
import CoursesService from "../services/CoursesService";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
export default {
  components: { LoadingSpinner },
  name: "course-details",
  props: ["isAdmin"],
  data() {
    return {
      classTemp: "",
      service: new CoursesService(),
      bookmarkService: new BookmarkService(),
      course: null,
      hoveredChapter: null,
      is_enrolled: false,
    };
  },
  async created() {
    await this.getCourseData();
  },
  methods: {
    async getCourseData() {
      const courseId = this.$route.path.split("/").pop();
      const res = await this.service.getPredataCourse(courseId);
      if (res.course) {
        this.course = res.course;
        this.chapters = res.chapters;
        this.is_enrolled = res.is_enrolled;
        this.is_completed = res.is_completed;
        this.is_bookmarked = res.is_bookmarked;
        this.is_recommended = res.is_recommended;
        this.thinkific_url = res.thinkific_url;
        this.recommended_courses = res.recommended_courses;

        this.recommend_checkbox = document.getElementById(res.course.id);
        if (this.recommend_checkbox) {
          this.recommend_checkbox.checked = res.is_recommended;
        }
      }
    },
    getChapterIcon(chapter) {
      const contentableIcons = {
        Lesson: "fad fa-film-alt fa-fw eu-course-chapter-icon",
        Audio: "fad fa-film-alt fa-fw eu-course-chapter-icon",
        Quiz: "fad fa-pencil-alt fa-fw eu-course-chapter-icon",
        Survey: "fad fa-pencil-alt fa-fw eu-course-chapter-icon",
        Assignment: "fad fa-pencil-alt fa-fw eu-course-chapter-icon",
        HtmlItem: "fad fa-head-side-brain fa-fw eu-course-chapter-icon",
        Presentation: "fad fa-head-side-brain fa-fw eu-course-chapter-icon",
      };
      return contentableIcons[chapter.contentable_type] || "fad fa-lightbulb-on fa-fw eu-course-chapter-icon";
    },
    setHoveredChapter(chapter) {
      this.hoveredChapter = chapter;
    },
    clearHoveredChapter() {
      this.hoveredChapter = null;
    },
    hideContentShowModal() {
      document.querySelector("#content-section").style.display = "none";
      document.querySelector("#button-section").style.display = "none";
      document.querySelector("#header-section").style.display = "none";
      document.querySelector("#loading-modal").style.display = "flex";
    },

    handleEnrollmentSuccess() {
      document.querySelector("#modal-content-loading").style.display = "none";
      document.querySelector("#modal-content-success").style.display = "flex";
    },
    showBookmarkSpinner(method, id) {
      if (method === "POST") {
        document.querySelector("#bookmark-button-" + id).style.display = "none";
      } else {
        document.querySelector("#bookmark-remove-button-" + id).style.display = "none";
      }

      document.querySelector("#bookmark-spinner-" + id).style.display = "flex";
    },
    showBookmarkButton(method, id) {
      document.querySelector("#bookmark-spinner-" + id).style.display = "none";

      if (method === "POST") {
        document.querySelector("#bookmark-remove-button-" + id).style.display = "block";
      } else {
        document.querySelector("#bookmark-button-" + id).style.display = "block";
      }
    },
    async enroll() {
      this.hideContentShowModal();
      const res = await this.service.courseEnroll(this.course.id);
      if (res.success) {
        this.handleEnrollmentSuccess();
      }
    },
    async toggleBookmark(method, id) {
      this.showBookmarkSpinner(method, id);

      await this.bookmarkService.postBookmarkCourses({ targets: [id] });
      this.showBookmarkButton(method, id);
    },
    async toggleRecommendedGuide(e, id) {
      const target = e.target;

      if (target.checked) {
        this.service.setRecommended({ guide_id: id });
        this.is_recommended = true;
      } else {
        this.service.removeRecommended({ guide_id: id });
        this.is_recommended = false;
      }
    },
  },
};
</script>

<style scoped>
</style>