<template>
  <section
    class="item-card"
    :style="`background: linear-gradient(to top, #000 10%, rgba(0, 0, 0, 0) 55%), url(${user.profile_picture}) no-repeat center center / cover;`"
  >
    <div class="toolbar">
      <div class="left"><div class="type-label green">Mentor</div></div>
      <div class="right">
        <i
          class="fa-bookmark"
          tabindex="0"
          :class="`${ids_favorite_mentors && ids_favorite_mentors.includes(user.id) ? 'active fas' : 'far'}`"
          :title="`${
            ids_favorite_mentors && ids_favorite_mentors.includes(user.id) ? 'Remove bookmark' : 'Bookmark item'
          }`"
          @click="handleBookmark(user.id)"
          @keyup.enter="handleBookmark(user.id)"
          aria-label="Toggle bookmark for item"
          role="button"
        ></i>
      </div>
    </div>
    <div class="img-card-info-wrapper">
      <div class="img-card-name text-clamp-1">{{ user.name }}</div>
      <div class="img-card-dept text-clamp-1">{{ user.department_name }}</div>
      <div class="img-card-btns">
        <a aria-label="View mentor profile" :href="`/profiles/${user.url}`" class="iu-main-btn x-small outlined light">
          View Profile
        </a>
        <router-link
          aria-label="Book a meeting with selected mentor"
          :to="`/meetings/book/${user.url}`"
          class="iu-main-btn x-small outlined light"
        >
          Book Meeting
        </router-link>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import handleOutsideClick from "../../directives/handleOutsideClick";
import AccountsService from "../services/AccountsService";
import Cookies from "js-cookie";

export default {
  directives: { closable: handleOutsideClick },
  components: {},
  name: "mentor-card",
  props: ["user", "className", "department_name", "focus_areas", "favorite_mentors", "userId"],
  data() {
    return {
      ids_favorite_mentors: [],
      lastOpenedBurger: null,
      accountsService: new AccountsService(),
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  async created() {
    this.ids_favorite_mentors = await this.accountsService.getFavoriteMentors({ flag: "ids" });
  },
  methods: {
    async handleBookmark(id) {
      if (this.ids_favorite_mentors.includes(id)) {
        await this.accountsService.removeFavoriteMentor(id, this.headers);
      } else {
        await this.accountsService.addFavoriteMentor({ user_id: this.userId, mentor_id: id }, this.headers);
      }
      this.ids_favorite_mentors = await this.accountsService.getFavoriteMentors({ flag: "ids" });
    },
  },
  computed: {},
};
</script>
