import HttpService from '../../services/HttpService';


export default class StudentService extends HttpService {
  constructor() {
    super('/accounts/services/');
  };

  deleteStudent(params, headers) {
    this.endpoint = '/accounts/services/delete';

    return this.postFormData(params, headers);
  };

  suspendStudent(params, headers) {
    this.endpoint = '/accounts/services/suspend';

    return this.postFormData(params, headers);
  };

  suspendMultiple(params, headers) {
    this.endpoint = '/accounts/services/suspend-multiple';

    return this.postFormData(params, headers);
  }
  deleteMultiple(params, headers) {
    this.endpoint = '/accounts/services/delete-multiple';

    return this.postFormData(params, headers);
  }
}