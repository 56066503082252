<template>
  <div class="details-page-container">
    <div class="info-cards-container">
      <!-- #region ContactInformation -->
      <div class="card" v-if="contactExists">
        <h3 class="title">Contact Information</h3>
        <div class="contact-content-container">
          <div v-if="partner.phone_number" class="contact-item-container">
            <i class="fa-regular fa-phone-alt"></i>
            <a
              :href="'tel:' + getPhoneNumberString(partner.phone_number)"
              :aria-label="getAriaLabelPhoneNumberString(partner.phone_number)"
              class="contact-text"
            >
              {{ partner.phone_number }}
            </a>
          </div>
          <div v-if="partner.email" class="contact-item-container">
            <i class="fa-regular fa-envelope"></i>
            <a :href="'mailto:' + partner.email" class="contact-text">{{ partner.email }}</a>
          </div>
          <div v-if="partner.website" class="contact-item-container">
            <i class="fa-regular fa-globe"></i>
            <a class="contact-text link" target="_blank" :href="partner.website">{{ shortenURL(partner.website) }}</a>
          </div>
        </div>
        <div class="separator"></div>
        <div class="social-networks-container">
          <div v-for="(item, index) of partner.socials" :key="index" v-show="item.url !== ''">
            <a
              v-if="item.url"
              :aria-label="`link to ${item.value} page`"
              class="social-network-icon"
              :href="item.url"
              target="_blank"
              ><i :class="item.icon" style="margin-right: 10px"></i
            ></a>
          </div>
        </div>
      </div>
      <!-- #endregion -->
      <!-- #region About -->
      <div class="card" v-if="partner.description">
        <h3 class="title">About {{ partner.name }}</h3>
        <p class="description" v-html="formatTextToHtml(partner.description)"></p>
      </div>
      <!-- #endregion -->
    </div>
    <div
      class="video-card"
      v-if="partner.welcome_video_url && (partner.partner_type == 'STANDARD' || partner.partner_type == 'PREMIUM')"
    >
      <iframe
        :src="getYoutubeOrVimeoEmbedLink(partner.welcome_video_url)"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </div>
    <div v-if="partner.partner_type == 'STANDARD' || partner.partner_type == 'PREMIUM'" class="info-cards-container">
      <content-card
        :partner_name="partner.name"
        :hasProgramsAccess="hasProgramsAccess"
        :hasEventsAccess="hasEventsAccess"
        class="card large"
      ></content-card>
    </div>
    <div v-if="partner.partner_type == 'PREMIUM'" class="recent-posts-wrapper">
      <div class="recent-posts-container">
        <div class="title-container">
          <i class="fa-regular fa-bullhorn"></i>
          <h3 class="text">Recent Posts</h3>
        </div>
        <div class="post-cards-container" v-if="isLoadingPosts">
          <loading-spinner></loading-spinner>
        </div>
        <div v-else-if="posts && posts.length" class="post-cards-container">
          <div v-for="(item, index) in posts" :key="index" class="card-wrapper">
            <div class="card-content-container">
              <div class="author-container">
                <img :src="item.profile_pic" alt="Author profile image" />
                <div class="text-container">
                  <div class="name">{{ item.actor_name }}</div>
                  <div class="info-text">{{ partner.name }} • {{ getTimeAgo(item.time) }}</div>
                </div>
              </div>
              <div class="post-text-container text-clamp-3">
                <span v-html="formatText(item.object.text)"></span>
              </div>
              <div v-if="shortShowOneElement(item)" class="post-attachments-container grid">
                <div class="item"><img :src="item.files[0].resource_url" alt="image item" /></div>
              </div>
              <div v-else-if="showOneAttachment(item)" class="post-attachments-container full-width">
                <div v-if="item.event" class="item">
                  <i class="file-type-icon fa-regular fa-calendar-star"></i>
                  <div class="text text-clamp-1">{{ item.event.title }}</div>
                </div>
                <div v-else-if="getAttachmentType(item.files[0]) === 'URL'" class="item">
                  <i class="file-type-icon fa-regular fa-external-link"></i>
                  <div class="text text-clamp-1">{{ item.files[0].name }}</div>
                </div>
                <div v-else-if="getAttachmentType(item.files[0]) === 'DOC'" class="item">
                  <i class="file-type-icon fa-regular fa-file-alt"></i>
                  <div class="text text-clamp-1">{{ item.files[0].name }}</div>
                </div>
                <div v-else-if="getAttachmentType(item.files[0]) === 'VID'" class="item">
                  <i class="file-type-icon fa-regular fa-video"></i>
                  <div class="text text-clamp-1">{{ item.files[0].name }}</div>
                </div>
              </div>
              <div v-else-if="showMultipleAttachments(item)" class="post-attachments-container grid">
                <div
                  v-for="(file, index) in getShorterFilesList(item.files)"
                  :key="index"
                  class="item"
                  :class="index === 3 && item.files.length > 4 ? 'more' : ''"
                >
                  <i v-if="getAttachmentType(file) === 'DOC'" class="file-type-icon fa-regular fa-file-alt"></i>
                  <i
                    v-else-if="getAttachmentType(file) === 'URL'"
                    class="file-type-icon fa-regular fa-external-url"
                  ></i>
                  <i v-else-if="getAttachmentType(file) === 'VID'" class="file-type-icon fa-regular fa-video"></i>
                  <img v-else-if="getAttachmentType(file) === 'IMG'" :src="file.resource_url" alt="image item" />
                  <div v-if="index === 3 && item.files.length > 4" class="tint">+{{ getMoreNumber(item.files) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="recent-posts-wrapper empty">
          <h4 class="empty-text-bold">There are no posts yet</h4>
          <p class="empty-text">Check back later!</p>
        </div>
        <a
          v-if="posts && posts.length"
          class="dabi-main-button-outlined see-all-button"
          aria-label="See all posts"
          :href="`/partners/${partner.name}?tab=feed`"
        >
          See all
        </a>
      </div>
    </div>
    <div class="info-cards-container uneven">
      <div class="card" v-if="contactUsersExist">
        <h3 class="title">Contacts</h3>
        <div class="contact-users-container">
          <div
            v-for="(item, index) in partner.contacts"
            :key="index"
            class="contact-user-item-container"
            :class="index !== 0 ? 'separator-top' : ''"
          >
            <img alt="Profile picture of user" :src="item.profile_pic" class="image" />
            <div class="text-container">
              <a
                :aria-label="`Go to ${item.full_name} profile page`"
                target="_blank"
                class="name"
                :href="`/profiles/${item.url}`"
                >{{ item.full_name }}</a
              >
              <div class="title" v-if="item.title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-if="hasImportantLinks">
        <h3 class="title">Helpful Links</h3>
        <div class="links-container">
          <div
            v-for="(value, title, index) in partner.important_links"
            :key="index"
            class="link-item-container"
            :class="index !== 0 ? 'separator-top' : ''"
          >
            <div class="text-container">
              <h4 class="description">{{ title }}</h4>
              <a :href="value" class="link" target="_blank" :aria-label="`Proceed to ${shortenURL(value)}`">{{
                shortenURL(value)
              }}</a>
            </div>
            <a class="proceed-button" :href="value" target="_blank" :aria-label="`Proceed to ${shortenURL(value)}`">
              <i class="fa-solid fa-external-link-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import { getYoutubeOrVimeoEmbedLink, formatTextToHtml } from '../../utils/CustomUtils';
import { formatURL, getAriaLabelPhoneNumberString, getPhoneNumberString } from '../../utils/StringUtils';
import ContentCard from './ContentCard.vue';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

export default {
  components: {
    ContentCard,
    LoadingSpinner,
  },

  name: 'partner-details',

  props: ['loggedUser', 'partner', 'isLoadingPosts', 'posts', 'hasProgramsAccess', 'hasEventsAccess'],

  computed: {
    contactExists() {
      return this.partner.email || this.partner.phone_number || this.partner.website;
    },
    contactUsersExist() {
      return this.partner.contacts && Object.keys(this.partner.contacts).length > 0;
    },
    hasImportantLinks() {
      return this.partner.important_links && Object.keys(this.partner.important_links).length > 0;
    },
  },
  data() {
    return {};
  },

  async created() {},

  methods: {
    getAriaLabelPhoneNumberString,
    getPhoneNumberString,
    getYoutubeOrVimeoEmbedLink,
    formatURL,
    formatTextToHtml,
    getShorterFilesList(files) {
      return files.slice(0, 4);
    },
    getMoreNumber(files) {
      return files.length - 3;
    },
    getAttachmentType(resource) {
      if (resource.resource_type.includes('URL')) {
        return 'URL';
      }

      return resource.resource_type;
    },
    showOneAttachment(item) {
      return (item.files && item.files.length === 1) || item.event;
    },
    shortShowOneElement(item) {
      if (item.event) {
        return false;
      }
      return this.showOneAttachment(item) && this.getAttachmentType(item.files[0]) === 'IMG';
    },
    showMultipleAttachments(item) {
      return item.files && item.files.length > 1;
    },
    formatText(text) {
      let l = text.split(' ');
      l = l.map((e) => {
        if (e.startsWith('http'))
          e = `<a aria-label="This is a link based on the post" target="_blank" href=${e}>${e}</a>`;
        return e;
      });
      return l.join(' ');
    },
    getTimeAgo(dateString) {
      return timeAgo.format(new Date(dateString.replace(' ', 'T')));
    },
    switchView() {
      const feedEl = document.querySelector('.eu-feed-posts');
      const detailsEl = document.querySelector('.eu-feed-settings');
      detailsEl.classList.add('unHiddenEl');
      feedEl.classList.remove('unHiddenEl');
    },
    shortenURL(url: string) {
      return this.formatURL(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.details-page-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  padding: 2rem 0 4rem;

  .info-cards-container {
    padding: 0 2rem;
    max-width: 1464px;
    width: 100%;
    display: flex;
    gap: 1.5rem;
    justify-content: center;

    &.uneven {
      align-items: flex-start;
    }

    .card {
      background-color: #fff;
      border-radius: 24px;
      box-shadow: 0 0 10px 4px rgba(20, 20, 20, 0.04);
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
      width: 50%;

      &.large {
        width: 100%;
        max-width: unset;
      }

      .title {
        padding: 0;
        font-size: 1.25rem;
        line-height: 1.2;
      }

      .description {
        font-size: 1.125rem;
        line-height: 1.4;
        color: #494949;
      }

      .contact-content-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.375rem 0;

        .contact-item-container {
          display: flex;
          gap: 0.563rem;
          justify-content: flex-start;
          padding: 0.375rem 0;
          align-items: center;

          i {
            font-size: 1.125rem;
            line-height: 1;
            color: #838ea1;
          }

          .contact-text {
            color: #444444;
            font-size: 1rem;

            &.link {
              color: #2d98d2;
              font-weight: 600;
            }

            &.link:hover {
              color: #006ead;
            }
          }
        }
      }

      .contact-users-container {
        width: 100%;
        .contact-user-item-container {
          display: flex;
          gap: 0.75rem;
          align-items: center;

          .image {
            width: 3rem;
            height: 3rem;
            object-fit: cover;
            object-position: center;
            border-radius: 100px;
          }

          .text-container {
            display: flex;
            flex-direction: column;
            gap: 0.375rem;

            .name {
              color: #444444;
              font-size: 1rem;
              font-weight: 600;
              line-height: 1;
            }

            .title {
              font-size: 0.75rem;
              line-height: 1;
              color: #838ea1;
              font-weight: 600;
            }
          }

          &.separator-top {
            margin-top: 1rem;
            padding-top: 1rem;
            border-top: 1px solid #d8dce5;
          }
        }
      }

      .links-container {
        width: 100%;

        .link-item-container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .text-container {
            display: flex;
            flex-direction: column;
            gap: 0.375rem;

            .link {
              color: #494949;
              font-weight: 600;
              line-height: 1;
              font-size: 1rem;
            }

            .description {
              font-size: 0.75rem;
              line-height: 1;
              font-weight: 600;
              color: #838ea1;
              padding: 0;
            }
          }
          .proceed-button {
            border: none;
            background-color: transparent;
            color: #05c1a2;
            font-size: 1rem;
            line-height: 1.3;

            &:hover {
              cursor: pointer;
              color: #00a993;
            }
          }

          &.separator-top {
            margin-top: 1rem;
            padding-top: 1rem;
            border-top: 1px solid #d8dce5;
          }
        }
      }

      .separator {
        width: 100%;
        border-top: 0.063rem solid #d8dce5;
      }
      .social-networks-container {
        display: flex;
        gap: 1rem;
        align-items: center;

        .social-network-icon {
          font-size: 1.25rem;
          line-height: 1.05;

          .fa-facebook {
            color: #3b5998;
          }

          .fa-twitter {
            color: #00acee;
          }

          .fa-linkedin {
            color: #0e76a8;
          }

          .fa-instagram {
            color: #fb3958;
          }

          .fa-tiktok {
            color: #000000;
          }
        }
      }
    }
  }
  .video-card {
    width: 100%;
    max-width: 1464px;
    height: 27.5rem;
    padding: 0 2rem;

    iframe {
      height: 100%;
      width: 100%;
      border: none;
      border-radius: 24px;
    }
  }
}

.recent-posts-wrapper {
  width: 100%;
  background-color: #e6ebf0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.empty {
    gap: 1rem;
    .empty-text-bold {
      padding: 0;
      color: #444;
      font-size: 1.25rem;
      line-height: 1.2;
      font-weight: 600;
    }

    .empty-text {
      font-size: 1rem;
      line-height: 1.25;
    }
  }
}
.recent-posts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  width: 100%;
  max-width: 1464px;

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    i {
      color: #2d98d2;
      font-size: 1.5rem;
      line-height: 1;
    }

    .text {
      font-size: 2rem;
      line-height: 1.2;
      font-weight: 600;
      padding: 0;
    }
  }

  .post-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    justify-content: center;
    width: 100%;

    .card-wrapper {
      background-color: #fff;
      padding: 1rem;
      border-radius: 24px;
      width: 100%;
    }

    .card-content-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .author-container {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        img {
          width: 3rem;
          height: 3rem;
          border-radius: 100px;
          object-fit: cover;
          object-position: center;
        }

        .text-container {
          display: flex;
          flex-direction: column;
          gap: 0.375rem;
        }

        .name {
          font-size: 1rem;
          color: #444;
          font-weight: 600;
          line-height: 1;
        }

        .info-text {
          color: #838ea1;
          font-size: 0.75rem;
          line-height: 1;
          font-weight: 600;
        }
      }

      .post-text-container {
        height: 3.688rem;
        font-size: 1rem;
        line-height: 1.23;
        color: #494949;
      }

      .post-attachments-container {
        &.grid {
          display: grid;
          grid-template-columns: repeat(4, 3.5rem);
          gap: 0.5rem;

          .item {
            height: 3.5rem;
            width: 3.5rem;
            border-radius: 16px;
            border: 1px solid #c3cbd6;
            overflow: hidden;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              position: absolute;
              top: 0;
              left: 0;
            }

            .file-type-icon {
              background-color: #c3cbd6;
              color: #24a797;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            }

            .tint {
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.4);
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }

            &.more {
              color: #fff;
              font-size: 1.25rem;
              line-height: 1.2;
              font-weight: 600;
            }
          }
        }

        &.full-width {
          width: 100%;

          .item {
            background-color: #f2f5f7;
            border-radius: 16px;
            padding: 1rem;
            display: flex;
            align-items: center;
            gap: 0.75rem;

            .file-type-icon {
              color: #24a797;
              font-size: 1.5rem;
              line-height: 1;
            }

            &.action-icon {
              color: #2d98d2;
              font-size: 1.5rem;
              line-height: 1;
            }

            .text {
              font-size: 0.875rem;
              font-weight: 500;
              line-height: 1;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 951px) {
  .recent-posts-container {
    .post-cards-container {
      overflow: auto;
      justify-content: flex-start;
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .card-wrapper {
        width: 21.438rem;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .details-page-container {
    padding-top: 1.5rem;
    gap: 1.5rem;

    .info-cards-container {
      padding: 0;
      flex-direction: column;

      .card {
        width: 100%;
        border-radius: 0;

        .contact-users-container {
          .contact-user-item-container {
            .separator-top {
              margin-top: 0.75rem;
              padding-top: 0.75rem;
            }
          }
        }
      }
    }

    .video-card {
      height: 15rem;
      padding: 0;

      iframe {
        border-radius: 0;
      }
    }
  }
}
.title {
  &.text-clamp-1 {
    height: unset;
  }
}
</style>
