import Vue from 'vue';
import Cookies from 'js-cookie';
import SendBird from 'sendbird';
import SendBirdCall from 'sendbird-calls';
import { User } from '../types';
import PrivateChat from './components/PrivateChat.vue';
import PrivateLobby from './components/PrivateLobby.vue';
import CallUserModal from './components/modals/CallUserModal.vue';
import ReceiveCallModal from './components/modals/ReceiveCallModal.vue';
import MeetingsService from './services/MeetingsService';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { focusElement, focus_burger_menu } from '../utils/CustomUtils';
import ProfileNotificationModal from '../accounts/components/ProfileNotificationModal.vue';
import { parseLongDate, parseTime } from './utils/DateParser';
import { capitalizeFirstLetter } from './utils/common';

interface appData {
  meetingId: string;
  requesterId: string;
  mentorId: string;
  appId: string;
  meetingType: string;
  meeting: object;
}

// how often user connection status is checked, this uses the open connection to sendbird
const POLL_USERS_SECONDS = 3;

/*
    How often a check is sent to the server to show that users are still in a meeting, tracked from the mentors side.
    This should always be 60 seconds in production environments, can be shortened for testing locally .
*/
const TRACK_MEETING_TIME_SECONDS = 60;

export default class MeetingsChat {
  protected mountPoint: HTMLElement;
  protected service: MeetingsService;

  protected sendBird: SendBird.SendBirdInstance;

  protected user: User;
  protected nickname: string;
  protected isMentor: boolean;

  protected meetingId: string;
  protected meetingType: string;
  protected userIds: string[];
  protected otherUserId: string;
  protected selectedMeeting: object;

  protected isSBCallsInit: boolean;

  constructor(el: HTMLElement, userData: User, appData: appData) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.service = new MeetingsService();

    this.sendBird = new SendBird({ appId: appData.appId });

    try {
      SendBirdCall.init(appData.appId);
      SendBirdCall.setLoggerLevel(SendBirdCall.LoggerLevel.INFO);
      this.isSBCallsInit = true;
    } catch (error) {
      this.isSBCallsInit = false;
    }

    this.user = userData;
    this.nickname = `${this.user.firstName} ${this.user.lastName}`;

    this.meetingId = appData.meetingId;
    this.meetingType = appData.meetingType;
    this.userIds = [appData.mentorId, appData.requesterId];
    this.otherUserId = this.userIds.filter((e) => e !== this.user.id.toString(10))[0];
    this.selectedMeeting = appData.meeting;

    this.isMentor = userData.id.toString(10) === appData.mentorId;

    this.run();
  }

  async run(): Promise<void> {
    const _self = this;

    const chat = new Vue({
      components: { PrivateChat, PrivateLobby, CallUserModal, ReceiveCallModal, LoadingSpinner, ProfileNotificationModal },

      template: `
                <div class="meeting-container">
                    <!-- Chat section -->
                    <div v-if="meetingStatus === 'started'">
                        <private-chat
                            ref="chat"
                            :local-id="id"
                            :message-list="messageList"
                            :usernameOther="otherUserNickname"
                            :avatarOther="otherUserProfilePic"
                            :leftoverTime="leftoverTime"
                            @open-meeting-modal="openProfileNotificationModal"
                            @send-message="handleMessageSend"
                            @open-call-modal="openCallModal"
                            @end-meeting="requestEndMeeting"
                        />
                    </div>

                    <!-- Direct call section -->
                    <div v-else-if="meetingStatus === 'directCall'">
                        <div class="video-container">
                            <video
                                id="local_video_element"
                                autoplay
                                muted
                                playsinline
                                width="30%"
                                height="auto"
                                class="local-video"
                            ></video>
                            <img
                                id="local-avatar"
                                :src="profilePic"
                                alt="avatar"
                                class="local-avatar"
                            >

                            <div class="remote-video-container">
                                <span class="remaining-time-text" v-show="leftoverTime"></span>
                                <video
                                    id="remote_video_element"
                                    autoplay
                                    playsinline
                                    width="100%"
                                    height="auto"
                                    class="remote-video"
                                >
                                    Your browser does not support HTML5 video.
                                </video>

                                <i v-show="isRemoteMicEnabled === false" class="fas fa-volume-mute fa-2x muted-icon"></i>

                                <button
                                    v-if="isRemoteCameraEnabled"
                                    class="fullscreen-button"
                                    @click="toggleFullscreen"
                                >
                                    <i class="fas fa-expand-alt fa-2x"></i>
                                </button>

                                <img
                                    id="remote-avatar"
                                    :src="otherUserProfilePic"
                                    alt="remoteAvatar"
                                    class="remote-avatar"
                                >

                                <div id="v-btn-container" class="video-button-container">
                                    <button
                                        v-if="isCameraEnabled"
                                        class="video-button-active"
                                        @click="toggleVideo"
                                    >
                                        <i class="fas fa-video fa-lg"></i>
                                    </button>
                                    <button
                                        v-else
                                        class="video-button-inactive"
                                        @click="toggleVideo"
                                    >
                                        <i class="fas fa-video-slash fa-lg"></i>
                                    </button>

                                    <button
                                        v-if="isMicEnabled"
                                        class="video-button-active middle"
                                        @click="toggleMute"
                                    >
                                        <i class="fas fa-microphone-alt fa-lg"></i>
                                    </button>
                                    <button
                                        v-else
                                        class="video-button-inactive middle"
                                        @click="toggleMute"
                                    >
                                        <i class="fas fa-microphone-slash fa-lg"></i>
                                    </button>

                                    <button
                                        class="video-button-end-call"
                                        @click="endCall"
                                    >
                                        <i class="fas fa-phone-slash fa-lg"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="device-select-container">
                            <select
                                v-model="selectedAudioDevice"
                                class="eu-select"
                                @change="changeAudioDevice($event)"
                                v-if="audioDeviceOptions.length > 0"
                            >
                                <option
                                    v-for="option in audioDeviceOptions"
                                    :key="option.deviceId"
                                    :value="option.deviceId"
                                >
                                    {{ option.label }}
                                </option>
                            </select>
                            <select v-else class="eu-select" disabled>
                            <option selected value="null">No Audio Device</option>
                            </select>
                            <i class="fal fa-angle-down eu-select-arrow"></i>

                            <select
                                v-model="selectedVideoDevice"
                                class="eu-select"
                                @change="changeVideoDevice($event)"
                                v-if="videoDeviceOptions.length > 0"
                            >
                                <option
                                    v-for="option in videoDeviceOptions"
                                    :key="option.deviceId"
                                    :value="option.deviceId"
                                >
                                    {{ option.label }}
                                </option>
                            </select>
                            <select v-else class="eu-select" disabled>
                            <option selected value="null">No Video Device</option>
                            </select>
                            <i class="fal fa-angle-down eu-select-arrow"></i>
                        </div>
                    </div>

                    <div v-else-if="meetingStatus === 'ended'" class="meeting-status-display">
                        <h2 style="color: #333333;">The meeting has been ended.</h2>
                        <div>
                            <a
                                href="/meetings/"
                                class="iu-btn-solid big"
                                style="display:block;margin:0 auto;width:261px;margin-top:32px;"
                            >Return to MentorConnect</a>
                        </div>
                    </div>

                    <!-- Meeting lobby -->
                    <div v-else-if="meetingStatus === 'connected'">
                        <private-lobby
                            :isMentor="isMentor"
                            :usernameOther="otherUserNickname"
                            :avatarOther="otherUserProfilePic"
                            :isStudentOnline="isStudentOnline"
                            :isMentorOnline="isMentorOnline"
                            @start-meeting="startMeeting"
                        />
                    </div>

                    <div v-else-if="meetingStatus === 'connecting'" class="meeting-status-display">
                        <loading-spinner/>
                    </div>

                    <!-- Initiate call modal -->
                    <call-user-modal
                        :isModalActive="isCallUserModalActive"
                        :isCalling="isCalling"
                        :userName="otherUserNickname"
                        :profilePicture="otherUserProfilePic"
                        :isMicEnabled="isMicEnabled"
                        :isCameraEnabled="isCameraEnabled"
                        @close-call-user-modal="closeCallModal"
                        @call-user="callUser"
                        @cancel-call="cancelCall"
                        @video-toggled="localVideoToggle"
                        @audio-toggled="localAudioToggle"
                    />

                    <!-- Receiving call modal -->
                    <receive-call-modal
                        :isModalActive="isReceiveCallModalActive"
                        :userName="otherUserNickname"
                        :profilePicture="otherUserProfilePic"
                        :isMicEnabled="isMicEnabled"
                        :isCameraEnabled="isCameraEnabled"
                        @close-receive-call-modal="closeReceiveCallModal"
                        @accept-call="acceptCall"
                        @decline-call="declineCall"
                        @video-toggled="localVideoToggle"
                        @audio-toggled="localAudioToggle"
                    />

                    <profile-notification-modal
                    :isModalActive="isProfileNotificationModalActive"
                    :user="selectedMeeting.is_mentor ? selectedMeeting.requester_obj : selectedMeeting.chosen_mentor"
                    :department="selectedMeeting.is_mentor ? '' : selectedMeeting.department && selectedMeeting.department.name"
                    modal_title_id="mentor_connect_appointment_details_modal"
                    :meetingMsg="
                      !selectedMeeting.is_mentor && selectedMeeting.mentors_answer
                        ? { title: 'Message from mentor: ', text: selectedMeeting.mentors_answer }
                        : selectedMeeting.is_mentor && selectedMeeting.mentors_answer
                        ? { title: 'Message from student: ', text: selectedMeeting.description }
                        : { title: 'Meeting Description: ', text: selectedMeeting.description }
                    "
                    title="Appointment Details"
                    :cards="[
                      [
                        { title: 'Status: ', text: selectedMeeting.status },
                        { title: 'Canceled note:', text: selectedMeeting.cancelation_note ? selectedMeeting.cancelation_note : false },
                        { title: 'Date: ', text: parseLongDate(selectedMeeting.meeting_hours.date), bold: true },
                        { title: 'Time: ', text: parseTime({ startTime: selectedMeeting.meeting_hours.startTime, endTime: selectedMeeting.meeting_hours.endTime }) },
                        { title: 'Meeting Type: ', text: capitalizeFirstLetter(selectedMeeting.meeting_type) },
                        { title: 'Focus Area: ', text: selectedMeeting.focus_area ? selectedMeeting.focus_area.name : 'Any' },
                        { title: 'Department: ', text: selectedMeeting.department ? selectedMeeting.department.name : 'Any' }
                      ]
                    ]"
                    @onClose="closeProfileNotificationModal"
                  />
                </div>
            `,

      data() {
        return {
          service: _self.service,

          selectedMeeting: _self.selectedMeeting,

          meetingId: _self.meetingId,
          meetingStatus: 'connecting',
          meetingType: _self.meetingType,
          leftoverTime: null,
          scrollTimeout: 150,

          id: _self.user.id.toString(10),
          nickname: _self.nickname,
          profilePic: _self.user.profilePic,
          isMentor: _self.isMentor,
          sbUser: null,

          otherUserId: _self.otherUserId,
          otherUserNickname: '',
          otherUserProfilePic: null,

          channel: null,
          channelHandler: null,

          messageList: [],

          directCall: null,
          mediaAccess: null,

          selectedAudioDevice: null,
          audioDeviceOptions: [],
          selectedVideoDevice: null,
          videoDeviceOptions: [],

          isMicEnabled: true,
          isCameraEnabled: true,
          isRemoteMicEnabled: true,
          isRemoteCameraEnabled: true,

          polling: null,
          tracking: null,
          isMentorOnline: false,
          isStudentOnline: false,

          isFullscreen: false,
          isCallUserModalActive: false,
          isCalling: false,
          isReceiveCallModalActive: false,
          isProfileNotificationModalActive: false,
          error: null,
          callError: null,

          headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            Accept: 'application/json',
          },
        };
      },

      beforeDestroy: function () {
        if (this.polling) {
          clearInterval(this.polling);
        }
        if (this.tracking) {
          clearInterval(this.tracking);
        }
      },

      created: async function () {
        if (window.screen.availWidth < 992) {
          this.setupMobileLayout();
        }

        await this.connectUser();
        await this.initiateChatChannel();
        if (_self.isSBCallsInit) {
          await this.initiateVideoCallCapabilities();
        }
        await this.pollMemberStatus();

        this.meetingStatus = 'connected';
      },

      methods: {
        closeProfileNotificationModal() {
          this.isProfileNotificationModalActive = false;
          const sidebar: HTMLElement = document.querySelector('#sidebar');
          sidebar.style.pointerEvents = 'auto';
          focus_burger_menu(this.selectedMeeting, this.view);
        },
        openProfileNotificationModal() {
          this.isProfileNotificationModalActive = true;
          const sidebar: HTMLElement = document.querySelector('#sidebar');
          sidebar.style.pointerEvents = 'none';
          focusElement('mentor_connect_appointment_details_modal');
        },
        parseLongDate,
        parseTime,
        capitalizeFirstLetter,
        connectUser: async function () {
          const _vue = this;

          await _self.sendBird.connect(this.id, function (user, error) {
            if (error) {
              _vue.error = error;

              return;
            }

            _vue.sbUser = user;
          });

          await _self.sendBird.updateCurrentUserInfo(this.nickname, this.profilePic, function (response, error) {
            if (error) {
              _vue.error = error;
            }
          });
        },

        initiateChatChannel: async function () {
          const _vue = this;

          await _self.sendBird.GroupChannel.createChannelWithUserIds(
            _self.userIds, // mentor and mentee IDs
            true, // distinct, old messages are saved
            'Private Appointment',
            null, // sending null values for channel image, data,
            null, // and custom type to avoid using
            null, // deprecated channel creation methods

            function (groupChannel, error) {
              if (error) {
                _vue.error = error;

                return;
              }

              _vue.channel = groupChannel;

              _vue.channelHandler = new _self.sendBird.ChannelHandler();

              _vue.channelHandler.onMessageReceived = function (channel, message) {
                channel.refresh(function () {});

                _vue.messageList.push(message);

                setTimeout(_vue.$refs.chat.scrollPositionBottom, _vue.scrollTimeout);
              };

              _vue.channelHandler.onMetaDataCreated = function (channel, metaData) {
                if (metaData['meeting'] === 'start') {
                  _vue.enterMeeting(channel);
                }

                if ('leftoverTime' in metaData) {
                  _vue.leftoverTime = metaData['leftoverTime'];
                }
              };

              _vue.channelHandler.onMetaDataUpdated = function (channel, metaData) {
                if (metaData['meeting'] === 'start') {
                  _vue.enterMeeting(channel);
                }

                if ('leftoverTime' in metaData) {
                  _vue.leftoverTime = metaData['leftoverTime'];
                }
              };

              _vue.channelHandler.onMetaDataDeleted = function (channel) {
                channel.refresh(function () {});

                _vue.closeCallModal();
                _vue.closeReceiveCallModal();
                _vue.handleEndMeeting();
              };

              const UNIQUE_HANDLER_ID = `m: ${_self.userIds[0]}; s: ${_self.userIds[1]}`;

              _self.sendBird.addChannelHandler(UNIQUE_HANDLER_ID, _vue.channelHandler);

              const members = groupChannel.members;

              members.forEach((element) => {
                if (element.userId === _vue.otherUserId) {
                  _vue.otherUserNickname = element.nickname;

                  if (!element.plainProfileUrl || element.plainProfileUrl === 'None' || element.plainProfileUrl === '' || element.plainProfileUrl === null) {
                    _vue.otherUserProfilePic = '/static/assets/icons/default-profile.svg';
                  } else {
                    _vue.otherUserProfilePic = element.plainProfileUrl;
                  }
                }
              });
            }
          );
        },

        initiateVideoCallCapabilities: async function () {
          const _vue = this;
          const authOption = { userId: this.id };

          await SendBirdCall.authenticate(authOption, (result, error) => {
            if (error) {
              _vue.callError = error;
            }
          });

          await SendBirdCall.connectWebSocket();

          const UNIQUE_HANDLER_ID = this.id;

          SendBirdCall.addListener(UNIQUE_HANDLER_ID, {
            onRinging: (call) => {
              _vue.directCall = call;

              _vue.mediaAccess = SendBirdCall.useMedia({ audio: true, video: false });
              _vue.closeCallModal();
              _vue.openReceiveCallModal();

              call.onEstablished = (call) => {};

              call.onConnected = (call) => {
                _vue.onConnectSetup(call);
              };

              call.onEnded = (call) => {
                _vue.closeReceiveCallModal();

                if (_vue.mediaAccess) _vue.mediaAccess.dispose();

                if (_vue.meetingStatus === 'directCall') {
                  _vue.restoreChatHTML();
                }

                if (_vue.meetingType === 'chat') {
                  _vue.meetingStatus = 'started';
                } else if (_vue.meetingStatus === 'directCall') {
                  _vue.meetingStatus = 'connected';
                }
              };

              call.onRemoteVideoSettingsChanged = (call) => {
                _vue.remoteVideoToggle(call);
              };

              call.onRemoteAudioSettingsChanged = (call) => {
                _vue.remoteAudioToggle(call);
              };
            },

            onAudioInputDeviceChanged: (currentDevice, availableDevices) => {
              SendBirdCall.selectAudioInputDevice(currentDevice);
            },

            onAudioOutputDeviceChanged: (currentDevice, availableDevices) => {
              SendBirdCall.selectAudioOutputDevice(currentDevice);
            },

            onVideoInputDeviceChanged: (currentDevice, availableDevices) => {
              SendBirdCall.selectVideoInputDevice(currentDevice);
            },
          });
        },

        handleMessageSend: async function (message: string) {
          const _vue = this;

          this.channel.sendUserMessage(message, function (message, error) {
            if (error) {
              // special case when a message doesn't go through
            }

            _vue.messageList.push(message);
            setTimeout(_vue.$refs.chat.scrollPositionBottom, _vue.scrollTimeout);
          });
        },

        pollMemberStatus: function () {
          const _vue = this;

          // initial check to see if users are already present, for UX purposes
          this.checkMemberStatus();

          this.polling = setInterval(() => {
            _vue.channel.refresh(function () {});

            _vue.checkMemberStatus();
          }, POLL_USERS_SECONDS * 1000);

          if (this.isMentor) {
            this.tracking = setInterval(() => {
              _vue.trackMeetingDuration();
            }, TRACK_MEETING_TIME_SECONDS * 1000);
          }
        },

        // the order of ID's sometimes gets switched when creating a Sendbird channel,
        // this is why we need a specific check for both mentor and student
        checkMemberStatus: function () {
          const _vue = this;

          if (this.isMentor) {
            if (_vue.id === _vue.channel.members[0].userId) {
              _vue.isStudentOnline = 'online' === _vue.channel.members[1].connectionStatus;
            } else {
              _vue.isStudentOnline = 'online' === _vue.channel.members[0].connectionStatus;
            }

            if (_vue.isStudentOnline === false) {
              if (_vue.meetingStatus === 'directCall') {
                _vue.endCall();
                _vue.restoreChatHTML();
              }

              _vue.meetingStatus = 'connected';
            }
          } else {
            if (_vue.id === _vue.channel.members[0].userId) {
              _vue.isMentorOnline = 'online' === _vue.channel.members[1].connectionStatus;
            } else {
              _vue.isMentorOnline = 'online' === _vue.channel.members[0].connectionStatus;
            }

            if (_vue.isMentorOnline === false) {
              if (_vue.meetingStatus === 'directCall') {
                _vue.endCall();
                _vue.restoreChatHTML();
              }
              _vue.meetingStatus = 'connected';
            }
          }
        },

        trackMeetingDuration: async function () {
          if (this.meetingStatus === 'connected' || this.meetingStatus === 'ended') {
            return;
          }

          const response = this.service.trackMeeting({ meetingId: this.meetingId }, this.headers);
          const _vue = this;

          response.then((res) => {
            if (res['status'] === 'end') {
              _vue.requestEndMeeting();
            }

            if (res['status'] === 'ongoing') {
              _vue.leftoverTime = res['leftover_time'];

              _vue.channel.updateMetaData({ leftoverTime: _vue.leftoverTime }, true, function (response, error) {
                if (error) {
                  _vue.error = error;
                }
              });
            }
          });
        },

        startMeeting: function () {
          const _vue = this;

          if (this.meetingType === 'video') {
            this.openCallModal(true);
          }

          if (this.meetingType === 'chat') {
            this.channel.updateMetaData({ meeting: 'start' }, true, function (response, error) {
              if (error) {
                _vue.error = error;
              }
            });
          }
        },

        enterMeeting: function (channel) {
          channel.refresh(function () {});

          const members = channel.members;

          members.forEach((element) => {
            if (element.userId === this.otherUserId) {
              this.otherUserNickname = element.nickname;
              this.otherUserProfilePic = element.plainProfileUrl;
            }
          });

          this.meetingStatus = 'started';
        },

        requestEndMeeting: function () {
          const _vue = this;

          this.channel.deleteMetaData('meeting', function (response, error) {
            if (error) {
              _vue.error = error;
            }
          });

          this.handleEndMeeting();
        },

        handleEndMeeting: function () {
          this.service.endMeeting({ meetingId: this.meetingId }, this.headers);

          this.meetingStatus = 'ended';
        },

        callUser: function () {
          const _vue = this;

          this.isCalling = true;

          const dialParams = {
            userId: _vue.otherUserId,
            isVideoCall: true,
            callOption: {
              localMediaView: document.getElementById('local_video_element') as HTMLMediaElement,
              remoteMediaView: document.getElementById('remote_video_element') as HTMLMediaElement,
              audioEnabled: _vue.isMicEnabled,
              videoEnabled: _vue.isCameraEnabled,
            },
          };

          _vue.directCall = SendBirdCall.dial(dialParams, (call, error) => {
            this.audioDeviceOptions = SendBirdCall.getAvailableAudioInputDevices();
            this.videoDeviceOptions = SendBirdCall.getAvailableVideoInputDevices();
            const audioDevice = SendBirdCall.getCurrentAudioInputDevice();
            const videoDevice = SendBirdCall.getCurrentVideoInputDevice();
            this.selectedAudioDevice = audioDevice && audioDevice.deviceId;
            this.selectedVideoDevice = videoDevice && videoDevice.deviceId;
            if (error) {
              _vue.callError = error;
            }
          });

          _vue.directCall.onEstablished = (call) => {
            this.meetingStatus = 'directCall';
            _vue.closeCallModal();
            _vue.setupCallHTML();
            _vue.mediaAccess = SendBirdCall.useMedia({ audio: true, video: true });
          };

          _vue.directCall.onConnected = (call) => {
            _vue.onConnectSetup(call);
          };

          _vue.directCall.onEnded = (call) => {
            _vue.closeCallModal();

            if (_vue.mediaAccess) _vue.mediaAccess.dispose();

            if (_vue.meetingStatus === 'directCall') _vue.restoreChatHTML();

            if (_vue.meetingType === 'chat') {
              _vue.meetingStatus = 'started';
            } else if (_vue.meetingStatus === 'directCall') {
              _vue.endCall();
              _vue.meetingStatus = 'connected';
            }
          };

          _vue.directCall.onRemoteAudioSettingsChanged = (call) => {
            _vue.remoteAudioToggle(call);
          };

          _vue.directCall.onRemoteVideoSettingsChanged = (call) => {
            _vue.remoteVideoToggle(call);
          };
        },

        acceptCall: function () {
          const _vue = this;
          this.audioDeviceOptions = SendBirdCall.getAvailableAudioInputDevices();
          this.videoDeviceOptions = SendBirdCall.getAvailableVideoInputDevices();
          const audioDevice = SendBirdCall.getCurrentAudioInputDevice();
          const videoDevice = SendBirdCall.getCurrentVideoInputDevice();
          this.selectedAudioDevice = audioDevice && audioDevice.deviceId;
          this.selectedVideoDevice = videoDevice && videoDevice.deviceId;

          this.closeReceiveCallModal();
          this.meetingStatus = 'directCall';
          this.setupCallHTML();

          const acceptParams = {
            callOption: {
              localMediaView: document.getElementById('local_video_element') as HTMLMediaElement,
              remoteMediaView: document.getElementById('remote_video_element') as HTMLMediaElement,
              audioEnabled: _vue.isMicEnabled,
              videoEnabled: _vue.isCameraEnabled,
            },
          };

          this.directCall.accept(acceptParams);
        },

        declineCall: function () {
          this.endCall();

          this.closeReceiveCallModal();
        },

        endCall: function () {
          this.directCall.end();
        },

        cancelCall: function () {
          this.endCall();
          this.closeCallModal();

          if (this.meetingType === 'chat') {
            this.meetingStatus = 'started';
          } else {
            this.meetingStatus = 'connected';
          }
        },

        changeAudioDevice: function (event) {
          const device = this.audioDeviceOptions.find((dev) => {
            return dev.deviceId === event.target.value;
          });

          SendBirdCall.selectAudioInputDevice(device);
        },

        changeVideoDevice: function (event) {
          const device = this.videoDeviceOptions.find((dev) => {
            return dev.deviceId === event.target.value;
          });

          SendBirdCall.selectVideoInputDevice(device);
        },

        toggleMute: function () {
          if (this.directCall.isLocalAudioEnabled) {
            this.directCall.muteMicrophone();
            this.isMicEnabled = false;
          } else {
            this.directCall.unmuteMicrophone();
            this.isMicEnabled = true;
          }
        },

        toggleVideo: function () {
          if (this.directCall.isLocalVideoEnabled) {
            this.directCall.stopVideo();
            this.isCameraEnabled = false;

            if (window.innerWidth >= 992) {
              document.getElementById('local_video_element').style.display = 'none';
              document.getElementById('local-avatar').style.display = 'flex';
            }
          } else {
            this.directCall.startVideo();
            this.isCameraEnabled = true;

            if (window.innerWidth >= 992) {
              document.getElementById('local-avatar').style.display = 'none';
              document.getElementById('local_video_element').style.display = 'revert';
            }
          }
        },

        toggleFullscreen: function () {
          if (document.fullscreenElement) {
            this.exitFullscreen();

            return;
          }

          const element = document.getElementsByClassName('remote-video-container')[0];

          if (element.requestFullscreen) {
            element.requestFullscreen();

            this.isFullscreen = true;
          } else {
            alert("Your browser doesn't support fullscreen");
          }
        },

        exitFullscreen: function () {
          document.exitFullscreen();

          this.isFullscreen = false;
        },

        localVideoToggle: function (value: Boolean) {
          this.isCameraEnabled = value;
        },

        localAudioToggle: function (value: Boolean) {
          this.isMicEnabled = value;
        },

        remoteAudioToggle: function (call) {
          if (call.isRemoteAudioEnabled) {
            this.isRemoteMicEnabled = true;
          } else {
            this.isRemoteMicEnabled = false;
          }
        },

        remoteVideoToggle: function (call) {
          if (call.isRemoteVideoEnabled) {
            document.getElementById('remote_video_element').style.display = 'revert';
            document.getElementById('remote-avatar').style.display = 'none';

            this.isRemoteCameraEnabled = true;
          } else {
            if (document.fullscreenElement) {
              this.exitFullscreen();
            }

            document.getElementById('remote_video_element').style.display = 'none';
            document.getElementById('remote-avatar').style.display = 'flex';

            this.isRemoteCameraEnabled = false;
          }
        },

        openCallModal: function (isVideo) {
          this.isCameraEnabled = isVideo;
          this.isCallUserModalActive = true;
        },

        closeCallModal: function () {
          this.isCallUserModalActive = false;
          this.isCalling = false;
        },

        openReceiveCallModal: function () {
          this.isReceiveCallModalActive = true;
        },

        closeReceiveCallModal: function () {
          this.isReceiveCallModalActive = false;
        },

        onConnectSetup: function (call) {
          this.audioDeviceOptions = SendBirdCall.getAvailableAudioInputDevices();
          this.videoDeviceOptions = SendBirdCall.getAvailableVideoInputDevices();
          const audioDevice = SendBirdCall.getCurrentAudioInputDevice();
          const videoDevice = SendBirdCall.getCurrentVideoInputDevice();
          this.selectedAudioDevice = audioDevice && audioDevice.deviceId;
          this.selectedVideoDevice = videoDevice && videoDevice.deviceId;

          call.setLocalMediaView(document.getElementById('local_video_element') as HTMLMediaElement);
          call.setRemoteMediaView(document.getElementById('remote_video_element') as HTMLMediaElement);

          if (!call.isLocalVideoEnabled) {
            document.getElementById('local_video_element').style.display = 'none';
            document.getElementById('local-avatar').style.display = 'flex';
          }

          if (!call.isRemoteVideoEnabled) {
            document.getElementById('remote_video_element').style.display = 'none';
            document.getElementById('remote-avatar').style.display = 'flex';
          }

          this.isRemoteCameraEnabled = call.isRemoteVideoEnabled;
          this.isRemoteMicEnabled = call.isRemoteAudioEnabled;
        },

        setupCallHTML: function () {
          const appView = document.getElementsByClassName('da-app-view')[0] as HTMLElement;
          const sidebar = document.getElementById('sidebar');
          const wrapper = document.getElementById('content-wrapper');
          const meetingWrapper = document.getElementById('meeting-wrapper');
          wrapper.style.marginLeft = '0';
          meetingWrapper.style.marginLeft = '0';
          sidebar.style.display = 'none';
          appView.style.background = '#ffffff';
        },

        restoreChatHTML: function () {
          const appView = document.getElementsByClassName('da-app-view')[0] as HTMLElement;
          const sidebar = document.getElementById('sidebar');
          const wrapper = document.getElementById('content-wrapper');
          const meetingWrapper = document.getElementById('meeting-wrapper');
          wrapper.style.marginLeft = '210px';
          meetingWrapper.style.marginLeft = '30px';

          sidebar.style.display = 'revert';
          //appView.style.background = '#40456f';
        },

        setupMobileLayout: function () {
          const body = document.body;

          body.style.position = 'fixed';
          body.style.overflow = 'hidden';
        },
      },
    });

    chat.$mount(this.mountPoint);
  }
}
