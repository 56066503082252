<template>
  <div class="partner-tenant-dashboard-cta-card">
    <div class="content-container">
      <i class="fa-solid fa-sparkles icon"></i>
      <div class="text-container">
        <div class="title">Customize Your Recommendations</div>
        <div class="description">Update your profile to get personalized recommendations.</div>
      </div>
      <button @click="redirectTo('/settings/interests')" aria-label="Redirect to customize your interests page" class="dabi-main-secondary-button button-mobile-style">
        Get Started
        <i class="fa-regular fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'partner-tenant-dashboard-cta',
  methods: {
    redirectTo(url) {
      window.location.href = url;
    }
  }
}
</script>
