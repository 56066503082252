import Vue from 'vue';
import MultiselectComponent from './components/MultiselectComponent.vue';

export default class MultiselectInterface {

  protected mountPoint: HTMLElement;
  protected input: HTMLInputElement;
  protected options: Array<any>;
  protected preselected: Array<any>;
  protected limit: number;

  constructor(el: HTMLElement, input: HTMLInputElement, options: Array<any>, preselected: Array<any>, limit: number = null) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.input = input;
    this.options = options;
    this.preselected = preselected;
    this.limit = limit;

    this.run();
  }

  async run(): Promise<void> {
    const _class = this;

    const multiselect = new Vue({
      components: { MultiselectComponent },

      template: `
        <multiselect-component
          :input="input"
          :options="options"
          :preselected="preselected"
          :limit="limit"
        />
      `,

      data() {
        return {
          input: _class.input,
          options: _class.options,
          preselected: _class.preselected,
          limit: _class.limit
        };
      },
    });

    multiselect.$mount(this.mountPoint);
  }
}