<template>
    <Modal :isActiveProp="isModalActive" @close="closeModal">
        <h3 tabindex="0" role="heading" id="modal_manual_add_student" v-if="editMode">Edit User</h3>
        <h3 tabindex="0" role="heading" id="modal_manual_add_student" v-else>Manually Add User</h3>

        <div class="container">
            <span class="error" v-show="error">Please fill out every field and<br> use a valid email address.</span>

            <div class="eu-input-container">
                <input
                    id="first-name"
                    type="text"
                    class="eu-input"
                    required
                    v-model="firstName"
                >
                <div class="label-container">
                    <label for="first-name">First Name<span> *</span></label>
                </div>
            </div>

            <div class="eu-input-container">
                <input
                    id="last-name"
                    type="text"
                    class="eu-input"
                    required
                    v-model="lastName"
                >
                <div class="label-container">
                    <label for="last-name">Last Name<span> *</span></label>
                </div>
            </div>

            <div class="eu-input-container">
                <input
                    id="email"
                    type="email"
                    class="eu-input"
                    required
                    v-model="email"
                    :disabled="editMode"
                >
                <div class="label-container">
                    <label for="email">Email<span> *</span></label>
                </div>
            </div>

            <div v-if="editMode" class="eu-input-container">
                <select
                    id="role"
                    class="eu-input"
                    v-model="selectedDepartment"
                >
                    <option
                        v-for="dept in departments"
                        :key="dept.name"
                        :value="dept.name"
                    >
                        {{ dept.name }}
                    </option>
                </select>
                <div class="label-container">
                    <label for="role">Department</label>
                </div>
            </div>

            <div v-if="!editMode" class="eu-input-container">
                <select
                    id="role"
                    class="eu-input"
                    v-model="selectedRole"
                >
                    <option
                        v-for="role in roles"
                        :key="role.id"
                        :value="role"
                    >
                        {{ role.name }}
                    </option>
                </select>
                <div class="label-container">
                    <label for="role">Role</label>
                </div>
            </div>

            <div v-if="!editMode" class="checkbox-content-container">
                <label for="inviteCheckbox" class="eu-checkbox-container eu-event-collection-checkmark-label">
                Send a confirmation email
                    <input
                        type="checkbox"
                        v-model="sendInvite"
                        id="inviteCheckbox"
                        style="margin-right: 10px;"
                    >
                    <span class="eu-checkmark"></span>
                </label>
            </div>
            <div v-if="!editMode && sso_provider" class="checkbox-content-container">
                <label for="importAsSSO" class="eu-checkbox-container eu-event-collection-checkmark-label">
                Import as {{sso_provider}} account
                    <input
                        type="checkbox"
                        id="importAsSSO"
                        v-model="isSSO"
                        style="margin-right: 10px;"
                    >
                    <span class="eu-checkmark"></span>
                </label>
            </div>
             <div v-if="editMode && sso_provider" class="checkbox-content-container">
                <label for="isSSO" class="eu-checkbox-container eu-event-collection-checkmark-label" style="cursor: default">
                {{sso_provider}} SSO account
                    <input
                        id="isSSO"
                        disabled
                        type="checkbox"
                        v-model="is_sso"
                        style="margin-right: 10px;"
                    >
                    <span class="eu-checkmark"></span>
                </label>
            </div>
        </div>

        <div class="button-container">
            <button
                class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                @click="addStudent"
            >{{editMode ? 'Edit User' : 'Add User'}}</button>
            <button
                class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                @click="closeModal"
            >Cancel</button>
        </div>
    </Modal>
</template>
<script lang="ts">
import Modal from '../../../components/ModalExtended.vue';
import { focusElement, isValidEmailString } from '../../../utils/CustomUtils';

export default {
    name: 'manual-upload-modal',

    components: { Modal, },

    props: [
        'isModalActive',
        'editMode',
        'studentObject',
        'sso_provider',
        'roles',
        'departments',
    ],

    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',

            sendInvite: true,
            selectedRole: null,
            selectedDepartment: null,
            isSSO: false,
            error: false,
        }
    },

    watch: {
        studentObject: function() {
            if (this.studentObject) {
                this.firstName = this.studentObject.first_name;
                this.lastName = this.studentObject.last_name;
                this.email = this.studentObject.email;
                this.selectedRole = this.studentObject.role ? this.studentObject.role.name: null;
                this.selectedDepartment = this.studentObject.department;
                this.is_sso = this.studentObject.is_sso;
            }
        },

        // preselectedRole: function() {
        //   if (this.preselectedRole) {
        //     this.selectedRole = this.roles.find(element => element.name.toUpperCase() == this.preselectedRole.name.toUpperCase())
        //   }
        // }
    },

    methods: {
        addStudent() {
            this.checkInput();

            if (this.error) {
                return;
            }

            this.$emit(
                'add-manual',
                {
                    studentObject: {
                        first_name: this.firstName,
                        last_name: this.lastName,
                        email: this.email,
                        role: this.selectedRole.name,
                        department: this.selectedDepartment
                    },
                    sendInvite: this.sendInvite,
                    isSSO: this.isSSO,
                    is_edit_user: this.editMode,
                }
            )

            this.closeModal();
        },

        checkInput() {
            this.error = false;

            if (!this.firstName) {
                const fnEl = document.getElementById('first-name');
                fnEl.style.setProperty("border-color", "#E65722");

                this.error = true;
            } else {
                const fnEl = document.getElementById('first-name');
                fnEl.style.setProperty("border-color", "#949494");
            }

            if (!this.lastName) {
                const lnEl = document.getElementById('last-name');
                lnEl.style.setProperty("border-color", "#E65722");

                this.error = true;
            } else {
                const lnEl = document.getElementById('last-name');
                lnEl.style.setProperty("border-color", "#949494");
            }

            if (!this.validateEmail(this.email)) {
                const emEl = document.getElementById('email');
                emEl.style.setProperty("border-color", "#E65722");

                this.error = true;
            } else {
                const emEl = document.getElementById('email');
                emEl.style.setProperty("border-color", "#949494");
            }
        },

        validateEmail(email: string) {
            return isValidEmailString(email, 'accounts')
        },

        closeModal() {
            this.firstName = '';
            this.lastName = '';
            this.email = '';

            this.error = false;

            const fnEl = document.getElementById('first-name');
            fnEl.style.setProperty("border-color", "#949494");
            const lnEl = document.getElementById('last-name');
            lnEl.style.setProperty("border-color", "#949494");
            const emEl = document.getElementById('email');
            emEl.style.setProperty("border-color", "#949494");

            this.editMode ? focusElement(`more_option_managa_accounts_${this.studentObject.id}`) : focusElement("manual_add_manage_account");
            this.$emit('close-modal');
        },
    },
}
</script>
<style scoped>
h3 {
    align-self: flex-start;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 30px;
    color: #666666;
    font-size: 25px;
    font-weight: 500;
}

.container {
    height: 100%;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 50px;
    margin-right: 50px;
}

.eu-input-container {
    position: relative;
    margin-top: 21px;
    margin-bottom: 31px;
}

.eu-input-container.last {
    margin-bottom: 20px;
}

.eu-input {
    border: 3px solid #949494;
    padding-left: 10px;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    outline: none;
    color: #707070;
    font-size: 16px;
}

.eu-input:focus {
    border: solid 6px #d0c2ff;
}

 .label-container {
    position: absolute;
    bottom: 39px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    left: 8px;
    padding: 0 8px;
    -webkit-transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
    transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
    z-index: 2;
    width: 100%;
}

.label-container label {
    font-size: 13px;
    font-weight: 500;
    color:  #4c55a7;
    background: #ffffff;
    padding: 0px 6px 0px 10px;
    text-align: left;
}

.label-container span {
    font-weight: 600;
    color: #E65722;
    padding: revert;
}

.button-container {
    margin-bottom: 30px;
}

.checkbox-content-container {
    margin-left: auto;
    margin-right: auto;
    max-width: fit-content;
    margin-bottom: 21px;
    max-height: fit-content;
}

.error {
    color: #E65722;
}
</style>
