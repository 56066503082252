<template>
  <div class="eu-feed-posts">
    <div v-if="has_navbar" class="eu-simple-navbar" style="display: block">
      <span tabindex="0" class="eu-simple-navbar-item eu-simple-navbar-item-active hiddenEl"> Feed View </span>
      <span
        tabindex="0"
        class="eu-simple-navbar-item hiddenEl"
        @click="switchView"
        @keyup.enter="switchView"
        aria-label="Show details"
        role="button"
      >
        Details
      </span>
    </div>
    <div v-if="showCreatePost" class="eu-create-post-wrapper">
      <div class="eu-post-multipart-btns">
        <button
          class="da-components-button da-btn-solid eu-upload-btn feed-post-btn"
          :style="`background: ${primaryColor}; margin: 16px 0;`"
          @click="openPostModal"
          @keyup.enter="openPostModal"
          aria-label="Open post modal"
        >
          <i class="fas fa-comment-alt-lines feed-post-btn-icon"></i>
          New Text Post
        </button>
        <resources-upload
          v-if="
            !questName &&
            loggedUser.resource_library_permission &&
            loggedUser.resource_library_permission.has_library_resource_managing_access
          "
          :filterData="filterData"
          :hasIcon="true"
          :isPartnerTenant="!!partnerName"
          :currentPartner="partnerName"
          :btnClassName="isPartnerTenant ? 'feed-post-btn dabiBg full-w' : 'feed-post-btn'"
          :currentDepartment="departmentName"
          :currentGroup="groupName"
          componentContainerClass="full-w"
          @reloadResources="filesUploaded"
        />
        <!-- <button
          v-if="loggedUser.has_event_access"
          class="da-components-button da-btn-solid eu-upload-btn feed-post-btn"
          :style="`background: ${primaryColor}; margin: 16px 0;`"
          @click="redirectToEventsPage"
          @keyup.enter="redirectToEventsPage"
          aria-label="Add new event"
        >
          <i class="fad fa-calendar-star feed-post-btn-icon"></i>
          Add Event
        </button> -->
      </div>
    </div>
    <div
      v-if="!isLoadingPosts && (show_search || posts.length !== 0)"
      class="eu-search"
      style="margin: 32px 0px 20px 2px"
    >
      <form action="" @submit.prevent="searchFeed()" class="eu-search-form" role="search">
        <input
          v-model="searchInput"
          type="text"
          placeholder="Search your feed"
          :class="`eu-search-input js-global-search-input ${isPartnerTenant() ? 'dabi-searchbar' : ''}`"
        />
        <button
          aria-label="Search button for feed"
          class="eu-search-btn-icon"
          :style="isPartnerTenant() ? 'padding: 10px;' : ''"
        >
          <i class="eu-search-icon fas fa-search" :style="isPartnerTenant() ? `color: ${primaryColor}` : ``"></i>
        </button>
      </form>
    </div>

    <div v-if="isLoadingPosts">
      <loading-spinner wrapperClass="wrapper" />
    </div>

    <div class="mobile-full-w" v-else>
      <div class="eu-empty-container" :class="isPartnerTenant ? 'partner' : ''" v-if="posts.length === 0">
        <div class="eu-empty">
          <div class="eu-empty-icon-container" :class="isPartnerTenant ? 'partner' : ''">
            <i class="fa-duotone fa-message-dots eu-empty-icon" :class="isPartnerTenant ? 'partner' : ''"></i>
          </div>
          <div style="display: flex; flex-direction: column">
            <span class="iu-empty-text-bold">There aren’t any posts yet.</span>
            <span class="eu-empty-text">Check back later!</span>
          </div>
        </div>
      </div>
      <div v-for="p of posts" :key="p.id" class="eu-feed-post">
        <section class="feed-upper-section">
          <td
            tabindex="0"
            :id="`burger_menu_${p.id}`"
            ref="burgerPost"
            v-if="isAuthorOrAdmin(p)"
            class="eu-resource-cell-dots-icon"
            style="float: right; position: relative; bottom: 3px"
            @click="showBurger(p)"
            @keyup.enter="showBurger(p)"
            aria-label="Toggle burger menu"
            role="menu"
            aria-haspopup="true"
          >
            <i
              :class="`fas fa-ellipsis${isPartnerTenant() ? '' : '-v'}`"
              :style="`color:${secondaryColor};background: ${isPartnerTenant() ? 'transparent' : '#d0f9f9'}`"
            ></i>
          </td>
          <div class="relative-wrapper">
            <div
              ref="burgerPostDropdown"
              v-closable="{ exclude: ['burgerPost', 'burgerPostDropdown'], handler: 'closeBurgers' }"
              :id="'postBurger' + p.id"
              class="eu-resource-burger"
              style="width: 140px; right: 15px"
            >
              <span
                aria-label="Edit post"
                tabindex="0"
                @click="startUpdatePost(p)"
                @keyup.enter="startUpdatePost(p)"
                role="button"
                class="eu-resource-burger-el"
              >
                <i style="margin-right: 5px" class="fad fa-edit eu-feed-trash-icon"></i>
                Edit
              </span>
              <span
                aria-label="Open delete post modal"
                aria-haspopup="dialog"
                role="button"
                tabindex="0"
                @click="openRemovePostDialog(p)"
                @keyup.enter="openRemovePostDialog(p)"
                class="eu-resource-burger-el"
              >
                <i style="margin-right: 5px" class="fad fa-trash eu-feed-trash-icon"></i>
                Delete
              </span>
            </div>
          </div>
          <img
            alt="profile picture"
            class="eu-feed-thumbnail-bg"
            :style="`border-radius: ${avatarRadius}`"
            :src="p.profile_pic"
          />
          <span class="eu-feed-post-author"> {{ p.actor_name }} </span> <br />
          <span class="eu-feed-post-time text-clamp-1">
            <!-- <span v-if="p.actor_role">
            {{ p.actor_role + " • " }}
          </span> -->
            <span v-if="p.department || p.group || p.partner || p.quest">
              <router-link
                v-if="!p.quest && (isPartnerTenant || (!isPartnerTenant && areDeptsEnabled))"
                :aria-label="`Go to ${p.department ? p.department : p.group ? p.group : p.partner} page`"
                class="eu-feed-href"
                :style="`color: ${ternaryColor}`"
                :to="
                  p.department
                    ? `/departments/${p.department}`
                    : p.group
                    ? `/groups/${p.group}`
                    : `/partners/${p.partner}`
                "
              >
                {{ `${p.department ? p.department : p.group ? p.group : p.partner} • ` }}
              </router-link>
              <span :style="`color: ${ternaryColor}`" v-if="p.quest"> {{ p.quest }} • </span>
              {{ getTimeAgo(p.time) }}
            </span>
          </span>
          <span class="eu-feed-post-text" :id="`postText_${p.id}`" v-html="formatText(p.object.text)"> </span>
          <textarea
            :id="`postUpdateText_${p.id}`"
            class="eu-feed-textarea eu-feed-update-textarea"
            v-model="p.object.text"
            type="text"
            rows="4"
          />
          <div class="eu-feed-update-btns" :id="`postUpdateBtns_${p.id}`">
            <button
              aria-label="Update post"
              @click="updatePost(p)"
              @keyup.enter="updatePost(p)"
              class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
              :style="`background: ${primaryColor}`"
              style="font-size: 14px"
            >
              Update
            </button>
            <button
              aria-label="Cancel updating post"
              @click="cancelUpdatePost(p)"
              @keyup.enter="cancelUpdatePost(p)"
              class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
              style="font-size: 14px"
            >
              Cancel
            </button>
          </div>
        </section>
        <!-- multiple file items -->
        <div
          v-if="
            (p.files && p.files.length > 1) || (p.files && p.files.length === 1 && p.files[0].resource_type === 'DOC')
          "
          class="eu-feed-multiple-files"
        >
          <div
            tabindex="0"
            v-for="f of p.files"
            :key="f.id"
            class="eu-feed-file-wrapper"
            @click="viewItem(f)"
            @keyup.enter="viewItem(f)"
            aria-label="View item"
            role="button"
          >
            <div v-if="f.resource_type == 'IMG'" class="feed-file-wrapper">
              <span class="eu-feed-image-preview" :style="{ backgroundImage: 'url(' + f.resource_url + ')' }"></span>
              <span class="eu-feed-file-preview-text">{{ f.name }}</span>
            </div>
            <div v-if="f.resource_type == 'DOC'" class="feed-file-wrapper">
              <i
                class="fas fa-file-upload eu-feed-file-preview"
                :style="isPartnerTenant() ? `color: ${primaryColor}` : ``"
              ></i>
              <span class="eu-feed-file-preview-text">{{ f.name }}</span>
            </div>
          </div>
        </div>
        <!-- one image item -->
        <img
          v-if="p.files && p.files.length === 1 && p.files[0].resource_type === 'IMG'"
          class="eu-feed-image-frame"
          :src="p.files[0].resource_url"
          @click="viewItem(p.files[0])"
          @keyup.enter="viewItem(p.files[0])"
          aria-label="View image"
        />

        <!-- video item (yt) -->
        <iframe
          v-if="
            p.files &&
            p.files.length === 1 &&
            p.files[0].resource_type === 'VID' &&
            !p.files[0].resource_url.includes('tiktok')
          "
          class="eu-feed-video-frame"
          :src="getYoutubeOrVimeoEmbedLink(p.files[0].resource_url)"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        >
        </iframe>

        <!-- video tiktok -->
        <blockquote
          v-if="
            p.files &&
            p.files.length === 1 &&
            p.files[0].resource_type === 'VID' &&
            p.files[0].resource_url.includes('tiktok') &&
            isTikTokLoaded(p.files[0].resource_url.split('/').pop().split('?')[0]) &&
            tikTokLoaded[p.files[0].resource_url.split('/').pop().split('?')[0]]
          "
          class="tiktok-embed"
          :cite="p.files[0].resource_url"
          :data-video-id="getYoutubeOrVimeoEmbedLink(p.files[0].resource_url)"
          style="max-width: 605px; min-width: 325px; max-height: 735px; overflow: auto"
        >
          <section>
            <a target="_blank" :title="p.files[0].name" href="#">{{ p.files[0].name }}</a>
            <p></p>
            <a target="_blank" :title="p.files[0].description" href="#">{{ p.files[0].description }}</a>
          </section>
        </blockquote>

        <!-- url item -->
        <div class="eu-feed-multiple-files">
          <a
            aria-label="Go to resource url"
            class="eu-feed-file-wrapper"
            :href="p.files[0].resource_url"
            target="_blank"
            v-if="
              p.files &&
              p.files.length === 1 &&
              (p.files[0].resource_type === 'URL' ||
                p.files[0].resource_type === 'URL_INTERNAL' ||
                p.files[0].resource_type === 'URL_EXTERNAL')
            "
          >
            <div class="feed-file-wrapper">
              <i
                class="fas fa-external-link eu-feed-file-preview"
                :style="isPartnerTenant() ? `color: ${primaryColor}` : ``"
              ></i>
              <span class="eu-feed-file-preview-text">{{ p.files[0].name }}</span>
            </div>
          </a>
        </div>
        <!-- event item -->
        <!-- thumbnail -->
        <div v-if="p.event" class="eu-feed-event">
          <router-link
            v-if="p.event.thumbnail"
            :to="`/${p.event.slug}`"
            :aria-label="'Go to event ' + p.event.title"
            class="iu-event-image-wrapper"
          >
            <img class="iu-event-card-img" :src="p.event.thumbnail" />
          </router-link>
          <div class="iu-feed-event-details-container">
            <div class="iu-feed-event-details-text-section">
              <router-link :to="`/${p.event.slug}`" :aria-label="'Go to event ' + p.event.title">
                <h5 class="iu-feed-event-details-title">{{ p.event.title }}</h5>
              </router-link>
              <!-- <div v-if="p.event.start_date" class="iu-feed-event-details-date-wrapper">
                {{ p.event.start_date | dateFormat() }} <span style="color: #00a1b3">|</span>
                <span v-if="p.event.end_date">{{ p.event.end_date | dateFormat() }}</span>
                <span v-else>{{ p.event.start_time + " - " + p.event.end_time }}</span>
              </div> -->
              <div class="iu-feed-event-details-text-container">
                <p v-html="p.event.description"></p>
              </div>
            </div>
            <div class="iu-feed-event-details-location-section">
              <span v-if="p.event.is_online" class="iu-feed-event-details-location-text">
                <i class="eu-event-collection-icon fas fa-desktop"></i>
                Virtual
              </span>
              <span v-else class="iu-feed-event-details-location-text">
                <i class="eu-event-collection-icon fas fa-map-marker-alt"></i>
                {{ p.event.city }}, {{ p.event.state }}
              </span>
              <button
                class="iu-feed-event-details-save-button"
                :class="p.event.is_bookmarked ? 'active' : ''"
                @click="toggleSaveEvent(p.event)"
                @keyup.enter="toggleSaveEvent(p.event)"
                aria-label="Save event"
              >
                <i :class="p.event.is_bookmarked ? 'fa-solid fa-bookmark' : 'fa-regular fa-bookmark'"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="iu-feed-like-section">
          <button
            class="iu-feed-like-button"
            v-if="!p.own_reactions.like"
            @click="addReaction(p, REACTIONS.LIKE)"
            @keyup.enter="addReaction(p, REACTIONS.LIKE)"
            aria-label="Like button"
          >
            <i class="fal fa-heart fa-swap-opacity iu-feed-like-icon"></i>
            {{ p.reaction_counts.like ? p.reaction_counts.like : 'Like' }}
          </button>
          <button
            class="iu-feed-like-button iu-feed-like-button-active"
            v-if="p.own_reactions.like"
            @click="removeReaction(p, REACTIONS.LIKE, p)"
            @keyup.enter="removeReaction(p, REACTIONS.LIKE, p)"
            aria-label="Dislike button"
          >
            <i class="fas fa-heart fa-swap-opacity iu-feed-like-icon"></i>
            {{ p.reaction_counts.like }}
          </button>
          <span class="iu-feed-like-section-comment-count-wrapper">
            {{ p.comments.length ? p.comments.length + ' comment' + (p.comments.length == 1 ? '' : 's') : '' }}
          </span>
        </div>
        <div class="iu-feed-comment-section" v-if="p.comments.length != 0" :id="`feedComments_${p.id}`">
          <div v-for="(c, i) of p.comments" :key="c.id">
            <div v-if="i < 2 || !p.shrinkComments" class="iu-feed-comment-container">
              <img alt="Profile picture" class="iu-feed-comment-thumbnail" :src="c.profile_pic" />
              <div class="iu-feed-comment-text-container">
                <div class="iu-feed-comment-text">
                  <span class="iu-feed-comment-text-name">{{ c.actor_name }}</span>
                  <span :id="`commText_${c.id}`">{{ '  ' + c.data.text }}</span>
                  <textarea
                    :id="`commUpdateText_${c.id}`"
                    style="width: 100%"
                    class="eu-feed-textarea eu-feed-update-textarea-comment"
                    v-model="c.data.text"
                    type="text"
                    rows="3"
                  />
                  <div class="eu-feed-update-btns" :id="`commUpdateBtns_${c.id}`">
                    <button
                      @click="updateComment(c)"
                      @keyup.enter="updateComment(c)"
                      class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                      style="font-size: 12px"
                      :style="`background: ${primaryColor}`"
                      aria-label="Update comment"
                    >
                      Update
                    </button>
                    <button
                      @click="cancelUpdateComment(c)"
                      @keyup.enter="cancelUpdateComment(c)"
                      class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                      style="font-size: 12px"
                      aria-label="Cancel updating comment"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div class="iu-feed-comment-date">
                  {{ getTimeAgo(c.updated_at) }}
                </div>
              </div>
              <div
                tabindex="0"
                class="iu-feed-comment-actions-container"
                ref="comment_dropdown_button"
                @click="toggleCommentActionDropdown('comment_dropdown_' + c.id)"
                @keyup.enter="toggleCommentActionDropdown('comment_dropdown_' + c.id)"
                role="button"
                area-label="Open comment actions dropdown menu"
                v-if="hasWriteAccess(p)"
              >
                <i class="fa-solid fa-ellipsis-v"></i>
              </div>
              <div
                class="iu-feed-comment-actions-dropdown-container"
                v-if="hasWriteAccess(p)"
                :id="'comment_dropdown_' + c.id"
                ref="comment_dropdown_container"
                v-closable="{
                  exclude: ['comment_dropdown_button', 'comment_dropdown_container'],
                  handler: 'closeCommentActionDropdown',
                }"
              >
                <div
                  tabindex="0"
                  v-if="isAuthorOrAdmin(c)"
                  role="button"
                  @click="startUpdateComment(c)"
                  @keyup.enter="startUpdateComment(c)"
                  aria-label="Update comment"
                  class="iu-feed-comment-actions-dropdown-item"
                >
                  <i style="margin-left: 10px" class="fad fa-edit"></i>
                  Edit
                </div>
                <div
                  tabindex="0"
                  v-if="isAuthorOrAdmin(c)"
                  role="button"
                  @click="removeReaction(c, REACTIONS.COMMENT, p)"
                  @keyup.enter="removeReaction(c, REACTIONS.COMMENT, p)"
                  aria-label="Remove reaction"
                  class="iu-feed-comment-actions-dropdown-item"
                >
                  <i style="margin-left: 10px" class="fad fa-trash"></i>
                  Remove
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iu-feed-comment-show-more-section" v-if="p.shrinkComments === true">
          <span
            class="iu-feed-comment-show-more-button"
            aria-label="Show all comments"
            @click="showAllComments(p)"
            @keyup.enter="showAllComments(p)"
            tabindex="0"
            role="button"
          >
            View more comments
          </span>
        </div>
        <div v-if="hasWriteAccess(p)" class="iu-feed-new-comment-section">
          <img
            :src="loggedUser.profile_pic"
            alt="Logged user prfile picture"
            class="iu-feed-new-comment-thumbnail"
            :style="`border-radius: ${avatarRadius}`"
          />
          <textarea
            class="iu-feed-new-comment-textarea"
            v-model="p.newComment"
            placeholder="Write a comment"
            type="text"
            rows="1"
          />
          <button
            aria-label="Add new comment"
            @click="addReaction(p, REACTIONS.COMMENT)"
            @keyup.enter="addReaction(p, REACTIONS.COMMENT)"
            style="top: -20px"
            :style="`color: ${primaryColor}`"
            class="iu-feed-new-comment-post-button"
          >
            <i class="fa-solid fa-paper-plane-top"></i>
          </button>
        </div>
      </div>
      <div v-if="isLoadingScroll">
        <loading-spinner wrapperClass="wrapper" />
      </div>
    </div>
    <feed-modals
      :isDeleteModalActive="isDeleteModalActive"
      :isPostModalActive="isPostModalActive"
      :showFileDialog="showFileDialog"
      :loggedUser="loggedUser"
      :files="files"
      :isLoadingUpload="isLoadingUpload"
      :postText="postText"
      :filePostDelete="isFilePostSelected"
      @create-post="createPost"
      @update-post-text="updatePostText"
      @handle-file-upload="handleFileUpload"
      @create-post-check="createPostCheck"
      @close-post-modal="closePostModal"
      @close-delete-modal="closeDeleteModal"
      @remove-post="removePost"
      @download-item="downloadItem"
    />
    <resource-modal
      :isResourceModalActive="isResourceModalActive"
      :activeResource="activeResource"
      @close-resource-modal="closeResourceModal"
    />
  </div>
</template>
<script lang="ts">
import * as Cookies from 'js-cookie';
import FeedService from '../services/FeedService';
import ResourceLibraryService from '../../library/services/ResourceLibraryService';
import ResourcesUpload from '../../library/components/ResourcesUpload.vue';
import FeedModals from './FeedModals.vue';
import Modal from '../../components/ModalExtended.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import '../../events/utils/DateFormat';
import { validateExtensionFile } from '../../utils/TypecheckUtils';
import { focusElement, getYoutubeOrVimeoEmbedLink, loadTikTokScript, isPartnerTenant } from '../../utils/CustomUtils';
import handleOutsideClick from '../../directives/handleOutsideClick';
import EventsIndexService from '../../events/services/EventsIndexService';
import { isFeatureEnabled, LICENSE_ONLY_FEATURES } from '../../utils/PermissionUtils';
import ResourceModal from '../../library/components/modals/ResourceModal.vue';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');
const reactionTypes = { LIKE: 'LIKE', COMMENT: 'COMMENT' };

export default {
  components: { ResourcesUpload, Modal, LoadingSpinner, FeedModals, ResourceModal },
  directives: { closable: handleOutsideClick },
  name: 'feed-posts',
  props: [
    'isLoadingScroll',
    'isLoadingPosts',
    'showCreatePost',
    'departmentName',
    'dashboardPath',
    'show_search',
    'focusAreas',
    'loggedUser',
    'identities',
    'audiences',
    'has_navbar',
    'showNavbar',
    'groupName',
    'questName',
    'partnerName',
    'posts',
  ],
  data() {
    return {
      primaryColor: this.isPartnerTenant() ? '#2d98d2' : '#00a1b3',
      secondaryColor: this.isPartnerTenant() ? '#516075' : '#007D99',
      ternaryColor: this.isPartnerTenant() ? '#838ea1' : '#5e50b5',
      avatarRadius: this.isPartnerTenant() ? '50%' : '8px',
      postText: '',
      files: [],
      filterData: {
        focus_areas: this.focusAreas,
        departments: [],
        identities: this.identities,
        audiences: this.audiences,
      },
      REACTIONS: reactionTypes,
      activeResource: { name: null, resource_url: null, description: null },
      activePost: { id: null, department: null },
      loading: false,
      searchInput: '',
      showFileDialog: false,
      isResourceModalActive: false,
      isDeleteModalActive: false,
      isPostModalActive: false,
      isLoadingUpload: false,
      clickedPostText: '',
      lastOpenedBurger: null,
      lastOpenedCommentAction_id: null,
      currentPostId: null,
      isFilePostSelected: false,
      tikTokLoaded: {},
      areDeptsEnabled: false,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
    };
  },
  async created() {
    this.areDeptsEnabled = isFeatureEnabled(LICENSE_ONLY_FEATURES.DEPTS);
    this.setFeedBtnWidth();
    this.service = new FeedService();
    this.eventService = new EventsIndexService();
    this.resourceLibraryService = new ResourceLibraryService();
    this.loadTikTokScript('https://www.tiktok.com/embed.js').then((status) => {
      if (status === 'loaded') {
        this.showTikTokBlock = true;
      }
    });
  },
  methods: {
    isPartnerTenant,
    closeCommentActionDropdown() {
      if (this.lastOpenedCommentAction_id) {
        const actionDropdown = document.getElementById(this.lastOpenedCommentAction_id);
        actionDropdown['style']['display'] = 'none';
        this.lastOpenedCommentAction_id = null;
      }
    },
    toggleCommentActionDropdown(id) {
      if (!this.lastOpenedCommentAction_id) {
        const actionDropdown = document.getElementById(id);
        actionDropdown['style']['display'] = 'block';
        this.lastOpenedCommentAction_id = id;
      } else {
        this.closeCommentActionDropdown();
      }
    },
    async handleAddBookmark(event): Promise<void> {
      const payload = {
        target: event.id,
        action: 'add',
      };

      await this.eventService.postBookmark(payload, this.headers);

      event.is_bookmarked = true;
    },

    async handleRemoveBookmark(event): Promise<void> {
      const payload = {
        target: event.id,
        action: 'remove',
      };

      await this.eventService.postBookmark(payload, this.headers);

      event.is_bookmarked = false;
    },

    toggleSaveEvent(event) {
      if (event.is_bookmarked) {
        this.handleRemoveBookmark(event);
      } else {
        this.handleAddBookmark(event);
      }
    },

    loadTikTokScript,
    async isTikTokLoaded(videoId) {
      if (!this.tikTokLoaded[videoId]) {
        const status = await this.loadTikTokScript('https://www.tiktok.com/embed.js');
        if (status === 'loaded') {
          this.tikTokLoaded = { ...this.tikTokLoaded, [videoId]: true };
        }
      }
      return true;
    },
    setFeedBtnWidth() {
      let width = '33%';
      if (
        !this.loggedUser.has_event_access &&
        !this.loggedUser.resource_library_permission?.has_library_resource_managing_access
      ) {
        width = '98%';
      } else if (
        !this.loggedUser.has_event_access ||
        !this.loggedUser.resource_library_permission?.has_library_resource_managing_access
      ) {
        width = '48%';
      } else return;
      setTimeout(() => {
        const x = document.getElementsByClassName('feed-post-btn');
        for (let i = 0; i < x.length; i++) {
          x[i]['style'].width = width;
        }
      });
    },
    setActiveTab(tab) {
      sessionStorage.setItem('activeDashboardTab', tab);
    },
    async filesUploaded() {
      await this.getPosts(1);
    },
    isPostLiked(p) {
      let is_liked = false;
      if (p.own_reactions.like) {
        p.own_reactions.like.forEach((r) => {
          if (r.user_id.split('_')[0] === this.loggedUser.id) is_liked = true;
        });
        return is_liked;
      } else return is_liked;
    },
    showAllComments(post) {
      this.$emit('show-all-comments', post);
    },
    formatText(text) {
      let l = text.split(' ');
      l = l.map((e) => {
        if (e.startsWith('http'))
          e = `<a aria-label="This is a link based on the post" target="_blank" href=${e}>${e}</a>`;
        return e;
      });
      return l.join(' ');
    },
    updatePostText(text) {
      this.postText = text;
    },
    getPosts(page) {
      this.$emit('get-posts', page);
    },
    updatePostReaction(p) {
      this.$emit('update-post-reaction', p);
    },
    showNotification(notification) {
      this.$emit('show-notification', notification);
    },
    setIsLoading(isLoading) {
      this.$emit('set-is-loading', isLoading);
    },
    searchFeed() {
      this.$emit('search-feed', this.searchInput);
    },
    startUpdatePost(p) {
      this.closeBurgers();
      document.getElementById(`postBurger${p.id}`).classList.add('eu-resource-burger-hidden');
      const postTextEl = document.querySelector(`#postText_${p.id}`);
      const postUpdateTextEl = document.querySelector(`#postUpdateText_${p.id}`);
      const postUpdateBtnsEl = document.querySelector(`#postUpdateBtns_${p.id}`);
      postTextEl['style'].display = 'none';
      postUpdateTextEl['style'].display = 'block';
      postUpdateBtnsEl['style'].display = 'block';
      this.clickedPostText = p.object.text;
      focusElement(`postUpdateText_${p.id}`);
    },
    async updatePost(p) {
      document.getElementById(`postBurger${p.id}`).classList.remove('eu-resource-burger-hidden');
      const res = await this.service.updatePost(p.id, { post: p, authorId: this.getAuthorId(p) }, this.headers);
      if (res?.success) {
        this.showNotification({ msg: 'Post successfully updated.', icon: 'fad fa-check-circle' });
        this.cancelUpdatePost(p, true);
      } else {
        this.showNotification({ msg: 'Failed to update post', isError: true, icon: 'fad fa-exclamation-circle' });
      }
      focusElement('notification_title');
    },
    cancelUpdatePost(p, isTextUpdated: boolean) {
      document.getElementById(`postBurger${p.id}`).classList.remove('eu-resource-burger-hidden');
      const postTextEl = document.querySelector(`#postText_${p.id}`);
      const postUpdateTextEl = document.querySelector(`#postUpdateText_${p.id}`);
      const postUpdateBtnsEl = document.querySelector(`#postUpdateBtns_${p.id}`);
      postTextEl['style'].display = 'block';
      postUpdateTextEl['style'].display = 'none';
      postUpdateBtnsEl['style'].display = 'none';
      if (!isTextUpdated) {
        p.object.text = this.clickedPostText;
      }
      focusElement(`burger_menu_${p.id}`);
    },
    startUpdateComment(c) {
      const commTextEl = document.querySelector(`#commText_${c.id}`);
      const commUpdateTextEl = document.querySelector(`#commUpdateText_${c.id}`);
      const commUpdateBtnsEl = document.querySelector(`#commUpdateBtns_${c.id}`);
      commTextEl['style'].display = 'none';
      commUpdateTextEl['style'].display = 'block';
      commUpdateBtnsEl['style'].display = 'block';
      this.clickedCommentText = c.data.text;
    },
    async updateComment(c) {
      const res = await this.service.updateReaction(
        c.id,
        { text: c.data.text, authorId: this.getAuthorId(c) },
        this.headers
      );
      if (res?.success) {
        this.showNotification({ msg: 'Comment successfully updated.', icon: 'fad fa-check-circle' });
        this.cancelUpdateComment(c, true);
      } else {
        this.showNotification({ msg: 'Failed to update comment', isError: true, icon: 'fad fa-exclamation-circle' });
      }
    },
    cancelUpdateComment(c, isTextUpdated: boolean) {
      const commTextEl = document.querySelector(`#commText_${c.id}`);
      const commUpdateTextEl = document.querySelector(`#commUpdateText_${c.id}`);
      const commUpdateBtnsEl = document.querySelector(`#commUpdateBtns_${c.id}`);
      commTextEl['style'].display = 'unset';
      commUpdateTextEl['style'].display = 'none';
      commUpdateBtnsEl['style'].display = 'none';
      if (!isTextUpdated) {
        c.data.text = this.clickedCommentText;
      }
    },
    redirectToEventsPage() {
      sessionStorage.setItem('redirectTo', window.location.href);
      if (this.groupName) {
        sessionStorage.setItem('groupName', this.groupName);
      } else if (this.partnerName) {
        sessionStorage.setItem('partnerName', this.partnerName);
      } else if (this.questName) {
        sessionStorage.setItem('questName', this.questName);
      }

      location.href = this.groupName
        ? `/events/create?group=${encodeURIComponent(this.groupName)}`
        : this.partnerName
        ? `/events/create/?partner=${encodeURIComponent(this.partnerName)}`
        : '/events/create/';
    },
    handleFileUpload(event) {
      const uploadFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      const imageExt = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
      const docExt = ['txt', 'pdf', 'doc', 'docx', 'xlsx', 'ppt', 'pptx'];
      if (!uploadFiles.length) return;
      for (let i = 0; i < uploadFiles.length; i++) {
        this.error = '';
        let file = {
          resource_url: URL.createObjectURL(uploadFiles[i]),
          file: uploadFiles[i],
          name: uploadFiles[i].name,
          resource_type: null,
          department: '',
          status: 'pending',
          error: null,
        };
        if (validateExtensionFile(uploadFiles[i].name, imageExt)) {
          file.resource_type = 'IMG';
        } else if (validateExtensionFile(uploadFiles[i].name, docExt)) {
          file.resource_type = 'DOC';
        } else {
          this.error = 'Please use a supported image filetype.';
          return;
        }

        if (!this.files.some((e) => e.name === file.name)) {
          this.files.push(file);
        } else {
          this.error = `File with name '${file.name}' already exists.`;
          return;
        }
      }
    },
    showBurger(el) {
      const burgerEl = document.querySelector(`#postBurger${el.id}`);
      if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl)
        this.lastOpenedBurger.classList.remove('eu-resource-burger-active');
      burgerEl.classList.toggle('eu-resource-burger-active');
      setTimeout(() => {
        this.lastOpenedBurger = burgerEl;
      }, 500);
    },
    closeBurgers() {
      this.lastOpenedBurger && this.lastOpenedBurger.classList.remove('eu-resource-burger-active');
    },
    switchView() {
      const feedEl = document.querySelector('.eu-feed-posts');
      const detailsEl = document.querySelector('.eu-feed-settings');
      feedEl.classList.add('unHiddenEl');
      detailsEl.classList.remove('unHiddenEl');
    },
    async submitFiles(saveToRL): Promise<void> {
      const uploadedFiles = [];
      if (this.files.length == 0) {
        this.closePostModal();
      }
      this.files.forEach(async (file) => {
        if (file.name.length > 0) {
          file.status = 'uploading';
          if (this.currentDepartment) file.department = this.currentDepartment;
          let res;
          if (saveToRL) {
            file['file_type'] = file.resource_type;
            file['group'] = this.groupName ? this.groupName : '';
            file['quest'] = this.questName ? this.questName : '';
            file['partner'] = this.partnerName ? this.partnerName : '';
            res = await this.resourceLibraryService.createPostFromGroup(file, this.headers);
          } else {
            res = await this.service.uploadFile(file, this.headers);
          }
          if (res.success) {
            uploadedFiles.push(res.resource);
            file.status = 'uploaded';
            this.files = this.files.filter((e) => e.name !== file.name);
            if (this.files.length == 0) {
              this.loading = false;
              this.createPost(saveToRL, true, uploadedFiles);
            }
          } else {
            file.status = 'failed';
          }
        } else {
          this.showNotification({
            msg: `Upload failed for file ${file.name}: Required file details missing.`,
            isError: true,
            icon: 'fad fa-exclamation-circle',
          });
        }
      });
    },
    createPostCheck() {
      if (this.files.length > 0) {
        if (this.questName) {
          this.createPost(false, false);
        } else {
          this.showFileDialog = true;
        }
      } else this.createPost(false, true);
    },
    async createPost(saveToRL, createFinalPost, uploadedFiles) {
      if (this.loading) return;
      this.loading = true;
      if (this.postText || this.files.length > 0 || createFinalPost) {
        const reqObj = { text: this.postText };
        if (this.groupName) {
          reqObj['group'] = this.groupName;
        } else if (this.partnerName) {
          reqObj['partner'] = this.partnerName;
        } else if (this.questName) {
          reqObj['quest'] = this.questName;
        } else {
          reqObj['department'] = this.departmentName;
        }
        if (this.files.length > 0 && !createFinalPost) {
          this.isLoadingUpload = true;
          this.submitFiles(saveToRL);
          return;
        }
        if (createFinalPost) {
          if (uploadedFiles) {
            if (saveToRL) reqObj['files'] = uploadedFiles.map((e) => e.id);
            else reqObj['files'] = uploadedFiles;
          }
          let res = null;
          if (this.groupName) {
            res = await this.service.createGroupPost(reqObj, this.headers);
          } else if (this.partnerName) {
            res = await this.service.createPartnerPost(reqObj, this.headers);
          } else if (this.questName) {
            res = await this.service.createQuestPost(reqObj, this.headers);
          } else {
            res = await this.service.createPost(reqObj, this.headers);
          }
          if (res?.success) {
            this.closePostModal();
            this.isLoadingUpload = false;
            await this.getPosts(1);
            this.showNotification({ msg: 'New post created successfully!', icon: 'fad fa-check-circle' });
            this.postText = '';
          } else {
            this.showNotification({ msg: 'Failed to create post', isError: true, icon: 'fad fa-exclamation-circle' });
          }
        }
      }
      this.loading = false;
    },
    openPostModal() {
      this.isPostModalActive = true;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'none';
    },
    closePostModal() {
      this.isPostModalActive = false;
      this.postText = '';
      this.files = [];
      this.showFileDialog = false;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'block';
    },
    openRemovePostDialog(post) {
      this.activePost = post;
      this.currentPostId = post.id;
      this.isDeleteModalActive = true;
      if (post.files && post.files.length != 0) {
        this.isFilePostSelected = true;
      } else {
        this.isFilePostSelected = false;
      }
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'none';
      focusElement('remove_post_modal_title');
    },
    closeDeleteModal() {
      this.isDeleteModalActive = false;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'block';
      focusElement(`burger_menu_${this.currentPostId}`);
      this.activePost = { id: null, department: null };
    },
    async removePost(removeFileFromLibrary) {
      if (removeFileFromLibrary) {
        await Promise.all(
          this.activePost.files.map(async (file) => {
            this.resourceLibraryService.deleteResource(file.id, this.headers, this.activePost.group);
          })
        );
      }
      const res = await this.service.deletePost(
        this.activePost.id,
        {
          department: this.activePost.department,
          group: this.activePost.group,
          partner: this.activePost.partner,
          authorId: this.getAuthorId(this.activePost),
        },
        this.headers
      );
      if (res?.success) {
        this.setIsLoading(true);
        this.closeDeleteModal();
        this.showNotification({ msg: 'Post successfully removed.', icon: 'fad fa-trash' });
        await this.getPosts(1);
      } else {
        this.showNotification({ msg: 'Failed to remove post', isError: true, icon: 'fad fa-exclamation-circle' });
        focusElement('notification_title');
        return;
      }
      focusElement('notification_title');
    },
    getTimeAgo(dateString) {
      return timeAgo.format(new Date(dateString.replace(' ', 'T')));
    },
    closeResourceModal() {
      this.isResourceModalActive = false;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'block';
      this.activeResource = { name: null, resource_url: null, description: null };
    },
    viewItem(el) {
      this.activeResource = el;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'none';
      this.resourceLibraryService.increaseViews(el.id);
      this.isResourceModalActive = true;
    },
    async downloadItem(dataurl, filename, id) {
      this.resourceLibraryService.increaseDownloaded(id);
      const res = await fetch(dataurl);
      const blob = await res.blob();
      this.startDownload(blob, filename);
    },
    startDownload(content, filename) {
      var a = document.createElement('a');
      a.download = filename ? filename : 'resources';
      document.body.appendChild(a);
      a.href = URL.createObjectURL(content);
      a.style.display = 'none';
      a.click();
      document.body.removeChild(a);
    },
    isAuthorOrAdmin(item) {
      if (this.loggedUser.isAdmin) return true;
      const authorId = this.getAuthorId(item);
      if (authorId === this.loggedUser.id) return true;
      return false;
    },
    getReactionAuthorId(item) {
      // check ? like : comment
      return item.actor ? item.own_reactions.like[0].user_id.split('_')[0] : item.user_id.split('_')[0];
    },
    getAuthorId(item) {
      // check ? post : comment
      return item.actor ? item.actor.split('_')[0] : item.user_id.split('_')[0];
    },
    async addReaction(post, type) {
      const commentText = post.newComment ? post.newComment : null;
      if (type === this.REACTIONS.COMMENT && commentText === null) return;
      // loading, beside spinner, is used to make sure that action will fire only after last action is done (forbids us to eg. fast click "like" multiple times)
      if (!this.loading) {
        this.loading = true;
        const res = await this.service.addReaction({ post_id: post.id, text: commentText, type }, this.headers);
        if (res?.success) {
          if (res.post.comments.length > 2) {
            res.post.shrinkComments = true;
          }
          await this.updatePostReaction(res.post);
          this.loading = false;
        } else {
          this.loading = false;
          this.showNotification({ msg: 'Failed to add reaction', isError: true, icon: 'fad fa-exclamation-circle' });
        }
      }
    },
    async removeReaction(item, type, post) {
      let reaction_id;
      if (type === this.REACTIONS.LIKE)
        // item is a post
        reaction_id = item.own_reactions.like[0].id;
      else if (type === this.REACTIONS.COMMENT)
        // item is a comment
        reaction_id = item.id;
      this.lastOpenedCommentAction_id = null;

      const res = await this.service.removeReaction(
        reaction_id,
        { authorId: this.getReactionAuthorId(item), postId: post.id },
        this.headers
      );
      if (res?.success) {
        if (res.post.comments.length >= 2) {
          res.post.shrinkComments = true;
        }
        await this.updatePostReaction(res.post);
      } else {
        this.showNotification({ msg: 'Failed to remove reaction', isError: true, icon: 'fad fa-exclamation-circle' });
      }
    },
    hasWriteAccess(post) {
      if (post.quest) {
        return true;
      }
      if (post.group && this.loggedUser.userManageGroups) {
        return this.loggedUser.hasWriteAccess && this.loggedUser?.userManageGroups.includes(post.group);
      } else if (post.partner && this.loggedUser.partnerAdmins) {
        return this.loggedUser.hasWriteAccess && this.loggedUser?.partnerAdmins.includes(post.partner);
      } else {
        return this.loggedUser.hasWriteAccess && this.loggedUser.userDepts.includes(post.department);
      }
    },
    getYoutubeOrVimeoEmbedLink,
  },
};
</script>
<style lang="scss" scoped>
.eu-post-multipart-btns {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 12px;
}
.dabi-searchbar {
  background: #fff;
  border: solid 1px #c3cbd6;
  height: 2.5rem;
}
.feed-file-wrapper {
  position: relative;
  bottom: 6px;
}
@media only screen and (max-width: 576px) {
  .eu-post-multipart-btns {
    display: block;
  }
}
</style>
