<template>
  <section
    class="passport-section"
    :style="
      `cursor: ${loading ? 'wait' : 'default'};
        overflow: ${isFinalStep ? 'initial' : 'auto'}`
    "
  >
    <div class="eu-form-page-backlink">
      <a :href="dashboardLink">
        <i class="fas fa-arrow-left icon"></i>
        <span>Quit &amp; Go to Dashboard</span>
      </a>
    </div>
    <div class="passport-title">Welcome to Includifi!</div>

    <div class="passport-step-component">
      <div class="passport-step-completed" :style="`width: ${(100 / currentStep.steps.length) * currentStepNo}%`"></div>
      <div
        v-for="(step, i) of currentStep.steps"
        :key="`step${i}`"
        :class="`passport-pin ${step == StepTypes.COMPLETED ? 'completed' : step == StepTypes.UNANSWERED ? 'pending' : ''}`"
        :style="`margin-left: ${100 / currentStep.steps.length + (100 / currentStep.steps.length) * i - percentageOfPassportPin()}%`"
      >
        <i v-if="step == StepTypes.COMPLETED" class="fas fa-check passport-pin-icon"></i>
      </div>
      <div class="passport-step-desc">
        <div class="passport-step-desc-text">{{ currentStep.name }}</div>
        <div v-if="isFinalStep" class="passport-step-desc-step">Done</div>
        <div v-else class="passport-step-desc-step">Step {{ currentStepNo + 1 }}/{{ currentStep.steps.length }}</div>
      </div>
    </div>
    <passport-your-profile
      v-if="currentStep.name == StepNames.PROFILE"
      :nextStep = StepNames.BACKGROUND
      :profile="profile"
      :currentStepNo="currentStepNo"
      :dashboardLink="dashboardLink"
      :socialOptions="socialOptions"
      @is-badge-earned="isBadgeEarned"
      @submit-image="submitImage"
      @show-notification="showNotification"
      @toggle-share-socials="toggleShareSocials"
    />

    <passport-my-background
      v-if="currentStep.name == StepNames.BACKGROUND"
      :nextStep = StepNames.EDUCATION
      :passport="passport"
      :currentStepNo="currentStepNo"
      :dashboardLink="dashboardLink"
      @is-badge-earned="isBadgeEarned"
      @set-passport-prop="setPassportProp"
    />

    <passport-my-education
      v-if="currentStep.name == StepNames.EDUCATION"
      :nextStep = StepNames.CAREER
      :passport="passport"
      :currentStepNo="currentStepNo"
      :dashboardLink="dashboardLink"
      :subjects="subjects"
      :languages="languages"
      @is-badge-earned="isBadgeEarned"
      @set-passport-prop="setPassportProp"
    />
    <passport-my-career
      v-if="currentStep.name == StepNames.CAREER"
      :nextStep = StepNames.INTERESTS
      :passport="passport"
      :currentStepNo="currentStepNo"
      :career_list="career_list"
      :dashboardLink="dashboardLink"
      @is-badge-earned="isBadgeEarned"
      @set-passport-prop="setPassportProp"
    />

    <passport-my-interests
      v-if="currentStep.name == StepNames.INTERESTS"
      :passport="passport"
      :currentStepNo="currentStepNo"
      :focus_areas="focus_areas"
      :dashboardLink="dashboardLink"
      @is-badge-earned="isBadgeEarned"
      @set-passport-prop="setPassportProp"
    />

    <div v-if="!isFinalStep" class="passport-footer">
      <div class="passport-btn-wrapper">
        <button
          v-if="currentStepNo > 0"
          @click="previousStep"
          class="eu-btn-secondary-small btn-transparent-tertiary passport-prev-btn"
          :style="`cursor: ${loading ? 'wait' : 'pointer'}`"
        >
          Previous
        </button>
        <button @click="saveAndNext" class="eu-btn-secondary-small btn-solid-tertiary passport-next-btn" :style="`cursor: ${loading ? 'wait' : 'pointer'}`">
          Save/Next
        </button>
      </div>
    </div>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>
<script lang="ts">
import * as Cookies from 'js-cookie';
import PassportYourProfile from './PassportYourProfile.vue';
import PassportMyBackground from './PassportMyBackground.vue';
import PassportMyEducation from './PassportMyEducation.vue';
import PassportMyCareer from './PassportMyCareer.vue';
import PassportMyInterests from './PassportMyInterests.vue';
import PassportService from '../services/PassportService';
import Notification from '../../components/Notification.vue';
import { checkIsFormCompleted, checkIsFormValid, StepTypes, StepNames } from '../utils/StepUtils';

const passportSteps = [
  { name: StepNames.PROFILE, steps: [StepTypes.ACTIVE, StepTypes.UNANSWERED, StepTypes.UNANSWERED, StepTypes.UNANSWERED] },
  { name: StepNames.BACKGROUND, steps: [StepTypes.ACTIVE, StepTypes.UNANSWERED, StepTypes.UNANSWERED, StepTypes.UNANSWERED, StepTypes.UNANSWERED] },
  { name: StepNames.EDUCATION, steps: [StepTypes.ACTIVE, StepTypes.UNANSWERED, StepTypes.UNANSWERED, StepTypes.UNANSWERED] },
  { name: StepNames.CAREER, steps: [StepTypes.ACTIVE, StepTypes.UNANSWERED, StepTypes.UNANSWERED] },
  { name: StepNames.INTERESTS, steps: [StepTypes.ACTIVE] }
];

const urlParams = new URLSearchParams(window.location.search);
const stepParam = urlParams.get('step');

export default {
  components: { PassportYourProfile, PassportMyBackground, PassportMyEducation, PassportMyCareer, PassportMyInterests, Notification },
  name: 'passport-index',
  props: ['_profile', '_passport', 'dashboardLink', 'socialOptions', 'subjects', 'languages', 'focus_areas', 'career_list'],
  data() {
    return {
      StepTypes,
      StepNames,
      passport: { ...this._passport },
      profile: { ...this._profile },
      currentStep: passportSteps.find(e => e.name === stepParam) || passportSteps[0],
      currentStepNo: 0,
      isNotificationActive: false,
      loading: false,
      isFinalStep: false,
      notification: { msg: '', isError: false, icon: null },
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json'
      },
      screenSize: 0
    };
  },
  async created() {
    this.service = new PassportService();
    this.convertMultipleItemsToList();
    this.screenSize = screen.width;
    this.hideTopNavbar();
  },
  methods: {
    hideTopNavbar() {
      const top_navbar: HTMLElement = document.getElementById("io-top-nav");
      const menu_icon: HTMLElement = document.getElementById("mobile-open-nav");
      if (top_navbar) {
        top_navbar.style.display= "none";
      }
      if (menu_icon) {
        menu_icon.style.display = "none";
      }
    },
    async saveAndNext() {
      if (!this.loading) {
        const objectToUpdate = this.currentStep.name === StepNames.PROFILE ? this.profile : this.passport;
        if (this.currentStep.steps.length > this.currentStepNo && checkIsFormValid(this.currentStep.name, this.currentStepNo, objectToUpdate)) {
          this.loading = true;
          const updatedSuccessfully = this.currentStep.name === StepNames.PROFILE ? await this.updateProfile() : await this.updatePassport();
          this.loading = false;
          if (updatedSuccessfully) {
            this.closeNotification();
            this.currentStepNo += 1;
            if (this.currentStepNo === this.currentStep.steps.length) {
              this.isFinalStep = true;
            } else {
              this.currentStep.steps[this.currentStepNo] = StepTypes.ACTIVE;
            }

            if (checkIsFormCompleted(this.currentStep.name, this.currentStepNo - 1, objectToUpdate)) {
              this.currentStep.steps[this.currentStepNo - 1] = StepTypes.COMPLETED;
            } else this.currentStep.steps[this.currentStepNo - 1] = StepTypes.UNANSWERED;
          }
        } else {
          this.showNotification({ msg: 'Form is not valid. Please check your inputs.', isError: true, icon: 'fad fa-exclamation-circle' });
        }
      }
    },
    previousStep() {
      if (!this.loading) {
        const objectToUpdate = this.currentStep.name === StepNames.PROFILE ? this.profile : this.passport;

        this.currentStepNo -= 1;
        this.currentStep.steps[this.currentStepNo] = StepTypes.ACTIVE;

        const stepNo = this.isFinalStep ? this.currentStepNo : this.currentStepNo + 1;
        this.isFinalStep = false;
        if (checkIsFormCompleted(this.currentStep.name, stepNo, objectToUpdate)) {
          this.currentStep.steps[stepNo] = StepTypes.COMPLETED;
        } else this.currentStep.steps[stepNo] = StepTypes.UNANSWERED;
      }
    },
    async updateProfile() {
      const res = await this.service.updateProfile(this.profile.user.id, { ...this.profile, ...this.profile.user }, this.headers);
      if (res?.success) {
        this.profile.profile_pic_file = null;
        return true;
      } else {
        this.showNotification({ msg: res.message, isError: true, icon: 'fad fa-exclamation-circle' });
        return false;
      }
    },
    async updatePassport() {
      const mapped_interests = this.passport.user_interests.map(e => e.name)
      const res = await this.service.updatePassport(this.profile.user.id, {...this.passport, user_interests: mapped_interests}, this.headers);
      if (res?.success) {
        return true;
      } else {
        this.showNotification({ msg: res.message, isError: true, icon: 'fad fa-exclamation-circle' });
        return false;
      }
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    submitImage(img) {
      this.profile.profile_pic = URL.createObjectURL(img);
      this.profile.profile_pic_file = img;
    },
    toggleShareSocials() {
      if (!this.profile.dontShareSocials) {
        this.profile.website = '';
        this.profile.social_networks = null;
        this.profile = { ...this.profile, dontShareSocials: true };
      } else this.profile = { ...this.profile, dontShareSocials: false };
    },
    setPassportProp(propName, value) {
      this.passport[propName] = value;
      this.passport = { ...this.passport, [`${propName}_answered`]: true };
    },
    convertMultipleItemsToList() {
        this.passport.major_field_of_study =  this.passport.major_field_of_study?.split(', ');
        this.passport.minor_field_of_study =  this.passport.minor_field_of_study?.split(', ');
        this.passport.fluent_languages =  this.passport.fluent_languages?.split(', ');
    },
    isBadgeEarned() {
        if(this.currentStep.steps.every(e => e === StepTypes.COMPLETED)) {
            if(this.currentStep.name === StepNames.PROFILE) {
                this.profile.badge = true;
            }
            else this.passport.badge = true;
        }
    },
   percentageOfPassportPin(){
      if(screen.width <=320)  return 10;
      if (screen.width > 320 && screen.width <=375)  return 8;
      else if(screen.width > 375 && screen.width <=768 ) return 6;
      else if (screen.width > 768 && screen.width <=1024 ) return 4
      else if (screen.width > 1024) return 2
    }
  },
  computed: {}
};
</script>
