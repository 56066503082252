<template>
  <div class="resource-card" :class="isSelected ? 'selected': ''">
    <div class="resource-card-image-section" :style="cardImageUrl ? `background-image: url('${cardImageUrl}')` : ''">
      <div v-if="!cardImageUrl" class="resource-card-background-icon-container">
        <i class="resource-card-background-icon" :class="resourceBackgroundIcon"></i>
      </div>
      <span
        :class="isSelected ? 'selected': ''"
        class="iu-resource-grid-checkbox"
        tabindex="0"
        role="checkbox"
        style="position: relative;"
        @click="toggleResourceSelection($event)"
        @keyup.enter="toggleResourceSelection($event)"
        :aria-label='`Select ${resource.name}`'
      >
        <i class="fa-solid fa-check iu-resource-grid-check-icon" v-if="isSelected"></i>
      </span>
      <div class="resource-card-action-container">
        <dropdown-burger-menu
          :actionList="getActionList()"
          @view-item="emitEvent('view-item')"
          @edit-item="emitEvent('edit-item')"
          @unfeature-item="emitEvent('unfeature-item')"
          @feature-item="emitEvent('feature-item')"
          @download-item="emitEvent('download-item')"
          @remove-item-bookmark="emitEvent('remove-item-bookmark')"
          @bookmark-item="emitEvent('bookmark-item')"
          @copy-item-link="emitEvent('copy-item-link')"
          @unarchive-item="emitEvent('unarchive-item')"
          @archive-item="emitEvent('archive-item')"
          @delete-item="emitEvent('delete-item')"
        ></dropdown-burger-menu>
        <button
          class="bookmark-button"
          :class="isBookmarkActive ? 'active':''"
          :aria-label="(isBookmarkActive ? 'Remove': 'Add') + ' resource bookmark'"
          @click="toggleResourceBookmark()"
        >
          <i class="fa-bookmark" :class="bookmarkIcon"></i>
        </button>
      </div>
    </div>
    <div class="resource-card-text-section">
      <div class="resource-card-full-height-text">
        <div
          class="resource-card-title"
          aria-label="See the resource"
          tabindex="0"
          @click="$emit('view-item', resource.id)"
          @keyup.enter="$emit('view-item', resource.id)"
        >
          {{resource.name}}
        </div>
        <div class="resource-card-description">
          {{resource.description}}
        </div>
      </div>
      <div class="resource-card-bottom">
        <span class="resource-card-department-text" style="flex-shrink: 0;"><i :class="resourceTypeIcon"></i></span>
        <span v-if="hasDepartment" class="resource-card-department-text">Posted by {{resource.department_name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownBurgerMenu from '../../components/DropdownBurgerMenu.vue';

export default {
  //the component styles are developed for mobile sizes
  //if we are going to use it on desktop, we need some restyling
  name: 'resources-card',
  components: {
    DropdownBurgerMenu,
  },
  props: [
    'isSelected',
    'resource',
  ],
  computed: {
    hasDepartment() {
      return !!this.departmentName;
    },
    isDownloadable() {
      return this.resource.resource_type != 'VID'
              && this.resource.resource_type != 'URL'
              && this.resource.resource_type != 'URL_INTERNAL'
              && this.resource.resource_type != 'URL_EXTERNAL';
    },
    hasDepartment() {
      return !!this.resource.department_name;
    },
    cardImageUrl() {
      if (this.resource.resource_type === 'IMG') {
        return this.resource.image_source;
      }
      if (this.resource.thumbnail_url) {
        return this.resource.thumbnail_url;
      }
      return null;
    },
    resourceTypeIcon() {
      if (this.resource.resource_type == 'VID') return "fa-regular fa-file-video";
      if (this.resource.resource_type == 'URL') return "fa-regular fa-link";
      if (this.resource.resource_type == 'DOC') return "fa-regular fa-file";
      if (this.resource.resource_type == 'IMG') return "fa-regular fa-image";
      return "";
    },
    resourceBackgroundIcon() {
      if (this.resource.resource_type === 'DOC') return "fa-regular fa-file-alt iu-resource-grid-icon";
      if (this.resource.resource_type === 'VID') return "fa-regular fa-file-video iu-resource-grid-icon";
      if (this.resource.resource_type === 'URL') return "fa-regular fa-external-link iu-resource-grid-icon";
      if (this.resource.resource_type === 'URL_INTERNAL') return "fa-regular fa-file-alt fa-external-link iu-resource-grid-icon";
      if (this.resource.resource_type === 'URL_EXTERNAL') return "fa-regular fa-file-alt fa-external-link iu-resource-grid-icon";
    },
    bookmarkIcon() {
      return this.resource.is_bookmarked ? "fa-solid" : "fa-regular";
    },
    isBookmarkActive() {
      return this.resource.is_bookmarked;
    }
  },
  data() {
    return {
    }
  },
  methods: {
    emitEvent(event_name) {
      this.$emit(event_name);
    },
    toggleResourceSelection(event) {
      this.$emit('toggle-selection', event, this.resource);
    },
    toggleResourceBookmark() {
      if (this.resource.is_bookmarked) {
        this.emitEvent('bookmark-item');
      } else {
        this.emitEvent('remove-item-bookmark');
      }
    },
    getActionList() {
      let actionList = [];

      actionList.push({
        'label': 'View',
        'icon': 'fa-solid fa-eye',
        'action': 'view-item',
      });

      if (this.resource.has_permission) {
        actionList.push({
          'label': 'Edit',
          'icon': 'fa-solid fa-pen',
          'action': 'edit-item',
        });
      }

      if (this.resource.is_featured && this.resource.has_permission) {
        actionList.push({
          'label': 'Unfeature',
          'icon': 'fa-solid fa-stars',
          'action': 'unfeature-item',
        });
      } else if (this.resource.has_permission) {
        actionList.push({
          'label': 'Feature',
          'icon': 'fa-solid fa-stars',
          'action': 'feature-item',
        });
      }

      if (this.isDownloadable) {
        actionList.push({
          'label': 'Download',
          'icon': 'fa-solid fa-download',
          'action': 'download-item',
        });
      }

      if (this.isBookmarked) {
        actionList.push({
          'label': 'Unsave',
          'icon': 'fa-solid fa-bookmark',
          'action': 'remove-item-bookmark',
        });
      } else {
        actionList.push({
          'label': 'Save',
          'icon': 'fa-solid fa-bookmark',
          'action': 'bookmark-item',
        });
      }

      actionList.push({
        'label': 'Copy link',
        'icon': 'fa-solid fa-link',
        'action': 'copy-item-link',
        'additionalDescription': 'File accessible to members only',
      })

      if (this.resource.is_archived && this.resource.has_permission) {
        actionList.push({
          'label': 'Unarchive',
          'icon': 'fa-solid fa-archive',
          'action': 'unarchive-item',
        })
      } else if (this.resource.has_permission) {
        actionList.push({
          'label': 'Archive',
          'icon': 'fa-solid fa-archive',
          'action': 'archive-item',
        })
      }

      if (this.resource.has_permission) {
        actionList.push({
          'label': 'Delete',
          'icon': 'fa-solid fa-trash',
          'action': 'delete-item',
        })
      }

      return actionList;
    }
  },
}
</script>
