<template>
  <div class="partner-tenant-discover-partners-container">
    <div class="title-container">
      <h2 class="text">
        <i class="fa-regular fa-landmark-dome icon"></i>
        Discover Partners
      </h2>
      <router-link
        class="dabi-main-button-outlined see-all-button"
        aria-label="See all partners"
        to="/browse"
      >
        See all
      </router-link>
    </div>
    <div class="content-container">
      <button
        class="left-arrow arrow-button"
        aria-label="Show previous page"
        @click="showPreviousPage"
        @keyup.enter="showPreviousPage"
      >
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      <button
        class="right-arrow arrow-button"
        aria-label="Show next page"
        @click="showNextPage"
        @keyup.enter="showNextPage"
      >
        <i class="fa-solid fa-arrow-right"></i>
      </button>
      <div
        class="item"
        v-for="(partner, index) in paginate(partner_list, pageSize, activePage)"
        :key="index"
      >
        <img :src="partner.cover_pic" alt="Partner cover image" class="image">
        <div class="info">
          <div
            class="name"
            role="button"
            aria-label="Redirect to the partners page"
            @click="redirectToPartner(partner.name)"
            @keyup.enter="redirectToPartner(partner.name)"
            tabindex="0"
          >
            {{partner.name}}
          </div>
          <div class="followers">
            {{partner.follower_count}} followers
          </div>
        </div>
        <button
          class="follow-button"
          :aria-label="`${partner.is_following ? 'Stop following' : 'Follow'} partner ${partner.name}`"
          @click="toggleFollowing(partner)"
        >
          <i v-if="partner.is_following" class="fa-solid fa-circle-check icon"></i>
          {{ partner.is_following ? 'Following' : 'Follow'}}
        </button>
      </div>
    </div>
    <div class="actions-container">
      <slider-dots
        ref="recommendedPartnersSlider"
        :totalNo="getPagesCount(partner_list, pageSize)"
        :initialSelectedNo="1"
        @changeSelected="changeDisplayedPageHandler"
      />
      <router-link
        class="dabi-main-button-outlined see-all-button mobile"
        aria-label="See all partners"
        to="/browse"
      >
        See all
      </router-link>
    </div>
  </div>
</template>
<script>
import PartnerAccountService from '../../../partneraccounts/services/PartnerAccountService';
import FeedService from '../../../feed/services/FeedService';
import SliderDots from '../../../partners/components/landing_pages/components/SliderDots.vue';
import * as Cookies from 'js-cookie';

export default {
  name: 'partner-tenant-discover-partners',
  components: {
    SliderDots,
  },
  props: [

  ],
  data() {
    return {
      partnerAccountService: new PartnerAccountService(),
      feedService: new FeedService(),
      partner_list: [],
      activePage: 1,
      pageSize: 5,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json'
      },
    }
  },
  created() {
    this.partnerAccountService.getRecommendedPartners({ limit: 16 }).then(data => {
      this.partner_list = data;
      this.emitIfEmpty();
    });
    if (window.screen.width <= 576) {
      this.pageSize = 999;
    } else if (window.screen.width <= 1166) {
      this.pageSize = 4;
    }
  },
  methods: {
    emitIfEmpty() {
      if (!this.partner_list || this.partner_list.length === 0) {
        this.$emit('is-empty');
      }
    },
    redirectToPartner(partner_name) {
      this.$router.push({name: 'partner-details', params: { partner_name: encodeURIComponent(partner_name) }});
    },
    paginate(array, page_size, page_number) {
      return array.slice((page_number - 1) * page_size, page_number * page_size);
    },
    getPagesCount(array, page_size=5) {
      let result = Math.floor(array.length / page_size);
      if (array.length % page_size === 0) {
        return result;
      }
      return result + 1;
    },
    changeDisplayedPageHandler(pageNo) {
      this.changePage(pageNo);
    },
    changePage(pageNo) {
      this.activePage = pageNo;
    },
    showPreviousPage() {
      this.$refs.recommendedPartnersSlider.selectPrevious();
    },
    showNextPage() {
      this.$refs.recommendedPartnersSlider.selectNext();
    },
    async toggleFollowing(partner) {
      const res = await this.feedService.followPartnerFeed({ isFollowing: !partner.is_following, partner: partner.name }, this.headers);
      if (res?.success) {
        if(partner.is_following) {
          partner.follower_count -= 1;
        } else {
          partner.follower_count += 1;
        }
        partner.is_following = !partner.is_following;
      }
    }
  },
  computed: {}
}
</script>
