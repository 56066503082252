import HttpService from '../../services/HttpService';
import * as Cookies from 'js-cookie';

const _headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class FeedService extends HttpService {
  constructor() {
    super('/departments/api');
  }

  getPredataDepartment(dept_name) {
    this.endpoint = `/departments/api/predata/${dept_name}`;
    return this.get();
  }

  getAllDepartments(params): Promise<any> {
    this.endpoint = `/departments/api`;
    return this.get(params);
  }

  removeDepartment(id, body, headers): Promise<any> {
    this.endpoint = `/departments/api/${id}`;
    return this.delete(body, headers);
  }

  getAdminsAndMentors(name): Promise<any>{
    this.endpoint = `/departments/api/${name}/users`;
    return this.get();
  }

  getDeptFollowers(params): Promise<any> {
    this.endpoint = '/departments/api/followers';
    return this.get(params);
  }


}
