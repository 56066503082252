<template>
  <section tabindex="0" class="item-card">
    <div
      class="top-part"
      :style="`background-image: linear-gradient(to bottom, #000 4%, rgba(0, 0, 0, 0) 57%), url(${event.thumbnail});`"
    >
      <div class="toolbar">
        <div class="left">
          <div class="type-label">{{ event.type_of_program_management === 'event' ? ' Event' : 'Program' }}</div>
        </div>
        <div class="right">
          <i
            class="fa-bookmark"
            tabindex="0"
            :class="`${event.is_bookmarked ? 'active fas' : 'far'}`"
            :title="`${event.is_bookmarked ? 'Remove bookmark from event' : 'Bookmark event'}`"
            @click="handleBookmark()"
            @keyup.enter="handleBookmark()"
            aria-label="Toggle bookmark for event"
            role="button"
          ></i>
          <i
            class="fas fa-ellipsis-v"
            tabindex="0"
            ref="resListBurger"
            @click="showBurger()"
            @keyup.enter="showBurger()"
            aria-label="Burger menu"
            role="menu"
            aria-haspopup="menu"
          >
          </i>
          <div
            ref="resListBurgerDropdown"
            :id="`evProg${event.id}`"
            v-closable="{ exclude: ['resListBurgerDropdown', 'resListBurger'], handler: 'closeBurger' }"
            class="inup-profile-row-burger dashboard-burger-pos"
          >
            <router-link
              :to="`/${event.slug}`"
              tabindex="0"
              :aria-label="`Open ${event.title} event`"
              aria-haspopup="dialog"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-eye" style="padding-right: 5px; color: #88858e"></i>
              <span>View {{ event.type_of_program_management === 'event' ? ' Event' : 'Program' }}</span>
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="event.is_featured || event.is_featured_for_audience" class="featured">
        <i class="fas fa-stars"></i>
        FEATURED
      </div>
    </div>
    <div class="bottom-part">
      <p class="date">
        <span v-if="event.start_date">{{ event.start_date | shortDateFormat }}</span>
        <span v-if="event.end_date"> - {{ event.end_date | shortDateFormat }} </span>
        <span v-else-if="event.start_time && event.end_time">
          <span style="color: #22a5a0"> | </span>
          <span> {{ event.start_time }} - {{ event.end_time }} </span>
        </span>
        <span v-else-if="!event.start_date && event.deadline"> Deadline: {{ event.deadline | shortDateFormat }} </span>
      </p>
      <div class="content">
        <router-link :to="`/${event.slug}`" tabindex="0" class="title text-clamp-1">{{ event.title }}</router-link>
        <div class="desc text-clamp-3">
          {{ event.description }}
        </div>
        <div class="item-card-footer">
          <span v-if="event.is_hybrid" class="item-card-footer-wrapper">
            <i class="fas fa-desktop"></i>
            <p>Hybrid</p>
          </span>
          <span v-if="event.is_online" class="item-card-footer-wrapper">
            <i class="fas fa-desktop"></i>
            <p>Virtual</p>
          </span>
          <span v-else class="item-card-footer-wrapper">
            <i class="fas fa-map-marker-alt"></i>
            {{ event.city }}, {{ event.state }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import handleOutsideClick from '../../directives/handleOutsideClick';
import BookmarkService from '../../bookmarks/services/BookmarkService';
import Cookies from 'js-cookie';

export default {
  directives: { closable: handleOutsideClick },
  components: {},
  name: 'event-card',
  props: ['event'],
  data() {
    return {
      lastOpenedBurger: null,
      bookmarkService: new BookmarkService(),
      assetPath: process.env.MEDIA_URL,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
    };
  },
  async created() {},
  methods: {
    showBurger() {
      const burgerEl = document.querySelector(`#evProg${this.event.id}`);
      if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl) {
        this.lastOpenedBurger.classList.remove('eu-resource-burger-active');
      }
      burgerEl.classList.toggle('eu-resource-burger-active');
      this.lastOpenedBurger = burgerEl;
    },
    closeBurger() {
      this.lastOpenedBurger && this.lastOpenedBurger.classList.remove('eu-resource-burger-active');
    },
    async handleBookmark() {
      const action = this.event.is_bookmarked ? 'remove' : 'add';
      const payload = {
        target: this.event.id,
        action,
      };
      await this.bookmarkService.toggleBookmarkEvent(payload, this.headers);
      this.event.is_bookmarked = !this.event.is_bookmarked;
      this.toggleEventBookmark(this.event.id, this.listName);
    },
  },
  computed: {},
};
</script>
