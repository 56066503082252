var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{style:("width:100%")},[(_vm.isLoading)?_c('div',[_c('loading-spinner')],1):_vm._e(),_vm._v(" "),(!_vm.isLoading && !_vm.is_pie_chart && !_vm.is_column_chart && !_vm.is_buble_chart)?_c('line-series-chart',{attrs:{"items":_vm.items,"keys":_vm.keys}}):_vm._e(),_vm._v(" "),(!_vm.isLoading && _vm.is_pie_chart && !_vm.is_column_chart)?_c('pie-chart',{attrs:{"array":_vm.items,"colors":[
            '#5e50b5',
            '#9950cd',
            '#1bd4d4',
            '#00a1b3',
            '#f6764d',
            '#9f2a8c',
            '#6df0d2',
            '#1bd4d4',
            '#00a1b3',
            '#007d99',
            '#5e50b5',
            '#9950cd',
            '#d35fa5',
            '#ff997b',
            '#fac484',
            '#fae994'
        ]}}):_vm._e(),_vm._v(" "),(!_vm.isLoading && _vm.is_column_chart && !_vm.is_buble_chart)?_c('column-series-chart',{attrs:{"items":_vm.items}}):_vm._e(),_vm._v(" "),(!_vm.isLoading && _vm.is_buble_chart)?_c('circular-packing-bubble',{attrs:{"items":_vm.bubble_chart_items,"items_domain":_vm.items_domain}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }