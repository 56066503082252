<template>
  <div :id="valid_id" :class="`multiselect-filter-container${classicStyle ? '-classic' : ''}`" :style="additionalStyle">
    <multiselect
      v-model="value"
      :options="options"
      :name="valid_id"
      :multiple="false"
      :placeholder="placeholder"
      :max-height="maxHeight"
      :label="label"
      :track-by="trackBy"
      @search-change="searchChange"
      open-direction="bottom"
      :searchable="true"
      :internal-search="false"
      :show-no-results="true"
      :custom-labels="{
        listIsEmpty: 'No options available', // Replace this text with what you want
      }"
      :options-limit="300"
      @close="closeHandler"
      @open="changeAriaExpanded(true)"
    >
      <template slot="tag">{{ "" }}</template>
      <template slot="selection" slot-scope="{ values, search, isOpen }">
        <span class="multiselect__single" v-if="values.length && !isOpen">
          <span class="multiselect_text">
            <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; display: block">
              <span class="multiselect_number"> {{ values.length }} </span>
              {{ values.length > 1 ? filter_name : value[0].name }}
            </span>
          </span>
        </span>
        <i v-if="!classicStyle" class="fas fa-chevron-down multiselect-chevron"></i>
      </template>
      <span slot="noOptions">{{ noOptionsText }}</span>
      <span slot="noResult">No Results</span>
    </multiselect>
  </div>
</template>
<script lang="ts">
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect },

  name: "singleselect-filter-component",
  props: [
    "id",
    "options",
    "placeholder",
    "filter_name",
    "selected_status",
    "additionalStyle",
    "param_name",
    "maxHeight",
    "labelProp",
    "trackByProp",
    "noOptionsText",
    "classicStyle"
  ],

  data() {
    return {
      value: [],
      valid_id: null,
      label: "name",
      trackBy: "name",
    };
  },
  created() {
    this.value = this.selected_status;
    this.label = this.labelProp ? this.labelProp : this.label;
    this.trackBy = this.trackByProp ? this.trackByProp : this.trackBy;
  },
  async mounted() {
    if (!this.id) {
      this.valid_id = this.makeId(15);
    } else {
      this.valid_id = this.id;
    }

    setTimeout(() => {
      this.changeAriaExpanded(false);
    }, 500);
  },
  // watch: {
  //   options(newVal, oldVal) {
  //     if (newVal !== oldVal) {
  //       this.value = []; // Resetting the selected value
  //       // You might also need to reset other internal states here
  //     }
  //   },
  // },
  methods: {
    makeId(length) {
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    searchChange(q) {
      this.$emit("search-change", q);
    },

    filterData() {
      this.$emit("filter-data", this.value ? this.value : null);
    },

    changeAriaExpanded(ariaExpanded) {
      const multiselectComponent = document.querySelectorAll("#" + this.valid_id + " .multiselect")[0];
      multiselectComponent.setAttribute("aria-expanded", ariaExpanded ? ariaExpanded : "false");
    },

    closeHandler() {
      this.filterData();
      this.changeAriaExpanded(false);
    },
  },
};
</script>
