import Vue from 'vue';
import PartnerWithIncludifi from './PartnerWithIncludifi.vue';

export default class PartnerWithIncludifiView {
  protected mountPoint: HTMLElement;

  constructor(el: HTMLElement) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const partnerWithIncludifi = new Vue({
      components: {
        PartnerWithIncludifi
      },
      template: "<partner-with-includifi />"
    })
    partnerWithIncludifi.$mount(this.mountPoint);
  }
}
