import HttpService from '../../services/HttpService';
import * as Cookies from 'js-cookie';

const headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class QuestService extends HttpService {
  constructor() {
    super('/quests/api');
  }

  getFilterData(params): Promise<any> {
    this.endpoint = `/quests/api/filterData`;
    return this.get(params);
  }

  getAll(params): Promise<any> {
    this.endpoint = `/quests/api`;
    return this.get(params);
  }

  getRecommended(params): Promise<any> {
    this.endpoint = `/quests/api/recommended`;
    return this.get(params);
  }

  getOne(id): Promise<any> {
    this.endpoint = `/quests/api/${id}`;
    return this.get({});
  }

  add(item): Promise<any> {
    this.endpoint = `/quests/api`;
    return this.post(item, headers);
  }

  remove(id, body): Promise<any> {
    this.endpoint = `/quests/api/${id}`;
    return this.delete(body, headers);
  }

  update(body): Promise<any> {
    this.endpoint = `/quests/api/${body.id}`;
    return this.put(body, headers);
  }

  rate(body): Promise<any> {
    this.endpoint = `/quests/api/${body.quest}/rate`;
    return this.post(body, headers);
  }

  addTask(item): Promise<any> {
    this.endpoint = `/quests/api/tasks`;
    return this.post(item, headers);
  }

  getTasks(params): Promise<any> {
    this.endpoint = `/quests/api/tasks`;
    return this.get(params)
  }

  removeTask(id, body): Promise<any> {
    this.endpoint = `/quests/api/tasks/${id}`;
    return this.delete(body, headers);
  }

  updateTask(body): Promise<any> {
    this.endpoint = `/quests/api/tasks/${body.id}`;
    return this.put(body, headers);
  }

  updateTaskOrder(body): Promise<any> {
    this.endpoint = `/quests/api/updateTaskOrder`;
    return this.put(body, headers);
  }

  getTaskFilterData(params): Promise<any> {
    this.endpoint = `/quests/api/taskFilterData`;
    return this.get(params);
  }
  startQuest(body): Promise<any> {
    this.endpoint = `/quests/api/startQuest`;
    return this.post(body, headers);
  }
  finishTask(body): Promise<any> {
    this.endpoint = `/quests/api/finishTask/${body.id}`;
    return this.put(body, headers);
  }
  getQuestsCount() {
    this.endpoint = `/quests/api/count`;
    return this.get();
  }
}
