<template>
  <div class="container">
    <div class="content">
      <div class="text-content">
        <h3 class="title">
          {{ video.title || "Welcome!" }}
        </h3>
        <div v-if="video.description" class="desc">
          {{ video.description }}
        </div>
        <button
            aria-label="Go to onboarding"
            @click="continueToOnboarding()"
            @keyup.enter="continueToOnboarding()"
            class="iu-main-btn solid medium main onboarding-next-button welcome-btn"
          >
            Continue to Onboarding
            <i aria-hidden="true" class="fas fa-chevron-right iu-main-button-arrow-icon"></i>
          </button>
          <div tabindex="0" @click="openCloseModal()"
            @keyup.enter="openCloseModal()" class="quitBtn">Quit & Go to Dashboard </div>
      </div>
      <div v-if="getYoutubeOrVimeoEmbedLink(video.url)" class="video-content">
        <iframe
          id="welcome-video-frame"
          class="video-frame"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          :src="getYoutubeOrVimeoEmbedLink(video.url)"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { getYoutubeOrVimeoEmbedLink } from "../../../utils/CustomUtils";
export default {
  name: "welcome-page",
  props: ["video"],
  data() {
    return {
    };
  },

  created() {
  },
  methods: {
    getYoutubeOrVimeoEmbedLink,
    openCloseModal() {
      this.$emit('open-close-modal');
    },
    continueToOnboarding() {
        this.$emit('continue-to-onboarding')
    }
  },
};
</script>
<style lang="scss" scoped >

.container {
    background: white;
    width: 100%;
    position: relative;
}

.content {
    max-width: 1080px;
    height: calc(100vh - 70px);
    align-items: center;
    margin: 0 auto;
    gap: 32px;
    display: flex;
    justify-content: space-between;
}
.text-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 50%;

    .title {
        color: #333333;
        font-size: 40px;
        line-height: 1.2;
        font-weight: bold;
    }
    .desc {
        font-size: 20px;
        color: #444444;
        line-height: 1.5;
    }
}
.video-content {
    height: 330px;
    width: 50%;
}

.video-frame {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.quitBtn {
    color: #444444;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-top: -8px;
    &:hover {
        opacity: 0.7;
    }
}
.welcome-btn {
    width: fit-content;
}

.goToDashBtn {
  padding-right: 45px;
  cursor: pointer;
  color: #5e50b5;
  align-self: center;
  border: none;
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 1124px) {
    .content {
        flex-direction: column;
        justify-content: center;
    }
    .text-content, .video-content {
        width: 80%;
        text-align: center;
    }
    .welcome-btn {
    width: auto;
}

}
</style>
