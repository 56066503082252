<template>
    <div class="analytics-table-wrapper">
        <table class="eu-resource-table">
            <tr class="eu-resource-row"> 
                <th class="eu-resource-cell eu-resource-cell-th analytics-table-small-screen-cell-th">
                    <span> {{firstColumn}}</span>
                </th>
                
                <th v-for="d in otherColumns" v-bind:key="d" class="eu-resource-cell eu-resource-cell-th analytics-table-small-screen-cell-th">
                    <span> {{d}} </span>
                </th>
            </tr>
            <tr v-for="d in data" v-bind:key="d.id" class="eu-resource-row">
                <td class="eu-resource-cell">  
                    <span>{{d.name}}</span>
                <td class="eu-resource-cell"> 
                    <span>{{d.value}}</span>
                </td>
                <td class="eu-resource-cell"> 
                    <span>{{d.value2}}</span>
                </td><td class="eu-resource-cell"> 
                    <span>{{d.value3}}</span>
                </td><td class="eu-resource-cell"> 
                    <span>{{d.value4}}</span>
                </td>
            </tr>
        </table>
    </div>
 
</template>

<script>
export default {
    name: "analytics-card-table",
    props:['firstColumn', 'otherColumns','data'],
    data(){
        return{

        }
    },
}
</script>

<style scoped>
 

</style>