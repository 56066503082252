<template>
    <div class="tab-container">
        <ul class="wrapper-tab-items">
            <li 
                v-for="item in items" 
                :key="item.title" 
                @click="activeTab(item)"
                @keyup.enter="activeTab(item)"
                :aria-label="`Change tab at ${item.title}`"
                class="tab-item" 
                :class="item.is_active? 'active':''">  
                <p class="tab-item-title"> {{item.title}} </p>
                <p v-if="item.value>=0 || item.value">
                    <i :class="item.icon == `fa-duotone fa-users`? `fad ${item.icon}` :`far ${item.icon}`"> </i> 
                    <span class="tab-item-number">{{item.value}} </span>
                </p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "tab-container",
    props: ['items'],
    data(){
        return{
            currentActiveTab: 0,
        }
    },
    created(){
        for(let i of this.items) this.saveActiveTab(i);
    },
    methods:{
        activeTab(item){
            if(this.currentActiveTab !== item.title){
                let active = this.currentActiveTab;
                this.currentActiveTab = item.title;
                this.$emit('clicked-tab', active, item.title, item.keys);
            }
        },
        saveActiveTab(item){
            if(item.is_active) this.currentActiveTab = item.title;
        }
    }
}
</script>

<style>

</style>