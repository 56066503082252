<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <div class="manage-mentor-header-container header-container">
        <div class="meeting-header-container" style="margin-top: 0">
          <div class="mentor-connect-title-wrapper">
            <common-title title="MentorConnect" description="Import/export and manage mentors." />
          </div>
          <div style="margin-left: 8px">
            <user-import-interface
              :roles="[{ name: 'Mentor' }]"
              :isRoleChangeable="false"
              :departments="departments"
              :is_dept_lead="isDeptLead"
            />
          </div>
        </div>
        <div id="main" class="eu-simple-navbar">
          <router-link
            class="eu-simple-navbar-item eu-simple-navbar-item-active"
            aria-label="Go to manage mentors page"
            to="/meetings/manage"
          >
            Manage Mentors
          </router-link>
          <router-link
            class="eu-simple-navbar-item"
            aria-label="Go to my appointments page"
            to="/meetings"
            :style="mobile_screen_size ? `padding-bottom:0;` : ''"
          >
            My Appointments
          </router-link>
          <router-link
            v-if="isMentor"
            class="eu-simple-navbar-item"
            aria-label="Go to My Availability page"
            to="/meetings/calendar"
          >
            My Availability
          </router-link>
          <router-link
            class="eu-simple-navbar-item"
            aria-label="Go to Favorite Mentors page"
            to="/meetings?tab=favorites"
          >
            Favorite Mentors
          </router-link>
        </div>
      </div>
      <div class="content-container">
        <div class="eu-search" v-if="mentors.length > 0 || isSearching === true">
          <form style="margin-top: 50px" action="" class="eu-search-form" @submit.prevent="search()" role="search">
            <input
              v-model="searchInput"
              type="search"
              placeholder="Search for mentors"
              onfocus="this.placeholder = ''"
              onblur="this.placeholder = 'Search for mentors'"
              class="eu-search-input"
            />
            <button class="eu-search-btn-icon" aria-label="Search for mentors">
              <i class="eu-search-icon fas fa-search"></i>
            </button>
          </form>
        </div>

        <div v-if="isLoading">
          <loading-spinner wrapperClass="wrapper" />
        </div>

        <div v-else-if="mentors.length > 0">
          <!-- TODO -->
          <div class="eu-selected-toolbar" v-if="selected.length > 1">
            <span class="eu-selected-toolbar-text"> {{ selected.length }} users selected. </span>
            <span
              tabindex="0"
              @click="openRemoveModal()"
              @keyup.enter="openRemoveModal()"
              aria-label="open remove add mentor modal"
              role="button"
              aria-haspopup="dialog"
              class="eu-selected-toolbar-option"
            >
              <i class="fas fa-trash-alt eu-selected-toolbar-icon"></i>Delete
            </span>
            <span
              tabindex="0"
              @click="cancelSelection()"
              aria-label="cancel selection"
              @keyup.enter="cancelSelection()"
              class="eu-selected-toolbar-option"
              role="button"
            >
              <i class="fas fa-times eu-selected-toolbar-icon"></i>Cancel
            </span>
          </div>
          <!-- TODO -->
          <mentor-table
            :mentors="mentors"
            :sortBy="sortBy"
            :sortDirection="sortDirection"
            :selected="selected"
            :selectedAll="selectedAll"
            @sort="sort"
            @toggle-mentor="toggleMentor"
            @toggle-all="toggleAll"
            @remove-mentor="handleRemoveMentor"
          />
        </div>

        <div v-else class="no-users-container">
          <i class="fad fa-chalkboard-teacher large-icon"></i>
          <p v-if="isSearching">No results.</p>
          <p v-else>
            There are no mentors that you can manage. Bulk <br />
            import multiple mentors by uploading a <br />
            spreadsheet or manually add one at a time.
          </p>
        </div>
      </div>

      <Modal :isActiveProp="isRemoveModalActive" @close="closeRemoveModal">
        <div class="row" style="width: auto">
          <div v-if="mentorForRemoval" style="display: flex; flex-direction: row">
            <i class="fad fa-user-circle small-icon"></i>
            <h4 class="delete-modal-header">{{ mentorForRemoval.full_name }}</h4>
          </div>
          <p tabindex="0" id="modal_delete_user_text" class="delete-text" style="text-align: center">
            Are you sure you want to remove mentoring for <span v-if="mentorForRemoval">this user</span
            ><span v-else>these users</span> ? <br />
            They will be unnasigned their role and will need to be assigned another one <br />
            to access website features.
          </p>
        </div>

        <div style="margin-bottom: 30px">
          <button
            class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
            @click="removeMentors(mentorForRemoval ? [mentorForRemoval.id] : selected)"
          >
            Yes
          </button>

          <button
            class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
            @click="closeRemoveModal"
          >
            Cancel
          </button>
        </div>
      </Modal>

      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </div>
  </div>
</template>
<script lang="ts">
import Cookies from "js-cookie";
import MentorTable from "./MentorTable.vue";
import MentorService from "../../services/MentorService";
import Modal from "../../../components/ModalExtended.vue";
import Notification from "../../../components/Notification.vue";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import { focusElement } from "../../../utils/CustomUtils";
import MeetingsService from "../../services/MeetingsService";
import UserImportInterface from "../../../users/components/UserImportInterface.vue";
import CommonTitle from "../../../components/CommonTitle.vue";

export default {
  name: "manage-mentors-interface",

  components: { CommonTitle, UserImportInterface, MentorTable, Modal, Notification, LoadingSpinner },

  data() {
    return {
      isLoading: true,

      mentors: [],
      selected: [],
      selectedAll: false,

      searchInput: "",
      isSearching: false,

      sortBy: "first_name",
      sortDirection: "up",

      mentor: null,
      mentorForEdit: null,
      editMode: false,
      mentorForRemoval: null,

      isRemoveModalActive: false,

      error: "",

      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },

      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      meetingsService: new MeetingsService(),
      departments: [],
      isDeptLead: null,
      isMentor: null,
    };
  },

  async created() {
    await this.loadInitialData();
    this.mentorService = new MentorService();
    this.fetchMentors();
  },
  computed: {
    mobile_screen_size() {
      return screen.width <= 860;
    },
  },

  methods: {
    async loadInitialData() {
      const res = await this.meetingsService.getMentorsPredata();
      this.departments = JSON.parse(res.departments);
      this.isDeptLead = res.is_dept_lead;
      this.isMentor = res.is_mentor;
    },
    async fetchMentors() {
      this.isLoading = true;
      this.selected = [];
      this.selectedAll = false;

      if (this.searchInput === "") {
        this.isSearching = false;
      }

      const result = await this.mentorService.getMentors({
        q: this.searchInput,
        sort_by: this.sortBy,
        sort_direction: this.sortDirection,
      });

      this.mentors = result["mentors"];
      this.isLoading = false;
    },

    search() {
      this.isSearching = true;
      this.fetchMentors();
    },

    sort(column: string) {
      if (this.sortBy === column) {
        this.sortDirection = this.sortDirection === "up" ? "down" : "up";
      } else {
        this.sortBy = column;
        this.sortDirection = "up";
      }

      this.fetchMentors();
    },

    toggleMentor(id: number) {
      const index = this.selected.indexOf(id);

      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(id);
      }
    },

    toggleAll() {
      this.selectedAll = !this.selectedAll;

      if (this.selectedAll) {
        this.selected = this.mentors.map((x) => x.id);
      } else {
        this.selected = [];
      }
    },

    handleRemoveMentor(mentor) {
      this.mentorForRemoval = mentor;
      this.openRemoveModal();
      focusElement("modal_delete_user_text");
    },

    async removeMentors(mentorIDList: Array<number>) {
      const result = await this.mentorService.delete(
        {
          ids: mentorIDList,
        },
        this.headers
      );

      if (result.success) {
        this.showNotification({
          msg: result.success,
          isError: false,
          icon: "fad fa-check-circle",
        });
      } else {
        this.showNotification({
          msg: result.message,
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
      this.closeRemoveModal();
      this.fetchMentors();
      focusElement("notification_title");
    },

    cancelSelection() {
      this.selectedAll = false;
      this.selected = [];
    },

    openRemoveModal() {
      this.isRemoveModalActive = true;
    },

    closeRemoveModal() {
      focusElement(`mentor_connect_manage_table_${this.mentorForRemoval.id}`);
      this.mentorForRemoval = null;
      this.isRemoveModalActive = false;
    },

    showNotification(notification: { msg: string; isError: boolean; icon: string }) {
      this.notification = notification;
      this.isNotificationActive = true;
    },

    closeNotification() {
      this.isNotificationActive = false;
    },
  },
};
</script>
<style scoped>
.mentor-connect-title-wrapper {
  padding: 24px 0;
}
.no-users-container {
  text-align: center;
  margin-top: 20vh;
}

.no-users-container p {
  font-size: 18px;
  line-height: 1.67;
  color: #555555;
}

.large-icon {
  font-size: 220px;
  opacity: 0.6;
  color: #d0c2ff;
}

.small-icon {
  font-size: 40px;
  opacity: 0.6;
  color: #d0c2ff;
}
.manage-mentors-section {
  padding: 24px 32px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.title {
  margin: 0;
}
.header-container {
  width: 100%;
  max-width: 1400px;
}

.content-container {
  width: 100%;
  max-width: 1400px;
}

@media screen and (max-width: 576px) {
  .mentor-connect-title-wrapper {
    padding-bottom: 0;
  }
  .manage-mentors-section {
    padding: 24px 16px 16px;
  }
  .content-container {
    padding: 0 !important;
  }
}
</style>
