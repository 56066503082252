<template>
  <section>
    <div class="table-container">
      <table class="eu-resource-table">
        <tr class="eu-resource-row">
          <th scope="col" class="eu-resource-cell eu-resource-cell-th">Appointment</th>
          <th scope="col" class="eu-resource-cell eu-resource-cell-th">Status</th>
          <th scope="col" class="eu-resource-cell eu-resource-cell-th">Person</th>
          <th v-if="!isPartnerTenant" scope="col" class="eu-resource-cell eu-resource-cell-th">Department</th>
          <th></th>
        </tr>
        <tr v-for="(item, i) in meetings" v-bind:key="i" v-show="item.status !== `archived`" class="eu-resource-row">
          <td v-if="item.status != 'canceled'" class="eu-resource-cell" :class="getDateCellClass(item.status)">
            <span class="bold-heading" :class="getDateCellClass(item.status)">
              {{ item.meeting_hours.date | parseLongDate }}
            </span>
            <br />
            {{ item.meeting_hours | parseTime }}
          </td>
          <td v-if="item.status == 'canceled'" class="eu-resource-cell" :class="getDateCellClass(item.status)">
            <span class="bold-heading" :class="getDateCellClass(item.status)">
              {{ item.meeting_hours.date ? item.meeting_hours.date : item.cancelled_start_time | parseLongDate }}
            </span>
            <br />
            {{ { startTime: item.cancelled_start_time, endTime: item.cancelled_end_time } | parseTime }}
          </td>

          <td v-if="getStatusText(item.status) === 'Active'" class="eu-resource-cell">
            <a
              v-if="item.meeting_type != 'inperson'"
              target="_blank"
              :href="hasVideoChat() ? `/meetings/${item.meeting_type}/${item.id}` : item.meeting_url"
            >
              <button class="da-components-button da-btn-solid eu-meetings-modal-btn enup-demo-button">
                Go to meeting
                <i class="fas fa-chevron-right right-chevron-btn" aria-hidden="true"></i>
              </button>
            </a>
            <button v-else class="da-components-button da-btn-solid eu-meetings-modal-btn" style="pointer-events: none; background: #685df3">
              In-person meeting
            </button>
          </td>
          <td v-if="getStatusText(item.status) !== 'Active'" class="eu-resource-cell">
            <span class="meeting-status" :class="item.status">
              <i :class="icons[item.status]"></i>
              {{ getStatusText(item.status) }}
            </span>
          </td>

          <td class="eu-resource-cell eu-resource-cell-name">
            {{ item.is_mentor ? item.requester : item.chosen_mentor_fullname }}
          </td>

          <td v-if="!isPartnerTenant" class="eu-resource-cell">
            {{ item.chosen_mentor.department && item.chosen_mentor.department.name }}
          </td>

          <td class="eu-resource-cell eu-resource-cell-dots-icon">
            <span
              tabindex="0"
              :id="`mentor_connect_appointments_table_${item.id}`"
              ref="burgerTable"
              @click="showBurger(item, 'table')"
              @keyup.enter="showBurger(item, 'table')"
              aria-label="Burger menu"
              aria-haspopup="menu"
              role="button"
            >
              <i class="fas fa-ellipsis-v"></i>
            </span>
          </td>

          <div
            ref="burgerTableDropdown"
            v-closable="{ exclude: ['burgerTable', 'burgerTableDropdown'], handler: 'closeBurgers' }"
            :id="'table' + item.id + item.timestamp"
            class="eu-resource-burger"
            style="width: 300px"
          >
            <span
              tabindex="0"
              @click="openModal(item)"
              @keyup.enter="openModal(item)"
              aria-label="Open appointemnt view details modal"
              aria-haspopup="dialog"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-eye" style="padding-right: 5px"></i>
              <span>View Details</span>
            </span>
            <span
              v-if="!isPartnerTenant"
              tabindex="0"
              @click="openFollowUpModal(item)"
              @keyup.enter="openFollowUpModal(item)"
              class="iu-resource-burger-el"
              aria-label="Request follow-up meeting"
              role="button"
            >
              <i class="fas fa-envelope" style="padding-right: 5px"></i>
              <span>Request Follow Up</span>
            </span>
            <span
              v-if="is_past"
              tabindex="0"
              @click="archive(item)"
              @keyup.enter="archive(item)"
              aria-label="Archive appointment"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-archive" style="padding-right: 5px"></i>
              <span>Archive</span>
            </span>
            <span
              tabindex="0"
              v-if="showCalendarAndCancelCheck(item.status)"
              ref="calendarDropdown"
              @click="toggleCalendarDropdown()"
              @keyup.enter="toggleCalendarDropdown()"
              aria-label="Bruger menu"
              aria-haspopup="menu"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-edit" style="padding-right: 5px"></i>
              <span>Add to Calendar</span>
              <i
                v-if="!calendarDropdownOpen"
                class="fal fa-angle-down"
                aria-hidden="true"
                style="margin-left: 20px; font-size: 23px; position: relative; top: 3px"
              ></i>
              <i
                v-else
                class="fal fa-angle-up"
                aria-hidden="true"
                style="margin-left: 20px; font-size: 23px; position: relative; top: 3px"
              ></i>
            </span>
            <span
              v-if="calendarDropdownOpen"
              tabindex="0"
              @click="openExternalCalendar('google', item)"
              @keyup.enter="openExternalCalendar('google', item)"
              aria-label="Add appointment into google calendar"
              role="button"
              class="eu-resource-burger-el"
              style="margin: 10px 0 0 15px"
            >
              <img
                :src="`${assetPath}assets/icons/768px-Google_Calendar_icon_%282020%29.png`"
                style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
              />
              <span>Google Calendar</span>
            </span>
            <span
              v-if="calendarDropdownOpen"
              tabindex="0"
              @click="openExternalCalendar('ics', item)"
              @keyup.enter="openExternalCalendar('ics', item)"
              aria-label="Add appointment into apple calendar"
              role="button"
              class="eu-resource-burger-el"
              style="margin: 10px 0 0 15px"
            >
              <img
                :src="`${assetPath}assets/icons/apple-512.webp`"
                style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
              />
              <span>Apple Calendar</span>
            </span>
            <span
              v-if="calendarDropdownOpen"
              tabindex="0"
              @click="openExternalCalendar('outlook', item)"
              @keyup="openExternalCalendar('outlook', item)"
              aria-label="Add appointment into outlook calendar"
              role="button"
              class="eu-resource-burger-el"
              style="margin: 10px 0 0 15px"
            >
              <img
                :src="`${assetPath}assets/icons/Outlook.com_icon.png`"
                style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
              />
              <span>Outlook Calendar</span>
            </span>
            <span
              v-if="calendarDropdownOpen"
              tabindex="0"
              @click="openExternalCalendar('yahoo', item)"
              @keyup.enter="openExternalCalendar('yahoo', item)"
              aria-label="Add appointment into yahoo calendar"
              role="button"
              class="eu-resource-burger-el"
              style="margin: 10px 0 10px 15px"
            >
              <img
                :src="`${assetPath}assets/icons/yahoo.png`"
                style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
              />
              <span>Yahoo Calendar</span>
            </span>
            <span
              tabindex="0"
              v-if="item.chosen_mentor.id != loggedUserId"
              :aria-label="
                !ids_favorite_mentors.includes(item.chosen_mentor.id)
                  ? `Add ${item.chosen_mentor_fullname} in favorite mentors`
                  : `Remove ${item.chosen_mentor_fullname} from favorite mentors`
              "
              @click="handleFavoritesMentors(item.chosen_mentor.id)"
              @keyup.enter="handleFavoritesMentors(item.chosen_mentor.id)"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fad fa-bookmark" style="padding-right: 5px"></i>
              <span v-if="!ids_favorite_mentors.includes(item.chosen_mentor.id)">Add Mentor to Favorites</span>
              <span v-else> Remove Mentor from Favorites </span>
            </span>

            <span
              v-if="showCalendarAndCancelCheck(item.status)"
              tabindex="0"
              @click="openCancelationModal(item)"
              @keyup.enter="openCancelationModal(item)"
              aria-label="Open cancelation appointment modal"
              aria-haspopup="dialog"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-times" style="padding-right: 5px"></i>
              <span>Cancel Appointment</span>
            </span>

            <span v-if="item.is_mentor && item.status === 'urgent'">
              <span
                tabindex="0"
                class="eu-resource-burger-mentor-el accept"
                @click="acceptMeeting(item)"
                @keyup.enter="acceptMeeting(item)"
                aria-label="Accept appointemnt"
                role="button"
              >
                <i class="fas fa-check-circle" style="padding-right: 5px"></i>
                <span>Accept</span>
              </span>
              <span
                tabindex="0"
                class="eu-resource-burger-mentor-el deny"
                @click="denyMeeting(item)"
                @keyup.enter="denyMeeting(item)"
                aria-label="Deny appointment"
                role="button"
              >
                <i class="fas fa-times-circle" style="padding-right: 5px"></i>
                <span>Deny</span>
              </span>
            </span>
          </div>
        </tr>
      </table>
    </div>
  </section>
</template>
<script lang="ts">
import handleOutsideClick from "../../../directives/handleOutsideClick";
import "../../utils/DateParser";
import { getStatusText, showCalendarAndCancelCheck } from "../../utils/common";

export default {
  directives: { closable: handleOutsideClick },

  name: "meetings-table",

  props: ["meetings", "isPartnerTenant", "calendarDropdownOpen", "loggedUserId", "icons", "ids_favorite_mentors"],

  data() {
    return {
      assetPath: process.env.MEDIA_URL,
      is_past: false,
    };
  },

  methods: {
    getStatusText,
    showCalendarAndCancelCheck,
    hasVideoChat() {
      const isMeetingsEnabled: HTMLElement = document.querySelector(".js-has-video-chat-meetings");
      return JSON.parse(isMeetingsEnabled.dataset.hasVideoChatMeetings.toLowerCase());
    },
    acceptMeeting(item) {
      this.$emit("accept-meeting", item);
    },

    denyMeeting(item) {
      this.$emit("deny-meeting", item);
    },

    getDateCellClass(status: string) {
      if (status === "past" || status === "canceled" || status === "expired") {
        return "faded";
      }

      return "";
    },

    showBurger: function (i, view) {
      this.is_past = i.status === "past" ? true : false;
      this.$emit("show-burger", i, view);
    },

    closeBurgers: function () {
      this.$emit("close-burgers");
    },

    openModal: function (i) {
      this.$emit("open-modal", i);
    },

    openCancelationModal: function (i) {
      this.$emit("open-cancelation-modal", i);
    },

    toggleCalendarDropdown: function () {
      this.$emit("toggle-calendar-dropdown");
    },
    openFollowUpModal: function (i) {
      this.$emit("open-follow-up-modal", i);
    },

    openExternalCalendar: function (cal, item) {
      this.$emit("open-external-calendar", cal, item);
    },
    archive: function (item) {
      this.$emit("archive-meeting", item);
    },
    handleFavoritesMentors: function (mentor_id) {
      this.$emit("handle-favorite", mentor_id);
    },
  },
};
</script>
