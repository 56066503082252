import HttpService from '../../services/HttpService';

export default class FeedService extends HttpService {
  constructor() {
    super('/passport/api');
  }

  updateProfile(id, body, headers): Promise<any> {
    this.endpoint = `/passport/api/profile/${id}`;
    return this.postFormData(body, headers);
  }

  updatePassport(id, body, headers): Promise<any> {
    this.endpoint = `/passport/api/passport/${id}`;
    return this.postFormData(body, headers);
  }

}
