//Global Apps
import CookieDisclaimer from './components/CookieDisclaimer.vue';
import Vue from 'vue';

let _initialized = false;
let _instance = null;

class DIVAGlobal {
    protected searchBox: any;

    constructor() { }

    searchBoxApp() {
        const searchIcon = document.querySelector(".js-global-search-icon");
        const searchBox = document.querySelector(".da-global-search");

        const searchInput: HTMLInputElement = document.querySelector(
            ".js-global-search-input"
        );

        if (searchIcon) {
            searchIcon.addEventListener("click", e => {
                searchBox.classList.toggle("active");
                searchInput.focus();
            });
        }

        const mobileSearchIcon = document.querySelector('#search-nav-mobile');
        const mobileSearchBox = document.querySelector('.da-global-mobile-search');

        if (mobileSearchIcon && mobileSearchBox) {
            mobileSearchIcon.addEventListener('click', () => {
                mobileSearchBox.classList.toggle('active');
                mobileSearchIcon.classList.toggle('active');
            });
        }
    }

    closeTaskNotification() {
        const closeSpan: HTMLElement = document.querySelector('.da-reminder-close');

        if (!closeSpan) {
            return;
        }

        closeSpan.addEventListener("click", e => {
            e.preventDefault();

            fetch(`/notifications/${closeSpan.id}/`, {
                method: 'PUT',
            }).then(response => {
                if (response.status === 200) {
                    const notifications = <HTMLElement[]><any>document.querySelectorAll('.notification-bar-section');
                    notifications.forEach(notification => {
                        notification.style.display = 'none';
                    });
                    const headerHeight = (<HTMLElement>document.querySelector('.da-sticky-header-container')).offsetHeight;
                    const pageContent = <HTMLElement>document.querySelector('.da-page-content');
                    pageContent.style.marginTop = `${headerHeight}px`;
                }
            });
        });
    }

    showCookieDisclaimer() {
        const cookieDisclaimerContainer: HTMLElement = document.querySelector('#cookie-disclaimer-container');
        const show: string = localStorage.getItem('show-cookie-disclamer');
        let cookieDisclaimer: Vue;
        fetch('/profiles/cookie/')
            .then(response => response.json())
            .then(preference => {
                if (!preference.cookies_accepted) {
                    cookieDisclaimer = new Vue({
                        components: { CookieDisclaimer },
                        template: `<CookieDisclaimer logged="yes" />`,
                    });
                }
            })
            .catch(() => {
                if (show !== 'accepted') {
                    cookieDisclaimer = new Vue({
                        components: { CookieDisclaimer },
                        template: `<CookieDisclaimer logged="no" />`,
                    });
                }
            })
            .finally(() => {
                if (cookieDisclaimer) {
                    cookieDisclaimer.$mount(cookieDisclaimerContainer);

                    const contentSection = <HTMLElement>document.querySelector('.destination-guide-content-section');
                    if (contentSection) {
                        const cdEl = <HTMLElement>document.querySelector('.da-global-cookie-disclaimer');
                        contentSection.style.bottom = `${cdEl.offsetHeight}px`;
                    }
                }
            });
    }

    tenantMobileMenuHandler() {
        const openMobileSidebarBtn: HTMLElement = document.querySelector(
            "#mobile-open-nav"
        );
        const closeMobileSidebarBtn: HTMLElement = document.querySelector(
            "#mobile-close-nav"
        );


        if (openMobileSidebarBtn) {
            const closeMenu = (e) => {
                e.preventDefault();
                const sidebar: HTMLElement = document.querySelector(
                    "#sidebar"
                );
                if (sidebar.classList.contains('eu-sidebar-active')) {
                    toggleMenu(e);
                }
            }
            const toggleMenu = (e) => {
                e.preventDefault();
                const sidebar: HTMLElement = document.querySelector(
                    "#sidebar"
                );
                const profileBtn: HTMLElement = document.querySelector(
                    "#profileBtn"
                );
                sidebar.classList.toggle('eu-sidebar-active');
                const content: HTMLElement = document.querySelector("#content");
                const contentWrapper: HTMLElement = document.querySelector("#content-wrapper");
                if (sidebar.classList.contains('eu-sidebar-active')) {
                    const sidebarContainer: HTMLElement = document.querySelector("#sidebarContainer");
                    closeMobileSidebarBtn.style.opacity = '1';
                    openMobileSidebarBtn.style.opacity = '0';
                    content.style.opacity = '0.4';
                    profileBtn.style.display = 'none';
                    contentWrapper.addEventListener("click", closeMenu);
                    sidebarContainer.addEventListener("click", closeMenu);

                } else {
                    closeMobileSidebarBtn.style.opacity = '0';
                    openMobileSidebarBtn.style.opacity = '1';
                    content.style.opacity = '1';
                    profileBtn.style.display = 'unset';
                    contentWrapper.removeEventListener("click", closeMenu);
                }

            }

            openMobileSidebarBtn.addEventListener("click", toggleMenu);
            closeMobileSidebarBtn.addEventListener("click", toggleMenu);

            openMobileSidebarBtn.addEventListener("keypress", function(e) {
                if (e.key === 'Enter') {
                    toggleMenu(e)
                  }
            });
            closeMobileSidebarBtn.addEventListener("keypress", function(e) {
                if (e.key === 'Enter') {
                    toggleMenu(e)
                  }
            });

        }

    }

    mobileMenuHandler() {
        const mobileMenuTrigger: HTMLElement = document.querySelector(
            "#mobile-menu"
        );

        const mobileUserMenuTrigger: HTMLElement = document.querySelector(
            "#mobile-user-menu"
        );

        const mobileMenuCloseTrigger: HTMLElement = document.querySelector(
            "#mobile-menu-close-icon"
        );

        const mobileUserMenuCloseTrigger: HTMLElement = document.querySelector(
            "#mobile-user-menu-close-icon"
        );

        const mobileMenu: HTMLElement = document.querySelector(
            "#mobile-menu-container"
        );

        const mobileUserMenu: HTMLElement = document.querySelector(
            "#mobile-user-menu-container"
        );

        if (!mobileMenuTrigger) {
            return;
        }

        const toggleMenu = (e) => {
            e.preventDefault();
            mobileMenu.classList.toggle('da-global-mobile-menu-active');
        }

        const toggleUserMenu = (e) => {
            e.preventDefault();
            mobileUserMenu.classList.toggle('da-global-mobile-user-menu-active');
        }

        mobileMenuTrigger.addEventListener("click", toggleMenu);
        mobileMenuCloseTrigger.addEventListener("click", toggleMenu);

        const userSubMenuSol: HTMLElement = document.querySelector(
            "#mobile-submenu-solutions"
        );

        const subMenuSolIcon: HTMLElement = document.querySelector(
            "#mobile-submenu-solutions-icon"
        );

        const subMenuSolHeader: HTMLElement = document.querySelector(
            "#mobile-submenu-solutions-header"
        );

        const userSubMenuFea: HTMLElement = document.querySelector(
            "#mobile-submenu-features"
        );

        const subMenuFeaIcon: HTMLElement = document.querySelector(
            "#mobile-submenu-features-icon"
        );

        const subMenuFeaHeader: HTMLElement = document.querySelector(
            "#mobile-submenu-features-header"
        );

        const subMenuInsights: HTMLElement = document.querySelector(
          "#mobile-submenu-insights"
        );

        const subMenuInsightsIcon: HTMLElement = document.querySelector(
          "#mobile-submenu-insights-icon"
        );

        const subMenuAboutUs: HTMLElement = document.querySelector(
          "#mobile-submenu-about-us"
        );

        const subMenuAboutUsIcon: HTMLElement = document.querySelector(
          "#mobile-submenu-about-us-icon"
        );



        const handleSubMenuClick = (subMenu: HTMLElement, iconEl: HTMLElement, header: HTMLElement) => {
            subMenu?.classList.toggle('da-global-mobile-submenu-active');
            iconEl?.classList.toggle('fa-angle-down');
            iconEl?.classList.toggle('fa-angle-up');
            header?.classList.toggle('da-global-mobile-submenu-header-active');
        };

            subMenuSolIcon.addEventListener("click", e => {
                e.preventDefault();
                handleSubMenuClick(userSubMenuSol, subMenuSolIcon, subMenuSolHeader);
            });
            subMenuFeaIcon.addEventListener("click", e => {
                e.preventDefault();
                handleSubMenuClick(userSubMenuFea, subMenuFeaIcon, subMenuFeaHeader);
            });

            subMenuInsightsIcon.addEventListener("click", e => {
              e.preventDefault();
              handleSubMenuClick(subMenuInsights, subMenuInsightsIcon, null);
            })

            subMenuAboutUsIcon.addEventListener("click", e => {
              e.preventDefault();
              handleSubMenuClick(subMenuAboutUs, subMenuAboutUsIcon, null);
            })

        if (mobileUserMenuTrigger && mobileUserMenuCloseTrigger) {
            mobileUserMenuTrigger.addEventListener("click", toggleUserMenu);
            mobileUserMenuCloseTrigger.addEventListener("click", toggleUserMenu);
        }
    }

    featuredProgramsHeight() {
        const featuredPrograms: Array<HTMLElement> = (document.querySelectorAll('.feature-card')) as any as Array<HTMLElement>;

        const adjustHeightByWidth = (array) => {
            array.forEach((program) => {
                program.style.height = getComputedStyle(program).width;
            });
        }

        adjustHeightByWidth(featuredPrograms);

        window.addEventListener('resize', () => {
            adjustHeightByWidth(featuredPrograms);
        });
    }

}

export default class DiversityGlobal {
    static getInstance() {
        if (_initialized == false) {
            _instance = new DIVAGlobal();
            _initialized = true;
            return _instance;
        } else {
            return _instance;
        }
    }
}
