import Vue from 'vue';
import * as Cookies from "js-cookie";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import ObjectsSelectModal from '../accounts/components/modals/ObjectsSelectModal.vue'
import AccountsService from "../accounts/services/AccountsService";
import ContactGroupService from "../services/ContactGroupService";
import Modal from "../components/ModalExtended.vue";
import { validateExtensionFile } from '../utils/TypecheckUtils'
import { focusElement } from '../utils/CustomUtils';

export default class GroupTable {
  protected crfToken: string;
  protected mountPoint: HTMLElement;
  protected contactGroup_list: any;

  constructor(el: HTMLElement, contactGroup_list) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.contactGroup_list = contactGroup_list;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const contactGroupsTable = new Vue({
      components: {
        LoadingSpinner,
        ObjectsSelectModal,
        Modal,
      },
      template: `
      <section>
        <div v-if="isLoading">
          <loading-spinner />
        </div>
        <table v-else class="eu-resource-table">
          <tr class="eu-resource-row">
              <th scope="col" class="eu-resource-cell eu-resource-cell-th analytics-table-small-screen-cell-th">
              <span> Name</span>
            </th>

            <th scope="col" class="eu-resource-cell eu-resource-cell-th analytics-table-small-screen-cell-th">
            </th>
          </tr>

          <tr v-for="contactGroup in contactGroup_list" :key="contactGroup.id" class="eu-resource-row">
            <td class="eu-resource-cell">
              <a tabindex="0" :aria-label="'Select ' + contactGroup.name +  'contact group users'" role="button" @click="openSelectUsersModal(contactGroup)" @keyup.enter="openSelectUsersModal(contactGroup)" style="cursor: pointer;"">{{contactGroup.name}}</a>
            </td>
            <td class="eu-resource-cell eu-resource-cell-dots-icon" style="display:inline-flex;float:right">
              <span title="Edit group name" tabindex="0" class="iu-contact-group-button-icon-upload" role="button" :aria-label="'Change ' + contactGroup.name + ' contact group name'" @click="openEditContactGroupModal(contactGroup)" @keyup.enter="openEditContactGroupModal(contactGroup)" style="display: flex; margin-right: 10px;"> <i class="fas fa-edit" style="font-size: 14px"></i></span>
              <span title="Bulk upload users to group" tabindex="0" role="button"  :aria-label="'Bulk upload users to ' + contactGroup.name" @click="openBulkUploadModal(contactGroup)" @keyup.enter="openBulkUploadModal(contactGroup)" style="display: flex; margin-right: 10px;font-size:14px;"> <i class="fa-solid fa-arrow-up-from-square"></i></span>
              <span title="Edit group users" tabindex="0" role="button" :aria-label="'Edit ' + contactGroup.name + ' contact group users'" @click="openSelectUsersModal(contactGroup)" @keyup.enter="openSelectUsersModal(contactGroup)" style="display: flex; margin-right: 10px;font-size:14px;"> <i class="fas fa-users" style="font-size: 14px"></i></span>
              <span title="Delete group" class="iu-contact-group-button-icon-delete" :aria-label="'Open ' + contactGroup.name + 'contact group delete modal'" aria-haspopup="dialog" role="button" tabindex="0" @click="openRemoveContactGroupModal(contactGroup)" @keyup.enter="openRemoveContactGroupModal(contactGroup)" style="display: flex"> <i class="fas fa-trash-alt"></i></span>
            </td>
          </tr>
        </table>
        <objects-select-modal
          :isModalActive="isSelectUsersModalActive"
          @close-modal="closeSelectUsersModalEventHandler"
          :title='"Add users in contact group " + selectedContactGroup.name'
          actionName="Select"
          objectName="User"
          :objectsList="usersList"
          :isLoading="isLoadingSelectUsers"
          :page="selectUserPage"
          :totalPageNumber="selectUserTotalPageNumber"
          :currentPage="selectUserCurrentPage"
          :showIcon="true"
          :preselectedObjects="preselectedUsers"
          :filterGroups="contactGroup_list"
          @loadData="loadUsersList"
          @searchObjects="loadUsersList"
        >
        </objects-select-modal>
        <Modal :isActiveProp="isRemoveContactGroupModalActive" @close="closeRemoveContactGroupModal">
                <h2 class="eu-profile-title" id="remove-contact-group-modal-title" tabindex="0">Remove Group</h2>
                <p style="padding:0 30px 30px 30px;text-align:center">Are you sure that you want to remove <i>{{selectedContactGroup.name}}</i>? This cannot be undone.</p>
                <p style="padding:0 30px 30px 30px;text-align:center" class="delete-text">For security reasons, please type in your password:</p>
                <form class="field-wrapper hasValue" style="margin-bottom: 20px; padding: 0 32px;">
                    <div class="da-form-formItem permission-input">
                        <input type="password" autocomplete="off" class="da-components-forms-input large js-da-login-email" v-model="password">
                    </div>
                    <div class="field-placeholder" style="padding: 0 42px">
                        <span>Password<span class="required-star">*</span></span>
                    </div>
                </form>
                <div class="row" style="width: 100%">
                  <p class='error' style="margin-bottom: 12px;">{{err}}</p>
                  <div class="column">
                      <span
                      @click="removeContactGroup"
                      @keyup.enter="removeContactGroup"
                      class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary orange"
                      style="width: fit-content; height: fit-content"
                      tabindex="0"
                      role="button"
                      aria-label="Remove contact group"
                      >
                      Remove Contact Group
                      </span>
                      <span
                      @click="closeRemoveContactGroupModal"
                      @keyup.enter="closeRemoveContactGroupModal"
                      class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                      style="width: fit-content; height: fit-content"
                      tabindex="0"
                      role="button"
                      aria-label="Cancel removing contact group"
                      >
                      Cancel
                      </span>
                  </div>
                </div>
            </div>
          </Modal>
          <Modal :isActiveProp="isEditContactGroupModalActive" @close="closeEditContactGroupModal">
            <div class='profile-notification-modal' >
                <h2 class="eu-profile-title" id="edit-contact-group-modal-title" tabindex="0">Edit Contact Group</h2>
                <form class="field-wrapper hasValue" style="margin-bottom: 20px; padding: 0 32px;">
                    <div class="da-form-formItem permission-input">
                        <input type="input" autocomplete="off" class="da-components-forms-input large js-da-login-email" v-model="newName">
                    </div>
                    <div class="field-placeholder" style="padding: 0 42px">
                        <span>Name<span class="required-star">*</span></span>
                    </div>
                    <p class='error' style="margin-bottom: 12px;">{{err}}</p>
                </form>
                <form class="field-wrapper hasValue" style="margin-bottom: 20px; padding: 0 32px;">
                    <div class="da-form-formItem">
                      <textarea v-model="newDescription" type="text" rows="4"  class="eu-textarea-modern" />
                    </div>
                    <div class="field-placeholder" style="padding: 0 42px; top: -10px;">
                      <span>Description</span>
                    </div>
                </form>
                <div class="row" style="width: 100%; margin-top: 100px;">
                  <div class="column">
                      <span
                      @click="editContactGroup"
                      @keyup.enter="editContactGroup"
                      class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                      style="width: fit-content; height: fit-content"
                      aria-label="Submit changes"
                      role="button"
                      tabindex="0"
                      >
                      Edit Contact Group
                      </span>
                      <span
                      @click="closeEditContactGroupModal"
                      @keyup.enter="closeEditContactGroupModal"
                      class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                      style="width: fit-content; height: fit-content"
                      aria-label="Cancel editing"
                      role="button"
                      tabindex="0"
                      >
                      Cancel
                      </span>
                  </div>
                </div>
              </div>
          </Modal>
          <Modal class="eu-file-upload-modal" :isActiveProp="isBulkUploadModalActive" @close="closeBulkUploadModal">
              <h3 id="upload_modal_title" tabindex="0" class="contact-group-modal-heading" role="heading">Bulk Add Users to {{ selectedContactGroup.name }}</h3>

              <span class="contact-group-error" v-show="upload_error">{{ upload_error }}</span>

              <div class="row contact-group-base-file-container">

                <span
                  v-for="(file, i) in files"
                  v-bind:key="i"
                  class="contact-group-file-container"
                >
                  <i class="fas fa-file-excel contact-group-file-icon"></i>
                  <span class="eu-upload-filename"> {{ file.name }} </span>
                  <span
                      class="eu-upload-status"
                      :class="{'contact-group-error': file.status === 'failed'}"
                  >({{ file.status }})</span>

                  <span
                      v-if="file.status == 'uploading'"
                      class="eu-line eu-stripesLoaderAnimated"
                  ></span>
                  <span
                      v-else-if="file.status == 'pending'"
                      class="eu-line eu-stripesLoader contact-group-line-pending"
                  ></span>
                  <span
                      v-else-if="file.status == 'failed'"
                      class="eu-line eu-stripesLoader contact-group-line-failed"
                  ></span>
                  <span
                      v-else-if="file.status == 'success'"
                      class="eu-line eu-stripesLoader contact-group-line-success"
                  ></span>

                  <p class="error file-upload-error" :title="file.error">{{ file.error }}</p>

                  <span v-if="file.status === 'success'">
                      <i class="fas fa-check-circle contact-group-success"></i>
                  </span>
                  <span v-else-if="file.status === 'failed'">
                      <i class="fas fa-trash-alt contact-group-delete" @click="removeFile(file)"></i>
                  </span>
                </span>
            </div>

            <div class="row" style="align-items: baseline">
              <div class="column" style="width: 100%">
                <div v-cloak @drop.prevent="handleFileUpload" @dragover.prevent class="innerRow dnd-wrapper">
                  <label
                    @dragenter="dragging = true"
                    @dragleave="dragging = false"
                    class="file-upload-label"
                    :style="{ background: dragging ? '#eaeeff' : '' }"
                    for="resource_image"
                  >
                    <span>
                      <i class="fad fa-cloud-upload-alt dnd-upload-icon"></i> <br />
                      Drag &amp; drop files here <br />
                      or <br />
                      <input @change="handleFileUpload" id="resource_image" type="file" multiple accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                      <span class='file-input-text'>
                        Browse from device <i class="fal fa-angle-right" style="color: #5E50B5; font-size: 15px"></i>
                      </span>
                    </span>

                  </label>
                </div>
              </div>
              <label id='acceptedFileTypes' class="eu-checkbox-container" style="padding-left: 0; margin-top: 25px; text-align: center; color: #707070"
                >Accepted file types: <br />
                <span style="font-weight: 500" aria-labelledby="acceptedFileTypes"> .csv, .xlsx </span>
              </label>
            </div>

            <div class="button-container">
              <button
                  class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                  @click="addUsersToContactGroup"
                  aria-label="Add users from selected file"
              >Add</button>
              <button
                  class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                  @click="closeBulkUploadModal"
                  aria-label="Cancel adding users"
              >Close</button>
            </div>
          </Modal>
      </section>
      `,
      data() {
        return {
          contactGroup_list: _class.contactGroup_list,
          selectedContactGroup: {name:null, id:null, description:null},
          isLoading: false,
          err: '',
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
            Accept: "application/json",
          },
          isSelectUsersModalActive: false,
          isLoadingSelectUsers: false,
          usersList: [],
          preselectedUsers: [],
          selectUserPage: 1,
          selectUserCurrentPage: 1,
          selectUserTotalPageNumber:1,

          isRemoveContactGroupModalActive:false,
          password: '',

          isEditContactGroupModalActive: false,
          newName: '',
          newDescription: '',

          dragging: false,
          isBulkUploadModalActive: false,
          upload_error: '',
          files: [],
        }
      },
      async created() {
        this.contactGroupService = new ContactGroupService();
        this.accountsService = new AccountsService();
        this.loadContactGroupUsers(1);
      },
      methods: {
        async addUsersToContactGroup() {
          this.$emit('started-upload');
          this.files.forEach(async file => {
              if (file.status === 'success') {
                  this.removeFile(file);
              } else {

                  file.status = 'uploading';
                  file.method = 'BULK_UPLOAD';
                  file.contactGroup_id = this.selectedContactGroup.id;

                  const result = await this.contactGroupService.bulkUploadUsers(file, this.headers);

                  if (result.data.success) {
                      file.status = 'success';
                      focusElement("user-import-button");

                  } else {
                      focusElement("upload_error_modal");
                      file.status = 'failed';
                      file.error = `Upload failed` + (result.data.message ? `: ${result.data.message}` : ``);
                  }
              }
          });
        },
        removeFile(file) {
          this.files = this.files.filter(e => e.name !== file.name);
        },
        openBulkUploadModal(contactGroup) {
          this.isBulkUploadModalActive = true;
          focusElement("upload_modal_title");
          this.selectedContactGroup = contactGroup;
        },
        closeBulkUploadModal() {
          this.isBulkUploadModalActive = false;
          this.selectedContactGroup = {name:null, id:null, description:null};
          this.files=[];
        },
        handleFileUpload(event) {
          const uploadFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
          const docExt = ['xlsx', 'xls', 'csv'];
          if (!uploadFiles.length) return;

          for (let i = 0; i < uploadFiles.length; i++) {
            this.upload_error = '';
            let file = {
              file_url: URL.createObjectURL(uploadFiles[i]),
              file: uploadFiles[i],
              name: uploadFiles[i].name,
              file_type: null,
              status: 'pending',
              error: null
            };
            if (validateExtensionFile(uploadFiles[i].name, docExt)) {
              file.file_type = 'DOC';
            } else {
              this.upload_error = 'Please use a supported image filetype.';
              return;
            }

            if (!this.files.some(e => e.name === file.name)) {
              this.files.push(file);
            } else {
              this.upload_error = `File with name '${file.name}' already exists.`;
              return;
            }
          }
        },
        userToSelectObjectMapper(user) {
          return {
            "id": user.id,
            "name": user.first_name + " " + user.last_name,
            "unique_signature": user.email,
            "icon": user.profile_picture,
          }
        },
        openRemoveContactGroupModal(contactGroup) {
          this.selectedContactGroup = contactGroup;
          this.isRemoveContactGroupModalActive = true;
          focusElement("remove-contact-group-modal-title");
          const sidebar: HTMLElement = document.querySelector("#sidebar");
          sidebar.style.display = "none";
        },
        openEditContactGroupModal(contactGroup) {
          this.selectedContactGroup = contactGroup;
          this.isEditContactGroupModalActive = true;
          focusElement("edit-contact-group-modal-title")
          this.newName = this.selectedContactGroup.name;
          this.newDescription = this.selectedContactGroup.description;
        },
        closeEditContactGroupModal() {
          this.selectedContactGroup = {name:null, id:null, description:null};
          this.isEditContactGroupModalActive = false;
          this.newName = '';
          this.newDescription = '';
        },
        async editContactGroup() {
          this.contactGroupService.editContactGroup({'id':this.selectedContactGroup.id, 'name':this.newName, 'description':this.newDescription}, this.headers).then(data => {
            if (data?.success) {
              this.closeEditContactGroupModal();
              location.reload();
            } else {
              this.err = data.message;
            }
          });
        },
        openSelectUsersModal(contactGroup){
          this.selectedContactGroup = contactGroup;
          this.isSelectUsersModalActive = true;
          focusElement("objects-select-modal-title")
          this.loadContactGroupUsers(contactGroup.id);
          this.loadUsersList("", 1);
        },
        closeSelectUsersModal() {
          this.isSelectUsersModalActive = false;
          this.selectedContactGroup = {name:null, id:null};
        },
        closeSelectUsersModalEventHandler(closeInfo) {
          if(!closeInfo["canceled"]){
            const data = {
              contactGroup_id: this.selectedContactGroup.id,
              user_list: closeInfo.selectedObjects.map(user => user.id),
            }
            this.contactGroupService.addContactGroupUsers(data, this.headers);
          }
          this.closeSelectUsersModal();
        },
        async loadUsersList(searchString="", page=1, selectedContactGroup_id) {
          this.selectUserPage = page;

          let selectedContactGroup_id_check = selectedContactGroup_id;
          if (selectedContactGroup_id == undefined) selectedContactGroup_id_check = 0;

          if(selectedContactGroup_id_check == 0) {
            if (page==1) this.usersList = [];
            this.isLoadingSelectUsers = true;

            let response = await this.accountsService.getUsers({
              "name":searchString,
              "page": page,
            });
            this.usersList = this.usersList.concat(response.page_data.map(user => this.userToSelectObjectMapper(user)));
            this.selectUserTotalPageNumber = response.total_pages_number;
            if(this.selectUserTotalPageNumber == this.selectUserPage) {
              this.isLoadingSelectUsers = false;
            }
            this.selectUserCurrentPage = page;
          } else {
            this.usersList = [];
            this.isLoadingSelectUsers = true;
            let response = await this.contactGroupService.getContactGroupUsers(selectedContactGroup_id, {"name":searchString});
            this.usersList = this.usersList.concat(response.data.map(user => this.userToSelectObjectMapper(user)));

            this.selectUserCurrentPage = page;
            this.isLoadingSelectUsers = false;
          }
        },

        async loadContactGroupUsers(contactGroup_id) {
          this.contactGroupService.getContactGroupUsers(contactGroup_id).then(data=>{
            this.preselectedUsers = data.data.map(user => this.userToSelectObjectMapper(user));
          });
        },

        removeContactGroup() {
          const contactGroup = this.selectedContactGroup;
          this.contactGroupService.deleteContactGroup(contactGroup, this.password, this.headers).then(res => {
            this.password = '';
            if (res?.success) {
              this.closeRemoveContactGroupModal();
              location.reload();
            } else {
              this.err = res.message;
            }
          });
        },

        closeRemoveContactGroupModal() {
          this.isRemoveContactGroupModalActive = false;
          this.selectedContactGroup = {name:null, id:null, description:null};
          const sidebar: HTMLElement = document.querySelector("#sidebar");
          sidebar.style.display = "block";
        },
      },
      mounted() {

      },
    });
    contactGroupsTable.$mount(this.mountPoint);
  }
}
