<template>
  <div>
    <section class="partner-tenant-leadership-programs-hero-section">
      <div class="partner-tenant-leadership-programs-hero-content-container">
        <div class="partner-tenant-leadership-programs-hero-text-container">
          <div style="display: flex">
            <div class="partner-tenant-leadership-programs-hero-tag">Opportunities</div>
          </div>
          <h2 class="partner-tenant-leadership-programs-hero-title">Leadership Programs</h2>
          <p class="partner-tenant-leadership-programs-hero-description">
            We are dedicated to offering unique learning opportunities for students and graduates to equip them with the
            skills, experiences, and networks to thrive personally, academically, and professionally in an
            interconnected global workforce.
          </p>
        </div>
      </div>
    </section>
    <breadcrumbs :routeItems="breadcrumbsLinkItems" currentPageLabel="Leadership Programs" />
    <section class="partner-tenant-leadership-programs-certificate-section">
      <div class="partner-tenant-leadership-programs-certificate-content-container">
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Global-Inclusive-Leadership-Certificate-Graphic.webp"
          alt="Global Inclusive Leadership Certificate"
          class="partner-tenant-leadership-programs-certificate-image"
        />
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Global-Inclusive-Leadership-Certificate-Graphic-Mobile.webp"
          alt="Global Inclusive Leadership Certificate"
          class="partner-tenant-leadership-programs-certificate-image mobile"
        />
        <div class="partner-tenant-leadership-programs-certificate-text-container">
          <h3 class="partner-tenant-leadership-programs-certificate-title">Global Inclusive Leadership Certificate</h3>
          <p class="partner-tenant-leadership-programs-certificate-description">
            Our flagship Global Inclusive Leadership Certificate, developed by BeGlobalii, is a unique hybrid learning
            experience that prepares participants with inclusive leadership skills and global competencies so that they
            can thrive in their communities and on globally diverse campuses and work teams. Current undergraduate
            students and recent graduates will have the opportunity to engage in online learning for their leadership
            development.
          </p>
          <a
            href="https://www.diversityabroad.org/Global-Inclusive-Leadership-Certificate"
            target="_blank"
            class="partner-tenant-leadership-programs-certificate-button"
          >
            Learn More <i class="fa-solid fa-arrow-up-right-from-square"></i>
          </a>
        </div>
      </div>
    </section>
    <section class="partner-tenant-leadership-programs-summit-section">
      <div></div>
      <div class="partner-tenant-leadership-programs-summit-content-container">
        <div class="partner-tenant-leadership-programs-summit-text-container">
          <h3 class="partner-tenant-leadership-programs-summit-title">The Global Student Leadership Summit</h3>
          <p class="partner-tenant-leadership-programs-summit-description">
            The Global Student Leadership Summit is the premier leadership conference for students from diverse
            backgrounds who have participated in study abroad or other international programs. The annual summit
            attracts dozens of diverse and underrepresented students from colleges and universities around the United
            States and abroad with the simple goal to develop the skills essential to being a successful leader in a
            global society.
          </p>
          <a
            href="https://conference.diversitynetwork.org/global_student_leadership_summit/"
            target="_blank"
            class="partner-tenant-leadership-programs-summit-button"
          >
            Learn More <i class="fa-solid fa-arrow-up-right-from-square"></i>
          </a>
          <div class="partner-tenant-leadership-programs-summit-icon"></div>
          <img
            src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/The-Global-Student-Leadership-Summit-Graphic.webp"
            alt="The Global Student Leadership Summit Icon"
            class="partner-tenant-leadership-programs-summit-icon"
          />
        </div>
      </div>
      <div class="partner-tenant-leadership-programs-summit-image-container">
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Global-Student-Leadership-Summit-Image.webp"
          alt="The Global Student Leadership Summit"
          class="partner-tenant-leadership-programs-summit-image"
        />
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from './components/Breadcrumbs.vue';

export default {
  name: 'partner-tenant-opportunities-leadership-programs',
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      breadcrumbsLinkItems: [
        {
          label: 'Home',
          path: '/',
          isSpa: true,
        },
        {
          label: 'Opportunities',
          path: '/opportunities',
          isSpa: true,
        },
      ],
    };
  },
};
</script>

<style></style>
