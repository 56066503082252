<template>
  <div class="dashboard-profile-banner">
    <div class="left">
      <i class="fa-solid fa-sparkles sparkles-icon"></i>
      <div class="text-wrapper">
        <div class="dashboard-profile-banner-title">Customize Your Recommendations</div>
        <div class="dashboard-profile-banner-subtitle">Update your profile to get personalized recommendations</div>
      </div>
    </div>
    <div class="right">
      <a aria-label="Customize profile" href="/settings/interests" class="iu-main-btn small solid light">
        Get Started
        <i aria-hidden="true" class="fas fa-chevron-right iu-main-button-arrow-icon"></i>
      </a>
    </div>
    <div class="mobile">
       <i class="fa-solid fa-sparkles sparkles-icon mobile"></i>
      <div class="dashboard-profile-banner-subtitle">Update your profile to get personalized recommendations</div>
      <a aria-label="Customize profile" href="/settings/interests" class="iu-main-btn small solid light">
        Get Started
        <i aria-hidden="true" class="fas fa-chevron-right iu-main-button-arrow-icon"></i>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "dashboard-customize-profile",
  components: {},
  props: [],
  data() {
    return {};
  },
  async created() {},
  methods: {},
  computed: {},
};
</script>