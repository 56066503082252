var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"department-event-container"},[_c('div',{staticClass:"event-search-manage-container"},[_c('div',{staticClass:"eu-search"},[_c('form',{staticClass:"eu-search-form",staticStyle:{"margin-top":"50px"},attrs:{"action":"","role":"search"},on:{"submit":function($event){$event.preventDefault();return _vm.fetchEvents()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_input),expression:"search_input"}],staticClass:"eu-search-input",attrs:{"name":"q","type":"search","placeholder":"Search for opportunities"},domProps:{"value":(_vm.search_input)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search_input=$event.target.value}}}),_vm._v(" "),_vm._m(0)])]),_vm._v(" "),(_vm.canManage())?_c('div',{staticStyle:{"min-width":"116px","position":"relative","top":"13px","white-space":"nowrap"}},[_c('popup-component',{attrs:{"buttonText":"Create"},scopedSlots:_vm._u([{key:"items",fn:function(){return [_c('a',{staticClass:"eu-resource-burger-el",attrs:{"aria-label":"Go to events creation page","href":("/events/create/" + (_vm.isGroupTab && _vm.group.name
                ? '?group=' + encodeURIComponent(_vm.group.name)
                : _vm.isPartnerTab && _vm.partner.name
                ? '?partner=' + encodeURIComponent(_vm.partner.name)
                : ''))},on:{"click":_vm.setRedirectPage,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setRedirectPage.apply(null, arguments)}}},[_c('i',{staticClass:"fa-solid fa-calendar-pen",staticStyle:{"padding-right":"5px"}}),_vm._v(" "),_c('span',[_vm._v("Event")])]),_vm._v(" "),_c('a',{staticClass:"eu-resource-burger-el",attrs:{"aria-label":"Go to program creation page","href":("/programs/create" + (_vm.isGroupTab && _vm.group.name
                ? '?group=' + encodeURIComponent(_vm.group.name)
                : _vm.isPartnerTab && _vm.partner.name
                ? '?partner=' + encodeURIComponent(_vm.partner.name)
                : ''))},on:{"click":_vm.setRedirectPage,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setRedirectPage.apply(null, arguments)}}},[_c('i',{staticClass:"fa-solid fa-building-columns",staticStyle:{"padding-right":"5px"}}),_vm._v(" "),_c('span',[_vm._v("Program")])])]},proxy:true}],null,false,968963461)})],1):_vm._e()]),_vm._v(" "),(_vm.isLoading)?_c('div',[_c('loading-spinner',{attrs:{"wrapperClass":"wrapper"}})],1):_vm._e(),_vm._v(" "),(!_vm.isLoading && _vm.events.length === 0)?_c('div',{staticClass:"eu-empty-container",class:_vm.isPartnerTenant ? 'partner' : ''},[_c('div',{staticClass:"eu-empty"},[_c('div',{staticClass:"eu-empty-icon-container",class:_vm.isPartnerTenant ? 'partner' : ''},[_c('i',{staticClass:"fad fa-calendar eu-empty-icon",class:_vm.isPartnerTenant ? 'partner' : ''})]),_vm._v(" "),_vm._m(1)])]):_vm._e(),_vm._v(" "),(_vm.isLoading)?_c('div',[_c('loading-spinner',{attrs:{"wrapperClass":"wrapper"}})],1):(_vm.events.length > 0)?_c('div',{staticClass:"grid-wrapper-four-card"},_vm._l((_vm.events),function(item){return _c('div',{key:item.id,staticClass:"eu-event-collection-card",staticStyle:{"height":"360px"}},[_c('card-component',{attrs:{"item":item,"is_attending":_vm.attending_event_ids.includes(item.id),"is_partner_page":_vm.isPartnerTab,"can_manage":_vm.canManage},on:{"handle-bookmark":_vm.handleBookmark,"change-attendance":_vm.changeAttendance}})],1)}),0):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"eu-search-btn-icon",attrs:{"aria-label":"search"}},[_c('i',{staticClass:"eu-search-icon fas fa-search"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('span',{staticClass:"eu-empty-text"},[_vm._v("\n          No events have been posted yet.\n          "),_c('br'),_vm._v("\n          Check back later!\n        ")])])}]

export { render, staticRenderFns }