import Vue from 'vue';
import DropzoneComponent from "./components/DropzoneComponent.vue";
import { ImageConstraints } from "../types";

export default class DropzoneUploader {
  private readonly mountPoint: HTMLElement;
  protected imageInput: HTMLInputElement;
  protected imageSrc: string;
  protected maxImageSize: number;
  protected maxImageSizeFormat: string;

  constructor(el: HTMLElement, imgInput: HTMLInputElement, imageSrc: string, imageSizeData: ImageConstraints) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.imageInput = imgInput;
    this.imageSrc = imageSrc;
    this.maxImageSize = imageSizeData.max_size;
    this.maxImageSizeFormat = imageSizeData.formatted;

    this.run();
  }

  async run(): Promise<void> {
    const _self = this;

    const dropzone = new Vue({
      components: { DropzoneComponent },
      template: `
        <dropzone-component
          :imageSrc="imageSrc"
          :imageInput="imageInput"
          :maxImageSize="maxImageSize"
          :maxImageSizeFormat="maxImageSizeFormat"
          :originalInputName="originalInputName"
          @removeImage="handleRemoveImage"
          @uploadImage="prepareUpload"
        />
      `,

      data() {
        return {
          imageSrc: _self.imageSrc,
          imageInput: _self.imageInput,

          maxImageSize: _self.maxImageSize,
          maxImageSizeFormat: _self.maxImageSizeFormat,

          originalInputName: '',
        };
      },

      created() {
        this.originalInputName = _self.imageInput.name;
      },

      methods: {
        prepareUpload() {
          this.imageInput.name = this.originalInputName;
        },

        handleRemoveImage() {
          this.imageSrc = '';
          this.imageInput.files = null;
          this.imageInput.value = '';

          this.imageInput.name = `delete-${this.originalInputName}`;
        },
      }
    });

    dropzone.$mount(this.mountPoint);
  }
}
