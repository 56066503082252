<template>
  <nav class="partner-tenant-header-container">
    <div class="partner-tenant-header-top-navigation-container">
      <router-link to="/">
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/BeGlobalii_logo_large.png"
          alt="BeGlobalii Logo"
          class="partner-tenant-header-logo"
        />
      </router-link>
      <div class="partner-tenant-header-navigation-container">
        <router-link
          class="partner-tenant-header-navigation-item"
          :class="{ active: isActive('/opportunities') }"
          aria-label="Navigate to home page"
          to="/opportunities"
        >
          Opportunities
        </router-link>
        <router-link
          class="partner-tenant-header-navigation-item"
          :class="{ active: isActive('/partners') }"
          aria-label="Navigate to about us page"
          to="/partners"
        >
          Partners
        </router-link>
        <router-link
          class="partner-tenant-header-navigation-item"
          :class="{ active: isActive('/articles') }"
          aria-label="Navigate to about us page"
          to="/articles"
        >
          Articles & Resources
        </router-link>
        <router-link
          class="partner-tenant-header-navigation-item"
          :class="{ active: isActive('/about-us') }"
          aria-label="Navigate to about us page"
          to="/about-us"
        >
          About BeGlobalii
        </router-link>
      </div>
    </div>
    <div class="partner-tenant-header-action-buttons-container">
      <a class="partner-tenant-header-navigation-item" href="/accounts/login"> Log In </a>
      <a class="partner-tenant-header-button-outlined" href="/accounts/register"> Sign Up </a>
    </div>
    <button ref="mobile-menu-button" @click="toggleMobileMenu" class="partner-tenant-header-burger-menu-button">
      <Transition name="fade-mobile-menu-button" mode="out-in">
        <component :is="mobileMenuIcon"></component>
      </Transition>
    </button>
    <Transition name="slide-mobile-menu">
      <div
        ref="mobile-menu"
        class="partner-tenant-header-navigation-container-mobile"
        v-if="isMobileMenuOpen"
        v-closableMobileMenu="{ exclude: ['mobile-menu-button', 'mobile-menu'], handler: 'closeMobileMenu' }"
      >
        <router-link
          class="partner-tenant-header-navigation-item"
          :class="{ active: isActive('/opportunities') }"
          aria-label="Navigate to home page"
          to="/opportunities"
          @click.native="closeMobileMenu"
        >
          Opportunities
        </router-link>
        <router-link
          class="partner-tenant-header-navigation-item"
          :class="{ active: isActive('/partners') }"
          aria-label="Navigate to partners page"
          to="/partners"
          @click.native="closeMobileMenu"
        >
          Partners
        </router-link>
        <router-link
          class="partner-tenant-header-navigation-item"
          :class="{ active: isActive('/articles') }"
          aria-label="Navigate to articles & resources page"
          to="/articles"
          @click.native="closeMobileMenu"
        >
          Articles & Resources
        </router-link>
        <router-link
          class="partner-tenant-header-navigation-item"
          :class="{ active: isActive('/about-us') }"
          aria-label="Navigate to about BeGlobalii page"
          to="/about-us"
          @click.native="closeMobileMenu"
        >
          About BeGlobalii
        </router-link>
        <div class="partner-tenant-header-action-buttons-container-mobile">
          <a
            class="partner-tenant-header-button-outlined secondary"
            style="width: 100%; justify-content: center"
            href="/accounts/login"
          >
            Log In
          </a>
          <a
            class="partner-tenant-header-button-outlined"
            style="width: 100%; justify-content: center"
            href="/accounts/register"
          >
            Sign Up
          </a>
        </div>
      </div>
    </Transition>
  </nav>
</template>

<script>
import handleOutsideClick from '../../../../directives/handleOutsideClick';

export default {
  name: 'partner-tenant-header',
  directives: {
    closableMobileMenu: handleOutsideClick,
  },
  computed: {
    mobileMenuIcon() {
      return this.isMobileMenuOpen ? 'x-icon' : 'bars-icon';
    },
  },
  components: {
    'bars-icon': {
      template: '<i class="fa-regular fa-bars"></i>',
    },
    'x-icon': {
      template: '<i class="fa-regular fa-x" style="font-size: 24px;"></i>',
    },
  },
  data() {
    return {
      isMobileMenuOpen: false,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
    isActive(path) {
      return this.$route.path === path;
    },
  },
};
</script>

<style scoped>
.active {
  color: #2d98d2;
}

.slide-mobile-menu-enter-active,
.slide-mobile-menu-leave-active {
  transition: all 0.8s ease-in-out;
  overflow: hidden;
}

.slide-mobile-menu-enter,
.slide-mobile-menu-leave-to {
  height: 0;
  padding: 0 32px;
  overflow: hidden;
}

.slide-mobile-menu-enter-to,
.slide-mobile-menu-leave {
  height: 320px;
  overflow: hidden;
}

.fade-mobile-menu-button-enter-active,
.fade-mobile-menu-button-leave-active {
  transition: opacity 0.4s ease;
}

.fade-mobile-menu-button-enter,
.fade-mobile-menu-button-leave-to {
  opacity: 0;
}
</style>
