import HttpService from '../../services/HttpService';

export default class DashboardService extends HttpService {
  constructor() {
    super('/dashboard/api/partner');
  }

  getOverallData(params): Promise<any>{
    this.endpoint = `/dashboard/api/partner/overall`;
    return this.get(params);
  }

  getFeaturedPrograms(params): Promise<any>{
    this.endpoint = `/dashboard/api/partner/featured-programs`;
    return this.get(params);
  }
}
