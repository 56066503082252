import { defineStore } from 'pinia';


export const useAppStateStore = defineStore({
  id: 'appState',
  state: () => ({
    activeFeature: null
  }),
  getters: {
    getActiveFeature: (state) => state.activeFeature
  },
  actions: {
    setActiveFeature(feature) {
      this.activeFeature = feature;
    },
    reset() {
      this.activeFeature = null;
    }
  }
})
