<template>
    <Modal :isActiveProp="true" @close="closeAddUserModal(false)" :classProp="'iu-modal-content-container'">
        <div class="iu-modal-content">
            <h4 class="iu-modal-title-text">Add User</h4>
            <div class="iu-modal-form-container">
                <div class="iu-modal-multiple-input-container">
                    <div class="iu-input-field-container">
                        <label for="first-name" class="iu-input-field-label-container iu-input-field-label-text">
                            First Name
                            <span class="iu-input-field-label-required-star">
                                *
                            </span>
                        </label>
                        <input
                            id="first-name"
                            type="text"
                            class="iu-input-field"
                            :class="fieldsWithError.includes('first_name') ? 'field-error': ''"
                            required
                            v-model="user.first_name"
                        >
                    </div>
                    <div class="iu-input-field-container">
                        <label for="last-name" class="iu-input-field-label-container iu-input-field-label-text">
                            Last Name
                            <span class="iu-input-field-label-required-star">
                                *
                            </span>
                        </label>
                        <input
                            id="last-name"
                            type="text"
                            class="iu-input-field"
                            :class="fieldsWithError.includes('last_name') ? 'field-error': ''"
                            required
                            v-model="user.last_name"
                        >
                    </div>
                </div>
                <div class="iu-input-field-container">
                    <input
                        id="email"
                        type="text"
                        class="iu-input-field"
                        :class="fieldsWithError.includes('email') ? 'field-error': ''"
                        required
                        v-model="user.email"
                    >
                    <label for="email" class="iu-input-field-label-container iu-input-field-label-text">
                        Email
                        <span class="iu-input-field-label-required-star">
                            *
                        </span>
                    </label>
                </div>
                <div class="iu-select-field-container">
                    <label for="department" class="iu-select-field-label-container iu-input-field-label-text">
                        Role
                        <span class="iu-input-field-label-required-star">
                            *
                        </span>
                    </label>
                    <select
                        name="department"
                        id="department"
                        class="iu-select-field"
                        v-model="user.role"
                    >
                        <option
                            v-for="role in roleList"
                            :key="role.id"
                            :value="role"
                            class="iu-select-field-option"
                        >
                            {{role.name}}
                        </option>
                    </select>
                    <span class="iu-select-field-arrow"><i class="fa-solid fa-chevron-down"></i></span>
                </div>
            </div>
            <div class="iu-modal-checkbox-container">
                <label class="eu-checkbox-container eu-event-collection-checkmark-label" style="font-size: 14px; margin: 0px; height: unset; width: unset; padding-left: 28px;">
                    Send a confirmation email
                    <input
                        type="checkbox"
                        v-model="user.sendConfirmationEmail"
                        style="margin-right: 8px;"
                    >
                    <span class="eu-checkmark" style="margin: 0;"></span>
                </label>
                <label v-if="isSSOEnabled" class="eu-checkbox-container eu-event-collection-checkmark-label" style="font-size: 14px; margin: 0px; height: unset; width: unset; padding-left: 28px;">
                Import as a {{sso_provider}} account
                    <input
                        type="checkbox"
                        v-model="user.isSSO"
                        style="margin-right: 8px;"
                    >
                    <span class="eu-checkmark" style="margin: 0;"></span>
                </label>
            </div>
            <div class="iu-modal-separator"></div>
            <div class="iu-modal-actions-container">
                <button
                    class="iu-main-btn small solid main iu-modal-save-button"
                    aria-label="Add new user"
                    @click="submitSave"
                >Save</button>
                <button
                    class="iu-main-btn small outlined gray"
                    aria-label="Close add user modal"
                    @click="closeAddUserModal"
                >Cancel</button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '../../../components/ModalExtended.vue'

export default {
    name: 'add-user-modal',

    components: {
        Modal,
    },

    props: [
        'roleList',
        'sso_provider',
    ],

    computed: {
        isSSOEnabled() {
            return !!this.sso_provider;
        },
    },

    data() {
        return {
            user: {
                id: 0,
                first_name: "",
                last_name: "",
                email: "",
                sendConfirmationEmail: false,
                isSSO: false,
                role: {id: 0, name: ''},
            },
            fieldsWithError: [],
        }
    },

    created() {
        this.initializeUser();
    },

    methods: {
        initializeUser() {
            this.user = {
                id: 0,
                first_name: "",
                last_name: "",
                email: "",
                sendConfirmationEmail: false,
                isSSO: false,
                role: this.roleList[0],
            }
        },
        closeAddUserModal(clearFields=true) {
            if (clearFields) {
                this.initializeUser();
            }
            this.$emit('closeAddUserModal');
        },
        isFieldValid(field_name) {
            return !this.fieldsWithError.includes(field_name);
        },
        validateFieldIsNotEmpty(field_name) {
            if (this.user[field_name] == "") {
                if (!this.fieldsWithError.includes(field_name)) {
                    this.fieldsWithError.push(field_name);
                }
            } else {
                this.fieldsWithError = this.fieldsWithError.filter(item => item !== field_name);
            }
        },
        validateFields() {
            this.validateFieldIsNotEmpty("first_name");
            this.validateFieldIsNotEmpty("last_name");
            this.validateFieldIsNotEmpty("email");

            return this.fieldsWithError.length == 0;
        },
        submitSave() {
            const valid = this.validateFields();

            if (valid) {
                this.$emit('addUser', this.user);
            }
        },
    },
}
</script>
