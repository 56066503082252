<template>
  <settings-wrapper activeTab="profile">
    <section class="profile-form">
      <div class="profile-form-header-container">
        <h2>Edit Profile</h2>
      </div>
      <div v-if="isLoading" id="objects-list-loading-spinner">
        <loading-spinner />
      </div>
      <div v-else>
        <div class="profile-form-section dropzone-container">
          <div>
            <h3>Cover Photo</h3>
            <p class="inup-thumbnail-desc">Use a high quality, landscape orientation image for best results.</p>
            <dropzone-component
              v-if="imageInput"
              :imageSrc="profile.cover_pic"
              :imageInput="imageInput"
              :maxImageSize="image_size_data.max_size"
              :maxImageSizeFormat="image_size_data.formatted"
              @uploadImage="prepareImageUpload"
              @removeImage="handleRemoveImage"
            />
            <input
              type="file"
              name="thumbnail"
              id="thumbnail"
              class="js-input-file-control da-components-hidden-file-control"
              accept="image/*"
              maxlength="400"
            />
          </div>
        </div>

        <div class="profile-form-section small-dropzone-container">
          <h3 class="small-margin-bottom">Basic Information</h3>
          <small-dropzone-component
            v-if="logoInput"
            :imageSrc="profile.profile_pic"
            :imageInput="logoInput"
            :maxImageSize="image_size_data.max_size"
            :maxImageSizeFormat="image_size_data.formatted"
            changeButtonText="Change Profile Picture"
            uploaderText="Upload Profile Picture"
            @removeImage="handleRemoveLogo"
            @uploadImage="prepareLogoUpload"
          />
          <input
            type="file"
            name="logo"
            id="logo"
            class="js-input-file-control da-components-hidden-file-control"
            accept="image/*"
            maxlength="400"
          />

          <div class="inup-form-element-container multiple" style="margin-top: 24px">
            <div class="inup-form-element-container small">
              <input type="text" v-model="user.first_name" id="firstName" class="inup-input" />
              <div v-if="v$.user.first_name.$error" class="error">First name is required</div>
              <div class="inup-form-label-container">
                <label for="firstName">First Name<span>*</span></label>
              </div>
            </div>

            <div class="inup-form-element-container small">
              <input type="text" v-model="user.last_name" id="lastName" class="inup-input" maxlength="255" />
              <div v-if="v$.user.last_name.$error" class="error">Last name is required</div>
              <div class="inup-form-label-container">
                <label for="lastName">Last Name<span>*</span></label>
              </div>
            </div>
          </div>
          <div class="inup-form-element-container">
            <textarea
              name="description"
              cols="40"
              rows="10"
              v-model="profile.about_me"
              id="desc"
              class="inup-textarea-input no-resize"
            />
            <div v-if="v$.profile.about_me.$error" class="error">This field must be less than 500 characters.</div>
            <div class="inup-form-label-container">
              <label for="desc">About me</label>
            </div>
          </div>
        </div>

        <div class="profile-form-section">
          <h3 class="medium-margin-bottom">Welcome Video</h3>
          <div class="inup-form-element-container">
            <input type="text" v-model="profile.welcome_video_url" id="video" class="inup-input" maxlength="255" />
            <div v-if="v$.profile.welcome_video_url.$error" class="error">This field should be a valid URL.</div>
            <div class="inup-form-label-container">
              <label for="video">Youtube URL</label>
            </div>
          </div>
        </div>

        <div class="profile-form-section">
          <h3 class="medium-margin-bottom">Contact Information</h3>

          <div class="inup-form-element-container multiple">
            <div class="inup-form-element-container small">
              <input type="text" v-model="profile.mobile_number" id="phone_number" class="inup-input" />
              <div class="inup-form-label-container">
                <label for="phone_number">Phone</label>
              </div>
            </div>

            <div class="inup-form-element-container small">
              <input type="email" v-model="user.email" id="email" class="inup-input" maxlength="255" />
              <div v-if="v$.user.email.$error" class="error">This field is required and should be a valid email.</div>
              <div class="inup-form-label-container">
                <label for="email">Email<span>*</span></label>
              </div>
            </div>
          </div>

          <div class="inup-form-element-container">
            <input type="text" v-model="profile.website" id="website" class="inup-input" maxlength="255" />
            <div v-if="v$.profile.website.$error" class="error">Website should be a valid URL.</div>
            <div class="inup-form-label-container">
              <label for="website">Website URL</label>
            </div>
          </div>

          <div class="eu-form-element-container">
            <multiple-input-select
              v-if="socialInput"
              :input="socialInput"
              :optionList="social_options"
              :filledOptions="profile.social_networks"
              selectLabel="Social Link"
              inputLabel="URL"
            />
            <input type="hidden" name="social_networks" id="social-networks-input" />
          </div>
        </div>

        <div class="profile-form-button-container">
          <button
            @click="submit"
            @keyup.enter="submit"
            aria-label="Save changes"
            type="submit"
            name="Save"
            class="eu-btn-primary-small"
            style="height: 41px; width: 91px"
          >
            Save
          </button>
        </div>
      </div>
      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </section>
  </settings-wrapper>
</template>
<script lang="ts">
import Notification from "../../components/Notification.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import FeedService from "../../feed/services/FeedService";
import Multiselect from "vue-multiselect";
import SettingsWrapper from "./SettingsWrapper.vue";
import ProfileService from "../../profile/services/ProfileService";
import SmallDropzoneComponent from "../../form/components/SmallDropzoneComponent.vue";
import MultipleInputSelect from "../../form/components/MultipleInputSelect.vue";
import DropzoneComponent from "../../form/components/DropzoneComponent.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, maxLength, url } from "@vuelidate/validators";

export default {
  components: {
    Notification,
    LoadingSpinner,
    Multiselect,
    SettingsWrapper,
    MultipleInputSelect,
    DropzoneComponent,
    SmallDropzoneComponent,
  },
  name: "edit-profile",
  props: [],
  data() {
    return {
      isNotificationActive: false,
      isLoading: true,
      imageInput: null,
      logoInput: null,
      thumbnail: {},
      logo: {},
      socialInput: null,
      user: null,
      profile: null,
      notification: { msg: "", isError: false, icon: null },
    };
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  validations() {
    return {
      user: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
      },
      profile: {
        about_me: { maxLength: maxLength(500) },
        welcome_video_url: { url },
        mobile_number: {},
        website: { url },
      },
    };
  },
  async created() {
    this.service = new ProfileService();
    this.feedService = new FeedService();
    await this.getPreData();
    this.isLoading = false;
  },
  methods: {
    async getPreData() {
      this.isLoading = true;
      const response = await this.service.getProfileSettings();
      if (response) {
        this.user = response.user;
        this.profile = response.profile;
        this.image_size_data = response.image_size_data;
        this.social_options = response.social_options;
      }
      this.isLoading = false;
      this.loadImageInputs();
    },
    loadImageInputs() {
      setTimeout(() => {
        this.imageInput = document.querySelector("#thumbnail");
        this.logoInput = document.querySelector("#logo");
        this.socialInput = document.querySelector("#social-networks-input");
      }, 0);
    },
    async submit() {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      this.isLoading = true;
      try {
        await this.uploadImage("thumbnail", "cover_pic");
        await this.uploadImage("logo", "profile_pic");

        const res = await this.service.updateProfile(
          {
            ...this.user,
            ...this.profile,
          },
          this.headers
        );
        this.handleResponse(res);
      } catch (error) {
        this.showNotification({
          msg: "Error while saving profile",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleResponse(res) {
      if (res?.success) {
        this.showNotification({
          msg: "Profile Successfully Updated",
          icon: "fad fa-check-circle",
        });
      } else {
        this.showNotification({
          msg: res ? res.message : "Server Error",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
    },
    async uploadImage(inputName, profileKey) {
      const fileObj = this[inputName];
      if (fileObj && Object.keys(fileObj).length > 0) {
        const res = await this.feedService.uploadFile(fileObj, this.headers);
        if (res?.resource) {
          this.profile[profileKey] = res.resource;
        } else {
          throw new Error(`${inputName} Upload Failed`);
        }
      }
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    handleRemoveImage() {
      this.profile.cover_pic = "";
      this.thumbnail = {};
    },
    prepareImageUpload(fileObject) {
      this.thumbnail = fileObject;
    },
    handleRemoveLogo() {
      this.profile.profile_pic = "";
      this.logo = {};
    },
    prepareLogoUpload(fileObject) {
      this.logo = fileObject;
    },
  },
  computed: {},
};
</script>
