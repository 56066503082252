<template>
  <section v-if="group">
    <section  class="da-content-main--hero" style="display: grid; grid-template-columns: repeat(16, 1fr); background: #fff; border-bottom: 2px solid #ececec">
      <section class="da-content-main--hero eu-cover-image" :style="`background-image: url(${group.cover_pic});`">
        <div class="blackLayerGradient"></div>
        <h1 class="eu-cover-image-text-top">
          <p class="iu-group-title label">Affinity Group</p>
          <p class="iu-group-title">{{ group.name }}</p>
        </h1>
      </section>
      <div style="grid-column: 2/16; display: flex">
        <div class="department-navbar-wrapper">
          <nav class="eu-simple-navbar">
            <span
              tabindex="0"
              aria-label="Go to department overview page"
              role="link"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'overview' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('overview')"
              @keyup.enter="change_tab('overview')"
            >
              Overview
            </span>
            <span
              v-if="loggedUser.has_activity_feed_access"
              tabindex="0"
              aria-label="Go to group feed page"
              role="link"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'feed' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('feed')"
              @keyup.enter="change_tab('feed')"
            >
              Feed
            </span>
            <span
              tabindex="0"
              aria-label="Go to department admins & mentors page"
              role="link"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'admin_mentors' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('admin_mentors')"
              @keyup.enter="change_tab('admin_mentors')"
            >
              Group Managers
            </span>
            <span
              v-if="loggedUser.has_event_access"
              tabindex="0"
              aria-label="Go to department event page"
              role="button"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'events' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('events')"
              @keyup.enter="change_tab('events')"
            >
              Events
            </span>
            <span
              v-if="loggedUser.resource_library_permission.has_library_resource_access"
              tabindex="0"
              aria-label="Go to department resources page"
              role="button"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'resources' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('resources')"
              @keyup.enter="change_tab('resources')"
            >
              Resources
            </span>
            <span
              tabindex="0"
              aria-label="Go to group followers page"
              role="button"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'followers' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('followers')"
              @keyup.enter="change_tab('followers')"
            >
              Followers
            </span>
          </nav>
        </div>
        <div v-if="loggedUser.isAdmin" class="feed-admin-btns">
          <button
            class="feed-admin-btn"
            style="margin-right: 16px; min-width: 100px"
            @click="handleFollow(group.is_following ? false : true)"
            @keyup.enter="handleFollow(group.is_following ? false : true)"
            :class="`${group.is_following ? 'iu-btn-light' : 'iu-btn-solid'}`"
            :aria-label="`${group.is_following ? 'Unfollow' : 'Follow'}`"
          >
            {{ group.is_following ? "Unfollow" : "Follow" }}
          </button>
          <a class="iu-btn-solid feed-admin-btn" :href="`/groups/${encodeURIComponent(group.name)}/edit`" :aria-label="`Go to ${group.name} edit page `">
            <i class="fa fa-edit" style="opacity: 0.5; margin-right: 5px"></i>
            Edit Group
          </a>
        </div>
      </div>
    </section>
    <div class="departments-main-wrapper">
      <div v-if="selected_tab === 'feed'" class="department-main-content-feed">
        <feed-posts
          :loggedUser="loggedUser"
          :isLoadingPosts="isLoadingPosts"
          :isLoadingScroll="isLoadingScroll"
          :posts="posts"
          :showCreatePost="canWritePost()"
          :groupName="group.name"
          :focusAreas="group.focus_areas"
          :identities="group.identities"
          :audiences="audiences"
          :has_navbar="has_navbar"
          :show_search="show_search"
          @show-all-comments="showAllComments"
          @show-notification="showNotification"
          @get-posts="getPosts"
          @set-is-loading="setIsLoading"
          @search-feed="searchFeed"
          @update-post-reaction="updatePostReaction"
        />
      </div>
      <div class="department-main-content-overview">
        <group-details
          v-if="selected_tab === 'overview'"
          :loggedUser="loggedUser"
          :group="group"
          @show-notification="showNotification"
          @get-posts="getPosts"
          @set-is-loading="setIsLoading"
        />
        <admins-and-mentors-tab
          v-if="selected_tab === 'admin_mentors'"
          :users="users"
          :favoriteMentorsPage="false"
          :loggedUser="loggedUser"
          :ids_favorite_mentors="ids_favorite_mentors"
          @handle-favorite="handleFavoritesMentors"
        />

        <events-tab v-if="selected_tab === 'events'" :group="group" :loggedUser="loggedUser" :isGroupTab="true" />

        <resources-tab v-if="selected_tab === 'resources'" :group="group" :loggedUser="loggedUser" :isGroupTab="true" />

        <followers-tab v-if="selected_tab === 'followers'" type="group" :id="this.group.id" />
      </div>

      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </div>
  </section>
</template>
<script lang="ts">
import * as Cookies from "js-cookie";
import GroupService from "../services/GroupService";
import ResourcesUpload from "../../library/components/ResourcesUpload.vue";
import Notification from "../../components/Notification.vue";
import FeedPosts from "../../feed/components/FeedPosts.vue";
import GroupDetails from "./GroupDetails.vue";
import EventsTab from "../../feed/components/EventsTab.vue";
import ResourcesTab from "../../feed/components/ResourcesTab.vue";
import AdminsAndMentorsTab from "../../feed/components/AdminsAndMentorsTab.vue";
import FollowersTab from "../../feed/components/FollowersTab.vue";
import AccountsService from "../../accounts/services/AccountsService";
import FeedService from "../../feed/services/FeedService";
import { FEATURES, hasPermission, PERMISSION_TYPES } from '../../utils/PermissionUtils';

export default {
  components: { ResourcesUpload, Notification, FeedPosts, GroupDetails, AdminsAndMentorsTab, ResourcesTab, EventsTab, FollowersTab },
  name: "group-index",
  props: [],
  data() {
    return {
      posts: [],
      events: [],
      isLoadingPosts: true,
      isLoadingScroll: false,
      isFetchPending: false,
      pageCount: 1,
      searchHolder: "",
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      searchResultsShown: false,
      has_navbar: false,
      show_search: false,
      selected_tab: "overview",
      users: [],
      ids_favorite_mentors: [],
      followers: [],
      loggedUser: null,
      group: null,
      audiences: [],
      active_tab: null,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  async created() {
    this.service = new GroupService();
    this.accountsService = new AccountsService();
    this.feedService = new FeedService();
    await this.getGroupData()
    this.active_tab = this.$route.query.active_tab;
    if (this.active_tab && ['overview', 'feed', 'admin_mentors', 'events', 'resources', 'followers'].includes(this.active_tab)) {
      this.change_tab(this.active_tab);
    }
    this.followers = this.service.getGroupFollowers({ group: this.group.id });
    this.scroll();
    await this.getPosts();
  },
  methods: {
    async getGroupData() {
      const groupName = this.$route.path.split("/").pop();
      const res = await this.service.getPredataGroup(groupName);
      if (res.group) {
        this.group = res.group;
        this.audiences = res.audiences;
        this.loggedUser = {
          id: res.loggedUser,
          profile_pic: res.profile_pic,
          fullname: res.user_fullname,
          isAdmin: res.isAdmin,
          isMentor: res.isMentor,
          has_event_access: hasPermission(FEATURES.EVENTS),
          has_event_managing_access: hasPermission(FEATURES.EVENTS, PERMISSION_TYPES.MANAGE),
          hasWriteAccess: hasPermission(FEATURES.ACTIVITY_FEED, PERMISSION_TYPES.READ_WRITE),
          has_activity_feed_access: hasPermission(FEATURES.ACTIVITY_FEED),
          userManageGroups: res.user_manage_groups,
          resource_library_permission: {
            has_library_resource_access: hasPermission(FEATURES.RESOURCE_LIBRARY),
            has_library_resource_managing_access: hasPermission(FEATURES.RESOURCE_LIBRARY, PERMISSION_TYPES.MANAGE),
          }
        }
      }
    },
    async handleFollow(isFollowing: boolean) {
      this.group.is_following = !this.group.is_following;
      const res = await this.feedService.followGroupFeed({ isFollowing, group: this.group.name }, this.headers);
      if (!res.success) {
        this.showNotification({ msg: "Server error!", isError: true, icon: "fad fa-exclamation-circle" });
      }
    },
    showAllComments(post) {
      this.posts = this.posts.map((p) => {
        if (post.id === p.id) {
          p.shrinkComments = false;
        }
        return p;
      });
    },
    checkCommentSize() {
      this.posts = this.posts.map((p) => {
        if (p.comments.length >= 3 && p.shrinkComments === undefined) {
          p.shrinkComments = true;
        }
        return p;
      });
    },
    canWritePost() {
      return this.loggedUser.hasWriteAccess && this.loggedUser?.userManageGroups.includes(this.group.name);
    },
    setIsLoading(isLoading) {
      this.isLoadingPosts = isLoading;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    async getPosts(pageNo, minPosts = 3) {
      this.pageCount = pageNo ? pageNo : this.pageCount;
      let newPosts = [];
      // with this while loop, we make sure that as a result we get at least 5 new posts
      while (newPosts.length <= minPosts) {
        this.isFetchPending = true;
        // get all posts from getstream (even if they're not the right type)
        const rawRes = await this.service.getGroupPosts({ group: encodeURIComponent(this.group.name), q: this.searchHolder, page: this.pageCount });
        if (rawRes.length === 0) {
          break;
        }
        // filter out right type of posts
        const res = rawRes.filter((p) => p.verb.includes("post"));
        newPosts = [...newPosts, ...res];
        if (newPosts.length <= minPosts) {
          this.pageCount++;
        }
      }
      this.posts = newPosts;
      this.posts &&
        this.posts.forEach((p) => {
          p.object = JSON.parse(p.object);
        });
      this.checkCommentSize();
      this.isFetchPending = false;
      this.isLoadingPosts = false;
    },
    async searchFeed(searchInput) {
      if (searchInput.length > 0) {
        this.searchResultsShown = true;
      } else this.searchResultsShown = false;
      this.searchHolder = searchInput;
      this.isLoadingPosts = true;
      await this.getPosts(1, 0);
    },
    async updatePostReaction(p) {
      p.object = JSON.parse(p.object);
      this.posts = this.posts.map((e) => {
        if (e.id === p.id) {
          e.own_reactions = p.own_reactions;
          e.reaction_counts = p.reaction_counts;
          e.comments = p.comments;
          e.newComment = "";
        }
        return e;
      });
    },
    async scroll() {
      window.onscroll = async () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;

        if (bottomOfWindow && this.pageCount !== 0 && !this.searchResultsShown && !this.isFetchPending && this.selected_tab == 'feed') {
          this.pageCount += 1;

          if (!this.isLoadingPosts) {
            this.isLoadingScroll = true;
          }
          if (!this.isFetchPending) {
            let newPosts = [];
            while (newPosts.length <= 3) {
              this.isFetchPending = true;
              // get all posts from getstream (even if they're not the right type)
              const rawRes = await this.service.getGroupPosts({
                page: this.pageCount,
                group: encodeURIComponent(this.group.name),
                q: this.searchHolder,
              });
              if (rawRes.length === 0) {
                this.pageCount = 0;
                break;
              }
              // filter out right type of posts
              const res = rawRes.filter((p) => p.verb.includes("post"));
              newPosts = [...newPosts, ...res];
              if (newPosts.length <= 3) {
                this.pageCount++;
              }
            }

            newPosts.forEach((p) => {
              p.object = JSON.parse(p.object);
              this.posts.push(p);
            });
            this.isLoadingScroll = false;
            this.isFetchPending = false;
            this.checkCommentSize();
          }
        }
      };
    },
    async change_tab(tab) {
      this.selected_tab = tab;
      if (tab == "admin_mentors") {
        this.ids_favorite_mentors = await this.accountsService.getFavoriteMentors({ flag: "ids" });
        this.users = await this.service.getAdminsAndMentors(this.group.name);
      }
    },
    async handleFavoritesMentors(id) {
      if (this.ids_favorite_mentors.includes(id)) {
        await this.accountsService.removeFavoriteMentor(id, this.headers);
      } else {
        await this.accountsService.addFavoriteMentor({ user_id: this.loggedUser.id, mentor_id: id }, this.headers);
      }
      this.ids_favorite_mentors = await this.accountsService.getFavoriteMentors({ flag: "ids" });
    },
  },

  computed: {},
};
</script>
