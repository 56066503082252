<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <quests-header :canManage="isAdmin || isDeptLead" :showAddBtn="isAdmin || isDeptLead"></quests-header>
      <div
        v-if="!isLoading && quests.length > 0"
        class="table-container"
        style="width: 100%; overflow-y: hidden; overflow-x: auto; margin: 30px 0"
      >
        <table v-if="!isLoading" class="eu-resource-table">
          <tr class="eu-resource-row">
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              <span>Quest Name </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              <span>{{deptSingular}} </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              <span>Tasks # </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              <span>Status</span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              <span>Date Added </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col"></th>
            <th v-if="isAdmin" class="eu-resource-cell eu-resource-cell-th" scope="col"></th>
          </tr>
          <tr v-for="item of quests" :key="item.id" class="eu-resource-row">
            <td class="eu-resource-cell clickable" @click="goToQuest(item.id)" @keyup.enter="goToQuest(item.id)">
              <i v-if="item.is_featured" class="fa-solid fa-stars" style="margin-right: 4px; color: #22a5a0;"></i
              ><span>{{ item.name }}</span>
            </td>

            <td class="eu-resource-cell">{{ item.department.name }}</td>
            <td class="eu-resource-cell">{{ item.tasks.length }}</td>
            <td class="eu-resource-cell">{{ item.is_published ? "Published" : "Draft" }}</td>
            <td class="eu-resource-cell">{{ item.timestamp | parseDate }}</td>
            <td class="eu-resource-cell eu-resource-cell-dots-icon">
              <i @click="goToEdit(item.id)" @keyup.enter="goToEdit(item.id)" tabindex="0" class="fas fa-pen"></i>
            </td>
            <td class="eu-resource-cell eu-resource-cell-dots-icon" style="padding-left: 5px">
              <i
                @click="openDeleteModal(item)"
                @keyup.enter="openDeleteModal(item)"
                tabindex="0"
                class="fas fa-trash-can"
              ></i>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="isLoading">
        <loading-spinner wrapperClass="wrapper" />
      </div>
      <div v-if="!isLoading && quests.length == 0">
        <div class="eu-empty-container">
          <div class="eu-empty">
            <div class="eu-empty-icon-container">
              <i class="fad fa-bullhorn eu-empty-icon"></i>
            </div>
            <div style="display: flex; flex-direction: column">
              <span class="eu-empty-text"> No data. </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal :isActiveProp="isDeleteModalActive" @close="closeDeleteModal">
      <div class="iu-resources-delete-modal-content-container">
        <div class="iu-resources-delete-modal-text-section">Are you sure you want to delete this Quest?</div>
        <div class="iu-resources-delete-modal-file-details-section">
          <div class="iu-resources-delete-modal-file-name">
            {{ itemToDelete.name }}
          </div>
        </div>
        <div class="iu-resources-delete-modal-actions-section">
          <button
            id="quest_delete"
            class="iu-resources-delete-modal-actions-button iu-main-btn small solid red"
            @click="deleteItem(itemToDelete.id)"
            aria-label="Remove an item"
          >
            Delete
          </button>
          <button
            class="iu-resources-delete-modal-actions-button iu-main-btn small outlined gray"
            @click="closeDeleteModal"
            aria-label="Close delete modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import QuestService from "../services/QuestService";
import Modal from "../../components/ModalExtended.vue";
import { focusElement } from "../../utils/CustomUtils";
import Notification from "../../components/Notification.vue";
import QuestsHeader from "./QuestsHeader.vue";
import { useCommonDataStore } from '../../stores/commonData';

export default {
  name: "quests-manage",
  components: { LoadingSpinner, Modal, Notification, QuestsHeader },
  props: ["isAdmin", "isDeptLead"],
  computed: {
    deptSingular() {
      return useCommonDataStore().getFeatureName('department_singular')
    },
  },
  data() {
    return {
      isLoading: false,
      quests: [],
      service: new QuestService(),
      isDeleteModalActive: false,
      itemToDelete: { id: null, name: null },
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
    };
  },
  async created() {
    if (!this.isDeptLead && !this.isAdmin) {
      this.$router.push(`/quests/`);
    }
    this.isLoading = true;
    await this.getQuests();
    this.isLoading = false;
  },
  methods: {
    async getQuests() {
      const filters = { draft: true, limit: 9999, page: 1 };
      if(!this.isAdmin && this.isDeptLead) {
          filters.is_dept_lead = true;
      }
      const res = await this.service.getAll(filters);
      this.quests = res.quests;
    },
    goToEdit(questId) {
      this.$router.push(`/quests/edit/${questId}`);
    },
    goToQuest(questId){
      this.$router.push(`/quests/${questId}`);
    },
    openDeleteModal(item) {
      this.isDeleteModalActive = true;
      focusElement("quest_delete");
      this.itemToDelete = { ...item };
    },
    closeDeleteModal() {
      this.isDeleteModalActive = false;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    async deleteItem(questId) {
      this.isLoading = true;
      this.isDeleteModalActive = false;
      const res = await this.service.remove(questId);
      if (res.success) {
        this.showNotification({ msg: "Quest removed successfully.", icon: "fad fa-check-circle" });
        this.getQuests();
      } else {
        this.showNotification({
          msg: "Error while removing a Quest",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.clickable {
    cursor: pointer;
    &:hover {
        color: #22a5a0;
    }
}
</style>
