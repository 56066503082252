<template>
  <div class="card-wrapper" id="card">
    <div style="height: 48%">
      <router-link class="card-thumbnail"  :to="`/departments/${encodeURIComponent(item.name)}`" :aria-label="`Go to page of ${item.name}`" :style="`display:block;background-image: linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 47%), url(${item.cover_pic});`">
        <div class="card-option-wrapper">
          <div style="display: flex; justify-content: flex-end; width: 100%">
            <span>
              <i v-if="false" class="far fa-user-alt card-option-icon"></i>
              <i v-if="false" class="fas fa-user-check card-option-icon active"></i>
            </span>
          </div>
        </div>
      </router-link>
    </div>
    <div id="body" class="card-body">
      <div class="iu-dept-card-wrapper">
        <div class="iu-dept-logo">
            <router-link :to="`/departments/${encodeURIComponent(item.name)}`" :aria-label="`Go to page of ${item.name}`">
          <img :src="item.logo_url" class="iu-dept-logo-img" />
          </router-link>
        </div>
        <div class="iu-dept-text">
          <router-link :to="`/departments/${encodeURIComponent(item.name)}`" :aria-label="`Go to page of ${item.name}`">
            <h5 class="card-title" style="padding-top: 0">{{ item.name }}</h5>
          </router-link>
          <p class="card-description" :style="item.name.length > 16 ? `-webkit-line-clamp: 1` : `-webkit-line-clamp: 2`">
            {{ item.description }}
          </p>
        </div>
      </div>
      <button
        class="dept-follow-btn"
        @click="handleFollow(item.is_following ? false : true)" @keyup.enter="handleFollow(item.is_following ? false : true)"
        :class="`${item.is_following ? 'iu-btn-light' : 'iu-btn-solid'}`"
        :aria-label="`${item.is_following ? 'Unfollow' : 'Follow'}`"
      >
        {{ item.is_following ? 'Following' : 'Follow' }}
      </button>
    </div>
    <div id="footer" class="card-footer"></div>
  </div>
</template>

<script>
export default {
  name: 'dept-card-component',
  props: ['item'],
  data() {
    return {};
  },
  async created() {},
  methods: {
    handleFollow(action) {
      this.$emit('handle-follow', action, this.item.name);
      this.item.is_following = !this.item.is_following;
    },
  },
};
</script>

<style></style>
