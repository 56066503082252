<template>
  <div class="title-container">
    <h1 class="title-text">{{title}}</h1>
    <p v-if="description" class="description-text">{{description}}</p>
  </div>
</template>

<script lang="ts">
export default {
  name: 'common-title',
  props: ['title', 'description'],
}
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.title-text {
  font-size: 40px;
  padding: 0;
  line-height: 1.2;
  color: #333333;
}
.description-text {
  font-size: 16px;
  line-height: 1.35;
  color: #555;
  white-space: pre-line;
}
@media screen and (max-width: 576px) {
  .title-text {
    font-size: 32px;
    line-height: 1.25;
  }
}
</style>
