<template>
    <section>   
        <div 
            v-if="data.has_select_filter" 
            class="analytics-button-container" 
            style="margin-bottom:10px;"
        >
            <multiselect-filter-component
                v-if="data.has_select_filter_department"
                placeholder="Any Department"
                filter_name="Departments"
                :options="filters_options.departments"
                @filter-data="filterData"
            />
            <multiselect-filter-component
                v-if="data.has_select_filter_focus_area"
                placeholder="Any Focus Area"
                filter_name="Focus Area"
                :options="filters_options.focus_area"
                @filter-data="filterData"
            />
            <multiselect-filter-component
                v-if="data.has_select_filter_groups"
                placeholder="Any Groups"
                filter_name="Groups"
                :options="filters_options.groups"
                @filter-data="filterData"
            />
        </div>
        <div v-if="isLoading">
            <loading-spinner />
        </div>

        <tab-container 
            v-if="!isLoading" 
            :items="items"  
            @clicked-tab="changeTab"
        />
        <analytics-body-card-fetch-data 
            v-if="!isLoading && data.has_body_tab"
            :keys="chart_keys"
        />

        <analytics-card-table-fetch-data 
            v-if="!isLoading && !popularity_is_chart" 
            :keys="chart_keys"
            :filters_options="filters_options"
            :filtersData="filtersData"
            :data="data"
        />

        <analytics-chart-fetch-data 
            v-if="!isLoading && popularity_is_chart" 
            :keys="chart_keys" 
            :selectedOption="selectedOption"
            :is_pie_chart="is_pie_chart"
            :is_column_chart="false"
            :is_buble_chart="is_buble_chart"
            :paramsOptional="params"
            :items_domain="[]"
        />
    </section>
</template>

<script>
import MultiselectFilterComponent from '../../../form/components/MultiselectFilterComponent.vue'
import AnalyticsCardTableFetchData from '../AnalyticsCardTableFetchData.vue';
import AnalyticsBodyCardFetchData from '../AnalyticsBodyCardFetchData.vue';
import AnalyticsChartFetchData from '../chart/AnalyticsChartFetchData.vue'
import LoadingSpinner from '../../../components/LoadingSpinner.vue';
import AnalyticsService from '../../services/AnalyticsService';
import TabContainer from './TabContainer.vue';


export default {
    name: "analytics-tab-container-fetch-data",

    components: {
            AnalyticsCardTableFetchData,
            AnalyticsBodyCardFetchData,
            MultiselectFilterComponent,
            AnalyticsChartFetchData,
            LoadingSpinner, 
            TabContainer,
        },
    props:{
        popularity_is_chart:Boolean,
        is_buble_chart:Boolean,
        is_pie_chart:Boolean,
        filters_options: {},
        filtersData : {
            start_date: Date,
            end_date: Date,
            keys: String,
        },
        keys:String, 
        data:{},
    },

    data(){
        return{
            isLoading : true,
            items: [],
            chart_keys: null,
            selectedOption: null,
            copy_items: [],
            restart_data: false,
            service: new AnalyticsService(),
            params: {
                type: Object,
                default: function() {
                    return {};
                },
            },   
            currentActiveTab: null,         
        }
    },

    async created(){       
        if(!this.keys) return;
        this.get_data(this.params);
    },

    methods:{
        changeTab(currentActiveTab, title, keys){
            for(let i of this.items){
                if(i.title === currentActiveTab) {
                    i.is_active = false;
                }
                if(i.title === title) {
                    i.is_active = true;
                    this.currentActiveTab = title;
                }
            }
            this.chart_keys = keys
      
        },
        async get_data(params){
            let data = await this.service.getTabData(this.keys, params);
            this.items = data.tabs;
            this.copy_items = JSON.parse(JSON.stringify(this.items));
            this.copy_items[0].is_active = false;
            if(this.items.length > 0){
                if(this.currentActiveTab){
                    this.changeTab(this.items[0].title, this.currentActiveTab, this.chart_keys)
                }else this.chart_keys = this.items[0].keys;
            }
            
            this.isLoading = false;
        },
        filterData(value, name){
            if(name === 'Departments'){
                this.params = {
                    ...this.params,
                     departments: value.map(v=> encodeURIComponent(v.name))
                }
            }
            else if(name === 'Focus Area'){
                 this.params = {
                    ...this.params,
                    focus_areas: value.map(v=> encodeURIComponent(v.name))
                }
            }
            else if(name === 'Groups'){
                 this.params = {
                    ...this.params,
                    groups: value.map(v=> encodeURIComponent(v.name))
                }
            }
            this.isLoading = true;
            this.get_data(this.params);
        }
    },
    watch:{
        filtersData: async function(newVal, oldVal) {
            let params= {
                ...this.params,
                start_date: this.filtersData.start_date,
                end_date: this.filtersData.end_date
            }
            this.isLoading = true;
            this.params = {...params};
            this.get_data(params);
        }
    }
}
</script>

<style>

</style>