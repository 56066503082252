import Vue from 'vue';
import VueRouter from 'vue-router';
import CampusAppsIndex from './campusapps/components/CampusAppsIndex.vue';
import CampusAppsManage from './campusapps/components/CampusAppsManage.vue';
import CampusAppsForm from './campusapps/components/CampusAppsForm.vue';
import QuestsIndex from './quests/components/QuestsIndex.vue';
import QuestsForm from './quests/components/QuestsForm.vue';
import TasksForm from './quests/components/TasksForm.vue';
import QuestDetails from './quests/components/QuestDetails.vue';
import QuestMode from './quests/components/QuestMode.vue';
import QuestsManage from './quests/components/QuestsManage.vue';
import ResourcesBrowse from './library/components/ResourcesBrowse.vue';
import BlogDetails from './blogs/components/BlogDetails.vue';
import CourseDetails from './courses/components/CourseDetails.vue';
import CoursesBrowse from './courses/components/CoursesBrowse.vue';
import ManageCourses from './courses/components/ManageCourses.vue';
import EditCourse from './courses/components/EditCourse.vue';
import GuideDetails from './guides/components/GuideDetails.vue';
import GuidesBrowse from './guides/components/GuidesBrowse.vue';
import ManageGuides from './guides/components/ManageGuides.vue';
import EditGuide from './guides/components/EditGuide.vue';
import SavedGuides from './guides/components/SavedGuides.vue';
import GroupsDeptsIndex from './groups/components/GroupsDeptsIndex.vue';
import GroupIndex from './groups/components/GroupIndex.vue';
import DepartmentIndex from './departments/components/DepartmentIndex.vue';
import MeetingsIndex from './meetings/components/MeetingsIndex.vue';
import MeetingsCalendar from './meetings/components/MeetingsCalendar.vue';
import ManageMentorsInterface from './meetings/components/ManageComponents/ManageMentorsInterface.vue';
import BookMentor from './meetings/components/BookMentor.vue';
import OneStopInterface from './bookmarks/components/OneStopInterface.vue';
import AnalyticsDetailed from './analytics/components/detailed_pages/AnalyticsDetailed.vue';
import AnalyticsSummary from './analytics/components/Analytics.vue';
import FeedIndex from './feed/components/user_feed/FeedIndex.vue';
import PartnersIndex from './partneraccounts/components/PartnersIndex.vue';
import PartnerIndex from './partneraccounts/components/PartnerIndex.vue';
import DashboardIndex from './dashboard/components/DashboardIndex.vue';
import DashboardAdminIndex from './dashboard/components/DashboardAdminIndex.vue';
import StudentBulkInterface from './accounts/components/StudentBulkInterface.vue';
import ManagePartners from './accounts/components/ManagePartners.vue';
import EventDetails from './events/components/EventDetails.vue';
import DabiEventDetails from './events/components/DabiEventDetails.vue';
import EventAdminManageComponent from './events/components/EventAdminManageComponent.vue';
import OpportunityLeadsManage from './events/components/OpportunityLeadsManage.vue';
import EventsIndex from './events/components/EventsIndex.vue';
import ProfileDetails from './profile/components/ProfileDetails.vue';
import EditInterestsIndex from './settings/components/EditInterestsIndex.vue';
import EditProfile from './settings/components/EditProfile.vue';
import EditMentorProfile from './settings/components/EditMentorProfile.vue';
import Preferences from './settings/components/Preferences.vue';
import ResetPassword from './settings/components/ResetPassword.vue';

import { globalProgressBarOptions } from './GlobalComponents';
import VueProgressBar from 'vue-progressbar';
import { injectUserPropsToRoutes } from './utils/RouterUtils';
import { useAppStateStore } from './stores/appState';
import { isPartnerTenant } from './utils/CustomUtils';
import { isFeatureEnabled } from './navigationGuards';

Vue.use(VueRouter);
Vue.use(VueProgressBar, globalProgressBarOptions); // Setting up VueProgressBar

const initPageTitle = document.title;

const routes = [
  { path: '/', component: DashboardAdminIndex, meta: { feature: 'dashboard', title: 'Dashboard' } },
  // -------------------------------------------------
  { path: '/dashboard', component: DashboardIndex, meta: { feature: 'dashboard', title: 'Dashboard' } },
  // -------------------------------------------------
  { path: '/dashboard/admin', component: DashboardAdminIndex, meta: { feature: 'dashboard', title: 'Dashboard' } },
  // -------------------------------------------------
  { path: '/campusapps', component: CampusAppsIndex, meta: { feature: 'campus_apps', title: 'Campus Apps' } },
  { path: '/campusapps/manage', component: CampusAppsManage, meta: { feature: 'campus_apps', title: 'Campus Apps' } },
  { path: '/campusapps/add', component: CampusAppsForm, meta: { feature: 'campus_apps', title: 'Campus Apps' } },
  { path: '/campusapps/edit/:id', component: CampusAppsForm, meta: { feature: 'campus_apps', title: 'Campus Apps' } },
  // -------------------------------------------------
  {
    path: '/accounts',
    component: StudentBulkInterface,
    meta: { feature: 'manage_accounts', title: 'Users & Accounts' },
  },
  {
    path: '/accounts/partners',
    component: ManagePartners,
    meta: { feature: 'manage_partners', title: 'Manage Partners' },
  },
  {
    path: '/accounts/managers',
    component: ManagePartners,
    meta: { feature: 'manage_managers', title: 'Manage Managers' },
  },
  // -------------------------------------------------
  { path: '/quests', component: QuestsIndex, meta: { feature: 'quests', title: 'Quests' } },
  { path: '/quests/add', component: QuestsForm, meta: { feature: 'quests', title: 'Quests' } },
  { path: '/quests/edit/:id', component: QuestsForm, meta: { feature: 'quests', title: 'Quests' } },
  { path: '/quests/manage', component: QuestsManage, meta: { feature: 'quests', title: 'Quests' } },
  { path: '/quests/tasks/:questId', component: TasksForm, meta: { feature: 'quests', title: 'Quests' } },
  { path: '/quests/:questId', component: QuestDetails, meta: { feature: 'quests', title: 'Quests' } },
  { path: '/quests/questMode/:questId', component: QuestMode, meta: { feature: 'quests', title: 'Quests' } },
  // -------------------------------------------------
  { path: '/courses', component: CoursesBrowse, meta: { feature: 'courses', title: 'Courses' } },
  { path: '/courses/manage', component: ManageCourses, meta: { feature: 'courses', title: 'Courses' } },
  { path: '/courses/:id', component: CourseDetails, meta: { feature: 'courses', title: 'Courses' } },
  { path: '/courses/edit/:id', component: EditCourse, meta: { feature: 'courses', title: 'Courses' } },
  // -------------------------------------------------
  { path: '/guides', component: GuidesBrowse, name: 'browse-guides', meta: { feature: 'guides', title: 'Guides' } },
  {
    path: '/guides/manage',
    component: ManageGuides,
    name: 'manage-guides',
    meta: { feature: 'guides', title: 'Guides' },
  },
  { path: '/guides/saved', component: SavedGuides, name: 'saved-guides', meta: { feature: 'guides', title: 'Guides' } },
  { path: '/guides/edit/:id', component: EditGuide, meta: { feature: 'guides', title: 'Guides' } },
  { path: '/guides/:id', component: GuideDetails, meta: { feature: 'guides', title: 'Guides' } },
  // -------------------------------------------------
  { path: '/groups', component: GroupsDeptsIndex, meta: { feature: 'groups', title: 'Groups' } },
  { path: '/groups/:groupName', component: GroupIndex, meta: { feature: 'groups', title: 'Groups' } },
  {
    path: '/groups/:groupName/events/:id',
    component: isPartnerTenant() ? DabiEventDetails : EventDetails,
    meta: { feature: 'events', title: 'Opportunities', type: 'event' },
  },
  {
    path: '/groups/:groupName/programs/:id',
    component: isPartnerTenant() ? DabiEventDetails : EventDetails,
    meta: { feature: 'events', title: 'Opportunities', type: 'program' },
  },
  // -------------------------------------------------
  { path: '/departments', component: GroupsDeptsIndex, meta: { feature: 'depts', title: 'Departments' } },
  { path: '/departments/:deptName', component: DepartmentIndex, meta: { feature: 'depts', title: 'Departments' } },
  {
    path: '/departments/:deptName/events/:id',
    component: isPartnerTenant() ? DabiEventDetails : EventDetails,
    meta: {
      feature: 'events',
      title: 'Opportunities',
      type: 'event',
    },
  },
  {
    path: '/departments/:deptName/programs/:id',
    component: isPartnerTenant() ? DabiEventDetails : EventDetails,
    meta: {
      feature: 'events',
      title: 'Opportunities',
      type: 'program',
    },
  },
  // -------------------------------------------------
  { path: '/opportunities', component: EventsIndex, meta: { feature: 'events', title: 'Opportunities' } },
  { path: '/events', redirect: '/opportunities' },
  { path: '/events/all', component: EventsIndex, meta: { feature: 'events', title: 'Events' } },
  {
    path: '/opportunities/manage',
    component: EventAdminManageComponent,
    meta: { feature: 'events', title: 'Opportunities' },
  },
  {
    path: '/opportunities/leads',
    component: OpportunityLeadsManage,
    meta: { feature: 'events', title: 'Opportunity Leads' },
  },
  {
    path: '/events/:id',
    component: isPartnerTenant() ? DabiEventDetails : EventDetails,
    meta: { feature: 'events', title: 'Opportunities', type: 'event' },
  },
  {
    path: '/programs/:id',
    component: isPartnerTenant() ? DabiEventDetails : EventDetails,
    meta: { feature: 'events', title: 'Opportunities', type: 'program' },
  },
  // -------------------------------------------------
  { path: '/feed', component: FeedIndex, meta: { feature: 'activity_feed', title: 'Feed' } },
  // -------------------------------------------------
  { path: '/profiles/:profileUrl', component: ProfileDetails, meta: { feature: 'profiles', title: 'My Profile' } },
  // -------------------------------------------------
  {
    path: '/settings/profile',
    component: EditProfile,
    meta: { feature: 'settings', title: 'Settings - Edit Profile' },
  },
  {
    path: '/settings/interests',
    component: EditInterestsIndex,
    meta: { feature: 'settings', title: 'Settings - My Interests' },
  },
  {
    path: '/settings/preferences',
    component: Preferences,
    meta: { feature: 'settings', title: 'Settings - Preferences' },
  },
  {
    path: '/settings/password',
    component: ResetPassword,
    meta: { feature: 'settings', title: 'Settings - Reset Password' },
  },
  {
    path: '/settings/mentor-profile',
    component: EditMentorProfile,
    meta: { feature: 'settings', title: 'Settings - Mentor Profile' },
  },
  // -------------------------------------------------
  { path: '/library', component: ResourcesBrowse, meta: { feature: 'resource_library', title: 'Library' } },
  {
    path: '/library/blog/:slug',
    component: BlogDetails,
    props: (route) => ({
      slug: route.params.slug,
      disableBreadcrumbs: true,
      isUserLoggedIn: true,
    }),
    meta: { loginRequired: true, feature: 'resource_library', title: 'Library' },
  },
  // -------------------------------------------------
  {
    path: '/my-hub',
    component: OneStopInterface,
    name: 'my-hub',
    meta: { feature: 'one_stop', title: 'My Hub' },
  },
  {
    path: '/analytics',
    component: AnalyticsSummary,
    name: 'analytic-summary',
    meta: { feature: 'analytics', title: 'Analytics' },
  },
  {
    path: '/analytics/detailed/:key',
    component: AnalyticsDetailed,
    name: 'analytics-details',
    meta: { feature: 'analytics', title: 'Analytics' },
    props: (route) => ({
      initialPage: route.params.key,
    }),
  },
  // -------------------------------------------------
  {
    path: '/browse',
    component: PartnersIndex,
    name: 'partner-browse',
    meta: {
      feature: 'partner_account',
      title: 'Partners',
    },
  },
  {
    path: '/partners/:partner_name',
    component: PartnerIndex,
    name: 'partner-details',
    meta: {
      feature: 'partner_account',
      title: 'Partner',
    },
    props: (route) => ({
      partner_name: route.params.partner_name,
    }),
  },
  {
    path: '/partners/:partner_name/events/:id',
    component: isPartnerTenant() ? DabiEventDetails : EventDetails,
    meta: { feature: 'events', title: 'Opportunities', type: 'event' },
  },
  {
    path: '/partners/:partner_name/programs/:id',
    component: isPartnerTenant() ? DabiEventDetails : EventDetails,
    meta: {
      feature: 'events',
      title: 'Opportunities',
      type: 'program',
    },
  },
  // -------------------------------------------------
  {
    path: '/meetings',
    component: MeetingsIndex,
    name: 'my-meetings',
    meta: { feature: 'appointments', title: 'MentorConnect' },
    props: (route) => ({
      tab: route.query.tab,
    }),
  },
  {
    path: '/meetings/calendar',
    component: MeetingsCalendar,
    name: 'meetings-calendar',
    meta: { feature: 'appointments', title: 'MentorConnect' },
  },
  {
    path: '/meetings/manage',
    component: ManageMentorsInterface,
    name: 'meetings-manage-mentors',
    meta: { feature: 'appointments', title: 'MentorConnect' },
  },
  {
    path: '/meetings/book/:profile_url',
    component: BookMentor,
    name: 'meeting-book-mentor',
    meta: { feature: 'appointments', title: 'MentorConnect' },
  },
];

const processedRoutes = injectUserPropsToRoutes(routes);

const router = new VueRouter({
  mode: 'history',
  routes: processedRoutes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

const app = Vue.prototype;

// Navigation guard
router.beforeEach(async (to, from, next) => {
  initProgressBar(to);
  updatePageTitle(to.meta.title);
  if (to.meta.feature) {
    let feature = to.meta.feature;
    // -----
    // --- ad-hoc event/program split

    if (feature === 'events') {
      const eventsEnabled = await isFeatureEnabled(feature);
      if (!eventsEnabled) {
        feature = 'programs';
      }
    }
    // -----
    const featureAvailable = await isFeatureEnabled(feature);
    if (!featureAvailable) {
      next('/not-found');
    } else {
      onFeatureChange(to);
      next();
    }
  } else {
    onFeatureChange(to);
    next();
  }
});

router.afterEach((to, from) => {
  app.$Progress.finish();
});

const onFeatureChange = (to) => {
  const feature = to.meta.feature;
  const appStateStore = useAppStateStore();
  appStateStore.setActiveFeature(feature);
};

const updatePageTitle = (title) => {
  if (title) {
    document.title = `${title} ${initPageTitle}`;
  } else {
    document.title = initPageTitle.substring(1);
  }
};

const initProgressBar = (to) => {
  if (app.$Progress) {
    app.$Progress.start();
  }
  // Check if the route has meta progress info
  if (to.meta.progress !== undefined) {
    let meta = to.meta.progress;
    if (app.$Progress) {
      // parse meta tags
      app.$Progress.parseMeta(meta);
    }
  }
};

export default router;
