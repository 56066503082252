import { Base64 } from 'js-base64';

export function getYoutubeOrVimeoEmbedLink(url) {
    var VimeoRegExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    var match = url.match(VimeoRegExp);
    if (match) {
      return `https://player.vimeo.com/video/${match[2]}?title=0&byline=0&portrait=0`;
    } else {
      var ID = '';
      url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
        return `https://www.youtube.com/embed/${ID}`;
      } else if(url[0].includes('tiktok')) {
        ID = (url[0].split('/').pop()).split('?')[0];
        return ID;
      }

    }
}


export const loadTikTokScript = (url) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById('tiktok-script')) {
      resolve('loaded');
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = url;
    script.setAttribute('id', 'tiktok-script');

    script.onload = () => {
      // script is loaded successfully, call resolve()
      resolve('loaded');
    };

    script.onerror = () => {
      // script is not loaded, call reject()
      reject('error');
    };

    document.head.appendChild(script);
  });
}

export function isURLInvalid(string) {
  const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return !pattern.test(string);
}

export function focusElement(elementId: string, time:number = 0){
  setTimeout(() => {
    const element = document.getElementById(elementId);

    if (element) {
      element.focus();
    }
  }, time);
}

export function focus_burger_menu(elemntId:string, view:string){
  view == "list"? focusElement(`resource_burger_list_${elemntId}`): focusElement(`resource_burger_grid_${elemntId}`)
}

export function isPartnerTenant() {
  const tenantTypeEl: HTMLElement = document.querySelector('.js-is-partner-tenant');
  //the lines below are a quick fix we should probably find a better solution
  if (tenantTypeEl.dataset.isPartnerTenant) {
    return JSON.parse(tenantTypeEl.dataset.isPartnerTenant.toLowerCase());
  }
  return null;
}

export const capitalizeFirstLetter = (string: string) => {

  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else return null;
};

export const capitalizeFirstLetterOnly = (string: string) => {

  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  } else return null;
};

export function getBackendEnvironment() {
  const clientXpPathData: HTMLElement = document.querySelector(
    ".js-data-client-xp-path"
  );
  const clientXpPath = clientXpPathData.dataset.clientXpPath;
  return Base64.decode(clientXpPath).split('*')[1]
}

export function isValidEmailString(email:string, entity:string='general' ): boolean {
  let entitiesList = [
    'accounts',
  ]
  if (entitiesList.includes(entity)){
    var regex = /^(\w|\.|\_|\-)+[@](\w|\_|\-|\.)+[.]\w{2,5}$/
  }
  else {
    var regex = getBackendEnvironment() === 'prod' ? /^(\w|\.|\_|\-)+[@](\w|\_|\-|\.)+[.]\w{2,3}$/ : /^(\w|\.|\_|\-)+[@](\w|\_|\-|\.)+[.]\w{2,5}$/
  }
  return regex.test(email);
}

export function formatTextToHtml(text) {
  // Define a regex pattern for URLs
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[A-Z0-9+&@#/%=~_|])/gi;

  // Replace URLs with anchor tags
  text = text.replace(urlRegex, (url) => {
    return `<a aria-label="This is a link based on the post" target="_blank" rel="noopener noreferrer" href="${url}">${url}</a>`;
  });

  // Replace escaped newlines with HTML breaks
  text = text.replace(/\\n/g, '<br>');
  text = text.replace(/\n/g, '<br>');

  return decodeUnicode(text);
}

export function decodeUnicode(str) {
  str = str.replace(/\\"/g, '"');
  return str.replace(/\\u[\dA-F]{4}/gi, (match) => {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
  });
}
