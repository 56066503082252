<template>
  <section
    class="da-partners-alumni-modal"
    :class="{ modalActive: isActive }"
    @click="$emit('close')">
    <div :class="classList" :style="style" @click.stop>
      <slot></slot>
    </div>
  </section>
</template>

<script lang='ts'>
  export default {
    name: 'modal',
    props: ['isActiveProp', 'styleProp', 'classProp'],
    watch: {
      isActiveProp: {
        immediate: true,
        handler(newValue, oldValue) {
          this.isActive = newValue;
        },
      },
    },
    data() {
      return {
        isActive: this.isActiveProp,
        style: this.styleProp ? this.styleProp : '',
        classList: this.classProp ? this.classProp : 'modal-content'
      };
    },
  }
</script>
