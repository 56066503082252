<template>
  <section>
    <div class="eu-simple-title" v-if="roleId === '' && !userEdit">Create New Role</div>
    <div class="eu-simple-title" v-else-if="!userEdit">Edit {{ capitalizeFirstLetter(name) }} Role</div>
    <form class="da-forms">
      <div class="permission-desc" v-if="!userEdit">
        <div class="permission-desc-left"><i class="fas fa-lightbulb permissions-bulb"></i></div>
        <div class="permission-desc-right">
          <p class="permission-desc-title">Permissions Defined</p>
          <p class="permission-desc-subtitle">
            <span class="permission-dot"></span>Read Only:
            <span class="permission-desc-text"
              >User is able to read but cannot make changes to the associated resource.
            </span>
          </p>
          <p class="permission-desc-subtitle">
            <span class="permission-dot"></span>Read/Write:
            <span class="permission-desc-text"
              >User is able to read and update the associated resource in a limited capacity, but cannot create or
              delete.
            </span>
          </p>
          <p class="permission-desc-subtitle">
            <span class="permission-dot"></span>Manage:
            <span class="permission-desc-text"
              >User has read/write permissions and is able to create and delete the associated resource.
            </span>
          </p>
          <p class="permission-desc-subtitle" style="margin-bottom: 30px">
            <span class="permission-dot"></span>Manage with Grant:
            <span class="permission-desc-text"
              >User has read, write and manage permissions and is able to grant this permission to other users.
            </span>
          </p>
        </div>
      </div>

      <div v-if="!readonly && !userEdit" class="field-wrapper hasValue" style="max-width: 875px; margin-bottom: 60px">
        <div class="da-form-formItem permission-input">
          <input
            type="text"
            class="da-components-forms-input large js-da-login-email"
            style="background-color: #f8f8f8"
            maxlength="255"
            v-model="name"
          />
        </div>
        <div class="field-placeholder">
          <span style="background-color: #f8f8f8"
            >Role Name<span style="background-color: #f8f8f8" class="required-star">*</span></span
          >
        </div>
      </div>

      <div v-if="!readonly && !userEdit" class="field-wrapper hasValue" style="max-width: 875px; margin-bottom: 60px">
        <div class="da-form-formItem permission-input">
          <input
            type="text"
            class="da-components-forms-input large js-da-login-email"
            style="background-color: #f8f8f8"
            maxlength="255"
            v-model="description"
          />
        </div>
        <div class="field-placeholder">
          <span style="background-color: #f8f8f8"
            >Role Description<span style="background-color: #f8f8f8" class="required-star">*</span></span
          >
        </div>
      </div>

      <h4 style="color: #444444; font-size: 26px; font-weight: 500; line-height: 1.54; margin-top: 60px">
        Permissions by Feature
      </h4>
      <p v-if="readonly && !userEdit" style="font-size: 18px; color: #707070; margin: 5px 0 20px">
        Permissions for the "{{ capitalizeFirstLetter(name) }}" role cannot be changed.
      </p>
      <hr class="permission-hr" />
      <div
        v-for="perm in permissions"
        v-bind:key="perm.name"
        class="da-form-formItem"
        :style="readonly ? 'opacity: 0.5; pointer-events:none' : ''"
      >
        <h4 class="permission-h4">{{ capitalizeFirstLetter(remapPermissionName(perm.name)) }}</h4>
        <p class="permission-p" v-for="verb in verbs" v-bind:key="perm.name + verb.name">
          <label class="radio-container">
            {{ permissionsEnum[verb.name] }}
            <input
              type="radio"
              :name="perm.value + '-radio-group'"
              :value="verb.value"
              v-model="perm.permissionLevel"
            />
            <span class="radio-checkmark"></span>
          </label>
        </p>
        <hr class="permission-hr" />
      </div>
      <div class="da-form-formItem" :style="readonly ? 'opacity: 0.5; pointer-events:none' : ''">
        <h4 class="permission-h4">Mentor</h4>
        <p class="permission-p">
          <label class="radio-container">
            Yes
            <input type="radio" :name="'mentor-radio-group'" :value="true" v-model="isMentor" />
            <span class="radio-checkmark"></span>
          </label>
        </p>
        <p class="permission-p">
          <label class="radio-container">
            No
            <input type="radio" :name="'mentor-radio-group'" :value="false" v-model="isMentor" />
            <span class="radio-checkmark"></span>
          </label>
        </p>
        <hr class="permission-hr" />
      </div>
      <div
        v-if="!isPartnerTenant"
        class="da-form-formItem"
        :style="readonly ? 'opacity: 0.5; pointer-events:none' : ''"
      >
        <h4 class="permission-h4">Department Lead</h4>
        <p class="permission-p">
          <label class="radio-container">
            Yes
            <input type="radio" :name="'dept-radio-group'" :value="true" v-model="isDeptLead" />
            <span class="radio-checkmark"></span>
          </label>
        </p>
        <p class="permission-p">
          <label class="radio-container">
            No
            <input type="radio" :name="'dept-radio-group'" :value="false" v-model="isDeptLead" />
            <span class="radio-checkmark"></span>
          </label>
        </p>
        <hr class="permission-hr" />
      </div>

      <div class="da-form-formItem" :style="readonly ? 'opacity: 0.5; pointer-events:none' : ''">
        <h4 class="permission-h4">Manage Affinity Groups</h4>
        <p class="permission-p">
          <label class="radio-container">
            Yes
            <input type="radio" :name="'group-radio-group'" :value="true" v-model="canManageCommunityGroups" />
            <span class="radio-checkmark"></span>
          </label>
        </p>
        <p class="permission-p">
          <label class="radio-container">
            No
            <input type="radio" :name="'group-radio-group'" :value="false" v-model="canManageCommunityGroups" />
            <span class="radio-checkmark"></span>
          </label>
        </p>
        <hr class="permission-hr" />
      </div>

      <div class="da-form-formItem" :style="readonly ? 'opacity: 0.5; pointer-events:none' : ''">
        <h4 class="permission-h4">Manage Contact Groups</h4>
        <p class="permission-p">
          <label class="radio-container">
            Yes
            <input type="radio" :name="'contact-radio-group'" :value="true" v-model="canManageContactGroups" />
            <span class="radio-checkmark"></span>
          </label>
        </p>
        <p class="permission-p">
          <label class="radio-container">
            No
            <input type="radio" :name="'contact-radio-group'" :value="false" v-model="canManageContactGroups" />
            <span class="radio-checkmark"></span>
          </label>
        </p>
        <hr class="permission-hr" />
      </div>

      <p class="permission-footer-text" v-if="!userEdit">
        To assign these roles to your users, please visit the <b>Accounts</b> tab in the Manage Accounts page.
      </p>
      <button
        v-if="!readonly && !userEdit"
        @click="handleSave"
        style="margin-bottom: 32px"
        class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
      >
        Save
      </button>
      <div v-if="userEdit" style="margin: 0 auto; display: block; width: max-content">
        <button @click="handleSave" class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary">
          Save
        </button>
        <button
          @click="closeRoleModal(false)"
          style="color: #00a1b3"
          class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
        >
          Close
        </button>
      </div>
      <a
        v-if="!userEdit"
        href="/cp/roles"
        style="color: #00a1b3; margin-bottom: 20px"
        class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
        >{{ readonly ? "Back" : "Cancel" }}</a
      >
      <section v-if="roleId !== '' && !readonly && !userEdit">
        <hr style="margin: 55px 0" class="permission-hr" />
        <span
          style="
            color: #444444;
            font-size: 26px;
            font-weight: 500;
            display: inline-block;
            position: relative;
            top: 3px;
            margin: 0 10px 32px 0;
          "
          >Delete Role</span
        >
        <button
          style="display: inline-block"
          @click="openDeleteModal"
          class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
        >
          Continue
        </button>
      </section>

      <p style="color: #e65722">{{ error }}</p>

      <modal class="eu-file-upload-modal" :isActiveProp="isDeleteModalActive" @close="closeDeleteModal">
        <div style="display: contents">
          <p style="font-size: 16px; color: #707070; margin: 30px 34px 10px; text-align: center">
            Select new role that users currently assigned to this role should be assigned to.
          </p>
          <div
            class="field-wrapper hasValue eu-file-upload-modal-field"
            style="margin-top: 31px; margin-bottom: 0; position: relative; text-align: center"
          >
            <select style="max-width: 250px" class="eu-upload-select" v-model="role_change">
              <option v-for="option in roles" v-bind:value="option.id" v-bind:key="option.id">
                {{ option.name }}
              </option>
            </select>
            <i class="fal fa-angle-down eu-select-arrow" style="left: -40px"></i>
            <div class="field-placeholder" style="bottom: 63px; position: relative; left: 100px">
              <span>Select Role</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="row">
            <p class="error">{{ error }}</p>
          </div>
          <div class="column">
            <button
              @click="handleDelete"
              type="button"
              aria-label="delete role"
              class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
              style="width: fit-content; height: fit-content"
            >
              Confirm & Delete Role
            </button>
            <button
              @click="closeDeleteModal"
              class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
              style="width: fit-content; height: fit-content"
            >
              Cancel
            </button>
          </div>
        </div>
      </modal>
    </form>
  </section>
</template>
<script>
import * as Cookies from "js-cookie";
import RoleService from "../services/RoleService";
import Modal from "../../components/ModalExtended.vue";
import { isPartnerTenant } from "../../utils/CustomUtils";

const permissionsEnum = {
  NONE: "None",
  READ: "Read only",
  WRITE: "Read/Write",
  MANAGE: "Manage",
  MANAGE_WITH_GRANT: "Manage with Grant",
};

export default {
  components: { Modal },
  name: "role-permissions",
  props: ["appData", "userEdit"],

  data() {
    return {
      roleId: this.appData.role_id,
      name: this.appData.name,
      description: this.appData.description,
      all_features: this.appData.all_features,
      verbs: this.appData.available_verbs,
      permissions: this.appData.permissions,
      subdomain: this.appData.subdomain,
      isPartnerTenant: isPartnerTenant(),
      readonly: this.appData.readonly,
      error: "",
      permissionsEnum: permissionsEnum,
      role_change: "",
      isDeleteModalActive: false,
      roles: this.appData.available_roles,
      isMentor: this.appData.is_mentor,
      isDeptLead: this.appData.is_department_lead,
      canManageCommunityGroups: this.appData.manage_community_groups,
      canManageContactGroups: this.appData.manage_contact_groups,
      service: new RoleService(),
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  methods: {
    remapPermissionName(perm) {
      const PERM_MAP = {
        EVENTS: "OPPORTUNITIES",
        "RESOURCE LIBRARY": "COMMUNITY LIBRARY",
        COURSES: "PATHWAY COURSES",
        "ACTIVITY FEED": "NEWS & UPDATES",
      };

      return PERM_MAP[perm] ? PERM_MAP[perm] : perm;
    },
    async handleSave(e) {
      e.preventDefault();
      const isCustomRole = this.userEdit && !this.readonly;
      const parsedPermissions = [];
      this.permissions.forEach((p) => {
        if (p.permissionLevel >= 0) parsedPermissions.push(`${p.value}:${p.permissionLevel}`);
      });

      const urlParams = new URLSearchParams(window.location.search);
      const data = {
        role_id: this.roleId === "" ? 0 : this.roleId,
        name: this.name.toUpperCase(),
        description: isCustomRole
          ? `Custom role for ${this.userEdit.first_name} ${this.userEdit.last_name} ${this.userEdit.id}`
          : this.description,
        subdomain: this.subdomain || urlParams.get("tenant"),
        permissions: parsedPermissions,
        isMentor: this.isMentor,
        isDeptLead: this.isDeptLead,
        canManageCommunityGroups: this.canManageCommunityGroups,
        canManageContactGroups: this.canManageContactGroups,
      };
      if (this.userEdit) {
        data.user_id = this.userEdit.id;
      }
      if (isCustomRole) {
        data.isCustom = true;
        if (data.name !== "CUSTOM") {
          data.role_id = 0;
        }
        data.name = "CUSTOM";
      }
      if (
        this.name.length > 1 &&
        this.name.length < 30 &&
        this.description.length > 1 &&
        this.description.length < 255
      ) {
        this.error = "";
        const res = await this.service.postFormData(data, this.headers);
        const redirectUrl = `${window.location.origin}/cp/roles/`;
        const redirectParams = `?tenant=${urlParams.get("tenant")}`;
        const hasParams = !!urlParams.get("tenant");
        if (res?.status === "success")
          if (this.userEdit) {
            this.closeRoleModal(true);
          } else {
            location.replace(hasParams ? redirectUrl + redirectParams : redirectUrl);
          }
        else {
          this.error = res.status;
        }
      } else {
        this.error = "You have to provide a proper role name & description.";
      }
    },
    closeRoleModal(refetchUsers) {
      this.$emit("close-role-permissions-modal", refetchUsers);
    },
    closeDeleteModal(e) {
      this.isDeleteModalActive = false;
      this.toggleSidebar(true);
      e.preventDefault();
    },
    openDeleteModal(e) {
      this.toggleSidebar(false);
      this.isDeleteModalActive = true;
      e.preventDefault();
    },
    toggleSidebar(isVisible) {
      const sidebar = document.querySelector("#sidebar");
      sidebar.style.visibility = isVisible ? "visible" : "hidden";
    },
    async handleDelete() {
      const urlParams = new URLSearchParams(window.location.search);
      const redirectUrl = `${window.location.origin}/cp/roles/`;
      const redirectParams = `?tenant=${urlParams.get("tenant")}`;
      const hasParams = !!urlParams.get("tenant");
      const res = await this.service.delete(
        { role_id: this.roleId, subdomain: this.subdomain || urlParams.get("tenant"), role_change: this.role_change },
        this.headers
      );
      if (res.status === "success") location.replace(hasParams ? redirectUrl + redirectParams : redirectUrl);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
  },
};
</script>