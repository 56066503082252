<template>
  <section class="meetings-calendar-section meeting-appointment-section">
    <div class="header-container">
      <common-title
        title="MentorConnect"
        description="Connect with peer mentors and manage appointments."
      />
    </div>
    <div class="content-container">
      <div id="main" class='eu-simple-navbar'>
        <router-link
          v-if="canManageAppointments"
          class="eu-simple-navbar-item"
          aria-label="Go to manage mentors page"
          to='/meetings/manage'
        >
          Manage Mentors
        </router-link>
        <router-link
          class="eu-simple-navbar-item"
          aria-label="Go to my appointments page"
          to="/meetings"
          :style=" mobile_screen_size ? `padding-bottom:0;` : ''"
        >
          My Appointments
        </router-link>
        <router-link
          v-if="isMentor"
          class="eu-simple-navbar-item eu-simple-navbar-item-active"
          aria-label="Go to My Availability page"
          to='/meetings/calendar'
        >
          My Availability
        </router-link>
        <router-link
          class="eu-simple-navbar-item"
          aria-label="Go to Favorite Mentors page"
          to='/meetings?tab=favorites'
        >
          Favorite Mentors
        </router-link>
      </div>
      <div class="eu-appoint-title">Add Available Hours</div>
      <div class="eu-form-container" style="display: block; max-width: 700px;">
        <div class="eu-form" style="margin-bottom:0">
          <div class="eu-form-element-container-small">
            <datetime
              type="date"
              class="datetime-theme-blues"
              @input="onChange($event)"
              input-class="eu-form-input"
              :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
              :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
              :min-datetime="new Date().toISOString()"
              :week-start="7"
              title="Date"
              use12-hour
              zone="UTC"
            ></datetime>
            <div class="eu-form-label-container">
              <label for="end_date">Start Date<span> *</span></label>
            </div>
          </div>
          <div class="multiple-elements">
            <div class="eu-form-element-container-small">
              <datetime
                type="time"
                class="datetime-theme-blues"
                v-model="event.startTime"
                input-class="eu-form-input"
                :format="{ hour: 'numeric', minute: '2-digit' }"
                :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
                :minute-step="30"
                :week-start="7"
                title="Start Time"
                use12-hour
              ></datetime>
              <div class="eu-form-label-container">
                <label for="end_date">Start Time<span> *</span></label>
              </div>
            </div>
            <div class="eu-form-element-container-small">
              <datetime
                type="time"
                class="datetime-theme-blues"
                v-model="event.endTime"
                input-class="eu-form-input"
                :format="{ hour: 'numeric', minute: '2-digit' }"
                :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
                :minute-step="30"
                :week-start="7"
                title="End Time"
                use12-hour
              ></datetime>
              <div class="eu-form-label-container">
                <label for="end_date">End Time<span> *</span></label>
              </div>
            </div>
          </div>
          <label class="eu-checkbox-container" style="margin: 10px auto;width: fit-content;display: block;"
            >Recurring Hours
            <input type="checkbox" v-model="isRecurring" />
            <span class="eu-checkmark"></span>
            <span class="eu-checkmark-tooltip">
              <a href="javascript:;">
                <i class="fad fa-question-circle"></i>
                <div class="eu-resource-desc" style="top: -33px; right: -213px;">
                  <span class="eu-resource-desc-left-arrow"></span>
                  <span class="eu-resource-desc-el">
                    <p class="eu-resource-desc-text">Check this box if you want to book recurring meeting hours.</p>
                  </span>
                </div>
              </a>
            </span>
          </label>
          <div v-if="isRecurring" class="multiple-elements" style="transition: 0.5s">
            <div class="eu-form-element-container-small">
              <select id="rec-select" class="eu-form-input" v-model="recurringType">
                <option value="days">Day</option>
                <option value="weeks">Week</option>
                <option value="months">Month</option>
              </select>
              <div class="eu-form-label-container">
                <label for="rec-select">Repeat every<span> *</span></label>
              </div>
            </div>
            <div class="eu-form-element-container-small">
              <datetime
                type="date"
                class="datetime-theme-blues"
                @input="onDueDateChange($event)"
                input-class="eu-form-input"
                :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
                :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
                :min-datetime="new Date().toISOString()"
                :week-start="7"
                title="End Date"
                use12-hour
              ></datetime>
              <div class="eu-form-label-container">
                <label for="end_date">End Date<span> *</span></label>
              </div>
            </div>
          </div>

          <span v-if="formError" tabindex="0" id="book_appointment_error" class="mc-create-meeting-error">Please fill in all required fields.</span>
          <button
            @click="createAppointmentSlot()"
            class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
            style="float:right; margin-top:20px"
          >
            Create Appointment Slot
          </button>
        </div>
      </div>

      <div class="eu-appoint-title">My Calendar</div>
      <div v-if="isLoading">
        <loading-spinner />
      </div>
      <vue-scheduler
        v-if="!isLoading"
        style="margin-top: 50px"
        :events="events"
        :event-dialog-config="dialogConfig"
        :event-display="eventDisplay"
        @event-clicked="eventClicked"
        @event-created="eventCreated"
        @view-changed="viewChanged"
      />
    </div>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    <meeting-edit-remove-modal :isModalActive="isEditModalActive" @onClose="closeEditMeetingModal" @remove-slot="removeSlot" :meeting="clickedEvent" />
  </section>
</template>
<script lang="ts">
import Vue from 'vue';
import MeetingsService from '../services/MeetingsService';
import * as Cookies from 'js-cookie';
import VueScheduler from 'v-calendar-scheduler';
import Notification from '../../components/Notification.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import moment from 'moment';
import MeetingEditRemoveModal from './MeetingEditRemoveModal.vue';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import CommonTitle from '../../components/CommonTitle.vue';

Vue.use(VueScheduler, {
  minDate: moment(),
  availableViews: ['month', 'week'],
  initialDate: new Date(),
  initialView: localStorage.calendarMeetingView || 'month',
  showTimeMarker: false,
  showTodayButton: false
});

export default {
  components: { CommonTitle, Notification, Datetime, MeetingEditRemoveModal, LoadingSpinner },
  name: 'meetings-calendar',
  props: [],
  data() {
    return {
      events: [],
      clickedEvent: { date: '', startTime: '', endTime: '' },
      event: { date: '', startTime: '', endTime: '' },
      isLoading: true,
      formError: false,
      dialogConfig: {
        title: 'Create Avaliable Appointment Slot'
      },
      isNotificationActive: false,
      isEditModalActive: false,
      notification: { msg: '', isError: false, icon: null },
      isRecurring: false,
      recurringType: 'weeks',
      recurringDueDate: '',
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json'
      },
      canManageAppointments: null,
      isMentor: null,
      meetingsService: new MeetingsService(),
    };
  },
  async created() {
    this.initializeData();
    await this.getHours();
    this.listenButtonClicks();
    this.changeEventBackground();
  },
  methods: {
    async initializeData() {
      const res = await this.meetingsService.getCalendarPredata();
      this.canManageAppointments = res.can_manage_appointments;
      this.isMentor = res.is_mentor;
    },
    async getHours() {
      const hours = await this.meetingsService.getHours();
      this.events = hours.map(h => {
        h.startTime = moment(h.startTime).format('HH:mm');
        h.endTime = moment(h.endTime).format('HH:mm');
        return h;
      });
      this.isLoading = false;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    eventClicked(event) {
      this.clickedEvent = event;
      this.openEditMeetingModal();
    },
    openEditMeetingModal() {
      this.isEditModalActive = true;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'none';
    },
    closeEditMeetingModal() {
      this.isEditModalActive = false;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'block';
    },
    listenButtonClicks() {
      var elements = document.getElementsByClassName('v-cal-button');
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', e => {
          setTimeout(() => {
            this.changeEventBackground();
          }, 100);
        });
      }
    },
    splitTimeIntoSlots(slotTime, start, end) {
      const slotInterval = slotTime;
      const startTime = moment(start, 'HH:mm');
      const endTime = moment(end, 'HH:mm');

      let allTimes = [];
      while (startTime <= endTime) {
        allTimes.push(startTime.format('HH:mm'));
        startTime.add(slotInterval, 'minutes');
      }
      return allTimes;
    },
    viewChanged(newView) {
      if (!newView) {
        localStorage.calendarMeetingView = 'month';
      } else {
        localStorage.calendarMeetingView = newView;
      }

      this.listenButtonClicks();
      setTimeout(() => {
        this.changeEventBackground();
      }, 100);
    },
    changeEventBackground() {
      const eventElements = document.querySelectorAll('.v-cal-event-item');
      eventElements.forEach(e => {
        if (!e.textContent.includes('AVAILABLE')) {
          e['style'].background = '#8676bc';
          e['style'].color = '#8676bc';
        }
      });
    },
    async createAppointmentSlot() {
      if (this.event.date && this.event.startTime && this.event.endTime
      && (!this.isRecurring || this.isRecurring && this.recurringDueDate && this.recurringType)) {
        const recurringData = this.isRecurring ?
        {recurringDueDate: this.recurringDueDate.split('T')[0], recurringType: this.recurringType} : {};
        await this.eventCreated({
          date:
            typeof this.event.date === 'string' || this.event.date instanceof String
              ? moment(this.event.date.split('T')[0])
              : moment(JSON.stringify(this.event.date).split('T')[0]),
          startTime: moment(this.event.startTime).format('HH:mm'),
          endTime: moment(this.event.endTime).format('HH:mm'),
          timeSlots: this.splitTimeIntoSlots(30, moment(this.event.startTime).format('HH:mm'), moment(this.event.endTime).format('HH:mm')),
          timezoneOffset: -new Date().getTimezoneOffset(),
          ...recurringData
        });
        this.formError = false;
        this.event = { date: this.event.date, startTime: '', endTime: '' };
        this.isRecurring = false;
        this.recurringDueDate = '';
      } else {
        this.formError = true;
      }
    },
    async eventCreated(event) {
      const validationRes = this.validateEvent(event);
      if (validationRes.isValid) {
        event.date = moment(event.date).format('YYYY-MM-DD');
        await this.meetingsService.addHours(event, this.headers);
        this.changeEventBackground();
        this.getHours();
      } else {
        this.showNotification({ msg: validationRes.error, isError: true, icon: 'fad fa-exclamation-circle' });
        this.getHours();
      }
    },
    async removeSlot(eventId) {
      const res = await this.meetingsService.removeHours(eventId, {}, this.headers);
      if (res.success) {
        this.getHours();
        this.showNotification({ msg: 'Meeting slot removed successfully.', icon: 'fad fa-check-circle' });
      } else {
        this.showNotification({ msg: 'Error while removing meeting slot.', isError: true, icon: 'fad fa-exclamation-circle' });
      }
    },
    validateEvent(event) {
      if (this.isLessThanToday(event.date)) return { isValid: false, error: 'Error: You cannot create a slot for a date from past.' };
      else if (event.startTime > event.endTime) return { isValid: false, error: 'Error: Start Time must be before End Time.' };
      else if (this.isInsideOtherEvent(event)) {
        return { isValid: false, error: "Error: There's already a created slot in that timespan." };
      }
      return { isValid: true };
    },
    isInsideOtherEvent(event) {

      const res = this.events.every((e, i) => {
        if (
          i !== this.events.length - 1 &&
          moment(e.date).format('YYYY-MM-DD').split('T')[0] ===
            moment(event.date)
              .format('YYYY-MM-DD')
              .split('T')[0]
        ) {
          return event.endTime < e.startTime || event.startTime > e.endTime;
        }
        return true;
      });
      return !res;
    },
    isLessThanToday(date) {
      return date < moment().startOf('day')
    },
    eventDisplay(event) {
      return `${event.startTime.format('hh:mm A')} - ${event.endTime.format('hh:mm A')} ${event.name ? `\xa0${event.name}` : ' \xa0\xa0AVAILABLE'}`;
    },
    onChange(event) {
      this.event.date = event;
    },
    onDueDateChange(event) {
      this.recurringDueDate = event;
    }
  },
  computed: {
    mobile_screen_size(){
      return screen.width <= 860
    }
  },
  updated() {
    this.changeEventBackground();
  }
};
</script>
<style lang="scss" scoped>
.meeting-appointment-section {
  padding: 24px 32px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.title {
  margin: 0;
}
.header-container {
  width: 100%;
  max-width: 1400px;
}

.content-container {
  width: 100%;
  max-width: 1400px;
}

@media screen and (max-width: 576px) {
  .meeting-appointment-section {
    padding: 24px 16px 16px;
  }
  .content-container {
    padding: 0 !important;
  }
}
</style>
