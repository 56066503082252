<template>
  <section class="main-container">
    <div class="lg-space-between">
      <section style="margin-top: 24px">
        <h1 class="text-clamp-1" :title="quest.name" v-if="$route.params.questId">
          Quest: <span>{{ quest.name }}</span>
        </h1>
      </section>
      <button
        aria-label="Add new Quest"
        class="iu-btn-light big title-btn"
        id="back-to-quest-edit"
        @click="changePage(`/quests/edit/${quest.id}`)"
      >
        Back/Cancel
      </button>
    </div>

    <div class="ca-form" style="max-width: 100%">
      <div style="max-width: 800px; margin: 0 auto" v-if="!isLoading">
        <h3 style="margin-top: 24px">Add Tasks</h3>
        <draggable v-model="quest.tasks" handle=".drag-handle" @end="handleDrop" :ghost-class="'ghost'">
          <transition-group name="list">
            <div v-for="t in quest.tasks" :key="t.id">
              <task-form
                v-if="isEditMode && selectedTaskId == t.id"
                :task="t"
                @show-notification="showNotification"
                @close-notification="closeNotification"
                @create-task="submitTask"
                @cancel-task-edit="cancelTaskEdit"
              />
              <task-card v-else @start-edit-task="startEditTask" @open-delete-modal="openDeleteModal" :task="t" :isEditMode="true" />
            </div>
          </transition-group>
        </draggable>
        <task-form
          v-if="isAddMode"
          :task="task"
          @show-notification="showNotification"
          @close-notification="closeNotification"
          @create-task="submitTask"
          @cancel-task-edit="cancelTaskEdit"
        />
        <div class="additionalTaskContainer">
          <button
            aria-label="Add another task"
            class="iu-btn-light big title-btn"
            :class="isAddMode ? 'disabled' : ''"
            style="width: 180px"
            @click="createAnotherTask()"
          >
            <i class="fal fa-plus" style="margin-right: 8px" />New Task
          </button>
        </div>
        <button
          aria-label="Finish Quest/Task creation process"
          class="iu-btn-solid big title-btn"
          @click="finishQuestForm"
        >
          Finish
        </button>
        <button aria-label="Go Back" class="iu-btn-light big title-btn" @click="changePage(`/quests/edit/${quest.id}`)">
          Back
        </button>
      </div>
    </div>
    <div v-if="isLoading">
      <loading-spinner wrapperClass="wrapper" />
    </div>
    <Modal :isActiveProp="isDeleteModalActive" @close="closeDeleteModal">
      <div class="iu-resources-delete-modal-content-container">
        <div class="iu-resources-delete-modal-text-section">Are you sure you want to delete this Task?</div>
        <div class="iu-resources-delete-modal-file-details-section">
          <div class="iu-resources-delete-modal-file-name">
            {{ itemToDelete.name }}
          </div>
        </div>
        <div class="iu-resources-delete-modal-actions-section">
          <button
            id="task_delete"
            class="iu-resources-delete-modal-actions-button iu-main-btn small solid red"
            @click="deleteItem(itemToDelete.id)"
            aria-label="Remove an item"
          >
            Delete
          </button>
          <button
            class="iu-resources-delete-modal-actions-button iu-main-btn small outlined gray"
            @click="closeDeleteModal"
            aria-label="Close delete modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import { Datetime } from "vue-datetime";
import DropzoneComponent from "../../form/components/DropzoneComponent.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import QuestService from "../services/QuestService";
import Notification from "../../components/Notification.vue";
import { focusElement } from "../../utils/CustomUtils";
import Modal from "../../components/ModalExtended.vue";
import TaskForm from "./TaskForm.vue";
import TaskCard from "./TaskCard.vue";
import draggable from "vuedraggable";

const msgs = {
  addTaskSuccess: "Task Successfully Created.",
  addTaskErr: "Error while creating Task.",
  updateTaskSuccess: "Task Successfully Updated.",
  updateTaskErr: "Error while updating Task.",
};

const emptyTask = {
  name: "",
  description: "",
  due_date: null,
  items: [],
};

export default {
  name: "tasks-form",
  components: {
    draggable,
    DropzoneComponent,
    Multiselect,
    LoadingSpinner,
    Notification,
    Datetime,
    TaskForm,
    Modal,
    TaskCard,
  },
  props: ["isAdmin", "isDeptLead"],
  computed: {},
  data() {
    return {
      isDeleteModalActive: false,
      itemToDelete: { id: null, name: null },
      focus_areas: [],
      identities: [],
      departments: [],
      contacts: [],
      selectedTaskId: null,
      isLoading: false,
      service: new QuestService(),
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      isEditMode: false,
      isAddMode: false,
      quest: {
        id: null,
      },
      task: { ...emptyTask },
    };
  },
  async created() {
    this.isLoading = true;
    await this.loadQuest();
    this.isLoading = false;
  },
  methods: {
    async loadQuest() {
      const questId = this.$route.path.split("/").pop();
      this.quest = await this.service.getOne(questId);
      if (this.isDeptLead !== this.quest.department.name && !this.isAdmin) {
        this.changePage("/quests/");
      }
      if (this.quest.tasks.length === 0) {
        this.isAddMode = true;
      } else {
        this.quest.tasks.sort((a, b) => a.order - b.order);
      }
    },
    async handleDrop() {
      const tasks_order = this.quest.tasks.map((e, i) => {e.order = i+1; return {id: e.id, order: e.order}})
      const res = await this.service.updateTaskOrder({tasks_order})
      if(res.success) {
        this.showNotification({ msg: "Task order updated successfully.", icon: "fad fa-check-circle" });
      } else {
        this.showNotification({
          msg: "Error while updating the order of the Task",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
      this.loadQuest();
    },
    finishQuestForm() {
      //TODO: logic for ordering? or do ordering after every change?
      this.changePage("/quests/");
    },
    cancelTaskEdit() {
      this.isAddMode = false;
      this.isEditMode = false;
      this.selectedTaskId = null;
      this.task = { ...emptyTask };
      this.loadQuest();
    },
    startEditTask(task) {
      this.isAddMode = false;
      this.isEditMode = true;
      this.selectedTaskId = task.id;
    },
    createAnotherTask() {
      if (this.isEditMode) {
        this.cancelTaskEdit();
      }
      this.isAddMode = true;
    },
    openDeleteModal(item) {
      this.isDeleteModalActive = true;
      focusElement("task_delete");
      this.itemToDelete = { ...item };
    },
    closeDeleteModal() {
      focusElement("back-to-quest-edit");
      this.isDeleteModalActive = false;
    },
    async deleteItem(appId) {
      this.isLoading = true;
      this.isDeleteModalActive = false;
      const res = await this.service.removeTask(appId);
      if (res.success) {
        await this.loadQuest();
        this.showNotification({ msg: "Task removed successfully.", icon: "fad fa-check-circle" });
      } else {
        this.showNotification({
          msg: "Error while removing Task",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
      this.isLoading = false;
    },
    changePage(page) {
      this.$router.push(page);
    },

    getErrorMsg() {
      const mode = this.isAddMode ? "addTask" : this.isEditMode ? "updateTask" : "";
      return msgs[`${mode}Err`];
    },
    async submitTask(taskDTO) {
      this.isLoading = true;
      const mode = this.isAddMode ? "addTask" : this.isEditMode ? "updateTask" : "";
      if (taskDTO) {
        const res = await this.service[mode]({ ...taskDTO, quest: this.quest.id });
        if (res.success) {
          this.showNotification({ msg: msgs[`${mode}Success`], icon: "fad fa-check-circle" });
          if (this.isAddMode) {
            this.isAddMode = false;
          }
          if (this.isEditMode) {
            this.isEditMode = false;
          }
          this.task = { ...emptyTask };
          await this.loadQuest();
        } else {
          this.showNotification({ msg: this.getErrorMsg(res), isError: true, icon: "fad fa-exclamation-circle" });
        }
      }

      this.isLoading = false;
    },

    closeNotification() {
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
      setTimeout(() => {this.closeNotification()}, 5000)
    },
  },
};
</script>
<style lang="scss" scoped>
.additionalTaskContainer {
  padding-bottom: 16px;
  border-bottom: 2px solid #e7e5ec;
}
.list-move {
  transition: transform 0.5s;
}
.ghost {
  opacity: 0.1;
}
@media only screen and (min-width: 578px) {
  .autoW {
    width: auto;
  }
  .flex-row-wrapper {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}
</style>
