<template>
  <div class="dept-card">
    <img
      class="dept-card-img"
      @click="redirectToDept(dept.name)"
      @keyup.enter="redirectToDept(dept.name)"
      :src="dept.cover_pic"
      alt="Department's cover image"
    />
    <div class="dept-card-text">
      <div class="dept-card-title text-clamp-2" @click="redirectToDept(dept.name)">
        {{ dept.name }}
      </div>
      <div class="dept-card-desc text-clamp-2">{{ dept.description }}</div>
    </div>
  </div>
</template>
<script>
export default {
  components: { },
  name: "dept-card",
  props: ["dept"],
  data() {
    return {};
  },
  created() {},
  methods: {
    redirectToDept(dept_name) {
      this.$router.push(`/departments/${encodeURIComponent(dept_name)}`);
    },
  },
  computed: {},
};
</script>
