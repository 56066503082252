import HttpService from './HttpService';

export default class ContactUsService extends HttpService {
  constructor() {
    super("/partner/api/contactUs");
  }

  getIfContactUsEnabled(): Promise<any> {
    return this.get();
  }

  sendContactUsMessage(message: string, headers: any): Promise<any> {
    let post_data = {}
    post_data["message_text"] = message
    return this.postFormData(post_data, headers)
  }
}
