import Cookies from 'js-cookie';
import HttpService from '../../services/HttpService';

const headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class EventsIndexService extends HttpService {
  constructor() {
    super('/events/api/index');
  }

  getEvents(params?): Promise<any> {
    this.endpoint = '/events/api/index';
    return this.get(params);
  }

  getEventsDabi(params?): Promise<any> {
    this.endpoint = '/events/api/index/dabi';
    return this.get(params);
  }
  getPredata() {
    this.endpoint = `/events/api/predata`;
    return this.get();
  }

  getPredataEvent(id, params?) {
    this.endpoint = `/events/api/predata/${id}`;
    return this.get(params);
  }

  getPredataProgram(id, params?) {
    this.endpoint = `/programs/api/predata/${id}`;
    return this.get(params);
  }

  getPredataManage() {
    this.endpoint = `/events/api/predata/manage`;
    return this.get();
  }

  increaseClickThroughs(id) {
    this.endpoint = `/events/api/clickthrough/${id}`;
    return this.put({}, headers);
  }

  increaseViews(id) {
    this.endpoint = `/events/api/views/${id}`;
    return this.put({}, headers);
  }

  getLeads(params?) {
    this.endpoint = `/events/api/leads`;
    return this.get(params);
  }

  exportLeads(params?) {
    this.endpoint = `/events/api/leads`;
    return this.get(params, {}, false, 'blob');
  }

  createLead(opportunityId, additionalInfo) {
    this.endpoint = '/events/api/leads';
    return this.postFormData({ id: opportunityId, description: additionalInfo }, headers);
  }

  removeLead(opportunityId) {
    this.endpoint = '/events/api/leads/' + opportunityId;
    return this.delete({}, headers);
  }

  getFeatured(params?): Promise<any> {
    this.endpoint = '/events/api/featured';
    return this.get(params);
  }

  getFilterData(): Promise<any> {
    this.endpoint = '/accounts/api/filter_data';
    return this.get();
  }

  postBookmark(payload: Object, headers: Object): Promise<any> {
    this.endpoint = '/events/api/index';
    return this.postFormData(payload, headers);
  }

  postFeatured(payload: Object, headers: Object): Promise<any> {
    this.endpoint = '/events/api/featured';
    return this.postFormData(payload, headers);
  }

  remove(id, headers: Object): Promise<any> {
    this.endpoint = '/events/api/events/' + id;
    return this.delete({}, headers);
  }

  update(item, headers: Object): Promise<any> {
    this.endpoint = '/events/api/' + item.id;
    return this.put(item, headers);
  }

  getRecommendedEvents(params?): Promise<any> {
    this.endpoint = '/events/api/recommended';
    return this.get(params);
  }

  getRecommendedPrograms(params?): Promise<any> {
    this.endpoint = '/programs/api/recommended';
    return this.get(params);
  }
}
