<template>
<Modal :isActiveProp="isModalActive" @close="closeModal">
  <div class='profile-notification-modal' >
    <h2 tabindex="0" :id="modal_title_id" role="heading" class="eu-profile-title">{{title}}</h2>
    <span tabindex="0" class="eu-file-upload-modal-icon" role="button" aria-label="Close modal" @click="closeModal" @keyup.enter="closeModal" style="right: 20px; top: 20px;">
      <i class="far fa-times"></i>
    </span>
    <div v-for="card in cards" v-bind:key="JSON.stringify(card)" class='eu-profile-card' style="padding-top: 20px;">
      <span v-for="item in card" v-bind:key="item.title" v-show="item.text">
        <div v-if="item.title !== 'Status: '" class='eu-profile-card-item'>
           <span class='eu-profile-card-item-title'>{{item.title}}</span> 
           <span class='eu-profile-card-item-text' :style="item.bold ? 'font-weight:600' : ''">{{item.text}}</span>
        </div> 
        <div v-else class='eu-profile-card-item'>
        <span class='eu-profile-card-item-title'>{{item.title}}</span> 
        <span
          class="eu-meeting-card-status eu-profile-card-item-text"
          :class="item.text"
          style="border-radius: 8px;padding: 2px 12px;font-weight: 500;"
        >
          <i :class="icons[item.text]"></i>
          {{getStatusText(item.text)}}
        </span>
        </div>
      </span>
    </div>
    <a :href="`/profiles/${user.profile_url}`" target="_blank">
      <profile-card
        :listView="true"
        :hideProfileHref="true"
        :user="user" 
        :department_name="user.department ? user.department.name : ''" 
        :focus_areas="stringifyArray(user.focus_areas, 'name')" 
        className='meeting-details-card profile-card-href'
            />
    </a>
    <div v-if="meetingMsg.text && meetingMsg.text.length > 0" class="eu-profile-card" style="border-bottom:none; padding-top: 0;">
      <div class='eu-profile-card-item meeting-msg'>
        <span class='eu-profile-card-item-title' style="width: auto">{{meetingMsg.title}}</span> 
        <span class='eu-profile-card-item-text'>{{meetingMsg.text}}</span>
      </div>
    </div>
    <div class="row" style="width: 100%">
      <div class="column">
        <button
          @click="closeModal"
          class="iu-btn-light gray"
        >
          Close
        </button>
      </div>
    </div>
    </div>
</Modal>
</template>
<script lang="ts">
import Modal from '../../components/ModalExtended.vue';
import ProfileCard from './ProfileCard.vue';
import { getStatusText, stringifyArray } from "../../meetings/utils/common";

export default {
  components: {Modal, ProfileCard},
  name: 'profile-notification-modal',
  props: ['user', 'isModalActive', 'title', 'cards', 'department', 'meetingMsg', 'modal_title_id'],
  data() {
    return {
      icons: {
        active: "",
        past: "fas fa-flag-checkered",
        pending: "fas fa-spinner",
        canceled: "fas fa-slash",
        expired: "fas fa-hourglass-end",
      },
    };
  },
  async created() {},
  methods: {
    getStatusText,
    stringifyArray,
    closeModal() {
      this.$emit('onClose');
    },
  },
  computed: {}
};
</script>
