<template>
  <div class="partner-events-tab-wrapper">
    <div class="event-search-manage-container partner">
      <search-base
        ref="searchEvents"
        id="search-events"
        style="width: 100%"
        placeholder="Search by keyword"
        aria-label="Search events by keyword"
        @search="fetchEvents"
      ></search-base>
      <div v-if="canManage()" class="manage-button-wrapper">
        <popup-component buttonText="Create" buttonClass="partner">
          <template #items>
            <a
              class="eu-resource-burger-el"
              aria-label="Go to events creation page"
              @click="setRedirectPage"
              @keyup.enter="setRedirectPage"
              :href="`/events/create/${
                isGroupTab && group.name
                  ? '?group=' + encodeURIComponent(group.name)
                  : isPartnerTab && partner.name
                  ? '?partner=' + encodeURIComponent(partner.name)
                  : '/'
              }`"
            >
              <i class="fa-solid fa-calendar-pen" style="padding-right: 5px"></i>
              <span>Event</span>
            </a>
            <a
              class="eu-resource-burger-el"
              aria-label="Go to program creation page"
              @click="setRedirectPage"
              @keyup.enter="setRedirectPage"
              :href="`/programs/create${
                isGroupTab && group.name
                  ? '?group=' + encodeURIComponent(group.name)
                  : isPartnerTab && partner.name
                  ? '?partner=' + encodeURIComponent(partner.name)
                  : '/'
              }`"
            >
              <i class="fa-solid fa-building-columns" style="padding-right: 5px"></i>
              <span>Program</span>
            </a>
          </template>
        </popup-component>
      </div>
    </div>
    <div class="eu-empty-container partner" v-if="!isLoading && events.length === 0">
      <div class="eu-empty">
        <div class="eu-empty-icon-container partner">
          <i class="fad fa-calendar-star eu-empty-icon partner"></i>
        </div>
        <div style="display: flex; flex-direction: column">
          <span class="eu-empty-text">
            <span style="font-weight: 600">No events have been posted yet.</span>
            <br />
            Check back soon!
          </span>
        </div>
      </div>
    </div>
    <div v-if="isLoading">
      <loading-spinner wrapperClass="wrapper" />
    </div>
    <div v-else-if="events.length > 0" class="events-content-container grid-wrapper-five-cards">
      <div v-for="item in events" :key="item.id" class="content-cell">
        <span class="label">{{ labelText(item) }}</span>
        <button
          class="dabi-bookmark-button"
          :class="item.is_bookmarked ? 'active' : ''"
          @click="handleBookmark(item, `${item.is_bookmarked ? 'remove' : 'add'}`)"
          :aria-label="`${item.is_bookmarked ? 'Remove' : 'Add'} bookmark`"
        >
          <i v-if="item.is_bookmarked" class="fa-solid fa-bookmark"></i>
          <i v-else class="fa-regular fa-bookmark"></i>
        </button>
        <img :src="item.thumbnail" alt="Event cover image" class="image" />
        <router-link class="title text-clamp-2" :to="`/${item.slug}`" aria-label="Visit opportunity page">
          <i v-if="item.is_featured || item.is_partner_featured" class="fa-solid fa-stars"></i>
          {{ item.title }}
        </router-link>
        <div v-if="item.is_online || item.location" class="location">
          <span v-if="item.is_online">
            <i class="fa-solid fa-desktop"></i>
            Online
          </span>
          <span v-else>
            <i class="fa-solid fa-location-dot"></i>
            {{ item.location }}
          </span>
        </div>
        <div class="event-card-partner-container" v-if="item.partner">
          <img
            class="event-partner-logo"
            v-if="item.partner.logo_url"
            :src="item.partner.logo_url"
            :alt="`${item.partner.name} logo`"
          />
          <span class="event-card-partner-name">{{ item.partner.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Cookies from 'js-cookie';
import EventsIndexService from '../../events/services/EventsIndexService';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import RSVPService from '../../events/services/RSVPService';
import CardComponent from '../../events/components/cards/CardComponent.vue';
import PopupComponent from '../../components/popup/PopupComponent.vue';
import EventUtils from '../../events/utils/EventUtils';
import SearchBase from '../../components/formBase/SearchBase.vue';

export default {
  components: { LoadingSpinner, CardComponent, PopupComponent, SearchBase },
  name: 'partner-events-tab',
  props: [
    'department',
    'group',
    'partner',
    'loggedUser',
    'isGroupTab',
    'isPartnerTab',
    'isOpportunitiesTab',
    'isProgramTab',
  ],
  watch: {
    isProgramTab(newValue, oldValue) {
      this.params.type = newValue ? ['program'] : ['event'];
      this.fetchEvents();
    },
  },
  data() {
    return {
      isLoading: true,
      events: [],
      attending_event_ids: [],
      params: {
        q: '',
        focus_areas: [],
        partners_name: [],
        type: this.isProgramTab ? ['program'] : ['event'],
      },
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
    };
  },
  async mounted() {},
  created() {
    this.isLoading = true;
    this.params.type = this.isProgramTab ? ['program'] : ['event'];
    this.params.focus_areas = this.group ? this.group.focus_areas.map((e) => encodeURIComponent(e.name)) : [];
    this.params.partners_name = this.partner ? [encodeURIComponent(this.partner.name)] : [];
    this.eventsService = new EventsIndexService();
    this.rsvpService = new RSVPService();
    this.eventUtils = new EventUtils(this.eventsService, this.headers);
    this.fetchEvents();
    this.getEventsIdsUserAttending();
  },
  methods: {
    labelText(item) {
      if (item.type_of_program_management == 'event') {
        return 'Event';
      }

      if (item.program_type) {
        return item.program_type;
      }

      return 'Program';
    },
    async fetchEvents(search_input = '') {
      this.params.q = search_input;
      this.isLoading = true;

      const payload = {
        q: encodeURIComponent(this.params.q),
        type: JSON.stringify(this.params.type),
        focus_areas: JSON.stringify(this.params.focus_areas),
        partners_name: JSON.stringify(this.params.partners_name),
        limit: 999,
        sort: JSON.stringify(['-is_partner_featured', '-is_featured', 'start_date']),
      };

      const res = await this.eventsService.getEventsDabi(payload);
      this.events = res.opportunities;
      this.isLoading = false;
    },
    async getEventsIdsUserAttending() {
      this.attending_event_ids = await this.rsvpService.getEventsIdsUserAttending(this.loggedUser.id);
    },
    async changeAttendance(id) {
      let isAttending = false;
      if (this.attending_event_ids.includes(id)) {
        isAttending = true;
      }

      const payload = {
        target: id,
        isAttending: !isAttending,
      };

      const res = await this.rsvpService.postAttendance(payload, this.headers);
    },
    handleBookmark(item, action) {
      this.eventUtils.handleBookmark(item.id, action);
      item.is_bookmarked = !item.is_bookmarked;
    },
    setRedirectPage() {
      sessionStorage.setItem('redirectTo', window.location.href);
      if (this.isGroupTab && this.group.name) {
        sessionStorage.setItem('groupName', this.group.name);
      }
      if (this.isPartnerTab && this.partner.name) {
        sessionStorage.setItem('partnerName', this.partner.name);
      }
    },
    canManage() {
      if (this.isGroupTab && this.group.name) {
        return this.loggedUser.has_event_access && this.loggedUser?.userManageGroups.includes(this.group.name);
      } else if (this.isPartnerTab && this.partner.name) {
        return this.loggedUser.has_event_managing_access || this.loggedUser?.partnerAdmins.includes(this.partner.name);
      } else {
        return this.loggedUser.has_event_managing_access;
      }
    },
    formatTime(date, time) {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const [year, month, day] = date.split('-');
      const [hour, minute, period] = time ? time.split(':') : ['', '', ''];

      let formattedHour = '';
      let formattedMinute = '';
      let formattedTime = '';
      if (hour && minute && period) {
        formattedHour = hour === '00' ? '12' : hour > 12 ? hour - 12 : hour;
        formattedMinute = minute.padStart(2, '0');
        formattedTime = `, ${formattedHour}:${formattedMinute} ${period.toUpperCase()}`;
      }

      const dayName = days[new Date(year, month - 1, day).getDay()];
      const monthName = months[month - 1];

      return `${dayName}, ${monthName} ${parseInt(day)}${formattedTime}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.event-search-manage-container.partner {
  gap: 0.75rem;
  flex-direction: row;
  margin-top: 0;
}

.content-cell {
  height: 283px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .label {
    position: absolute;
    top: 1rem;
    left: 1rem;

    padding: 0.063rem 0.5rem 0.188rem;
    border-radius: 16px;
    background-color: #f2f5f7;
    color: #516075;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .image {
    height: 10rem;
    width: 16.344rem;
    object-position: center;
    object-fit: cover;
    border-radius: 16px;
  }

  .dabi-bookmark-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    height: 40px;
    color: #444;
    overflow: hidden;

    i {
      color: #22a5a0;
    }
  }

  .start-date-time {
    color: #838ea1;
    font-size: 0.875rem;
    line-height: 1;
  }

  .location {
    color: #95919b;
    font-size: 12px;
    font-weight: 600;
  }
}

.partner-events-tab-wrapper {
  width: 100%;
  max-width: 1400px;
}

.iu-main-btn.solid.main.partner {
  background-color: #2d98d2;

  &:hover {
    color: #006ead;
  }
}

.event-card-partner-container {
  display: flex;
  align-items: center;
}

.event-card-partner-name {
  color: #444444;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
}

.event-partner-logo {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #d8dce6;
  border-radius: 16px;
  margin-right: 0.5rem;
}

@media screen and (max-width: 576px) {
  .content-cell {
    width: 100%;

    .image {
      height: 10rem;
      width: 100%;
    }
  }
  .event-search-manage-container.partner {
    flex-direction: column-reverse;
  }

  .iu-main-btn.solid.main.partner {
    width: 100%;
  }

  .manage-button-wrapper {
    width: 100%;
  }
}
</style>
