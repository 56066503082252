import { render, staticRenderFns } from "./OneStopInterface.vue?vue&type=template&id=234c676c&scoped=true"
import script from "./OneStopInterface.vue?vue&type=script&lang=ts"
export * from "./OneStopInterface.vue?vue&type=script&lang=ts"
import style0 from "./OneStopInterface.vue?vue&type=style&index=0&id=234c676c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234c676c",
  null
  
)

export default component.exports