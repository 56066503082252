<template>
  <section class="partner-manage-section">
    <form
      v-if="!isLoading"
      class="profile-form"
      @submit.prevent="submitForm()"
      id="eu-form"
      enctype="multipart/form-data"
    >
      <div class="eu-form-page-backlink" v-if="!is_verify_partner">
        <a :href="`/partners/${partner.name}`" aria-label="Go to partner accounts's page">
          <i class="fas fa-arrow-left icon" aria-hidden="true"></i>
          <span>Go to the partner page</span>
        </a>
      </div>

      <div id="main" class="profile-form-header-container">
        <h2>Edit Partner</h2>
      </div>
      <div class="profile-form-section dropzone-container">
        <div>
          <h3>Cover Photo</h3>
          <p class="inup-thumbnail-desc">Use a high quality, landscape orientation image for best results.</p>
          <dropzone-component
            v-if="imageInput"
            :imageSrc="partner.cover_pic"
            :imageInput="imageInput"
            :maxImageSize="image_size_data.max_size"
            :maxImageSizeFormat="image_size_data.formatted"
            @uploadImage="prepareImageUpload"
            @removeImage="handleRemoveImage"
          />
          <input
            type="file"
            name="thumbnail"
            id="thumbnail"
            class="js-input-file-control da-components-hidden-file-control"
            accept="image/*"
            maxlength="400"
          />
        </div>
      </div>

      <div class="profile-form-section small-dropzone-container">
        <h3 class="small-margin-bottom">Basic Information</h3>
        <small-dropzone-component
          v-if="logoInput"
          :imageSrc="partner.logo_url"
          :imageInput="logoInput"
          :maxImageSize="image_size_data.max_size"
          :maxImageSizeFormat="image_size_data.formatted"
          changeButtonText="Change Logo"
          uploaderText="Upload Partner logo"
          @removeImage="handleRemoveLogo"
          @uploadImage="prepareLogoUpload"
        />
        <input
          type="file"
          name="logo"
          id="logo"
          class="js-input-file-control da-components-hidden-file-control"
          accept="image/*"
          maxlength="400"
        />

        <div class="inup-form-element-container partner-manage">
          <input type="text" v-model="partner.name" id="name" class="inup-input" maxlength="255" required="" />
          <div class="inup-form-label-container">
            <label for="name">Name<span>*</span></label>
          </div>
        </div>
        <div class="inup-form-element-container">
          <textarea
            name="tag_line"
            maxlength="130"
            v-model="partner.tag_line"
            id="tag-line"
            class="inup-textarea-input no-resize small"
          />
          <div class="inup-form-label-container">
            <label for="tag_line">Tag Line</label>
          </div>
        </div>
        <div class="inup-form-element-container">
          <textarea
            name="description"
            cols="40"
            rows="10"
            v-model="partner.description"
            id="desc"
            class="inup-textarea-input no-resize"
          />
          <div class="inup-form-label-container">
            <label for="desc">Description</label>
          </div>
        </div>

        <div class="multiselect-wrapper" style="width: 100%; margin: 0; position: relative">
          <multiselect
            v-model="partner.focus_areas"
            :options="focus_areas"
            :multiple="true"
            label="name"
            track-by="name"
            :max="5"
            :close-on-select="false"
            placeholder=""
          >
          </multiselect>
          <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer; left: 96%"></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">Focus Areas <span>*</span></label>
          </div>
        </div>

        <div class="multiselect-wrapper" style="width: 100%; margin: 0; position: relative">
          <multiselect
            v-model="partner_destinations"
            :options="destinations"
            :multiple="true"
            label="name"
            track-by="name"
            :max="5"
            :close-on-select="false"
            placeholder=""
          >
          </multiselect>
          <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer; left: 96%"></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">Destinations</label>
          </div>
        </div>

        <div class="multiselect-wrapper" style="width: 100%; margin: 0; position: relative">
          <multiselect
            v-model="partner.audiences"
            :options="audiences"
            :multiple="true"
            label="name"
            track-by="name"
            :max="5"
            :close-on-select="false"
            placeholder=""
          >
          </multiselect>
          <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer; left: 96%"></i>
          <div class="inup-form-label-container">
            <label for="audiences_list">Audiences</label>
          </div>
        </div>

        <div class="multiselect-wrapper" style="width: 100%; margin: 0; position: relative">
          <multiselect
            v-model="partner.career_academic_interests"
            :options="career_academic_interests"
            :multiple="true"
            label="name"
            track-by="name"
            :max="5"
            :close-on-select="false"
            placeholder=""
          >
          </multiselect>
          <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer; left: 96%"></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">Career Academic Interests</label>
          </div>
        </div>

        <div class="multiselect-wrapper" style="width: 100%; margin: 0; position: relative">
          <multiselect
            v-model="partner.opportunity_types"
            :options="opportunity_type"
            :multiple="true"
            label="name"
            track-by="name"
            :max="5"
            :close-on-select="false"
            placeholder=""
          >
          </multiselect>
          <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer; left: 96%"></i>
          <div class="inup-form-label-container">
            <label for="opportunity_types_list">Opportunity Type</label>
          </div>
        </div>

        <div class="inup-from-element-container">
          <div class="iu-partner-settings-status-container">
            <h3 class="preference-container-title">Status</h3>
            <toggle-button
              id="status-toggle"
              :value="partner.is_active"
              :sync="true"
              :width="88"
              :height="24"
              :fontSize="12"
              :color="{ checked: '#4c55a7', unchecked: '#b4b4b4' }"
              :labels="{ checked: 'Active', unchecked: 'Disabled' }"
              @change="statusSwitch"
            />
          </div>
        </div>
      </div>
      <div class="profile-form-section">
        <h3 class="medium-margin-bottom">Welcome Video</h3>
        <div class="inup-form-element-container">
          <input type="text" v-model="partner.welcome_video_url" id="video" class="inup-input" maxlength="255" />
          <div class="inup-form-label-container">
            <label for="video">Youtube URL</label>
          </div>
        </div>
      </div>

      <div class="profile-form-section">
        <h3 class="medium-margin-bottom">Contact Information</h3>
        <div class="inup-form-element-container">
          <input type="text" v-model="partner.website" id="website" class="inup-input" maxlength="255" />
          <div class="inup-form-label-container">
            <label for="website">Website URL</label>
          </div>
        </div>

        <div class="inup-form-element-container multiple">
          <div class="inup-form-element-container small">
            <input type="text" v-model="partner.phone_number" id="phone_number" class="inup-input" />
            <div class="inup-form-label-container">
              <label for="phone_number">Phone</label>
            </div>
          </div>

          <div class="inup-form-element-container small">
            <input type="email" v-model="partner.email" id="email" class="inup-input" maxlength="255" />
            <div class="inup-form-label-container">
              <label for="email">Email</label>
            </div>
          </div>
        </div>

        <div class="eu-form-element-container">
          <multiple-input-select
            v-if="socialInput"
            :input="socialInput"
            :optionList="social_options"
            :filledOptions="partner.social_networks"
            selectLabel="Social Link"
            inputLabel="URL"
          />
          <input type="hidden" name="social_networks" id="social-networks-input" />
        </div>
      </div>
      <section class="profile-form-section">
        <h3 id="important-links-title" tabindex="0" class="medium-margin-bottom">Important Links</h3>
        <div class="multiple-input-select">
          <div class="multiple-elements">
            <div class="eu-form-element-container-small">
              <input v-model="titleInput" id="il-title-input" type="text" class="eu-form-input" />
              <div class="eu-form-label-container">
                <label for="il-title-input">Important Link Title</label>
              </div>
            </div>
            <div class="eu-form-element-container-small">
              <input v-model="urlInput" id="il-url-input" type="text" class="eu-form-input" />
              <div class="eu-form-label-container">
                <label for="il-url-input">Important Link URL</label>
              </div>
            </div>
            <button type="button" class="mis-add-button" @keyup.enter="addImportantLink" @click="addImportantLink">
              + Add
            </button>
          </div>
          <div id="il-list"></div>
        </div>
      </section>
      <div class="profile-form-section">
        <h3 id="partner-contacts-and-managers-title" tabindex="0" class="medium-margin-bottom">
          Partner Contacts &amp; Managers
        </h3>
        <div
          class="multiselect-wrapper hidden__content partner-manage"
          @click="openPartnerContactUsersSelectModal"
          @keyup.enter="openPartnerContactUsersSelectModal"
        >
          <multiselect
            id="partner-contacts-multiselect"
            v-model="partner.partner_contacts"
            :options="all_users"
            :multiple="true"
            label="name"
            track-by="name"
            :limit="20"
            :limitText="limitText"
            :close-on-select="false"
            placeholder=""
            aria-label="Open dialog for selecting partner contacts."
            @click.self.prevent
          >
          </multiselect>
          <div class="inup-form-label-container">
            <label for="partner-contacts-multiselect">Account Contacts</label>
          </div>
        </div>
        <div
          class="multiselect-wrapper hidden__content partner-manage"
          @click="openPartnerManagerUsersSelectModal"
          @keyup.enter="openPartnerManagerUsersSelectModal"
        >
          <multiselect
            id="partner-managers-multiselect"
            v-model="partner.partner_admins"
            :options="all_users"
            :multiple="true"
            label="name"
            track-by="name"
            :limit="20"
            :limitText="limitText"
            :close-on-select="false"
            placeholder=""
            aria-label="Open dialog for selecting partner managers."
            @click.self.prevent
          >
          </multiselect>
          <div class="inup-form-label-container">
            <label for="partner-managers-multiselect">Partner Managers</label>
          </div>
        </div>
        <div
          class="multiselect-wrapper hidden__content partner-manage"
          @click="openPartnerAdminUsersSelectModal"
          @keyup.enter="openPartnerAdminUsersSelectModal"
        >
          <multiselect
            id="partner-managers-multiselect"
            v-model="partner.partner_superadmins"
            :options="all_users"
            :multiple="true"
            label="name"
            track-by="name"
            :limit="20"
            :limitText="limitText"
            :close-on-select="false"
            placeholder=""
            aria-label="Open dialog for selecting partner admins."
            @click.self.prevent
          >
          </multiselect>
          <div class="inup-form-label-container">
            <label for="partner-managers-multiselect">Partner Admins</label>
          </div>
        </div>
      </div>
      <div v-if="isAdmin()" class="profile-form-section inup-form-section eu-form-section">
        <h3 tabindex="0" class="medium-margin-bottom">Admin Settings</h3>
        <div style="position: relative; margin-bottom: 31px; margin-top: 21px">
          <select name="ptype" class="inup-input" v-model="partner.partner_type">
            <option value="BASIC">Basic</option>
            <option value="STANDARD">Standard</option>
            <option value="PREMIUM">Premium</option>
          </select>
          <div class="inup-form-label-container">
            <label for="ptype">Partner Type</label>
          </div>
        </div>
        <div class="multiple-input-select">
          <div class="multiple-elements">
            <div class="eu-form-element-container-small">
              <input
                v-model="partner.partner_admins_limit"
                id="manager-limit-input"
                type="number"
                class="eu-form-input"
              />
              <div class="eu-form-label-container">
                <label for="il-title-input">Partner Managers Limit</label>
              </div>
            </div>
            <div class="eu-form-element-container-small">
              <input
                v-model="partner.partner_superadmins_limit"
                id="admin-limit-input"
                type="number"
                class="eu-form-input"
              />
              <div class="eu-form-label-container">
                <label for="il-url-input">Partner Admins Limit</label>
              </div>
            </div>
          </div>
        </div>
        <div class="inup-from-element-container">
          <div class="iu-partner-settings-featured-container">
            <h3 class="preference-container-title">Featured</h3>
            <toggle-button
              id="featured-toggle"
              :value="partner.is_featured"
              :sync="true"
              :width="88"
              :height="24"
              :fontSize="12"
              :color="{ checked: '#4c55a7', unchecked: '#b4b4b4' }"
              :labels="{ checked: 'Active', unchecked: 'Disabled' }"
              @change="featuredSwitch"
            />
          </div>
        </div>
      </div>
      <div class="profile-form-button-container">
        <input type="submit" :value="submitText" class="eu-btn-primary-small" />
      </div>
    </form>
    <div v-if="isLoading">
      <loading-spinner wrapperClass="wrapper" />
    </div>
    <objects-select-modal
      :isModalActive="isUsersSelectModalActive"
      @close-modal="closeModalFunction"
      :title="selectModalTitle"
      actionName="Select"
      objectName="User"
      :objectsList="usersList"
      :isLoading="isLoadingSelectUsers"
      :page="selectUserPage"
      :totalPageNumber="selectUserTotalPageNumber"
      :currentPage="selectUserCurrentPage"
      :showIcon="false"
      :preselectedObjects="selectedUsers"
      :filterGroups="contactGroup_list"
      @loadData="loadUsers"
      @searchObjects="loadUsers"
    />

    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>
<script lang="ts">
import * as Cookies from 'js-cookie';
import PartnerAccountService from '../services/PartnerAccountService';
import FeedService from '../../feed/services/FeedService';
import Notification from '../../components/Notification.vue';
import DropzoneComponent from '../../form/components/DropzoneComponent.vue';
import SmallDropzoneComponent from '../../form/components/SmallDropzoneComponent.vue';
import MultipleInputSelect from '../../form/components/MultipleInputSelect.vue';
import Multiselect from 'vue-multiselect';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import ObjectsSelectModal from '../../accounts/components/modals/ObjectsSelectModal.vue';
import AccountsService from '../../accounts/services/AccountsService';
import { focusElement } from '../../utils/CustomUtils';
import ContactGroupService from '../../services/ContactGroupService';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  components: {
    ObjectsSelectModal,
    Notification,
    LoadingSpinner,
    Multiselect,
    MultipleInputSelect,
    DropzoneComponent,
    SmallDropzoneComponent,
    ToggleButton,
  },
  name: 'partner-manage',
  props: [
    'social_options',
    'partner',
    'image_size_data',
    'all_users',
    'focus_areas',
    'career_academic_interests',
    'is_verify_partner',
    'destinations',
    'opportunity_type',
    'audiences',
  ],
  data() {
    return {
      isNotificationActive: false,
      notification: { msg: '', isError: false, icon: null },
      thumbnail: {},
      logo: {},
      imageInput: null,
      logoInput: null,
      socialInput: null,
      titleInput: null,
      urlInput: null,
      submitText: 'Save',
      isLoading: false,
      isUsersSelectModalActive: false,
      isLoadingSelectUsers: false,
      selectUserPage: 1,
      selectUserTotalPageNumber: 1,
      selectUserCurrentPage: 1,
      usersList: [],
      selectedUsers: [],
      selectModalTitle: '',
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
      contactGroup_list: [],
      closeModalFunction: this.closePartnerContactUsersSelectModal,
      partner_destinations: [],
    };
  },
  async created() {
    this.service = new PartnerAccountService();
    this.feedService = new FeedService();
    this.accountsService = new AccountsService();
    this.contactGroupService = new ContactGroupService();
    setTimeout(() => {
      this.imageInput = document.querySelector('#thumbnail');
      this.logoInput = document.querySelector('#logo');
      this.socialInput = document.querySelector('#social-networks-input');

      const ilList = document.querySelector('#il-list');
      this.populateListData(ilList, this.partner.important_links, 'url');
    }, 0);
    this.loadContactGroups();
    this.partner_destinations = this.partner.focus_areas.filter((fa) => fa.type === 'destinations');
    this.partner.focus_areas = this.partner.focus_areas.filter((fa) => fa.type !== 'destinations');
  },
  methods: {
    isAdmin() {
      const userStatusDataComponent: HTMLElement = document.querySelector('.js-status-data');
      const loggedUser = JSON.parse(userStatusDataComponent.dataset.userStatus);
      return loggedUser.user.role === 'ADMIN';
    },
    statusSwitch() {
      this.partner.is_active = !this.partner.is_active;
    },
    featuredSwitch() {
      this.partner.is_featured = !this.partner.is_featured;
    },
    limitText(count) {
      return `+ ${count} more`;
    },
    userToSelectObjectMapper(user) {
      return {
        id: user.id,
        name: user.first_name + ' ' + user.last_name,
        unique_signature: user.email,
        icon: user.profile_pic,
      };
    },
    async loadContactGroups() {
      this.contactGroupService.getContactGroupList().then((data) => {
        this.contactGroup_list = data.data;
      });
    },
    async loadUsers(searchString = '', page = 1, selectedContactGroup_id) {
      if (this.isAdmin()) {
        this.selectUserPage = page;

        let selectedContactGroup_id_check = selectedContactGroup_id;
        if (selectedContactGroup_id == undefined) selectedContactGroup_id_check = 0;

        if (selectedContactGroup_id_check == 0) {
          if (page == 1) this.usersList = [];
          this.isLoadingSelectUsers = true;
          let response = await this.accountsService.getUsers({
            name: searchString,
            page: page,
          });
          this.usersList = this.usersList.concat(response.page_data.map((user) => this.userToSelectObjectMapper(user)));
          this.selectUserTotalPageNumber = response.total_pages_number;
          if (this.selectUserTotalPageNumber == this.selectUserPage) {
            this.isLoadingSelectUsers = false;
          }
          this.selectUserCurrentPage = page;
        } else {
          this.usersList = [];
          let response = await this.contactGroupService.getContactGroupUsers(selectedContactGroup_id, {
            name: searchString,
          });
          this.usersList = this.usersList.concat(response.data.map((user) => this.userToSelectObjectMapper(user)));
          this.selectUserCurrentPage = page;
          this.isLoadingSelectUsers = false;
        }
      } else {
        this.usersList = this.all_users;
      }
    },

    containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }
      return false;
    },
    async openPartnerUsersSelectModal() {
      this.selectModalTitle = 'Select partner members';
      this.closeModalFunction = this.closePartnerUsersSelectModal;
      this.isUsersSelectModalActive = true;
      this.selectedUsers = this.partner.partner_user_list;
      focusElement('objects-select-modal-title');
      this.loadUsers('', 1);
    },

    closePartnerUsersSelectModal(closeInfo) {
      if (!closeInfo['canceled']) {
        const newSelectedUsersList = closeInfo['selectedObjects'];
        this.partner.partner_user_list = newSelectedUsersList;
      }
      this.isUsersSelectModalActive = false;
      focusElement('partner-members-title');
    },

    async openPartnerContactUsersSelectModal() {
      this.selectModalTitle = 'Select partner contacts';
      this.closeModalFunction = this.closePartnerContactUsersSelectModal;
      this.isUsersSelectModalActive = true;
      this.selectedUsers = this.partner.partner_contacts;
      focusElement('objects-select-modal-title');
      this.loadUsers('', 1);
    },

    closePartnerContactUsersSelectModal(closeInfo) {
      if (!closeInfo['canceled']) {
        const newSelectedUsersList = closeInfo['selectedObjects'];
        this.partner.partner_contacts = newSelectedUsersList;
      }
      this.isUsersSelectModalActive = false;
      focusElement('partner-contacts-and-managers-title');
    },

    async openPartnerManagerUsersSelectModal() {
      this.selectModalTitle = 'Select partner managers';
      this.closeModalFunction = this.closePartnerManagerUsersSelectModal;
      this.isUsersSelectModalActive = true;
      this.selectedUsers = this.partner.partner_admins;
      focusElement('objects-select-modal-title');
      this.loadUsers('', 1);
    },

    closePartnerManagerUsersSelectModal(closeInfo) {
      if (!closeInfo['canceled']) {
        const newSelectedUsersList = closeInfo['selectedObjects'];
        this.partner.partner_admins = newSelectedUsersList;
        // Update partner_superadmins by removing any user that is now in partner_admins
        this.partner.partner_superadmins = this.partner.partner_superadmins.filter(
          (superadmin) => !newSelectedUsersList.some((admin) => admin.id === superadmin.id)
        );
      }
      this.isUsersSelectModalActive = false;
      focusElement('partner-contacts-and-managers-title');
    },

    async openPartnerAdminUsersSelectModal() {
      this.selectModalTitle = 'Select partner admins';
      this.closeModalFunction = this.closePartnerAdminUsersSelectModal;
      this.isUsersSelectModalActive = true;
      this.selectedUsers = this.partner.partner_superadmins;
      focusElement('objects-select-modal-title');
      this.loadUsers('', 1);
    },

    closePartnerAdminUsersSelectModal(closeInfo) {
      if (!closeInfo['canceled']) {
        const newSelectedUsersList = closeInfo['selectedObjects'];
        this.partner.partner_superadmins = newSelectedUsersList;
        // Update partner_admins by removing any user that is now in partner_superadmins
        this.partner.partner_admins = this.partner.partner_admins.filter(
          (admin) => !newSelectedUsersList.some((superAdmin) => superAdmin.id === admin.id)
        );
      }
      this.isUsersSelectModalActive = false;
      focusElement('partner-contacts-and-managers-title');
    },

    addImportantLink() {
      const ilList = document.querySelector('#il-list');
      if (this.titleInput && this.urlInput) {
        this.handleAddItem(ilList);
      }
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    populateListData(el) {
      el.innerHTML = '';
      if (this.partner.important_links && Object.keys(this.partner.important_links).length > 0) {
        for (const title in this.partner.important_links) {
          el.innerHTML += `<div class="mis-item-container">
                            <a target="_blank" href="${this.partner.important_links[title]}" class="mis-link-text">${title}</a>
                            <span tabindex="0" aria-label="Remove ${title}" class="list-remove-button" id="trash--url--${title}"><i class="fad fa-trash mis-remove-icon"></i></span>
                        </div>`;
        }
      }
      this.handleDeleteListener();
    },
    handleAddItem(list) {
      if (!this.partner.important_links) this.partner.important_links = {};
      this.partner.important_links[this.titleInput] = !this.urlInput.toLowerCase().startsWith('http')
        ? 'https://' + this.urlInput
        : this.urlInput;
      this.populateListData(list);
      this.titleInput = '';
      this.urlInput = '';
    },
    handleDeleteListener() {
      const ilList = document.querySelector('#il-list');
      const trashBtn = document.getElementsByClassName('list-remove-button');
      Array.from(trashBtn).forEach(function (element) {
        element.addEventListener('click', (e) => {
          const objKey = e.target['parentElement'].id.split('--')[2];
          if (e.target['parentElement'].id.split('--')[1] === 'url') {
            delete this.partner.important_links[objKey];
            this.urlInput = JSON.stringify(this.partner.important_links);
            this.populateListData(ilList, this.partner.important_links, 'url');
          }
        });
      });
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    handleRemoveImage() {
      this.thumbnail = {};
    },
    prepareImageUpload(fileObject) {
      this.thumbnail = fileObject;
    },
    handleRemoveLogo() {
      this.logo = {};
    },
    prepareLogoUpload(fileObject) {
      this.logo = fileObject;
    },
    validateForm() {
      if (!this.partner.name || this.partner.focus_areas.length === 0) {
        this.showNotification({
          msg: 'Error: Please fill in all required fields',
          isError: true,
          icon: 'fad fa-exclamation-circle',
        });
        return false;
      }
      return true;
    },
    async submitForm() {
      if (this.submitText === 'Save' && this.validateForm()) {
        this.submitText = 'Saving...';
        this.isLoading = true;
        const uploadRes = await this.uploadImgs();
        if (uploadRes.success) {
          const partnerDTO = this.setAdditionalFields();
          const res = await this.service.updatePartner(partnerDTO, this.headers);
          if (res.success) {
            window.scrollTo(0, 0);
            this.showNotification({ msg: 'Partner Successfully Updated', icon: 'fad fa-check-circle' });
          } else {
            let err = 'Server Error';
            if (res.partner_superadmins) {
              err = res.partner_superadmins[0];
            } else if (res.partner_admins) {
              err = res.partner_admins[0];
            }
            this.showNotification({ msg: err, isError: true, icon: 'fad fa-exclamation-circle' });
          }
        } else {
          this.showNotification({ msg: uploadRes.msg, isError: true, icon: 'fad fa-exclamation-circle' });
        }
        this.submitText = 'Save';
        this.isLoading = false;
        if (this.is_verify_partner) {
          window.location.href = `/partners/${this.partner.name}`;
        }
      }
    },
    setAdditionalFields() {
      let partnerDTO = { ...this.partner };
      partnerDTO.partner_admins = this.partner.partner_admins.map((e) => e.id);
      partnerDTO.partner_superadmins = this.partner.partner_superadmins.map((e) => e.id);
      partnerDTO.partner_user_list = this.partner.partner_user_list.map((e) => e.id);
      partnerDTO.partner_contacts = this.partner.partner_contacts.map((e) => e.id);
      partnerDTO.focus_areas = this.partner.focus_areas
        .map((e) => e.id)
        .concat(this.partner_destinations.map((e) => e.id));
      partnerDTO.career_academic_interests = this.partner.career_academic_interests.map((e) => e.id);
      partnerDTO.audiences = this.partner.audiences.map((e) => e.id);
      if (this.socialInput?.value) {
        partnerDTO.social_networks = JSON.parse(this.socialInput.value);
      }
      return partnerDTO;
    },
    async uploadImgs() {
      let imgRes, logoRes;
      if (this.thumbnail && Object.keys(this.thumbnail).length > 0) {
        imgRes = await this.feedService.uploadFile(this.thumbnail, this.headers);
        if (imgRes?.resource) {
          this.partner.cover_pic = imgRes.resource;
        } else {
          return { success: false, msg: 'Partner Cover Image Upload Failed' };
        }
      }
      if (this.logo && Object.keys(this.logo).length > 0) {
        logoRes = await this.feedService.uploadFile(this.logo, this.headers);
        if (logoRes?.resource) {
          this.partner.logo_url = logoRes.resource;
        } else {
          return { success: false, msg: 'Partner Logo Upload Failed' };
        }
      }
      return { success: true };
    },
  },

  computed: {},
};
</script>

<style scoped lang="scss">
.partner-manage-section {
  width: 100%;
}

.inup-form-element-container {
  &.partner-manage {
    pointer-events: none;
    margin-top: 40px;
  }
}

.multiple-input-select {
  &.partner-manage {
    margin-bottom: 20px;
  }
}

.multiselect-wrapper.hidden__content {
  &.partner-manage {
    width: 100%;
    margin: 0;
    margin-bottom: 30px;
    position: relative;
  }

  &.inactive {
    pointer-events: none;
    opacity: 0.7;
  }
}
</style>
