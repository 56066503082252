<template>
  <div class="pop-up-textbox" v-show="visible && text">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'pop-up-textbox',
  props: [
    'visible',
    'text'
  ]
}
</script>

<style scoped>
.pop-up-textbox {
  max-width: 310px;
  padding: 6px 12px;
  border-radius: 4px;
  background-color: #3b3355;
  color: #fff;
  position: relative;
  z-index: 2;
}

.pop-up-textbox::after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	top: 100%;
  left: 50%;
	border: 6px solid transparent;
	border-bottom: none;

	border-top-color: #3b3355;
	filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}
</style>
