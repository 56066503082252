<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <quests-header :canManage="isAdmin || isDeptLead" :showAddBtn="isAdmin || isDeptLead"></quests-header>
      <div
        class="event-search-with-background-image-container mobile-full-w"
        :style="`background-image: url('${assetPath}assets/globalii-opportunities-landing-hero.jpg'); background-size: cover; background-position: center; margin: 24px 0 24px 0;border-radius: 8px;`"
      >
        <div class="tint" style="border-radius: 8px"></div>
        <div style="display: flex; align-items: center; justify-content: center; flex-direction: column">
          <h1 class="event-search-with-background-image-title">Search quests</h1>
          <p class="event-search-with-background-image-description">
            Search for a specific list by keyword, {{ areDeptsEnabled ? `${deptSingularToLowerCase}, ` : "" }} focus area, or identity.
          </p>
        </div>
        <form
          action=""
          @submit.prevent="fetchQuests()"
          class="eu-search-form"
          style="margin-top: 24px; position: relative"
          role="search"
        >
          <input v-model="searchInput" name="q" type="search" placeholder="Search for quests" class="eu-search-input" />
          <button
            class="eu-search-btn-icon"
            style="
              padding: 14px 19px 10px 15px;
              right: 0;
              background-color: #5e50b5;
              border-radius: 0px 8px 8px 0;
              height: 50px;
            "
            aria-label="search"
          >
            <i class="eu-search-icon fas fa-search" style="color: #fff"></i>
          </button>
        </form>
      </div>
      <div class="filter-container content-filters-container" v-if="!isMobile">
        <multiselect-filter-component
          v-for="(filter, index) in filters"
          :key="index"
          :placeholder="filter.placeholder"
          :filter_name="filter.name"
          :singleSelect="filter.singleSelect"
          class="filter-item"
          :options="filter.options"
          @filter-data="createFilterData"
        />
      </div>
      <div class="option-pills-container" v-if="isMobile">
        <button
          class="collapse-filter-button"
          :class="!filterSectionCollapsed ? 'active' : ''"
          style="margin-left: auto"
          aria-label="Toggle filter section"
          @click="toggleFilterSection"
        >
          <i class="fa-solid fa-filter-list"></i>
        </button>
      </div>
      <div class="filter-container content-filters-container" v-if="!filterSectionCollapsed">
        <multiselect-filter-component
          v-for="(filter, index) in filters"
          :key="index"
          :placeholder="filter.placeholder"
          :filter_name="filter.name"
          class="filter-item"
          :options="filter.options"
          @filter-data="createFilterData"
        />
      </div>

      <div v-if="isLoading">
        <loading-spinner wrapperClass="wrapper" />
      </div>
      <div v-if="!isLoading && recommendedQuests.length > 0 && showRecommendations">
        <div class="title">
          <i class="fa-solid fa-stars" style="margin-right: 8px; color: #22a5a0"></i>Suggested for You
        </div>
        <div @scroll="onScrollHandlerMobile" class="grid-wrapper-four-card">
          <div v-for="item in recommendedQuests" :key="item.id + 'featured'">
            <quest-card :q="item" :showDetails="true" @show-notification="showNotification" />
          </div>
        </div>
      </div>
      <div v-if="!isLoading && quests.length > 0" style="margin-bottom: 32px">
        <div class="title" style="margin-top: 32px">
          {{ showRecommendations ? "All Quests" : `${total_num} Result${total_num.length > 1 ? "s" : ""}` }}
        </div>
        <div @scroll="onScrollHandlerMobile" class="grid-wrapper-four-card">
          <div v-for="item in quests" :key="item.id">
            <quest-card :q="item" :showDetails="true" @show-notification="showNotification" />
          </div>
        </div>
      </div>
      <div v-if="!isLoading && quests.length === 0">
        <div class="eu-empty-container">
          <div class="eu-empty">
            <div class="eu-empty-icon-container">
              <i class="fad fa-chalkboard-teacher eu-empty-icon"></i>
            </div>
            <div style="display: flex; flex-direction: column">
              <div>
                <span class="eu-empty-text"> No results. </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </div>
  </div>
</template>

<script>
import MultiselectFilterComponent from "../../form/components/MultiselectFilterComponent.vue";
import QuestService from "../services/QuestService";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import QuestsHeader from "./QuestsHeader.vue";
import Notification from "../../components/Notification.vue";
import QuestCard from "./QuestCard.vue";
import { useCommonDataStore } from "../../stores/commonData";
import { LICENSE_ONLY_FEATURES, isFeatureEnabled } from "../../utils/PermissionUtils";

const QUEST_STATUS_FILTERS = [
  { name: "All", value: "all" },
  { name: "In Progress", value: "in_progress" },
  { name: "Completed", value: "completed" },
];
export default {
  name: "quests-index",
  components: { QuestsHeader, QuestCard, MultiselectFilterComponent, LoadingSpinner, Notification },
  props: ["isAdmin", "isDeptLead", "userDepartment"],
  data() {
    return {
      quests: [],
      QUEST_STATUS_FILTERS: QUEST_STATUS_FILTERS,
      assetPath: process.env.MEDIA_URL,
      displaySearchInputText: "",
      pageCount: 1,
      searchInput: "",
      blockFetching: false,
      showSearchOverallSection: false,
      isFeaturedFilterEnabled: this.isMobile,
      filterSectionCollapsed: true,
      notification: { msg: "", isError: false, icon: null },
      isNotificationActive: false,
      showRecommendations: true,
      params: {
        departments: [],
        focus_areas: [],
        identities: [],
        status: null,
      },
      service: new QuestService(),
      commonDataStore: useCommonDataStore(),
      isLoading: false,
      recommendedQuests: [],
      areDeptsEnabled: false,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 576;
    },
    filters() {
      let baseFilters = [
        {
          placeholder: "Any Focus Area",
          name: "Focus Areas",
          options: this.commonDataStore.getSingleDataArray("focus_areas"),
        },
        {
          placeholder: "Any Identity",
          name: "Identities",
          options: this.commonDataStore.getSingleDataArray("identities"),
        },
        {
          placeholder: "Any Status",
          name: "Status",
          singleSelect: true,
          options: this.QUEST_STATUS_FILTERS,
        },
      ];

      // Conditionally add the Departments object based on areDeptsEnabled
      if (this.areDeptsEnabled) {
        baseFilters.unshift({
          placeholder: "Any " + this.deptSingular,
          name: "Departments",
          options: this.commonDataStore.getSingleDataArray("departments"),
        });
      }

      return baseFilters;
    },
    deptSingularToLowerCase() {
      return useCommonDataStore().getFeatureName('department_singular').toLowerCase()
    },
    deptSingular() {
      return useCommonDataStore().getFeatureName('department_singular')
    },
  },
  async created() {
    this.areDeptsEnabled = isFeatureEnabled(LICENSE_ONLY_FEATURES.DEPTS);
    this.isLoading = true;
    this.filterData = await this.service.getFilterData();
    this.isLoading = false;
    this.getRecommendedQuests();
    this.fetchQuests();
    this.scroll();
  },
  methods: {
    async getRecommendedQuests() {
      this.recommendedQuests = await this.service.getRecommended({ limit: 4 });
      this.recommendedQuests = this.recommendedQuests.sort((a, b) => b.is_required - a.is_required);
    },
    areParamsEmpty() {
      return (
        this.params.departments.length === 0 &&
        this.params.focus_areas.length === 0 &&
        this.params.identities.length === 0 &&
        this.searchInput === "" &&
        (this.params.status === "all" || !this.params.status)
      );
    },
    toggleFilterSection() {
      if (this.filterSectionCollapsed) {
        this.openFilterSection();
      } else {
        this.closeFilterSection();
      }
    },
    openFilterSection() {
      this.filterSectionCollapsed = false;
    },
    closeFilterSection() {
      this.filterSectionCollapsed = true;
    },
    openSearchResults() {
      this.displaySearchInputText = this.searchInput;
      this.showSearchOverallSection = true;
      this.isFeaturedFilterEnabled = false;
    },
    closeSearchResults(fetch_after = true) {
      this.showSearchOverallSection = false;
      this.search_input = "";
      this.displaySearchInputText = "";
      this.isFeaturedFilterEnabled = this.isMobile;
      if (fetch_after) this.fetchQuests();
    },
    closeNotification() {
      this.notification = { msg: "", isError: false, icon: null };
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.notification = { ...notification };
      this.isNotificationActive = true;
    },
    async search() {
      this.isLoading = true;
      this.apps = await this.service.getAll({
        q: this.searchInput,
        focus_areas: this.selectedFocusAreas,
        departments: this.selectedDepartments,
      });
      this.isLoading = false;
    },
    changeSearch(searchOption) {
      this.searchOption = searchOption;
    },
    parseFocusAreas(app) {
      return app.campus_focus_areas?.map((e) => e.name).join(", ");
    },
    goToQuest(questSlug) {
      this.$router.push(`/quests/${questSlug}`);
    },
    async fetchQuests() {
      this.pageCount = 1;

      this.showRecommendations = this.areParamsEmpty() ? true : false;
      this.isLoading = true;
      const response = await this.service.getAll({
        ...this.params,
        q: encodeURIComponent(this.searchInput),
        page: this.pageCount,
      });
      if (response) {
        this.quests = response.quests;
        this.total_num = response.total_num;
      }
      this.isLoading = false;
    },
    async onScrollHandlerMobile(event) {
      if (window.innerWidth <= 576) {
        const quests_container = event.target;
        const newScrollLeft = quests_container.scrollLeft;
        const width = quests_container.offsetWidth;
        const scrollWidth = quests_container.scrollWidth;
        var offset = 150;

        if (
          scrollWidth - newScrollLeft - width < offset &&
          !this.isLoading &&
          this.pageCount !== 0 &&
          !this.blockFetching
        ) {
          this.blockFetching = true;
          this.pageCount += 1;
          const response = await this.service.getAll({
            ...this.params,
            q: this.search_input,
            page: this.pageCount,
          });

          let newQuests = [];

          if (response) {
            newQuests = response.quests;
          }

          if (newQuests && newQuests.length > 0) {
            newQuests.forEach((e) => {
              this.quests.push(e);
            });
          } else this.pageCount = 0;
          this.blockFetching = false;
        }
      }
    },

    async onScrollHandlerDesktop() {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;

      if (bottomOfWindow && this.pageCount !== 0) {
        this.pageCount += 1;

        const response = await this.service.getAll({
          ...this.params,
          q: this.search_input,
          page: this.pageCount,
        });

        let newQuests = [];

        if (response && response.quests) {
          newQuests = response.quests;
        }

        if (newQuests && newQuests.length > 0) {
          newQuests.forEach((e) => {
            this.quests.push(e);
          });
        } else this.pageCount = 0;
      }
    },
    async scroll() {
      window.onscroll = async () => {
        if (window.innerWidth > 576) {
          this.onScrollHandlerDesktop();
        }
      };
    },
    createFilterData(value, name) {
      if (name === "Departments") {
        this.params.departments = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === "Focus Areas") {
        this.params.focus_areas = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === "Identities") {
        this.params.identities = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === "Status") {
        this.params.status = value.value;
      }

      this.showRecommendations = this.areParamsEmpty() ? true : false;

      this.fetchQuests();
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  color: #444444;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  border-bottom: 2px solid #e7e5ec;
  margin-bottom: 32px;
  padding: 16px 0;
}
</style>
