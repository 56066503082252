<template>
  <table class="eu-resource-table">
    <tr class="eu-resource-row">
      <th scope="col" class="eu-resource-cell eu-resource-cell-th">
        <span tabindex="0"
          @click="selectAll(resources, !selectedAll, resourceTable)"
          @keyup.enter="selectAll(resources, !selectedAll, resourceTable)"
          aria-label="Select all resources"
          role="button"
          :class="selectedAll ? 'eu-resource-cell-select eu-resource-cell-select-active' : 'eu-resource-cell-select'"
        ></span>
        <span tabindex="0" @click="sort('name')" @keyup.enter="sort('name')" aria-label="Sort resources by file name" role="button">
          File Name
          <i :class="sortedBy !== 'name' ? 'fas fa-sort' : directionUp.name ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i
        ></span>
      </th>
      <th v-if="areDeptsGroupsEnabled" scope="col" class="eu-resource-cell eu-resource-cell-th">
        <span tabindex="0" @click="sort('department')" @keyup.enter="sort('department')" aria-label="Sort resources by uploader" role="button">
          Uploaded By
          <i :class="sortedBy !== 'department' ? 'fas fa-sort' : directionUp.department ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th scope="col" class="eu-resource-cell eu-resource-cell-th">
        <span tabindex="0" @click="sort('timestamp')" @keyup.enter="sort('timestamp')" aria-label="Sort resources by upload date" role="button">
          Upload Date
          <i :class="sortedBy !== 'timestamp' ? 'fas fa-sort' : directionUp.timestamp ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th v-if="currentPage != 'archive'"></th>
      <th v-if="currentPage != 'archive'"></th>
      <th></th>
    </tr>
    <tr
      v-for="(item, i) in resources"
      v-bind:key="i"
      :class="selectedResources.includes(item.id) ? 'eu-resource-row eu-resource-row-selected' : 'eu-resource-row'"
      style="position: relative;"
    >
      <td class="eu-resource-cell eu-resource-cell-name">
        <span tabindex="0"
          @click="setSelectedResource(item.id, resourceTable)"
          @keyup.enter="setSelectedResource(item.id, resourceTable)"
          :aria-label="`Select ${item.name}`"
          role="button"
          :class="selectedResources.includes(item.id) ? 'eu-resource-cell-select eu-resource-cell-select-active' : 'eu-resource-cell-select'"
        ></span>
        <span class='eu-resource-cell-title' @click="viewItem(item, i)" @keyup.enter="viewItem(item, i)" :aria-label="`Open ${item.name} in modal`" role="button" aria-haspopup="dialog">
          <i v-if="resourceTable === 'featured'" class="fas fa-stars" style="padding-right: 5px; color:#00a1b3"></i>{{ item.name }}
          <div v-if="item.description" class="eu-resource-desc">
            <span class="eu-resource-desc-left-arrow"></span>
            <span class="eu-resource-desc-el">
              <h4 class="eu-resource-desc-title">Description</h4>
              <p class="eu-resource-desc-text">{{ item.description }}</p>
            </span>
          </div>
        </span>
      </td>
      <td v-if="isPartnerTenant || areDeptsGroupsEnabled">
        <span v-if="item.department" class="eu-resource-cell">{{ item.department.name }}</span>
        <span v-else-if="item.group" class="eu-resource-cell">{{ item.group.name }}</span>
        <span v-else-if="item.partner" class="eu-resource-cell">{{ item.partner.name }}</span>
        <span v-else></span>
      </td>
      <td class="eu-resource-cell">{{ formatUploadDate(item.timestamp) }}</td>
      <td class="eu-resource-cell eu-resource-cell-feature-icon" style="width: 36px;">
        <span
          v-if="currentPage != 'archive' && isAdminRole && isPartnerTab"
          tabindex="0"
          @click="featureItem(item)"
          @keyup.enter="featureItem(item)"
          :aria-label="item.is_partner_featured ? `Unfeature ${item.name}` : `Feature ${item.name}`"
          role="button"
        >
          <i class="fas fa-stars" :style="item.is_partner_featured ? 'color: #00a1b3; background: #eaeeff' : ''"></i>
        </span>
        <span
          v-if="currentPage != 'archive' && isAdminRole && !isPartnerTab"
          tabindex="0"
          @click="featureItem(item)"
          @keyup.enter="featureItem(item)"
          :aria-label="item.is_recommended ? `Unfeature ${item.name}` : `Feature ${item.name}`"
          role="button"
        >
          <i class="fas fa-stars" :style="item.is_recommended ? 'color: #00a1b3; background: #eaeeff' : ''"></i>
        </span>
      </td>
      <td class="eu-resource-cell eu-resource-cell-bookmark-icon" style="width: 36px;">
        <span
          v-if="currentPage != 'archive'"
          tabindex="0"
          @click="toggleBookmark(item)"
          @keyup.enter="toggleBookmark(item)"
          :aria-label="item.is_bookmarked ? 'Remove '+ item.name +' from saved resources' : 'Add '+ item.name +' to saved resources'"
          role="button"
        >
          <i :style="item.is_bookmarked ? 'color: #9f2a8c; background: #eaeeff' : ''" class='fad fa-bookmark' ></i>
        </span>
      </td>
      <td class="eu-resource-cell eu-resource-cell-dots-icon" style="width: 36px;">
        <span tabindex="0" ref='resListBurger' :id="`resource_burger_list_${item.id}`"  @click="showBurger(item)" @keyup.enter="showBurger(item)" aria-label="Burger menu" role="menu" aria-haspopup="menu">
          <i class="fas fa-ellipsis-v"></i>
        </span>
      </td>
      <div
        ref='resListBurgerDropdown'
        :id="'r' + item.id + item.timestamp"
        class="eu-resource-burger"
        style="right: 0; top: 46px;"
        >
        <span
          tabindex="0"
          :aria-label='`Open ${item.name} in modal`'
          @click="viewItem(item, i)"
          @keyup.enter="viewItem(item, i)"
          aria-haspopup="dialog"
          role="button"
          class="eu-resource-burger-el"
        >
          <i class="fas fa-eye" style="padding-right: 5px"></i>
          <span>View</span>
        </span>
        <span
          v-if="has_permission(item)"
          tabindex="0"
          :aria-label='`Open edit modal for ${item.name}`'
          aria-haspopup="dialog"
          role="button"
          @click="openEditModal(item)"
          @keyup.enter="openEditModal(item)"
          class="eu-resource-burger-el"
        >
          <i class="fas fa-edit" style="padding-right: 5px"></i>
          <span>Edit</span>
        </span>
        <span
          v-if="currentPage != 'archive' && has_permission(item) && isPartnerTab"
          tabindex="0"
          :aria-label="item.is_partner_featured  ? 'Unfeature '+ item.name : 'Feature '+ item.name"
          @click="featureItem(item)"
          @keyup.enter="featureItem(item)"
          role="button"
          class="eu-resource-burger-el"
        >
          <i class="fas fa-stars" style="padding-right: 5px"></i>
          <span>{{ item.is_partner_featured ? 'Unfeature' : 'Feature' }}</span>
        </span>
        <span
          v-if="currentPage != 'archive' && has_permission(item) && !isPartnerTab"
          tabindex="0"
          :aria-label="item.is_recommended  ? 'Unfeature '+ item.name : 'Feature '+ item.name"
          @click="featureItem(item)"
          @keyup.enter="featureItem(item)"
          role="button"
          class="eu-resource-burger-el"
        >
          <i class="fas fa-stars" style="padding-right: 5px"></i>
          <span>{{ item.is_recommended ? 'Unfeature' : 'Feature' }}</span>
        </span>
        <span
          v-if="item.resource_type != 'VID' && item.resource_type != 'URL' && item.resource_type != 'URL_INTERNAL' && item.resource_type != 'URL_EXTERNAL'"
          tabindex="0"
          :aria-label='`Download ${item.name}`'
          @click="downloadItem(item.resource_url, item.name, item.id)"
          @keyup.enter="downloadItem(item.resource_url, item.name, item.id)"
          role="button"
          class="eu-resource-burger-el">
          <i class="fas fa-download" style="padding-right: 5px"></i>
          <span>Download</span>
        </span>
        <span
          v-if="currentPage != 'archive'"
          tabindex="0"
          :aria-label="item.is_bookmarked ? 'Remove '+ item.name +' from saved resources' : 'Add '+ item.name +' to saved resources'"
          role="button"
          @click="toggleBookmark(item)"
          @keyup.enter="toggleBookmark(item)"
          class="eu-resource-burger-el"
        >
          <i class="fad fa-bookmark" style="padding-right: 10px"></i>
          <span>{{ item.is_bookmarked ? 'Unsave' : 'Save' }}</span>
        </span>
        <span
          tabindex="0"
          :aria-label="'Copy '+ item.name +' link.'"
          v-if="currentPage != 'archive'"
          @click="copyResourceLink(item.id)"
          @keyup.enter="copyResourceLink(item.id)"
          role="button"
          class="eu-resource-burger-el"
        >
          <div>
            <i class="fa-solid fa-link" style="padding-right: 10px"></i>
            Copy link
          </div>
          <div class="iu-resource-copy-link-additional-text">File accessible to members only</div>
        </span>
        <span
          tabindex="0"
          :aria-label="item.is_archived ? 'Remove '+ item.name +' from archived resources' : 'Add '+ item.name +' to archived resources'"
          role="button"
          v-if="has_permission(item)"
          @click="toggleArchive(item)"
          @keyup.enter="toggleArchive(item)"
          class="eu-resource-burger-el">
          <i class="fas fa-archive" style="padding-right: 5px"></i>
          <span>{{ item.is_archived ? 'Unarchive' : 'Archive' }}</span>
        </span>
        <span
          tabindex="0"
          :aria-label='`Open remove modal for ${item.name}`'
          v-if="has_permission(item)"
          @click="openDeleteModal(item)"
          @keyup.enter="openDeleteModal(item)"
          aria-haspopup="dialog"
          role="button"
          class="eu-resource-burger-el"
        >
          <i class="fas fa-trash-alt" style="padding-right: 5px"></i>
          <span>Delete</span>
        </span>
      </div>
    </tr>
  </table>
</template>
<script lang="ts">

import handleOutsideClick from '../../directives/handleOutsideClick';
import moment from 'moment';

export default {
  components: {},
  directives: { closable: handleOutsideClick },
  name: 'resources-table',
  props: [
      'resources',
      'directionUp',
      'sortedBy',
      'sortBy',
      'resourceTable',
      'selectedAll',
      'selectedResources',
      'manageAccess',
      'currentPage',
      'isAdminRole',
      'department_name',
      'group',
      'partner',
      'isPartnerTab',
      'areDeptsGroupsEnabled',
      'isPartnerTenant'
    ],
  data() {
    return {};
  },
  async created() {},
  methods: {
    copyResourceLink(resource_id) {
      this.$emit('copyResourceLink', resource_id);
    },
    formatUploadDate(dateString) {
      return moment(dateString).format('MMM DD, YY');
    },
    sort(column) {
      this.$emit('sortTable', column);
    },
    viewItem(el, index) {
      this.$emit('viewTableItem', el, index);
    },
    closeBurgers: function () {
      this.$emit('close-burgers');
    },
    showBurger(el) {
      this.$emit('showBurgerWindow', el);
    },
    toggleArchive(el) {
      this.$emit('archive', el);
    },
    featureItem(el) {
      this.$emit('feature', el);
    },
    downloadItem(dataurl, filename, id) {
      this.$emit('download', dataurl, filename, id);
    },
    openDeleteModal(el) {
      this.$emit('openDelete', el);
    },
    openEditModal(el) {
      this.$emit('openEdit', el);
    },
    toggleBookmark(el) {
      this.$emit('bookmark', el);
    },
    setSelectedResource(resource, resourceTable) {
      this.$emit('setSelectedResourceItem', resource, resourceTable);
    },
    selectAll(resources, isSelected, resourceTable) {
      this.$emit('selectAllItems', resources, isSelected, resourceTable);
    },

    has_permission(el){
      if(this.group) return el.group?.id === this.group?.id && this.manageAccess;
      if(this.partner) return el.partner?.id === this.partner?.id && this.manageAccess;
      if(this.isAdminRole) return true;
      if(el.department) return this.manageAccess && this.department_name === el.department.name? true: false;

      return false;
    }
  },
  computed: {}
};
</script>
