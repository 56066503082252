<template>
  <section>
    <div tabindex="0" class="" style="display: flex">
      <div class="iu-group-logo-wrapper">
        <router-link :to="`/groups/${encodeURIComponent(item.name)}`">
          <img class="iu-group-logo" alt="Group Img" :src="`${item.logo_url ? item.logo_url : item.cover_pic}`" />
        </router-link>
      </div>
      <div class="iu-group-text-wrapper">
        <router-link :to="`/groups/${encodeURIComponent(item.name)}`" :aria-label="`Go to page of ${item.name}`">
          <h5 class="card-title" style="padding-top: 0">{{ item.name }}</h5>
        </router-link>
        <p class="card-description" :style="item.name.length > 16 ? `-webkit-line-clamp: 1` : `-webkit-line-clamp: 2`">
          {{ item.description }}
        </p>
        <p class="card-description" style="margin-top: 12px;"> {{item.group_followers.length}} {{item.group_followers.length == 1 ? 'follower': 'followers'}}</p>
        <button
      class="group-follow-btn small-screen"
      @click="handleFollow(item.is_following ? false : true)"
      @keyup.enter="handleFollow(item.is_following ? false : true)"
      :class="`${item.is_following ? 'iu-btn-light' : 'iu-btn-solid'}`"
      :aria-label="`${item.is_following ? 'Unfollow' : 'Follow'}`"
    >
      {{ item.is_following ? 'Following' : 'Follow' }}
    </button>
      </div>
      <div class="iu-group-btn-wrapper">
      </div>
    </div>
    <button
      class="group-follow-btn big-screen"
      @click="handleFollow(item.is_following ? false : true)"
      @keyup.enter="handleFollow(item.is_following ? false : true)"
      :class="`${item.is_following ? 'iu-btn-light' : 'iu-btn-solid'}`"
      :aria-label="`${item.is_following ? 'Unfollow' : 'Follow'}`"
    >
      {{ item.is_following ? 'Following' : 'Follow' }}
    </button>
  </section>
</template>

<script>
export default {
  name: 'group-card-component',
  props: ['item'],
  data() {
    return {};
  },
  async created() {},
  methods: {
    handleFollow(action) {
      this.$emit('handle-follow', action, this.item.name);
      this.item.is_following = !this.item.is_following;
    },
  },
};
</script>

<style></style>
