import * as Cookies from "js-cookie";
import { LogService, getClientData } from "./LogService";

declare interface RequestOptions {
    endpoint?:string;
    method: string;
    headers: Headers,
    responseType?: string;
    constructor?: Function;
    toString?: Function;
}

class ApiResponseError extends Error {
  responseObject: Object;
  constructor(message, responseObject=null) {
    super(message);
    this.responseObject = responseObject;
    this.name = 'ApiResponseError'
  }
}

export default abstract class HttpService {
    protected endpoint:string;
    protected params:Object;
    private connection:Object;
    protected defaultOpts:RequestOptions;

    constructor(url:string){
        this.endpoint = url;
        this.defaultOpts = {
            endpoint: null,
            method: 'GET',
            headers: new Headers({
                'content-type': 'application/json'
            })
        };
    }

    protected call(opts:any, throwError:boolean=false):Promise<any> {
        let options = {...this.defaultOpts, ...opts};
        let endpoint = '';

        endpoint = options.endpoint ? options.endpoint : this.endpoint;

        return fetch(endpoint, options)
        .then(async (resp) => {
            if (!resp.ok) {
                const responseJson = await resp.json();
                const logService = new LogService();
                const additionalInfo = getClientData();
                const payload = {
                    'error': responseJson.error ? responseJson.error : 'no_error_key',
                    'message': responseJson.message ? responseJson.message : 'no_error_message',
                    'status': resp.status,
                    'endpoint': endpoint,
                    'method': options.method,
                    'params': this.params ? this.params : {},
                    'additionalInfo': additionalInfo,
                };
                logService.log(payload);
                throw new ApiResponseError(resp.statusText, responseJson);
            }
            if (options.responseType === 'blob') {
                return resp.blob();
            } else {
                return resp.json();
            }
        })
        .catch(error => {
            if (throwError) {
                throw(error);
            }
        });
    }

      get(data?: Object, customHeaders?: Object, throwError: boolean = false, responseType: string = 'json'): Promise<any> {
        let optionalHeaders = (customHeaders ? customHeaders : {});
        let endpoint;

        let requestOptions = {...this.defaultOpts,
            ...{headers: new Headers(optionalHeaders as HeadersInit)}
        };
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');
        requestOptions['responseType'] = responseType; // Set the response type

        if (data) {
            requestOptions['endpoint'] = this.buildRequestURL(data);
        }

        return this.call(requestOptions, throwError);
    }
    post(data?:Object, customHeaders?:Object, throwError:boolean=false):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});
        let overrides = {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(data),
            headers: new Headers(optionalHeaders as HeadersInit)
        };

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('Content-Type','application/json');
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions, throwError);
    }

    postFormData(data?:Object, customHeaders?:Object, throwError:boolean=false):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});

        let overrides = {
            method: 'POST',
            credentials: 'same-origin',
            body: this.buildFormData(data),
            headers: new Headers(optionalHeaders as HeadersInit)
        };

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions, throwError);
    }

    loginToPayload(data) {
      let headers = {};

      let overrides = {
        method: 'POST',
        credentials: 'credentials',
        body: this.buildFormData(data),
        headers: new Headers(headers as HeadersInit)
      };

      let requestOptions = {...this.defaultOpts,...overrides};
      requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

      return this.call(requestOptions, false);
    }

    put(data?:Object, customHeaders?:Object, throwError:boolean=false):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});
        let overrides = {
            method: 'PUT',
            credentials: 'same-origin',
            body: JSON.stringify(data),
            headers: new Headers(optionalHeaders as HeadersInit)
        }

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('Content-Type','application/json');
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions, throwError);
    }

    putFormData(data?:Object, customHeaders?:Object):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});

        let overrides = {
            method: 'PUT',
            credentials: 'same-origin',
            body: this.buildFormData(data),
            headers: new Headers(optionalHeaders as HeadersInit)
        };

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions);
    }

    delete(data?:Object, customHeaders?:Object):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});
        let overrides = {
            method: 'DELETE',
            credentials: 'same-origin',
            body: JSON.stringify(data),
            headers: new Headers(optionalHeaders as HeadersInit)
        };

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('Content-Type','application/json');
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions);
    }

    uploadFile(data?:Object, customHeaders?:Object):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});
        let overrides = {
            method: 'POST',
            credentials: 'same-origin',
            body: data,
            headers: new Headers(optionalHeaders as HeadersInit)
        };

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');
        requestOptions['headers'].delete('content-type');
        requestOptions['headers'].delete('Content-Type');

        return this.call(requestOptions);
    }

    /* Low level pure request object */
    request(data?:Object, options?:Object):Promise<any> {
        let requestOptions:Object = { ...this.defaultOpts, ...options};
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions);
    }

    buildRequestURL(params):string {
        let keys:string[] = Object.keys(params);
        if (!keys.length) {
            return this.endpoint;
        }

        let currentUrl:string = this.endpoint + '?';

        let endpoint = keys.reduce((acc, curr) => {
            return acc + curr + '=' + params[curr] + '&'
        }, currentUrl);

        return endpoint.substr(0, endpoint.length - 1); // remove trailing '&'
    }

    buildFormData(data:Object):FormData {
        let formResponse = new FormData();

        Object.keys(data).forEach((item) => {
            formResponse.append(item, data[item]);
        })

        return formResponse;
    }
}
