import Vue from 'vue';
import PoliciesManage from './components/PoliciesManage.vue'
interface AppData {
  audience:[],
  type:[],
  departments:[],
  identities:[],
  focus_areas:[],
  policy:any,
  is_update:boolean,
}
  

export default class PoliciesManageView {
  protected mountPoint: HTMLElement;
  protected audience;
  protected type;
  protected departments;
  protected identities;
  protected focus_areas;
  protected policy_update;
  protected is_update: Boolean;

  constructor(el: HTMLElement, appData: AppData) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.audience = appData.audience;
    this.type = appData.type;
    this.departments = appData.departments;
    this.identities = appData.identities;
    this.focus_areas = appData.focus_areas;
    this.policy_update = appData.policy[0];
    this.is_update = appData.is_update;

    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const policiesManageView = new Vue({
      components: {PoliciesManage},
      template: `
        <policies-manage
          :audience="audience"
          :type="type"
          :departments="departments"
          :identities="identities"
          :focus_areas="focus_areas"
          :policy_update="policy_update"
          :is_update="is_update"
        />
      `,
      data() {
        return {
          audience: _class.audience,
          type: _class.type,
          departments: _class.departments,
          identities: _class.identities,
          focus_areas: _class.focus_areas,
          policy_update: _class.policy_update,
          is_update: _class.is_update,
        };
      },
      methods: {},
    });
    policiesManageView.$mount(this.mountPoint);
  }
}
