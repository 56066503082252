<template>
  <div style="padding-top: 5rem">
    <section
      class="partner-tenant-partners-hero-section"
      style="background-image: url('https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/blog-hero.jpg')"
    >
      <div class="tint"></div>
      <div class="partner-tenant-partners-hero-content-container">
        <h2 class="partner-tenant-partners-hero-title">BeGlobalii Articles & Resources</h2>
        <p class="partner-tenant-partners-hero-description">
          Dive into our curated resources - tips, insights, real stories and more!
        </p>
      </div>
    </section>
    <breadcrumbs currentPageLabel="Articles & Resources" :routeItems="breadcrumbsLinkItems" />

    <!-- <section class="partner-tenant-blogs-featured-section" v-if="featuredBlogs && featuredBlogs.length > 0">
      <div
        class="partner-tenant-blogs-featured-content-container"
        :style="`background-image: url(${activeFeaturedBlogImageUrl})`"
      >
        <div class="partner-tenant-blogs-featured-overview-card">
          <div class="partner-tenant-blogs-featured-card-title-container">
            <h4 class="partner-tenant-blogs-featured-card-title">
              <i class="fa-regular fa-sparkles"></i>
              <i class="fa-solid fa-stars mobile-only"></i>
              Featured Article
            </h4>

            <slider-dots
              :totalNo="featuredBlogs.length"
              :initialSelectedNo="selectedFeaturedBlogIndex + 1"
              @changeSelected="changeSelectedFeaturedBlogSliderHandler"
            />
          </div>
          <div class="partner-tenant-blogs-featured-text-container">
            <h4 style="padding: 0">
              <router-link
                class="partner-tenant-blogs-featured-title"
                :to="`/articles/${activeFeaturedBlog.urlSlug}`"
                tabindex="0"
              >
                {{ activeFeaturedBlog.title }}
              </router-link>
            </h4>
            <p class="partner-tenant-blogs-featured-description">
              {{ activeFeaturedBlog.description }}
            </p>
          </div>
          <div class="partner-tenant-blogs-featured-pills-container">
            <span
              v-for="(tag, index) in activeFeaturedBlog.tag"
              :key="index"
              class="partner-tenant-blogs-featured-pill"
            >
              {{ tag.tagText }}
            </span>
          </div>
          <div class="partner-tenant-blogs-featured-author-container">
            <img
              :src="activeFeaturedBlogAuthorProfilePicUrl"
              alt="authorImg"
              class="partner-tenant-blogs-featured-author-image"
            />
            <div class="partner-tenant-blogs-featured-author-text-container">
              <div class="partner-tenant-blogs-featured-author-name">
                {{ activeFeaturedBlog.author.name }}
              </div>
              <div class="partner-tenant-blogs-featured-date-created">
                {{ formatDate(activeFeaturedBlog.createdAt) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section class="partner-tenant-blogs-cards-section">
      <div class="partner-tenant-blogs-cards-content-container">
        <blog-overview-card v-for="(blog, index) in blog_list" :key="index" :blog="blog" />
      </div>
    </section>
    <section class="partner-tenant-blogs-pages-slider-section">
      <div class="partner-tenant-blogs-pages-slider-content-container">
        <div class="partner-tenant-blogs-pages-slider-arrow-button back">
          <i class="fa-solid fa-arrow-left"></i>
          <i class="fa-solid fa-caret-left mobile-only"></i>
          <span class="text"> Previous </span>
        </div>
        <div class="partner-tenant-blogs-pages-slider-number-buttons-container">
          <button class="partner-tenant-blogs-pages-slider-number-button active">1</button>
        </div>
        <div class="partner-tenant-blogs-pages-slider-arrow-button forward">
          <span class="text"> Next </span>
          <i class="fa-solid fa-arrow-right"></i>
          <i class="fa-solid fa-caret-right mobile-only"></i>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from './components/Breadcrumbs.vue';
import SliderDots from './components/SliderDots.vue';
import BlogOverviewCard from './components/BlogOverviewCard.vue';
import BlogService from '../../../services/BlogService';
import { MediaCMSService } from '../../../services/CMSService';
import moment from 'moment';

export default {
  name: 'partner-tenant-blogs',
  components: {
    Breadcrumbs,
    SliderDots,
    BlogOverviewCard,
  },
  computed: {
    activeFeaturedBlog() {
      return this.featuredBlogs[this.selectedFeaturedBlogIndex];
    },
    activeFeaturedBlogImageUrl() {
      if (this.activeFeaturedBlog) {
        return this.activeFeaturedBlog.image.url;
      } else {
        return null;
      }
    },
    activeFeaturedBlogAuthorProfilePicUrl() {
      return this.activeFeaturedBlog.author.profilePic.url;
    },
    featuredBlogs() {
      return this.blog_list.filter((blog) => blog.featured == 'true');
    },
  },
  data() {
    return {
      breadcrumbsLinkItems: [
        {
          label: 'Home',
          path: '/',
          isSpa: true,
        },
      ],
      blogService: new BlogService(),
      mediaCMSService: new MediaCMSService(),
      blog_list: [],
      selectedFeaturedBlogIndex: 0,
    };
  },
  created() {
    this.blogService.getBlogsOverview().then((data) => {
      this.blog_list = data;
    });
  },
  methods: {
    formatDate(dateString) {
      return moment(dateString).format('MMMM DD, YYYY');
    },
    changeSelectedFeaturedBlogSliderHandler(sliderIndex) {
      this.changeSelectedFeaturedBlog(sliderIndex - 1);
    },
    changeSelectedFeaturedBlog(index) {
      this.selectedFeaturedBlogIndex = index;
    },
    redirectTo(url) {
      this.$router.push(url);
    },
  },
};
</script>
