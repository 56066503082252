export const capitalizeFirstLetter = (string: string) => {

  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else return null;
};

export const getStatusText = (status: string) => {

  const statusTexts = {
    active: 'Active', // Go to Meeting button
    confirmed: 'Confirmed',
    past: 'Completed',
    pending: 'Pending',
    canceled: 'Canceled',
    expired: 'Expired',
    urgent: 'Action Required'
  }

  return statusTexts[status];
}

export const stringifyArray = (array, field) => {

  if (array) {
    if (!field) {
      return array.join(', ');
    } else {
      return array.map(e => e[field]).join(', ')
    }
  }
}

/*
  If the meeting status is among the ones listed below, we don't want to show
  some parts of the hamburger menu.
*/
export const showCalendarAndCancelCheck = (status: string) => {

  const forbiddenStates = ['canceled', 'past', 'refused', 'expired', 'urgent',];

  const result: number = forbiddenStates.indexOf(status);

  // appointment state is not on the forbidden list
  if (result === -1) return true;

  return false;
}
