<template>
  <section class="">
    <div v-if="isLoading">
      <loading-spinner wrapperClass="wrapper" />
    </div>
    <div v-else>
      <div v-if="isProfileViewable">
        <section class="profile-header-section">
          <h1 v-if="isOwnProfile">Your Profile</h1>

          <div class="profile-header-info">
            <img v-if="profile.profile_pic" :src="profile.profile_pic" alt="User profile image" class="profile-pic" />
            <div class="profile-text-container">
              <h2 style="margin-right: 12px">
                {{ name }}
                <span v-if="isMentor" class="mentor-card">
                  <i class="fas fa-chalkboard-teacher mc-list-card-icon mentor-card-icon"></i>
                  Mentor
                </span>
                <div
                  v-if="isMentor"
                  id="bookmark-mentor-button"
                  :class="{
                    'bookmark-mentor-icon-active': isFavoriteMentor,
                    'bookmark-mentor-icon': !isFavoriteMentor,
                  }"
                  :aria-label="
                    isFavoriteMentor ? 'Remove mentor from favorite mentors.' : 'Add mentor to favorite mentors.'
                  "
                  @click="toggleFavoriteMentor"
                >
                  <i class="fad fa-bookmark"></i>
                  <span
                    id="bookmark-mentor-tooltip-text"
                    class="bookmark-mentor-tooltip"
                    :style="{ width: isFavoriteMentor ? '208px' : '165px' }"
                  >
                    {{ isFavoriteMentor ? "Remove mentor from favorites" : "Add mentor to favorites" }}
                  </span>
                </div>
              </h2>

              <div class="desc-line-clamp" style="margin-top: 8px">
                <p>{{ profile.about_me }}</p>
              </div>
              <p class="join-date">Joined {{ joinDate }}</p>
            </div>
            <a
              v-if="isOwnProfile"
              href="/settings/profile"
              name="Edit Profile"
              class="da-components-button eu-file-upload-modal-btn-secondary edit-profile-button"
            >
              Edit Profile
            </a>
          </div>
        </section>
        <div class="main-wrapper">
          <section class="profile-tab-section">
            <router-link :to="`/profiles/${userProfileUrl}`" class="profile-tab active"> Profile </router-link>
          </section>
          <mentor-meetings-analytics-component v-if="hasAnalyticsPermission" :mentorId="userId" />

          <section class="da-student-dashboard-content">
            <div class="profile-container">
              <div class="profile-info-container">
                <section v-if="focusAreas && focusAreas.length > 0" class="profile-info-section mentor-focus-areas">
                  <h2 class="mentor-focus-areas-text">Mentor Focus Areas</h2>
                  <a
                    v-if="isOwnProfile"
                    href="/profiles/settings/mentor-profile"
                    name="Edit Mentor Profile"
                    class="da-components-button eu-file-upload-modal-btn-secondary edit-profile-button"
                    style="position: absolute; right: 24px; top: 30px"
                    >Edit</a
                  >
                  <div class="profile-data flex-wrap" style="margin-top: 0">
                    <span v-for="fa in focusAreas" :key="fa.id" class="profile-user-interest">{{ fa.name }}</span>
                  </div>
                </section>

                <section class="profile-info-section">
                  <h3>Education</h3>
                  <div class="profile-data">
                    <div v-if="passport.major" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">Major:</span></div>
                      <div>
                        <p class="profile-data-element">{{ passport.major }}</p>
                      </div>
                    </div>

                    <div v-if="passport.minor" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">Minor:</span></div>
                      <div>
                        <p class="profile-data-element">{{ passport.minor }}</p>
                      </div>
                    </div>

                    <div v-if="passport.graduation_year" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">Graduation Year:</span></div>
                      <div>
                        <p class="profile-data-element">{{ passport.graduation_year }}</p>
                      </div>
                    </div>

                    <div v-if="passport.achievements" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">Achievements:</span></div>
                      <div>
                        <p class="profile-data-element">{{ passport.achievements }}</p>
                      </div>
                    </div>

                    <div v-if="passport.fluent_languages" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">Fluent Languages:</span></div>
                      <div>
                        <p class="profile-data-element">{{ passport.fluent_languages }}</p>
                      </div>
                    </div>

                    <div v-if="passport.proficient_languages" class="data-row-container">
                      <div class="data-label-container">
                        <span class="profile-data-label">Proficient Languages:</span>
                      </div>
                      <div>
                        <p class="profile-data-element">{{ passport.proficient_languages }}</p>
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  v-if="passport.grad_school || passport.current_industry || passport.career_interests"
                  class="profile-info-section"
                >
                  <h3>Career Goals &amp; Experience</h3>
                  <div class="profile-data">
                    <div v-if="passport.grad_school" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">Grad School:</span></div>
                      <p class="profile-data-element">{{ passport.grad_school }}</p>
                    </div>
                    <div v-if="passport.current_industry" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">Current Industry:</span></div>
                      <p class="profile-data-element">{{ passport.current_industry }}</p>
                    </div>
                    <div v-if="passport.career_interests" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">Industry Goals:</span></div>
                      <p class="profile-data-element">{{ passport.career_interests }}</p>
                    </div>
                  </div>
                </section>

                <section v-if="passport.user_interests" class="profile-info-section">
                  <h3>Interests</h3>
                  <div class="profile-data flex-wrap">
                    <span
                      v-for="interest in passport.user_interests"
                      :key="interest.id"
                      class="profile-user-interest"
                      >{{ interest.name }}</span
                    >
                  </div>
                </section>

                <section
                  v-if="
                    passport.first_generation ||
                    passport.pell_grant_recipient ||
                    passport.gender ||
                    passport.ethnicity ||
                    passport.differently_abled
                  "
                  class="profile-info-section"
                >
                  <h3>Background</h3>
                  <div class="profile-data">
                    <div v-if="passport.first_generation" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">First Generation:</span></div>
                      <p class="profile-data-element">{{ passport.first_generation }}</p>
                    </div>
                    <div v-if="passport.pell_grant_recipient" class="data-row-container">
                      <div class="data-label-container">
                        <span class="profile-data-label">Pell Grant Recipient:</span>
                      </div>
                      <p class="profile-data-element">{{ passport.pell_grant_recipient }}</p>
                    </div>
                    <div v-if="passport.gender" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">Gender:</span></div>
                      <p class="profile-data-element">{{ passport.gender }}</p>
                    </div>
                    <div v-if="passport.ethnicity" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">Race/Ethnicity:</span></div>
                      <p class="profile-data-element">{{ passport.ethnicity }}</p>
                    </div>
                    <div v-if="passport.differently_abled" class="data-row-container">
                      <div class="data-label-container"><span class="profile-data-label">Differently Abled:</span></div>
                      <p class="profile-data-element">{{ passport.differently_abled }}</p>
                    </div>
                  </div>
                </section>
              </div>

              <div class="profile-socials-container">
                <a
                  v-if="isMentor"
                  :href="`/meetings/book/${userProfileUrl}`"
                  class="da-components-button da-btn-solid enup-demo-button"
                  :style="`width: 100%; text-align:center; ${
                    !mentorHasMeetingSlots
                      ? 'margin-bottom: 12px;opacity: 0.5;pointer-events: none;cursor: default;'
                      : ''
                  }`"
                  aria-label="Go to book a meeting page"
                >
                  Book Meeting
                </a>
                <p v-if="isMentor && !mentorHasMeetingSlots" style="font-size: 14px">
                  Mentor doesn't have available meeting slots.
                </p>

                <iframe
                  v-if="profile.welcome_video"
                  :src="profile.welcome_video"
                  class="profile-video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>

                <div class="profile-socials-box">
                  <div class="profile-personal-info">
                    <div v-if="profile.mobile_number">
                      <i class="fas fa-phone-alt info-icon"></i> <span>{{ profile.mobile_number }}</span>
                    </div>
                    <div v-if="email">
                      <i class="fas fa-envelope info-icon"></i> <a :href="'mailto:' + email">{{ email }}</a>
                    </div>
                    <div v-if="profile.website">
                      <i class="fas fa-globe info-icon"></i>
                      <a :href="profile.website">{{ profile.websiteFormatted }}</a>
                    </div>
                  </div>
                  <div v-if="profile.social_networks" class="profile-social-networks">
                    <a
                      v-for="network in profile.social_networks"
                      :key="network.id"
                      :href="'https://' + network.url"
                      class="icon-link"
                      target="_blank"
                    >
                      <i :class="network.icon + ' icon-social'"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div v-else class="private_profile">
        This profile is private.
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import ProfileService from "../services/ProfileService";
import AccountsService from "../../accounts/services/AccountsService";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import MentorMeetingsAnalyticsComponent from "../../analytics/components/MentorMeetingsAnalyticsComponent.vue";

export default {
  name: "profile-details",

  props: [],

  components: {
    LoadingSpinner,
    MentorMeetingsAnalyticsComponent,
  },

  data() {
    return {
      service: new ProfileService(),
      accountsService: new AccountsService(),
      isLoading: true,
      isFavoriteMentor: false,
      hasAnalyticsPermission: true,
    };
  },
  computed: {},

  async created() {
    const profileSlug = this.$route.path.split("/").pop();
    await this.getPreData(profileSlug);
  },

  async mounted() {},

  methods: {
    async getPreData(profileSlug) {
      this.isLoading = true;
      const response = await this.service.getPreData(profileSlug);
      if (response) {
        this.isOwnProfile = response.is_own_profile;
        this.activeUserId = response.active_user_id;
        this.focusAreas = response.focus_areas;
        this.name = response.name;
        this.joinDate = response.join_date;
        this.email = response.email;
        this.activeTab = response.active_tab;
        this.userId = response.user_id;
        this.userProfileUrl = response.user_profile_url;
        this.isMentor = response.is_mentor;
        this.hasPassportAccess = response.has_passport_access;
        this.isFavoriteMentor = response.is_favorite_mentor;
        this.isProfileViewable = response.is_profile_viewable;
        this.mentorHasMeetingSlots = response.mentor_has_meeting_slots;
        this.hasAnalyticsPermission = response.has_analytics_permission;
        this.profile = response.profile;
        this.passport = response.passport;
      }
      this.isLoading = false;
    },

    async toggleFavoriteMentor() {
      if (this.isFavoriteMentor) {
        await this.accountsService.removeFavoriteMentor(this.userId);
        this.isFavoriteMentor = false;
      } else {
        await this.accountsService.addFavoriteMentor({ user_id: this.activeUserId, mentor_id: this.userId });
        this.isFavoriteMentor = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
