import Cookies from 'js-cookie';
import HttpService from '../../services/HttpService';

const headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class AccountsService extends HttpService {
  constructor() {
    super('/accounts/api/accounts');
  }

  getPreData(params): Promise<any> {
    this.endpoint = `/accounts/api/predata`;
    return this.get(params);
  }
  getPartnerPreData(params): Promise<any> {
    this.endpoint = `/accounts/api/predata/partners`;
    return this.get(params);
  }

  getUsers(params): Promise<any> {
    this.endpoint = `/accounts/api/accounts`;
    return this.get(params);
  }

  editUser(item:Object, id:String): Promise<any> {
    this.endpoint = `/accounts/api/details/${id}`;
    return this.put(item, headers, true);
  }

  sendResetPasswordEmail(item, headers): Promise<any> {
    this.endpoint = `/accounts/api/send_reset_password_mail`;
    return this.postFormData(item, headers);
  }

  setFirstLoginInitDataAndGetRecommendedDepts(item, headers): Promise<any> {
    this.endpoint = `/accounts/api/first_login_step`;
    return this.postFormData(item, headers);
  }

  setFirstLoginData(item, headers): Promise<any> {
    this.endpoint = `/accounts/api/first_login`;
    return this.postFormData(item, headers);
  }

  updateMyInterests(item, headers): Promise<any> {
    this.endpoint = `/profiles/api/settings/interests`;
    return this.postFormData(item, headers);
  }

  closeOnboarding(item, headers): Promise<any> {
    this.endpoint = `/accounts/api/first_login/closeOnboarding`;
    return this.postFormData(item, headers);
  }

  getFavoriteMentors(params): Promise<any>{
    this.endpoint = `/accounts/api/favorite_mentors`;
    return this.get({...params, timezone_offset: -new Date().getTimezoneOffset()});
  }

  addFavoriteMentor(data , _headers): Promise<any>{
    this.endpoint = `/accounts/api/favorite_mentors`;
    return this.postFormData(data, headers);
  }

  removeFavoriteMentor(mentor_id , _headers): Promise<any>{
    this.endpoint = `/accounts/api/favorite_mentors/${mentor_id}`;
    return this.delete({}, headers);
  }

  resetUser(params, headers) {
    this.endpoint = '/accounts/api/reset_user';

    return this.postFormData(params, headers);
  };

  getAudienceUsers(audience_id, params): Promise<any> {
    this.endpoint = `/accounts/api/audience/${audience_id}/user`;
    return this.get(params);
  };

  addAudienceUsers(data, headers): Promise<any> {
    this.endpoint = `/accounts/api/audience/${data.audience_id}/user`;
    let put_data = {};
    put_data["user_id"] = data.user_id? data.user_id: null;
    put_data["user_id_list"] = data.user_list? data.user_list: [];
    put_data["method"] = "REPLACE";

    return this.putFormData(put_data, headers);
  };

  rememberSideMenuExpandedChoice(sideMenuExpanded, headers): Promise<any> {
    this.endpoint = `/accounts/api/user/side-menu-expanded`;
    let put_data = {};

    put_data["side_menu_expanded"] = sideMenuExpanded;
    return this.putFormData(put_data, headers);
  };
}
