<template>
    <div class="private-chat-container">
        <div class="chat-top-section">
            <div class="user-info-section">
                <img :src="avatarOther" alt="other user's avatar">
                <p class="user-info-name">{{ usernameOther }}</p>
            </div>

            <!-- <div class="leftover-time-section-top">
                <span v-show="leftoverTime">{{ leftoverTime }} minutes left</span>
            </div> -->

            <div class="button-section">
                <button title="Start audio call" class="hidden-button" @click="openCallModal(false)"><i class="fas fa-phone"></i></button>
                <button title="Start a video call" class="hidden-button" @click="openCallModal(true)"><i class="fas fa-video"></i></button>
                <button title="See meeting info" class="hidden-button" @click="openMeetingModal"><i class="fas fa-circle-info"></i></button>

                <button class="end-meeting-button" @click="endMeeting">End Meeting</button>
            </div>
        </div>
        <div id="chat-message-container" class="chat-message-container">
            <div v-for="message in messageList" :key="message.messageId">
                <div class="main-message-container" :class="{ 'right' : localId === message._sender.userId }">
                    <div class="secondary-message-container">
                        <div class="small-container" :class="{ 'right' : localId === message._sender.userId }">
                            <img :src="message._sender.plainProfileUrl" alt="Avatar" class="avatar" :class="{ 'right' : localId === message._sender.userId }">
                            <div class="text-container">
                                <span class="top-text" :class="{ 'right' : localId === message._sender.userId }">{{ message.createdAt | unixFormat() | formatTimeUS() }}</span>
                                <div class="message-bubble" :class="{ 'right' : localId === message._sender.userId }">
                                    <p>{{ message.message }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="chat-bottom-section">
            <form @submit.prevent="sendMessage" class="send-message-form">
                <input type="text" placeholder="Write a message..." v-model="message" class="chat-input">
                <button type="submit" class="send-msg-button"><i class="fas fa-paper-plane send-msg-icon"></i></button>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import "../utils/TimeFormatUnix";
import "../utils/TimeFormatUS";

export default {
    name: "private-chat",

    props: ['localId', 'messageList', 'usernameOther', 'avatarOther', 'leftoverTime', ],

    data() {
        return {
            message: "",
        };
    },

    computed: {
        
    },

    methods: {
        sendMessage: function () {
            if (this.message === "")
                return

            this.$emit('send-message', this.message);

            this.message = "";
        },

        openCallModal: function(isVideo) {
            this.$emit('open-call-modal', isVideo);
        },

        endMeeting: function() {
            this.$emit('end-meeting');
        },

        openMeetingModal: function() {
            this.$emit('open-meeting-modal');
        },

        scrollPositionBottom: function () {
            const chatBox = document.getElementById('chat-message-container');
            const scrollHeight = chatBox.scrollHeight;

            chatBox.scrollTop = scrollHeight;
        },
    },
}
</script>
