import HttpService from '../../services/HttpService';

export default class GroupService extends HttpService {
  constructor() {
    super('/groups/api');
  }

  getPredataGroupsAndDepts() {
    this.endpoint = `/groups/api/predata`;
    return this.get();
  }

  getPredataGroup(group_name) {
    this.endpoint = `/groups/api/predata/${group_name}`;
    return this.get();
  }

  getAdminsAndMentors(name): Promise<any>{
    this.endpoint = `/groups/api/${name}/users`;
    return this.get();
  }

  getGroupPosts(params): Promise<any> {
    this.endpoint = `/feed/api/feed/group`;
    return this.get(params);
  }

  updateGroup(payload: Object, headers: Object): Promise<any> {
    this.endpoint = `/groups/api/${payload['id']}`;
    return this.put(payload, headers);
  }

  removeGroup(id, body, headers): Promise<any> {
    this.endpoint = this.endpoint = `/groups/api/${id}`;
    return this.delete(body, headers);
  }

  getAllGroups(params): Promise<any> {
    this.endpoint = '/groups/api/';
    return this.get(params);
  }

  getGroupFollowers(params): Promise<any> {
    this.endpoint = '/groups/api/followers';
    return this.get(params);
  }

  postGroupFollower(params, headers): Promise<any> {
    this.endpoint = '/groups/api/followers';
    return this.post(params, headers);
  }

  deleteGroupFollower(params, headers): Promise<any> {
    this.endpoint = '/groups/api/followers';
    return this.delete(params, headers);
  }

}
