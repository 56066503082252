<template>
  <div class="partner-landing-card">
    <div class="image-container">
      <img
        v-if="item.cover_pic"
        :src="item.cover_pic"
        @click="redirectToItem"
        @keyup.enter="redirectToItem"
        tabindex="0"
        aria-label="Go to partner profile page"
        :alt="item.name"
        class="image"
      />
      <img
        v-else
        :src="fallback_image"
        @click="redirectToItem"
        @keyup.enter="redirectToItem"
        tabindex="0"
        aria-label="Go to partner profile page"
        :alt="item.name"
        class="image"
      />
    </div>
    <div class="content-container">
      <div class="logo-container">
        <img
          v-if="item.logo_url"
          @click="redirectToItem"
          @keyup.enter="redirectToItem"
          tabindex="0"
          aria-label="Go to partner profile page"
          :src="item.logo_url"
          alt="Partner logo"
          class="logo-image"
        />
        <img
          v-else
          :src="fallback_image"
          @click="redirectToItem"
          @keyup.enter="redirectToItem"
          tabindex="0"
          aria-label="Go to partner profile page"
          alt="Partner logo"
          class="logo-image"
        />
      </div>
      <div class="actions-container">
        <div class="followers">{{ item.follower_count }} followers</div>
        <button
          class="follow-button"
          @click="handleFollow"
          :aria-label="item.is_following ? `Unfollow ${item.name}` : `Follow ${item.name}`"
        >
          {{ item.is_following ? 'Following' : 'Follow' }}
        </button>
      </div>
      <div class="text-container">
        <a
          class="partner-name text-clamp-1"
          @click="redirectToItem"
          @keyup.enter="redirectToItem"
          tabindex="0"
          aria-label="Go to partner profile page"
        >
          {{ item.name }}
        </a>
        <div v-if="item.description" class="partner-description text-clamp-3">
          {{ item.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'partner-card-component',
  props: ['item', 'fallback_image'],
  data() {
    return {};
  },
  async created() {},
  methods: {
    handleFollow() {
      this.$emit('toggle-follow');
    },
    redirectToItem() {
      this.$router.push({name: 'partner-details', params: { partner_name: encodeURIComponent(this.item.name) }});
    },
  },
};
</script>

<style lang="scss" scoped>
.partner-landing-card {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .image-container {
    height: 8.125rem;
    width: 100%;
    overflow: hidden;
    background-color: #000;
    flex-shrink: 0;

    .image {
      width: 100%;
      height: 8.125rem;
      object-fit: cover;
      object-position: center;
      opacity: 1;
      transition: all 0.3s;

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        opacity: 0.85;
      }
    }
  }

  .content-container {
    padding: 0.813rem 1.25rem 1.25rem;
    background-color: #f2f5f7;
    height: 100%;
    position: relative;

    .text-container {
      padding-top: 1.25rem;
      .partner-name {
        color: #444;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.25;
        margin-bottom: 0.5rem;

        &:hover {
          cursor: pointer;
          color: #666;
        }
      }

      .partner-description {
        color: #555;
        font-size: 1rem;
      }
    }
  }

  .actions-container {
    padding-left: 4.875rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .followers {
      color: #838ea1;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1;
    }

    .follow-button {
      background-color: transparent;
      border: none;
      color: #2d98d2;
      font-weight: 600;

      &:hover {
        cursor: pointer;
        color: #006ead;
      }
    }
  }

  .logo-container {
    background-color: #000;
    position: absolute;
    left: 1.25rem;
    top: -1.6rem;
    border: 3px solid #fff;
    border-radius: 88px;
    width: 4rem;
    height: 4rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.08);
  }

  .logo-image {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    object-position: center;
    opacity: 1;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      opacity: 0.85;
    }
  }
}

@media screen and (max-width: 576px) {
  .partner-landing-card {
    max-width: 17rem;
  }
}
</style>
