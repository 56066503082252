<template>
    <div id="app">
         <ejs-accumulationchart 
            class="container"
            :tooltip='tooltip'
            :legendSettings='legendSettings'
        >
            <e-accumulation-series-collection>
                <e-accumulation-series 
                    :dataSource='seriesData' 
                    :palettes="palettes"
                    xName='x' 
                    yName='y'
                    legendShape='Rectangle'
                    radius='70%'
                > </e-accumulation-series>
            </e-accumulation-series-collection>
        </ejs-accumulationchart>
    </div>
</template>
<script>
import Vue from "vue";
import { AccumulationChartPlugin, PieSeries, AccumulationLegend, AccumulationTooltip } from "@syncfusion/ej2-vue-charts";

Vue.use(AccumulationChartPlugin);

export default {
    name: "pie-chart",
    props: ['array', 'colors'],
    data() {
        return {
            seriesData: this.array.filter(e => e.x !== 'CUSTOM'),
            legendSettings:{ 
                    visible: true,  
                    height: '100', 
                    width:'150' 
                },
            tooltip:{enable: true},
            palettes: this.colors,
        };
    },
    provide: {
        accumulationchart: [PieSeries, AccumulationLegend, AccumulationTooltip ]
    }
};
</script>
<style  scoped>
 .container {
     height: 350px;
 }
</style>