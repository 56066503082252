
<template>
  <div id="app"></div>
</template>

<script>
// Global App Options
import Vue from "vue";

export default Vue.extend({
  name: "App",
});
</script>

