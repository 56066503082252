import Vue from "vue";
import moment from 'moment';

Vue.filter("parseLongDate", function (date: string) {
    return `${moment(date).format("dddd, MMMM Do YYYY")}`;
});

Vue.filter("parseDate", function (date: string) {
    return `${moment(date).format("MMMM Do, YYYY")}`;
});

Vue.filter("parseDateUTC", function (date: string) {
    return `${moment.utc(date).format("MMMM Do, YYYY")}`;
});

Vue.filter("parseShortDate", function (date: string) {
    return `${moment(date).format("MMM D")}`;
});

Vue.filter("parseMidDate", function (date: string) {
    return `${moment(date).format("MMM D, YYYY")}`;
});

Vue.filter("parseMonth", function (date: string) {
    return `${moment(date).format("MMM")}`;
});

Vue.filter("parseDay", function (date: string) {
    return `${moment(date).format("ddd, MMM Do")}`;
});

Vue.filter("parseTime", function (date: any) {
    return  `${moment(date.startTime).format("LT")} - ${moment(date.endTime).format("LT")}`;
});

Vue.filter("parseOneTime", function (time: any) {
    return moment(time).format("LT");
});

export const parseLongDate = (date: string) =>  `${moment(date).format("dddd, MMMM Do YYYY")}`;

export const parseTime = (date: any) => `${moment(date.startTime).format("LT")} - ${moment(date.endTime).format("LT")}`;
