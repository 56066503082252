import { defineStore } from 'pinia';
import router from '../router';

type User = {
  firstName: string,
  lastName: string,
  email: string,
  permissions: string[]
}

type Department = {
  name: string
}

type AuthStore = {
  user: User | null,
  sessionId: number | null,
  returnUrl: string | null,
  isAdmin: boolean,
  isPartnerAdmin: boolean,
  isPartnerManager: boolean,
  isDepartmentLead: boolean,
  userDepartment: Department | false,
  additionalFields: any
}

export const PERMISSION_TYPES = {
  READ: 0,
  READ_WRITE: 1,
  MANAGE: 2,
  MANAGE_WITH_GRANT: 3
}

export const FEATURES = {
  COURSES: 'courses',
  RESOURCE_LIBRARY: 'resource_library',
  BULK_UPLOAD: 'bulk_upload',
  EVENTS: 'events',
  MENTOR_CONNECT: 'appointments',
  ACTIVITY_FEED: 'activity_feed',

}


export const useAuthStore = defineStore({
  id: 'auth',
  state: (): AuthStore => ({
    //some basic authentication store architecture
    //has to be extended with session id (which can be added
    //alongside user or instead of user)
    user: JSON.parse(localStorage.getItem('user')),
    sessionId: null,
    returnUrl: null,
    isAdmin: false,
    isPartnerAdmin: false,
    isPartnerManager: false,
    isDepartmentLead: false,
    userDepartment: false,
    additionalFields: null
    //This is just an example, discuss more about it and see if there's
    //a better way to handle managing permissions
    // isSuperuser: false,
    // isDepartmentLead: false,
    //maybe to user role????
    //role: {permissions}
  }),
  getters: {
    getUser: (state) => state.user,
    getSessionId: (state) => state.sessionId,
    getReturnUrl : (state) => state.returnUrl,
    getIsAdmin : (state) => state.isAdmin,
    getIsPartnerAdmin : (state) => state.isPartnerAdmin,
    getIsPartnerManager: (state) => state.isPartnerManager,
    getIsDepartmentLead : (state) => state.isDepartmentLead,
    getUserDepartment : (state) => state.userDepartment,
    getAdditionalFields: (state) => state.additionalFields
  },
  actions: {
    loadUserData() {
      //This logic should be updated once we completely stop using the templates
      //the login function should be used instead. There the login api will be called
      //and it will return all of the required data
      const userStatusDataComponent: HTMLElement = document.querySelector('.js-status-data');
      const additionalFieldsData: HTMLElement = document.querySelector('.js-additional-fields');
      const loggedUser = JSON.parse(userStatusDataComponent.dataset.userStatus);
      const isAdmin = loggedUser.user.role === 'ADMIN';
      const isPartnerAdmin = loggedUser.user.partner_role === 'Partner Admin';
      const isPartnerManager = loggedUser.user.partner_role === 'Partner Manager';
      const isDeptLead = loggedUser.user.role === 'DEPARTMENT_LEAD' && loggedUser.user.dept;
      const userDepartment = loggedUser.user.dept || false;
      const additionalFields = JSON.parse(additionalFieldsData.dataset.additionalFields);

      this.user = loggedUser;
      this.isAdmin = isAdmin;
      this.isPartnerAdmin = isPartnerAdmin;
      this.isPartnerManager = isPartnerManager;
      this.isDepartmentLead = isDeptLead;
      this.userDepartment = userDepartment;
      this.additionalFields = additionalFields;
    },
    async login(username, password) {
      const user = { firstName: 'FirstName', lastName: 'LastName', email: 'example@email.com' };
      this.user = user;
      localStorage.setItem('user', JSON.stringify(user));

      router.push(this.returnUrl || '/');
    },
    logout() {
      this.user = null;
      localStorage.removeItem('user');
      router.push('/login');
    }
  },
});
