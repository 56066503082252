<template>
  <ul class="eu-course-collection grid">
    <li v-for="item in apps" :key="item.id" class="eu-course-collection-card grid">
      <a :aria-label="`Go to ${item.name}`" :href="`${item.url}`" target="_blank">
        <img :src="item.logo_url" alt="Campus App Logo" class="eu-course-collection-card-img grid" />
      </a>
      <div class="eu-course-collection-body">
        <a :aria-label="`Go to ${item.name}`" target="_blank" :href="`${item.url}`">
          <h5 class="eu-course-collection-title grid">{{ item.name }}<i class="far fa-external-link ca-link-icon"></i></h5>
        </a>
        <div class="eu-course-collection-details grid">
          <p>{{ item.description }}</p>
        </div>
        <div
          tabindex="0"
          @click="removeBookmark(item.id)"
          @keyup.enter="removeBookmark(item.id)"
          class="eu-course-collection-bookmark grid"
          role="button"
          :aria-label="`Remove ${item.name} from saved apps`"
        >
          <i class="fad fa-bookmark eu-course-collection-bookmark-icon" style="color: #4c55a7"></i>
          <span style="color: #4c55a7">Saved</span>
        </div>
      </div>
    </li>
  </ul>
</template>
<script lang="ts">
export default {
  name: "browse-saved-campus-apps",

  props: ["apps"],

  data() {
    return {};
  },

  methods: {
    removeBookmark(id: number) {
      this.$emit("remove-bookmark", id);
    },
  },
};
</script>
