import Vue from "vue";

Vue.filter("formatTimeUS", function (timestamp: string) {

    const hoursStr = timestamp.substring(0, 2);
    const minutesStr = timestamp.substring(3, 5);
    const hoursInt = parseInt(hoursStr);

    let period = "";
    let hoursUS: number

    if (hoursInt > 12) {
        hoursUS = hoursInt - 12;
        period = "p.m.";
    } else if (hoursInt === 12) {
        hoursUS = hoursInt;
        period = "p.m";
    } else {
        hoursUS = hoursInt;
        period = "a.m."
    }

    if (hoursStr === "00") {
        hoursUS = 12
    }

    return `${hoursUS}:${minutesStr} ${period}`;
});