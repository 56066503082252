import HttpService from './HttpService';

export default class DIVAppService extends HttpService {
    constructor(url:string) {
        super(url);
    }

    setEndPoint(url:string) {
        this.endpoint = url;
    }
}