<template>
  <div
    class="partner-tenant-featured-programs-card-wrapper full-w-section-based-on-sidebar"
    v-if="featuredProgramsCount"
  >
    <div class="partner-tenant-featured-programs-card">
      <div class="partner-tenant-featured-programs-content-wrapper-constrained">
        <div class="featured-label-container">
          <div class="featured-label">Featured Opportunities</div>
          <div class="dots-container">
            <slider-dots
              :totalNo="featuredProgramsCount"
              :initialSelectedNo="featuredProgramSelectedId + 1"
              :switchSeconds="7"
              @changeSelected="changeSelectedProgramSliderHandler"
            />
          </div>
        </div>

        <div class="featured-programs-content-mobile">
          <div class="image-container">
            <button @click="previousOpportunity()" class="arrow-button arrow-button-left">
              <i class="fa-solid fa-arrow-left"></i>
            </button>

            <div class="image-wrapper">
              <router-link
                :to="`/${featuredProgramCardActive.slug}`"
                :aria-label="`Visit ${featuredProgramCardActive.title} page`"
                class="image-wrapper__link"
              ></router-link>
              <img class="image" :src="featuredProgramCardActive.thumbnail" :alt="featuredProgramCardActive.title" />
            </div>

            <button @click="nextOpportunity()" class="arrow-button arrow-button-right">
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
          <div class="content">
            <div>
              <div class="title-wrapper">
                <div class="title">
                  <router-link :to="`/${featuredProgramCardActive.slug}`">
                    {{ featuredProgramCardActive.title }}
                  </router-link>
                </div>
              </div>
              <div class="text-wrapper">
                <div class="text">
                  {{ featuredProgramCardActive.description }}
                </div>
              </div>
            </div>
            <div>
              <div class="location">
                <i v-if="featuredProgramCardActive.is_online" class="fa-solid fa-desktop location__icon"></i>
                <i v-else class="fa-solid fa-location-dot location__icon"></i>
                {{ featuredProgramCardActive.location }}
              </div>
              <div class="action-buttons-container">
                <router-link
                  class="dabi-main-button-outlined"
                  :to="`/${featuredProgramCardActive.slug}`"
                  :aria-label="`Visit ${featuredProgramCardActive.title} page`"
                >
                  View Details
                  <i class="fa-regular fa-chevron-right"></i>
                </router-link>
                <button
                  class="dabi-bookmark-button"
                  :class="featuredProgramCardActive.is_bookmarked ? 'active' : ''"
                  @click="toggleBookmark(featuredProgramCardActive.id)"
                  :aria-label="`${featuredProgramCardActive.is_bookmarked ? 'Remove' : 'Add'} bookmark`"
                >
                  <i v-if="featuredProgramCardActive.is_bookmarked" class="fa-solid fa-bookmark"></i>
                  <i v-else class="fa-regular fa-bookmark"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="featured-programs-content-desktop">
          <partner-event-card-v2
            v-for="(item, index) in featuredProgramsList.slice(0, 3)"
            :key="index"
            :item="item"
            :isFeatured="item.is_dashboard_featured"
            @toggle-bookmark="toggleBookmark(item.id)"
          ></partner-event-card-v2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PartnerDashboardService from '../../services/PartnerDashboardService';
import BookmarkService from '../../../bookmarks/services/BookmarkService';
import * as Cookies from 'js-cookie';
import SliderDots from '../../../partners/components/landing_pages/components/SliderDots.vue';
import fullWSectionBasedOnSidebarMixin from '../../../mixins/fullWSectionBasedOnSidebarMixin';
import PartnerEventCardV2 from '../../../events/components/cards/PartnerEventCardV2.vue';

export default {
  name: 'partner-tenant-featured-programs',
  mixins: [fullWSectionBasedOnSidebarMixin],
  components: {
    PartnerEventCardV2,
    SliderDots,
  },
  props: [],
  created() {
    this.partnerDashboardService.getFeaturedPrograms().then((data) => {
      this.featuredProgramsList = data;
      this.emitIfEmpty();

      this.$nextTick(() => {
        this.invalidateFullWSections();
      });
    });
  },
  computed: {
    featuredProgramsCount() {
      return this.featuredProgramsList ? this.featuredProgramsList.length : 0;
    },
    featuredProgramsIdsArray() {
      return this.featuredProgramsList ? [...Array(this.featuredProgramsCount).keys()] : [];
    },
    featuredProgramCardActive() {
      return this.featuredProgramsList ? this.featuredProgramsList[this.featuredProgramSelectedId] : {};
    },
  },
  data() {
    return {
      featuredProgramsList: [],
      featuredProgramSelectedId: 0,
      switchSeconds: 10,
      intervalFunctionId: null,
      partnerDashboardService: new PartnerDashboardService(),
      eventBookmarkService: new BookmarkService(),
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
    };
  },
  methods: {
    emitIfEmpty() {
      if (!this.featuredProgramsList || this.featuredProgramsList.length === 0) {
        this.$emit('is-empty');
      }
    },
    changeSelectedProgramSliderHandler(sliderDotIndex) {
      this.changeSelectedProgram(sliderDotIndex - 1);
    },
    changeSelectedProgram(index) {
      this.featuredProgramSelectedId = index;
    },
    selectNextProgramId() {
      if (this.featuredProgramSelectedId + 1 == this.featuredProgramsCount) {
        this.featuredProgramSelectedId = 0;
      } else {
        this.featuredProgramSelectedId = this.featuredProgramSelectedId + 1;
      }
    },
    isFeaturedProgramSelected(index) {
      return this.featuredProgramSelectedId == index;
    },
    toggleBookmark() {
      if (this.featuredProgramCardActive.is_bookmarked) {
        const payload = {
          targets: [this.featuredProgramCardActive.id],
        };
        this.eventBookmarkService.deleteBookmarkedEvents(payload, this.headers);
        this.featuredProgramsList[this.featuredProgramSelectedId].is_bookmarked = false;
      } else {
        const payload = {
          event_id: this.featuredProgramCardActive.id,
        };
        this.eventBookmarkService.postBookmarkEvent(payload, this.headers);
        this.featuredProgramsList[this.featuredProgramSelectedId].is_bookmarked = true;
      }
    },
    nextOpportunity() {
      this.featuredProgramSelectedId = (this.featuredProgramSelectedId + 1) % this.featuredProgramsCount;
    },
    previousOpportunity() {
      this.featuredProgramSelectedId =
        (this.featuredProgramSelectedId - 1 + this.featuredProgramsCount) % this.featuredProgramsCount;
    },
  },
};
</script>
