import { defineStore } from 'pinia';

type NotificationState = {
  text: string,
  isError: boolean,
  timeoutId: number | null;
}

export const useNotificationStore = defineStore({
  id: 'notification',
  state: (): NotificationState => ({
    text: '',
    isError: false,
    timeoutId: null
  }),
  getters: {
    notification: (state) => state.text
  },
  actions: {
    addNotification(text, time=null, isError=false) {
      this.text = text;
      this.isError = isError;
      if (time) {
        this.timeoutId = setTimeout(() => {
          this.closeNotification();
        }, time);
      }
    },
    closeNotification() {
      this.text = '';
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
})
