<template>
  <section>
    <section class="da-student-dashboard-content">
      <section class="dashboard-cards-wrapper">
        <dashboard-alerts />
        <dashboard-hero-carousel
          v-if="featuredCoursesEvents.length > 0"
          :currentlyFeatured="currentlyFeatured"
          :featuredCoursesEvents="featuredCoursesEvents"
          :isLoading="isLoading"
          @set-featured-course-or-event="setFeaturedCourseOrEvent"
        />
        <dashboard-customize-profile />

        <dashboard-card
          @set-page="setPage"
          @set-section="setSection"
          title="Recommended For You"
          faIcon="fa-stars"
          :pageIndex="recommendationIndex"
          :section="currentSection"
          :showOptions="true"
          :pagesLen="currentPageLen"
          :availableFeatures="availableFeatures"
          :cardFeatures="RECOMMENDED_SECTION_ITEMS"
        >
          <section v-if="currentSection == SECTIONS.events">
            <div
              v-if="!isLoading.recommendedEvents && parseEventsOnly(eventsData.recommendedEvents).length > 0"
              class="main-content"
            >
              <div
                v-for="(event, i) in createPages(parseEventsOnly(eventsData.recommendedEvents, itemLimit))[
                  recommendationIndex
                ]"
                :key="i"
              >
                <event-card :event="event" />
              </div>
            </div>
            <div v-else-if="!isLoading.recommendedEvents && parseEventsOnly(eventsData.recommendedEvents).length == 0">
              <no-recommendations :redirectUrl="SEE_ALL_URLS[currentSection]" feature="opportunities" />
            </div>
            <div v-else style="height: 272px">
              <loading-spinner wrapperClass="absolute-center" />
            </div>
          </section>
          <section v-if="currentSection == SECTIONS.experiences">
            <div
              v-if="!isLoading.recommendedEvents && parseProgramsOnly(eventsData.recommendedEvents).length > 0"
              class="main-content"
            >
              <div
                v-for="(program, i) in createPages(parseProgramsOnly(eventsData.recommendedEvents, itemLimit))[
                  recommendationIndex
                ]"
                :key="i"
              >
                <event-card :event="program" />
              </div>
            </div>
            <div
              v-else-if="!isLoading.recommendedEvents && parseProgramsOnly(eventsData.recommendedEvents).length == 0"
            >
              <no-recommendations :redirectUrl="SEE_ALL_URLS[currentSection]" feature="opportunities" />
            </div>
            <div v-else style="height: 272px">
              <loading-spinner wrapperClass="absolute-center" />
            </div>
          </section>
          <section v-if="currentSection == SECTIONS.courses">
            <div v-if="!isLoading.recommendedCourses && coursesData.recommendedCourses.length > 0" class="main-content">
              <div
                v-for="(c, i) in createPages(coursesData.recommendedCourses, itemLimit)[recommendationIndex]"
                :key="i"
              >
                <course-card :course="c" />
              </div>
            </div>
            <div v-else-if="!isLoading.recommendedCourses && coursesData.recommendedCourses.length == 0">
              <no-recommendations :redirectUrl="SEE_ALL_URLS[currentSection]" feature="courses" />
            </div>
            <div v-else style="height: 272px">
              <loading-spinner wrapperClass="absolute-center" />
            </div>
          </section>
          <section v-if="currentSection == SECTIONS.resources">
            <div
              v-if="!isLoading.recommendedResources && resourcesData.recommendedResources.length > 0"
              class="main-content"
            >
              <div
                v-for="(r, i) in createPages(resourcesData.recommendedResources, itemLimit)[recommendationIndex]"
                :key="i"
              >
                <resource-card :item="r" />
              </div>
            </div>
            <div v-else-if="!isLoading.recommendedResources && resourcesData.recommendedResources.length == 0">
              <no-recommendations :redirectUrl="SEE_ALL_URLS[currentSection]" feature="resources" />
            </div>
            <div v-else style="height: 272px">
              <loading-spinner wrapperClass="absolute-center" />
            </div>
          </section>
          <section v-if="currentSection == SECTIONS.mentors">
            <div
              v-if="!isLoading.recommendedMentors && meetingsData.recommendedMentors.length > 0"
              class="main-content"
            >
              <div
                v-for="(u, i) in createPages(meetingsData.recommendedMentors, itemLimit)[recommendationIndex]"
                :key="i"
              >
                <mentor-card :user="u" :userId="props.userId" />
              </div>
            </div>
            <div v-else-if="!isLoading.recommendedMentors && meetingsData.recommendedMentors.length == 0">
              <no-recommendations :redirectUrl="SEE_ALL_URLS[currentSection]" feature="mentors" />
            </div>
            <div v-else style="height: 272px">
              <loading-spinner wrapperClass="absolute-center" />
            </div>
          </section>
        </dashboard-card>

        <dashboard-card
          v-if="availableFeatures.quests"
          title="Quests"
          faIcon="fa-flag-swallowtail"
          iconColor="#a054c3"
          section="quests"
          contentWrapperClass="quests-content-wrapper"
          @set-page="changeQuestPage"
          :showOptions="false"
          :pageIndex="activeQuestsPageIndex"
          :pagesLen="questsPageLen"
          :cardFeatures="['quests']"
        >
          <div v-if="questsCount" class="quests-overall-container">
            <div class="quests-overall-item-container">
              <span class="title">Available Quests</span>
              <span class="number">{{ questsCount.available_quests_count }}</span>
              <span class="bottom-line green"></span>
            </div>
            <div class="quests-overall-item-container">
              <span class="title">Active Quests</span>
              <span class="number">{{ questsCount.active_quests_count }}</span>
              <span class="bottom-line purple"></span>
            </div>
          </div>
          <div class="quests-cards-container" v-if="!isQuestLoading && activeQuestList.length > 0">
            <dashboard-quest-card
              v-for="(quest, index) in createPages(activeQuestList, questItemLimit, 'quests')[activeQuestsPageIndex]"
              :key="index"
              :quest="quest"
              @show-notification="showNotification"
              @reload="getQuests"
            />
          </div>
          <div class="quests-loading-or-empty-container" v-else-if="isQuestLoading">
            <loading-spinner />
          </div>
          <div class="quests-loading-or-empty-container text" v-else-if="activeQuestList.length === 0">
            There are no quests yet. Please check again later.
          </div>
        </dashboard-card>

        <dashboard-card
          v-if="availableFeatures.groups || availableFeatures.depts"
          @set-page="setDeptsPage"
          @set-section="setDeptSection"
          :title="
            availableFeatures.groups && availableFeatures.depts
              ? `Groups &amp; ${deptPlural}`
              : availableFeatures.groups
              ? 'Groups'
              : `${deptPlural}`
          "
          seeAllUrl="/groups"
          faIcon="fa-stars"
          :section="currentDeptSection"
          :pagesLen="deptsPageLen"
          :pageIndex="deptIndex"
          :showOptions="true"
          :cardFeatures="DEPARTMENTS_SECTION_ITEMS"
          :availableFeatures="availableFeatures"
        >
          <section v-if="currentDeptSection == SECTIONS.departments">
            <div v-if="!isLoading.departments && departments.length > 0" class="main-content small">
              <div v-for="(d, i) in createPages(departments, itemLimit, 'depts')[deptIndex]" :key="i">
                <dept-card :dept="d" />
              </div>
            </div>
            <div v-else-if="!isLoading.departments && departments.length == 0">
              <no-recommendations redirectUrl="/groups" feature="departments" />
            </div>
            <div v-else style="height: 272px">
              <loading-spinner wrapperClass="absolute-center" />
            </div>
          </section>
          <section v-else-if="currentDeptSection == SECTIONS.groups">
            <div v-if="!isLoading.recommendedGroups && groupsData.recommendedGroups.length > 0" class="main-content">
              <div v-for="(g, i) in createPages(groupsData.recommendedGroups, itemLimit, 'groups')[deptIndex]" :key="i">
                <group-card @toggle-group-follow="toggleGroupFollow" :userId="props.userId" :group="g" />
              </div>
            </div>
            <div v-else-if="!isLoading.recommendedGroups && groupsData.recommendedGroups.length == 0">
              <no-recommendations :redirectUrl="SEE_ALL_URLS[currentDeptSection]" feature="groups" />
            </div>
            <div v-else style="height: 272px">
              <loading-spinner wrapperClass="absolute-center" />
            </div>
          </section>
        </dashboard-card>
      </section>
    </section>

    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>
<script lang="ts">
import * as Cookies from 'js-cookie';
import Notification from '../../components/Notification.vue';
import DashboardService from '../services/DashboardService';
import DashboardHeroCarousel from './DashboardHeroCarousel.vue';
import DashboardAlerts from './DashboardAlerts.vue';
import DashboardCustomizeProfile from './DashboardCustomizeProfile.vue';
import EventCard from '../../events/components/EventCard.vue';
import DashboardCard from './DashboardCard.vue';
import ResourceCard from '../../library/components/ResourceCard.vue';
import MentorCard from '../../accounts/components/MentorCard.vue';
import GroupCard from '../../groups/components/GroupCard.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import NoRecommendations from './NoRecommendations.vue';
import CourseCard from '../../courses/components/CourseCard.vue';
import DeptCard from '../../groups/components/DeptCard.vue';
import DashboardQuestCard from './DashboardQuestCard.vue';
import QuestService from '../../quests/services/QuestService';
import { useCommonDataStore } from '../../stores/commonData';

export const SECTIONS = {
  events: 'events',
  experiences: 'experiences',
  resources: 'resources',
  mentors: 'mentors',
  groups: 'groups',
  quests: 'quests',
  questCourses: 'questCourses',
  departments: 'departments',
  courses: 'courses',
};

export const RECOMMENDED_SECTION_ITEMS = ['events', 'experiences', 'courses', 'mentors', 'resources'];
export const DEPARTMENTS_SECTION_ITEMS = ['groups', 'departments'];

export const SEE_ALL_URLS = {
  events: '/opportunities',
  experiences: '/opportunities',
  resources: '/library',
  mentors: '/meetings',
  groups: '/groups',
  quests: '/quests',
  questCourses: '/courses',
  courses: '/courses',
};

export default {
  components: {
    Notification,
    DashboardCard,
    DashboardHeroCarousel,
    DashboardAlerts,
    DashboardCustomizeProfile,
    EventCard,
    ResourceCard,
    MentorCard,
    GroupCard,
    LoadingSpinner,
    NoRecommendations,
    CourseCard,
    DeptCard,
    DashboardQuestCard,
  },
  name: 'dashboard-cards',
  props: ['props'],
  data() {
    return {
      currentlyFeatured: null,
      currentIndex: 0,
      recommendationIndex: 0,
      windowWidth: 0,
      coursesIndex: 0,
      deptIndex: 0,
      eventsData: { eventsUpcoming: [], recommendedEvents: [], featuredEventsPrograms: [] },
      meetingsData: { mentorMeetings: [], recommendedMentors: [], userId: this.props.userId },
      coursesData: { enrolledCourses: [], featuredCourses: [], recommendedCourses: [] },
      resourcesData: { savedResources: [], recommendedResources: [] },
      groupsData: { recommendedGroups: [], userId: this.props.userId },
      departments: [],
      recommendedPage: [],
      coursesPage: [],
      deptPage: [],
      isLoading: {
        eventsUpcoming: true,
        recommendedEvents: true,
        featuredEventsPrograms: true,
        mentorMeetings: true,
        recommendedMentors: true,
        enrolledCourses: true,
        featuredCourses: true,
        recommendedCourses: true,
        savedResources: true,
        recommendedResources: true,
        recommendedGroups: true,
        featuredCoursesEvents: true,
        departments: true,
      },
      SECTIONS,
      SEE_ALL_URLS,
      DEPARTMENTS_SECTION_ITEMS,
      RECOMMENDED_SECTION_ITEMS,
      currentPageLen: null,
      coursesPageLen: null,
      deptsPageLen: null,
      currentSection: SECTIONS.courses,
      currentDeptSection: SECTIONS.groups,
      featuredCoursesEvents: [],
      isNotificationActive: false,
      service: new DashboardService(),
      questService: new QuestService(),
      notification: { msg: '', isError: false, icon: null },
      questsCount: null,
      availableFeatures: [],
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
      isQuestLoading: false,
      activeQuestsPageIndex: 0,
      questsPageLen: 0,
      activeQuestList: [],
    };
  },
  computed: {
    itemLimit() {
      return this.windowWidth > 1166 ? 4 : 8;
    },
    questItemLimit() {
      return this.windowWidth > 777 ? 4 : 8;
    },
    deptPlural() {
      return useCommonDataStore().getFeatureName('department_plural');
    },
  },
  async created() {
    this.availableFeatures = this.getAvailableFeatures();
    this.currentDeptSection = this.availableFeatures.groups ? SECTIONS.groups : SECTIONS.departments;
    this.getQuests();
    this.getDashboardData('eventsData', 'recommendedEvents');
    this.getDashboardData('meetingsData', 'recommendedMentors');
    this.getDashboardData('coursesData', 'recommendedCourses');
    this.getDashboardData('resourcesData', 'recommendedResources');
    this.getDashboardData('groupsData', 'recommendedGroups');
    this.getDepartments();
    this.getFeaturedData();
    this.getWindowWidth();
    window.addEventListener('resize', this.getWindowWidth);

    let self = this;
    this.timer = setInterval(function () {
      if (self.featuredCoursesEvents.length > 0) {
        if (self.currentIndex === self.featuredCoursesEvents.length - 1) {
          self.currentIndex = 0;
        } else {
          self.currentIndex++;
        }
        self.setFeaturedCourseOrEvent(self.featuredCoursesEvents[self.currentIndex], self.currentIndex);
      }
    }, 4000);
  },

  methods: {
    setDeptSection(section) {
      this.currentDeptSection = section;
      this.deptIndex = 0;
    },
    async getQuests() {
      this.isQuestLoading = true;
      const res = await this.questService.getRecommended({ limit: 8 });
      if (!('Error' in res)) {
        this.activeQuestList = res;
        this.activeQuestList = this.activeQuestList.sort((a, b) => b.is_required - a.is_required);
      }
      this.isQuestLoading = false;
      const countRes = await this.questService.getQuestsCount();
      if (countRes.available_quests_count) {
        this.questsCount = countRes;
      }
    },
    changeQuestPage(index) {
      this.activeQuestsPageIndex = index;
    },
    async getAvailableFeatures() {
      const filtered_permissions = await this.service.getAvailableFeaturesFromLicense();
      this.availableFeatures = filtered_permissions.reduce((acc, item) => {
        acc[item.value] = item.isActive;
        return acc;
      }, {});
    },
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    parseEventsOnly(list) {
      return list?.filter((e) => e.type_of_program_management === 'event').slice(0, 8);
    },
    parseProgramsOnly(list) {
      return list?.filter((e) => e.type_of_program_management === 'program').slice(0, 8);
    },
    createPages(list, size = 4, feature) {
      let result = [];
      if (list) {
        for (let i = 0; i < list.length; i += size) {
          let chunk = list.slice(i, i + size);
          result.push(chunk);
        }

        if (feature === 'courses') {
          this.coursesPageLen = result.length;
        } else if (feature === 'depts') {
          this.deptsPageLen = result.length;
        } else if (feature === 'quests') {
          this.questsPageLen = result.length;
        } else if (feature === 'groups') {
          this.deptsPageLen = result.length;
        } else {
          this.currentPageLen = result.length;
        }

        return result;
      }
      return [];
    },
    async toggleGroupFollow() {
      await this.getDashboardData('groupsData', 'recommendedGroups');
    },
    async getFeaturedData() {
      await this.getDashboardData('coursesData', 'featuredCourses');
      await this.getDashboardData('eventsData', 'featuredEventsPrograms');
      if (this.isLoading.featuredCoursesEvents) {
        const featured = [];
        this.coursesData.featuredCourses &&
          this.coursesData.featuredCourses.forEach((e) => {
            featured.push({
              url: `/courses/${e.id}`,
              name: e.name,
              description: e.description,
              img: e.course_card_image_url,
              type: 'course',
            });
          });
        this.eventsData.featuredEventsPrograms &&
          this.eventsData.featuredEventsPrograms.forEach((e) => {
            featured.push({
              url: `/${e.slug}`,
              name: e.title,
              description: e.description,
              img: e.thumbnail,
              type: e.type_of_program_management,
            });
          });
        this.isLoading.featuredCoursesEvents = false;
        this.featuredCoursesEvents = featured;
      }
    },
    async getDepartments() {
      this.departments = await this.service.getDashboardData({ section: 'followedDepts' });
      this.isLoading.departments = false;
    },
    async getDashboardData(section, param) {
      this.isLoading[param] = true;
      this[section][param] = await this.service.getDashboardData({ section: param });
      if (this[section][param] && this[section][param].length > 0) {
        if (param === 'featuredCourses') {
          const featuredCourse = this[section][param][0];
          if (featuredCourse) {
            this.currentlyFeatured = {
              url: `/courses/${featuredCourse.id}`,
              name: featuredCourse.name,
              description: featuredCourse.description,
              img: featuredCourse.course_card_image_url,
              type: 'course',
            };
          }
        }
        if (param === 'featuredEventsPrograms' && !this.currentlyFeatured) {
          const e = this[section][param][0];
          if (e) {
            this.currentlyFeatured = {
              url: `/${e.slug}`,
              name: e.title,
              description: e.description,
              img: e.thumbnail,
              type: e.type_of_program_management,
            };
          }
        }
      }
      this.isLoading[param] = false;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    setPage(index) {
      this.recommendationIndex = index;
    },
    setCoursesPage(index) {
      this.coursesIndex = index;
    },
    setDeptsPage(index) {
      this.deptIndex = index;
    },
    setSection(section) {
      this.currentSection = section;
      this.recommendationIndex = 0;
    },
    setFeaturedCourseOrEvent(courseOrEvent: object, index: number) {
      let oldDot = document.getElementsByClassName('eu-featured-event-carousel-dot-selected')[0];
      oldDot.classList.remove('eu-featured-event-carousel-dot-selected');
      let selectedDot = document.getElementsByClassName('carousel-dot-' + index)[0];
      selectedDot.classList.add('eu-featured-event-carousel-dot-selected');
      this.currentIndex = index;
      this.currentlyFeatured = courseOrEvent;
    },
    toggleEventBookmark(id, listName) {
      this.eventsData[listName].forEach((e) => {
        if (e.id === id) {
          e.is_bookmarked = !e.is_bookmarked;
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
};
</script>
