import { render, staticRenderFns } from "./QuestDetails.vue?vue&type=template&id=5e785d35&scoped=true"
import script from "./QuestDetails.vue?vue&type=script&lang=js"
export * from "./QuestDetails.vue?vue&type=script&lang=js"
import style0 from "./QuestDetails.vue?vue&type=style&index=0&id=5e785d35&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e785d35",
  null
  
)

export default component.exports