import { render, staticRenderFns } from "./PartnerDetails.vue?vue&type=template&id=bcbd25e0&scoped=true"
import script from "./PartnerDetails.vue?vue&type=script&lang=ts"
export * from "./PartnerDetails.vue?vue&type=script&lang=ts"
import style0 from "./PartnerDetails.vue?vue&type=style&index=0&id=bcbd25e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcbd25e0",
  null
  
)

export default component.exports