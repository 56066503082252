<template>
    <div id="my_dataviz" style="position: relative"> </div>
</template>
<script>
import * as d3 from "d3";


export default {

    name: "circular-packing-bubble",
    components:{},
    props:[
        'items',
        'items_domain'
    ],
    data() {
        return{}
    },
    created(){
        
    },
    mounted: async function() {
        var width =  400
        var height = 150

        // append the svg object to the body of the page
        var svg = 
        d3.select("#my_dataviz")
        .append("div")
        // Container class to make it responsive.
        .classed("svg-container", true) 
        .append("svg")
        // Responsive SVG needs these 2 attributes and no width and height attr.
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", "0 0 400 150")
        // Class to make it responsive.
        .classed("svg-content-responsive", true)
        // Fill with a rectangle for visualization.

        var xScale = d3.scaleLinear().domain([0, 1]).range([6,15]);

        var color = d3.scaleOrdinal()
            .domain(this.items_domain)
            .range(["#6DF0D2", "#1BD4D4", "#00A1B3", "#007D99", "#5E50B5", "#9950CD", "#D35FA5", "#FF997B", "#FAC484", "#FAE994"])

        var size = this.get_size()

        var Tooltip = this.get_tooltip();

            function mouseover(e, data) {
                Tooltip
                .style("opacity", 1)
            }
            function mousemove (e, data) {
                Tooltip
                .html('<u>' + data.key + '</u>' + "<br>" + data.value)
                .style("left", (e.offsetX+20) + "px")
                .style("top", (data.y) + "px")
            }
            function mouseleave (e, data) {
            Tooltip
                .style("opacity", 0)
            }
            var node = svg.append("g")
                .classed("g-container", true)
                .selectAll("circle")
                .data(this.items)
                .enter()
                .append("circle")
                .attr("class", "node")
                .attr("r", function(d){ return size(d.value)})
                .attr("cx", width / 2)
                .attr("cy", height / 2)
                .style("fill", function(d){ return color(d.domain)})
                .style("fill-opacity", 0.8)
                .on("mouseover", mouseover) // What to do when hovered
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)

            var simulation = d3.forceSimulation()
                .force("center", d3.forceCenter().x(width/2).y(height/2)) // Attraction to the center of the svg area
                .force("charge", d3.forceManyBody().strength(.1)) // Nodes are attracted one each other of value is > 0
                .force("collide", d3.forceCollide().strength(.2).radius(function(d){ return (size(d.value)+0.5) }).iterations(1)) // Force that avoids circle overlapping
                .force('y', d3.forceY().y(function(d) {return 0;}))
                // .force('x', d3.forceX().x(function(d) { return xScale(d.value);}))
             simulation
                .nodes(this.items)
                .on("tick", function(d){
                    node
                        .attr("cx", function(d){ return d.x; })
                        .attr("cy", function(d){ return d.y; })
                });
    },
    methods:{
        get_tooltip(){
            return d3.select("#my_dataviz")
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "2px")
            .style("border-radius", "5px")
            .style("padding", "5px")
        },
        get_size(){
            return d3.scaleLinear()
                .domain([0, this.items[this.items.length-1].value + 2])
                .range([5,30])
        }
    }

}
</script>
<style scoped>
.node:hover{
  stroke-width: 7px !important;
  opacity: 1 !important;
}
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: top;
  overflow: hidden;
}
.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

svg .rect {
  fill: gold;
  stroke: steelblue;
  stroke-width: 5px;
}

</style>