<template>
  <div>
    <div class="partner-tenant-sign-up-student-content-container">
      <section class="partner-tenant-sign-up-student-description-section">
        <div class="partner-tenant-sign-up-student-description-text-container">
          <h3 class="partner-tenant-sign-up-student-description-title">
            Get ready to reach your academic and career potential.
          </h3>
          <p class="partner-tenant-sign-up-student-description-text">
            Join our community and get access to the opportunities
            and resources to support your success.
          </p>
        </div>
        <img class="partner-tenant-sign-up-student-description-image" src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/sign-in-student.webp" alt="sing up image"/>
      </section>
      <section class="partner-tenant-sign-up-student-form-section">
        <div class="partner-tenant-sign-up-student-sign-in-button-container">
          Already have an account?
          <router-link to="/accounts/login" class="partner-tenant-sign-up-student-sign-in-button">Sign In</router-link>
        </div>
        <form @submit.prevent="submit_form" class="partner-tenant-sign-up-student-form-container">
          <div class="partner-tenant-sign-up-student-form-title-text-container">
            <h3 v-if="!is_sso_form" class="partner-tenant-sign-up-student-form-title">
              Sign Up to BeGlobalii
            </h3>
            <h3 v-else class="partner-tenant-sign-up-student-form-title">
              Welcome to BeGlobalii
            </h3>
            <p class="partner-tenant-sign-up-student-description-text-dark mobile-only">
              Join our community and get access to the opportunities
              and resources to support your success.
            </p>
          </div>
          <div class="partner-tenant-sign-up-student-form-title-text-container">
            <p v-if="is_sso_form" class="partner-tenant-sign-up-student-description-text-dark">
              Please take a moment to complete your account.
            </p>
          </div>
          <div v-if="show_signin_buttons" class="partner-tenant-sign-up-student-sso-buttons-container" style="height: 2.75rem;">
            <div id="google_signup_button"></div>
            <a
              :href="linkedin_sso_redirect_uri"
            >
              <div class="partner-tenant-login-sso-button-linkedin">
                <div class='partner-tenant-login-sso-button-linkedin-ico-wrapper'>
                  <svg class="partner-tenant-login-sso-button-linkedin-ico" width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                      <g id="LinkedIn" sketch:type="MSArtboardGroup" fill="#0B78B7">
                        <g id="Fill-6-+-Fill-7" sketch:type="MSLayerGroup" transform="translate(6.000000, 5.000000)">
                          <path d="M3.44222222,0 C5.34,0 6.88,1.54111111 6.88,3.44 C6.88,5.34 5.34,6.88111111 3.44222222,6.88111111 C1.53666667,6.88111111 0,5.34 0,3.44 C0,1.54111111 1.53666667,0 3.44222222,0 L3.44222222,0 Z M0.471111111,9.48888889 L6.41,9.48888889 L6.41,28.5777778 L0.471111111,28.5777778 L0.471111111,9.48888889 Z"
                          id="Fill-6" sketch:type="MSShapeGroup"></path>
                          <path d="M10,9.47333333 L15.6866667,9.47333333 L15.6866667,12.0833333 L15.7688889,12.0833333 C16.56,10.5822222 18.4955556,9 21.3811111,9 C27.3888889,9 28.4988889,12.9522222 28.4988889,18.0933333 L28.4988889,28.5622222 L22.5666667,28.5622222 L22.5666667,19.2788889 C22.5666667,17.0655556 22.5288889,14.2177778 19.4844444,14.2177778 C16.3966667,14.2177778 15.9255556,16.63 15.9255556,19.1211111 L15.9255556,28.5622222 L10,28.5622222 L10,9.47333333"
                          id="Fill-7" sketch:type="MSShapeGroup"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                Sign in with LinkedIn
              </div>
            </a>
          </div>
          <div  v-if="show_signin_buttons" class="partner-tenant-sign-up-student-separator">
            <span class="partner-tenant-sign-up-student-separator-line"></span>
            <span class="partner-tenant-sign-up-student-separator-text">Or</span>
            <span class="partner-tenant-sign-up-student-separator-line"></span>
          </div>
          <div class="partner-tenant-sign-up-student-input-wrapper">
            <div class="partner-tenant-sign-up-student-input-label-wrapper">
              <label
                for="first_name"
                class="partner-tenant-sign-up-student-input-label"
                >First Name <span class="required">*</span>
              </label>
            </div>
            <input
              v-model="user_data.first_name"
              type="text"
              placeholder="First Name"
              class="partner-tenant-sign-up-student-input"
              id="first_name"
              :class="errors.first_name ? 'error' : ''"
            >
            <div v-if="errors.first_name" class="partner-tenant-login-input-error-message">
              {{errors.first_name}}
            </div>
          </div>
          <div class="partner-tenant-sign-up-student-input-wrapper">
            <div class="partner-tenant-sign-up-student-input-label-wrapper">
              <label
                for="last_name"
                class="partner-tenant-sign-up-student-input-label"
              >Last Name <span class="required">*</span></label>
            </div>
            <input
              v-model="user_data.last_name"
              type="text"
              placeholder="Last Name"
              class="partner-tenant-sign-up-student-input"
              :class="errors.last_name ? 'error' : ''"
              id="last_name"
            >
            <div v-if="errors.last_name" class="partner-tenant-sign-up-student-input-error-message">
              {{errors.last_name}}
            </div>
          </div>
          <div v-if="!is_sso_form" class="partner-tenant-sign-up-student-input-wrapper">
            <label
              for="email"
              class="partner-tenant-sign-up-student-input-label"
            >Email <span class="required">*</span></label>
            <input
              v-model="user_data.email"
              type="email"
              placeholder="Email"
              class="partner-tenant-sign-up-student-input"
              :class="errors.email ? 'error' : ''"
              id="email"
            >
            <div v-if="errors.email" class="partner-tenant-sign-up-student-input-error-message">
              {{errors.email}}
            </div>
          </div>
          <div v-if="!is_sso_form" class="partner-tenant-sign-up-student-input-wrapper">
            <label
              for="password"
              class="partner-tenant-sign-up-student-input-label"
            >Password <span class="required">*</span></label>
            <input
              v-model="user_data.password"
              type="password"
              placeholder="Password"
              class="partner-tenant-sign-up-student-input"
              :class="errors.password ? 'error': ''"
              id="password"
            >
            <div v-if="errors.password" class="partner-tenant-sign-up-student-input-error-message">
              {{errors.password}}
            </div>
          </div>
          <div v-if="!is_sso_form" class="partner-tenant-sign-up-student-input-wrapper">
            <label
              for="re-password"
              class="partner-tenant-sign-up-student-input-label"
            >Re-enter Password <span class="required">*</span></label>
            <input
              v-model="user_data.re_password"
              type="password"
              placeholder="Password"
              class="partner-tenant-sign-up-student-input"
              :class="errors.re_password ? 'error': ''"
              id="re-password"
            >
            <div v-if="errors.re_password" class="partner-tenant-sign-up-student-input-error-message">
              {{errors.re_password}}
            </div>
          </div>
          <div class="partner-tenant-sign-up-student-checkbox-container">
            <div class="partner-tenant-sign-up-student-checkbox-wrapper">
              <input
                v-model="user_data.isSubscribed"
                id="newsletter"
                type="checkbox"
                class="partner-tenant-sign-up-student-checkbox"
              >
              <label for="newsletter" class="partner-tenant-sign-up-student-checkbox-label">
                I'd like to hear about education and career opportunities
                from BeGlobalii partners.
              </label>
            </div>
            <div class="partner-tenant-sign-up-student-checkbox-wrapper">
              <input
                v-model="user_data.isAgreedTerms"
                id="terms"
                type="checkbox"
                class="partner-tenant-sign-up-student-checkbox"
                :class="errors.isAgreedTerms ? 'error': ''"
              >
              <label for="terms" class="partner-tenant-sign-up-student-checkbox-label">
                Creating an account means you’re okay with our
                <a href="/terms">
                  <u>Terms of Service</u>
                </a>
                and
                <a href="/privacy">
                  <u>Privacy Policy</u>
                </a>
                .
              </label>
            </div>
            <div v-if="errors.isAgreedTerms" class="partner-tenant-sign-up-student-input-error-message" style="position: relative; top: 0;">
              {{errors.isAgreedTerms}}
            </div>
          </div>
          <div>
            <button type="submit" class="partner-tenant-sign-up-student-submit-button">
              Create Account
            </button>
          </div>
        </form>
        <div class="partner-tenant-sign-up-student-sign-in-button-container mobile-only">
          Already have an account?
          <router-link to="/accounts/login" class="partner-tenant-sign-up-student-sign-in-button">Sign In</router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import PartnerService from '../../services/PartnerService';
import Cookies from 'js-cookie';
import { useNotificationStore } from '../../../stores/notification';
import { throwError } from '@syncfusion/ej2-base';
import AccountService from "../../services/AccountService";

export default {
  name: 'partner-tenant-sign-up-student',
  computed: {},
  props: [
    'google_client_id',
    'google_sso_redirect_uri',
    'google_sso_token',
    'linkedin_sso_redirect_uri',
    'linkedin_sso_token'
  ],
  data() {
    return {
      user_data: {
        'first_name': '',
        'last_name': '',
        'email': '',
        'password': '',
        're_password': '',
        'isSubscribed': false,
        'isAgreedTerms': false,
        'code': '',
      },
      errors: {
        'first_name': '',
        'last_name': '',
        'email': '',
        'password': '',
        're_password': '',
        'isSubscribed': '',
        'isAgreedTerms': '',
      },
      show_signin_buttons: true,
      is_sso_form: false,
      partnerService: null,
      notificationStore: useNotificationStore(),
      accountService: new AccountService(),
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      google_sso: false,
      linkedin_sso: false
    }
  },
  created() {
    this.partnerService = new PartnerService();
    if (this.google_sso_token){
      this.user_data.code = this.google_sso_token
      this.verifyGoogleSSOToken(this.user_data.code)
    }
  },
  mounted() {
    this.initializeGoogleSSO();
  },
  methods: {
    initializeGoogleSSO() {
      let script = document.createElement('script')
      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: this.google_client_id,
          ux_mode: 'redirect',
          login_uri: this.google_sso_redirect_uri,
        });
        window.google.accounts.id.renderButton(
          document.getElementById("google_signup_button"),
          { type: "standard", shape: "rectangular", theme: "filled_blue", size: "large" }
        );
      }
      script.async = true
      script.defer = true
      script.src = 'https://accounts.google.com/gsi/client'
      document.head.appendChild(script)
    },
    verifyGoogleSSOToken(code){
      this.errors.email = ''
      this.errors.email_validation = ''
      this.$Progress.start()
      this.accountService.verifyGoogleToken({code:code}, this.headers, true).then(verifyResponse => {
        if (verifyResponse.status === 'user_exist'){
          this.accountService.loginWithGoogleToken({code:code}, this.headers, true).then(loginResponse => {
            window.location.href = '/login'
            this.$Progress.finish()
          }).catch(error => {
            this.$Progress.fail();
          });
        }
        else if(verifyResponse.status === 'user_does_not_exist'){
          this.is_sso_form = true;
          this.show_signin_buttons = false;
          this.user_data.first_name = verifyResponse.social_profile_data.first_name;
          this.user_data.last_name = verifyResponse.social_profile_data.last_name;
        }
        else{
          this.$Progress.fail();
          this.showError('invalid_response');
        }
      }).catch(error => {
        this.$Progress.fail();
        this.showError(error.responseObject.error);
      });
    },
    verifyLinkedinSSOToken(token){
      this.errors.email = ''
      this.errors.email_validation = ''
      this.$Progress.start()
      this.accountService.verifyLinkedinToken({token:token}, this.headers, true).then(verifyResponse => {
        if (verifyResponse.status === 'user_exist'){
          this.accountService.loginWithLinkedinToken({token:token}, this.headers, true).then(loginResponse => {
            window.location.href = '/login'
            this.$Progress.finish()
          }).catch(error => {
            this.$Progress.fail();
          });
        }
        else if(verifyResponse.status === 'user_does_not_exist'){
          this.is_sso_form = true;
          this.show_signin_buttons = false;
          this.linkedin_sso = true,
          this.user_data.first_name = verifyResponse.social_profile_data.first_name;
          this.user_data.last_name = verifyResponse.social_profile_data.last_name;
        }
        else{
          this.$Progress.fail();
          this.showError('invalid_response');
        }
      }).catch(error => {
        this.$Progress.fail();
        this.showError(error.responseObject.error);
      });
    },
    validate_form() {
      if (!this.user_data.first_name) {
        this.errors.first_name = 'This field is required.';
      } else {
        this.errors.first_name = '';
      }

      if (!this.user_data.last_name) {
        this.errors.last_name = 'This field is required.';
      } else {
        this.errors.last_name = '';
      }

      if (!this.user_data.email) {
        this.errors.email = 'This field is required.';
      } else {
        this.errors.email = '';
      }

      if (!this.user_data.password) {
        this.errors.password = 'This field is required.';
      } else {
        this.errors.password = '';
      }

      if (this.user_data.password && !this.user_data.re_password) {
        this.errors.re_password = 'Please re-enter password.';
      } else if (
        this.user_data.password
        && this.user_data.re_password
        && this.user_data.password != this.user_data.re_password
      ) {
        this.errors.re_password = 'Passwords don\'t match.';
      } else {
        this.errors.re_password = '';
      }

      if (!this.user_data.isAgreedTerms) {
        this.errors.isAgreedTerms = 'Agreeing to Terms of Service and Privacy Policy is necessary for creating an account.';
      } else {
        this.errors.isAgreedTerms = '';
      }

      if (Object.values(this.errors).some(value => !!value)) {
        return false;
      }

      return true;
    },
    showError(error) {
      switch (error) {
        case 'validation':
          this.notificationStore.addNotification('Validation failed, please check the data and try again.', 10000);
          break;
        case 'email_already_exist':
          this.errors.email = 'This email is already in use.';
          break;
        case 'user_exist_with_another_channel':{
          this.errors.email = 'That email address is associated with an existing BeGlobalii account';
          break;
        }
        case 'terms_of_service':{
          this.errors.isAgreedTerms = 'Agreeing to Terms of Service and Privacy Policy is necessary for creating an account.';
          break;
        }
        case 'invalid_google_token':
          this.errors.email = 'There was an error processing your request, please try again. (TOKEN)';
          break;
        case 'invalid_response':
          this.errors.email = 'There was an error processing your request, please try again.';
          break;
        case 'invalid_linkedin_token':
          this.errors.email = 'There was an error processing your request, please try again. (TOKEN)';
          break;
        default:
          this.errors.password = 'Email or password you provided is invalid.';
          break;
      }
    },
    async submit_form () {
      this.$Progress.start();
      if (this.is_sso_form){
        if (this.google_sso){
          this.accountService.registerAndLoginWithGoogleToken({new_account:true,...this.user_data}, this.headers, true).then(loginResponse => {
          this.$Progress.finish()
          window.location.href = '/login'
        }).catch(error => {
          this.$Progress.fail();
          this.showError(error.responseObject.error);
        });
        }
        else{
          this.accountService.registerAndLoginWithLinkedinToken({new_account:true,...this.user_data}, this.headers, true).then(loginResponse => {
            this.$Progress.finish()
            window.location.href = '/login'
          }).catch(error => {
            this.$Progress.fail();
            this.showError(error.responseObject.error);
          });
        }
      }
      else{
        if (this.validate_form()) {
          this.partnerService.signUpStudent(this.user_data, this.headers, true).then(res => {
            this.notificationStore.addNotification('Account successfully created. Please check your email inbox and verify your account.', 10000);
            this.$router.push("/accounts/login");
          }).catch(error => {
            this.showError(error.responseObject.error);
            this.$Progress.fail();
          });
        } else {
          this.$Progress.fail();
        }
      }
    },
  }
}
</script>
