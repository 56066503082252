import HttpService from "../../services/HttpService";


export default class ProgramService extends HttpService {
    constructor() {
      super('/programs/api');
    }

    //TODO remove recurring attribute once backend is implemented
    //together with promise placeholder
    create(payload: Object, headers: object): Promise<any> {
      this.endpoint = '/programs/api';
      return this.post(payload, headers);
    }

    remove(id, headers: Object): Promise<any> {
      this.endpoint = '/programs/api/' + id;
      return this.delete({},headers)
    }

}
