import BulkUploadService from "../../services/BulkUploadService";

export async function manualImportUser(params: {
    studentObject: {
        first_name : string,
        last_name: string,
        email: string,
        role: string,
        department: string
    },
    sendInvite: boolean,
    isSSO: boolean,
    is_edit_user: boolean,
    disabled: boolean,
  },
  service: BulkUploadService,
  headers: any
  ): Promise<any> {
    const requestObject = {
      user: JSON.stringify(params.studentObject),
      import_type: 'MANUAL',
      send_invite: params.sendInvite,
      is_sso: params.isSSO,
      is_edit_user: params.is_edit_user,
      disabled: params.disabled,
    }

    const result = await service.postFormData(
      requestObject,
      headers,
    );

    return result;
}
