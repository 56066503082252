<template>
    <analytics-card
        :key="data.title"
        :typeFlag="typeFlag"
        :flag="typeOfCards"
        :typeOfCards="data.title"
    >
        <template #header>
            <analytics-card-header
                :title="data.title"
                :icon="data.icon"
                :color="data.color"
                :isImg="data.isImg" 
                :has_icon="data.has_icon"
                :url="data.url"
                @clicked="show"
            />
        </template>
        <template #body>
            <analytics-body-card-fetch-data :keys="data.cards" :params_mentor="params"/>
            <analytics-card-table-fetch-data :keys="data.cards" :params_mentor="params"/>
        </template>   
        <template #footer> 
            <!-- <p style="max-width: fit-content; margin-left: auto; margin-right: auto;">
                This is where I would put some pagination, if I had some.
            </p> -->
        </template>       
    </analytics-card>
</template>
<script lang="ts">
import AnalyticsCard from "./AnalyticsCard.vue";
import AnalyticsCardHeader from "./AnalyticsCardHeader.vue";
import AnalyticsBodyCardFetchData from './AnalyticsBodyCardFetchData.vue'
import AnalyticsCardTableFetchData from './AnalyticsCardTableFetchData.vue'

export default ({
    name: "mentor-meetings-analytics-component",
    props:['mentorId'],
    components: {
        AnalyticsCard,
        AnalyticsCardHeader,
        AnalyticsBodyCardFetchData,
        AnalyticsCardTableFetchData,
    },

    data() {
        return {
            typeFlag: '',
            typeOfCards: '',

            data: {
                cards: "mentor_meetings",
                title: "Appointment Analytics",
                icon: "fas fa-analytics",
                color: "#cf8e7d",
                isImg: false,
                has_icon: true,
                url: "",
            },

            params: {
                mentorId: 0,
            }
        };
    },
    created(){
        this.params.mentorId = this.mentorId;
    },
    methods: {

        show(typeOfCards: string, typeFlag: string ) {
            this.typeOfCards = typeOfCards;
            this.typeFlag = typeFlag;
        },
    },
})
</script>
