<template>
  <section class="partner-tenant-home-testimonials-container">
    <div class="partner-tenant-home-testimonials-text-container">
      <h2 class="partner-tenant-home-testimonials-title">Testimonials</h2>
      <p class="partner-tenant-home-testimonials-description">
        See how our students and professionals
        <b>get connected</b> with BeGlobalii
      </p>
    </div>
    <div class="partner-tenant-home-testimonials-cards-container">
      <div class="partner-tenant-home-testimonials-card-container-back"></div>
      <div class="partner-tenant-home-testimonials-card-container-mid"></div>
      <div class="partner-tenant-home-testimonials-card-container-front">
        <div class="partner-tenant-home-testimonials-card-dots-container">
          <slider-dots :totalNo="2" :initialSelectedNo="1" @changeSelected="changeTestimonial" :switchSeconds="7" />
        </div>
        <div class="partner-tenant-home-testimonials-card-title">
          <div class="partner-tenant-home-testimonials-mobile-image-placeholder"></div>
          “{{testimonialSteps[testimonialStep-1]['heading']}}
        </div>
        <p class="partner-tenant-home-testimonials-card-description">{{testimonialSteps[testimonialStep-1]['rest']}}”</p>
        <p class="partner-tenant-home-testimonials-card-author">{{testimonialSteps[testimonialStep-1]['author']}}</p>
      </div>
      <div class="partner-tenant-home-testimonials-picture-container" :style="`background-image: url(${testimonialSteps[testimonialStep-1]['authorImg']});`">
        <!-- <div class="partner-tenant-home-testimonials-picture-tint"></div> -->
        <!-- <div class="partner-tenant-home-testimonials-author-name">{{testimonialSteps[testimonialStep-1]['author']}}</div>
        <div class="partner-tenant-home-testimonials-author-title">{{testimonialSteps[testimonialStep-1]['authorTitle1']}}</div>
        <div class="partner-tenant-home-testimonials-author-subtitle">{{testimonialSteps[testimonialStep-1]['authorTitle2']}}</div> -->
      </div>
    </div>
  </section>
</template>
<script>
import SliderDots from "./SliderDots.vue";
export default {
  name: "partner-tenant-testimonials",
  components: {
    SliderDots,
  },
  data() {
    return {
      testimonialStep: 1,
      testimonialSteps: [
        {
          heading: "The whole journey with GILC really helped me get in touch with myself more than anything.",
          rest: "We are living in a highly globalized world, so it is important to empower each other and connect by empathizing with each and everyone’s lived experiences. My journey with GILC was both healing and eye-opening.",
          authorImg: `${process.env.MEDIA_URL}assets/TestimonialAuthor1.jpeg`,
          author: "Hazel Aung",
          authorTitle1: "University of Auckland",
          authorTitle2: "Fall 2022 GILC Cohort"
        },
        { heading: "BeGlobalii’s platform is amazing! There are so many resources for anyone who is interested in going abroad.",
          rest: "They offer expert advice about how you can get yourself abroad and afford to go abroad. Not only can you get advice, you can also get involved.",
          authorImg: `${process.env.MEDIA_URL}assets/TestimonialAuthor2.jpeg`,
          author: "Joi Wade",
          authorTitle1: "Southern California Uni.",
          authorTitle2: "Diversity Abroad Influencer" },
      ],
    };
  },
  methods: {
    changeTestimonial(stepNo) {
      this.testimonialStep = stepNo;
    },
  },
};
</script>
